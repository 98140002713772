
import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "../AuthHelper";

const getToken = () => {
    const persistedRoot = localStorage.getItem("persist:root");
    if (!persistedRoot) return null;
    const persistedRootObj = JSON.parse(persistedRoot);
    const userObj = persistedRootObj?.user
        ? JSON.parse(persistedRootObj.user)
        : null;
    if (userObj !== undefined && userObj !== null) {
        localStorage.setItem('Token', userObj?.user?.token);
        return userObj?.user?.token || null;
    }
};

export const apiSlice = createApi({
    reducerPath: "apiSlice",
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        userProfile: builder.mutation({
            query: (newUser) => ({
                url: "profile",
                method: "PUT",
                body: newUser,
            }),
            invalidatesTags: ["User"],
        }),
        addProperty: builder.mutation({
            query: (newProperty) => ({
                url: "add/property",
                method: "POST",
                body: newProperty,
            }),
            invalidatesTags: ["Property"],
        }),
        addGroup: builder.mutation({
            query: (newProperty) => {
                return {
                    url: "groups",
                    method: "POST",
                    body: newProperty,
                };
            },
            invalidatesTags: ["Group"],
        }),
        addUtility: builder.mutation({
            query: (newProperty) => ({
                url: `add/provider`,
                method: "POST",
                body: newProperty,
            }),
            invalidatesTags: ["Provider"],
        }),
        addCompany: builder.mutation({
            query: (newProperty) => ({
                url: `add/company`,
                method: "POST",
                body: newProperty,
            }),
            invalidatesTags: ["Company"],
        }),
        addField: builder.mutation({
            query: (newProperty) => ({
                url: `add/field`,
                method: "POST",
                body: newProperty,
            }),
            invalidatesTags: ["Field"],
        }),
        addPropertyProvider: builder.mutation({
            query: (newProperty) => ({
                url: "property/provider",
                method: "POST",
                body: newProperty,
            }),
            invalidatesTags: ["Propertyprovider"],
        }),
        addIncome: builder.mutation({
            query: (income) => ({
                url: "add/income",
                method: "POST",
                body: income,
            }),
            invalidatesTags: ["Income"],
        }),
        addCategory: builder.mutation({
            query: (category) => ({
                url: "add/category",
                method: "POST",
                body: category,
            }),
            invalidatesTags: ["Category"],
        }),
        changePassword: builder.mutation({
            query: (password) => ({
                url: "change/password",
                method: "POST",
                body: password,
            }),
            invalidatesTags: ["Password"],
        }),
        subAccount: builder.mutation({
            query: (data) => ({
                url: "sub_account",
                method: "POST",
                body: data,
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                },
            }),
            //     invalidatesTags: ['SubAccount'],
        }),
        // setting: builder.mutation({
        //     query: ({ data, id }) => ({
        //         url: `setting/${id}`,
        //         method: "POST",
        //         body: data,
        //     }),
        //     invalidatesTags: ["Setting"],
        // }),
        deletePropertyProvider: builder.mutation({
            query: (data) => ({
                url: `property-provider/${data}`,
                method: "DELETE",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                },
            }),
            invalidatesTags: ["Delete"],
        }),
        editPropertyProvider: builder.mutation({
            query: ({ data, id }) => {
                return {
                    url: `property-provider/${id}`,
                    method: "PUT",
                    body: data,
                };
            },
            invalidatesTags: ["Edit"],
        }),
        editProperty: builder.mutation({
            query: ({ data, id }) => {
                return {
                    url: `properties/${id}`,
                    method: "PUT",
                    body: data,
                };
            },
            invalidatesTags: ["SubAccount"],
        }),
        setting: builder.mutation({
            query: ({ data, id }) => ({
                url: `setting/${id}`,
                method: "POST",
                body: data, 
                formData: true,
            }),
            invalidatesTags: ["Setting"],
        }),        
        deletePropertyProvider: builder.mutation({
            query: (data) => ({
                url: `property-provider/${data}`,
                method: "DELETE",
            }),
            invalidatesTags: ["Delete"],
        }),
        editPropertyProvider: builder.mutation({
            query: ({ data, id }) => ({
                url: `property-provider/${id}`,
                method: "PUT",
                body: data,
            }),
            invalidatesTags: ["Edit"],
        }),
        editProperty: builder.mutation({
            query: ({ data, id }) => {
                return {
                    url: `properties/${id}`,
                    method: "PUT",
                    body: data,
                };
            },
            invalidatesTags: ["EditProperty"],
        }),
        deleteProperty: builder.mutation({
            query: (id) => ({
                url: `properties/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["DeleteProperty"],
        }),
        editIncome: builder.mutation({
            query: ({ data, id }) => ({
                url: `incomes/${id}`,
                method: "PUT",
                body: data,
            }),
            invalidatesTags: ["EditIncome"],
        }),
        deleteIncome: builder.mutation({
            query: (id) => ({
                url: `incomes/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["DeleteIncome"],
        }),
        editGroup: builder.mutation({
            query: ({ data, id }) => ({
                url: `groups/${id}`,
                method: "PUT",
                body: data,
            }),
            invalidatesTags: ["EditGroup"],
        }),
        deleteGroup: builder.mutation({
            query: (id) => ({
                url: `groups/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["DeleteGroup"],
        }),
        addPropertyType: builder.mutation({
            query: (data) => ({
                url: `add/property_type`,
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["AddPropertyType"],
        }),
        addOwnerType: builder.mutation({
            query: (data) => ({
                url: `ownership_type`,
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["AddOwnershipType"],
        }),
        editSubAccount: builder.mutation({
            query: ({ data, id }) => ({
                url: `sub-account/${id}`,
                method: "PUT",
                body: data,
            }),
            invalidatesTags: ["EditSubAccount"],
        }),

        logout: builder.mutation({
            query: () => {
                return {
                    url: 'logout',
                    method: 'POST',
                }
            },
            invalidatesTags: ["Logout"],
        }),


        deleteField: builder.mutation({
            query: (id) => ({
                url: `fields/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["DeleteField"],
        }),
        monthlyIncome: builder.mutation({
            query: (data) => {
                if (data) {
                    return {
                        url: `monthly-income`,
                        method: "POST",
                        body: data
                    }
                }
            },
            invalidatesTags: ["MonthlyIncome"],
        }),
        monthlyExpense: builder.mutation({
            query: (data) => {
                return {
                    url: `monthly-expense`,
                    method: "GET",
                }
            },
            invalidatesTags: ["MonthlyExpense"],
        }),
        unassignProperty: builder.mutation({
            query: ({ userId, recordId }) => ({
                url: `user/${userId}/unassign-property/${recordId}`,
                method: "DELETE",
            }),
            invalidatesTags: ["UnassignProperty"],
        }),
        deactivateUser: builder.mutation({
            query: (id) => ({
                url: `user/deactivate/${id}`,
                method: "POST",
            }),
            invalidatesTags: ["DeactivateUser"],
        }),
        updateLabels: builder.mutation({
            query: ({ label, value }) => ({
                url: `form-label/${label}`,
                method: "POST",
                body: value,
            }),
            invalidatesTags: ["updateLabel"],
        }),
        addRecurringData: builder.mutation({
            query: (value) => ({
                url: `property/provider/recurring`,
                method: "POST",
                body: value,
            }),
            invalidatesTags: ["addRecurring"],
        }),
        addTransaction: builder.mutation({
            query: () => ({
                url: `plaid/Transactions`,
                method: "POST",

            }),
            invalidatesTags: ["addTransaction"],
        }),
        addRecurringTransaction: builder.mutation({
            query: () => ({
                url: `import-recuring`,
                method: "POST",

            }),
            invalidatesTags: ["addRecurringTransaction"],
        }),
        addAccessToken: builder.mutation({
            query: (data) => ({
                url: `set_access_token`,
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["addAccessToken"],
        }),

        getPropertyProviderById: builder.mutation({
            query: (data) => ({
                url: `property/provider/${data}`,
                method: "GET"
            }),
            invalidatesTags: ["ViewPropertyProviderDetails"],
        }),

        deleteExpenseById: builder.mutation({
            query: (data) => ({
                url: `expense/${data}`,
                method: "DELETE"
            }),
            invalidatesTags: ["deleteExpense"],
        }),

        editExpenseById: builder.mutation({
            query: (data) => ({
                url: `update_expense`,
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["editExpense"],
        }),

        getDetailsByCmpanyAndProvider: builder.mutation({
            query: ({ provider_id, company_id }) => ({
                url: `provider/${provider_id}/company/${company_id}`,
                method: "GET",
            }),
            invalidatesTags: ["detailsByCompany&Provider"],
        }),

        createNewCompany: builder.mutation({
            query: (data) => ({
                url: `save_company`,
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["addNewCompany"],
        }),

        deleteNewCompany: builder.mutation({
            query: (data) => ({
                url: `delete_company/${data}`,
                method: "DELETE",
            }),
            invalidatesTags: ["deleteCompany"],
        }),

        updateCompany: builder.mutation({
            query: (data) => ({
                url: `update_company/${data.id}`,
                method: "POST",
                body: data.value,
            }),
            invalidatesTags: ["updateCompany"],
        }),

        getCategoryListing: builder.mutation({
            query: (data) => {
              let queryString = "";

              if (data?.transaction_id) {
                queryString = `?transaction_id=${data.transaction_id}`;  
              }
              
              const { transaction_id, ...bodyData } = data;
              
              return {
                url: `categories${queryString}`,  
                method: "POST",
                body: bodyData,  
              };
            },
            invalidatesTags: ["getNewCategories"],
          }),
          
        addNewExpense: builder.mutation({
            query: (data) => ({
                url: `add_expense`,
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["addExpense"],
        }),
        deleteExpenseNew: builder.mutation({
            query: (data) => ({
                url: `delete_expense/${data}`,
                method: "DELETE",
            }),
            invalidatesTags: ["deleteExpense"]
        }),
        deleteExpenseNew: builder.mutation({
            query: (data) => ({
                url: `delete_expense/${data}`,
                method: "DELETE",
            }),
            invalidatesTags: ["deleteExpense"]
        }),
        addIncomeType: builder.mutation({
            query: (data) => ({
                url: `add/payment_type`,
                method: "POST",
                body:data
            }),
            invalidatesTags: ["incomeType"]
        }),
        importMerchantData: builder.mutation({
            query: (data) => ({
              url: '/import_merchants',
              method: 'POST',
              body: data,
            }),
            invalidatesTags: ['importMerchant'],
          }),

          addPaymentMethod: builder.mutation({
            query: (data) => ({
              url: '/payment-modes',
              method: 'POST',
              body: data,  
            }),
            invalidatesTags: ['PaymentMethods'], 
          }),

          updateRecurring: builder.mutation({
            query: (data) => ({
              url: "/update_recurring_status",
              method: "POST",
              body: data,
            }),
            invalidatesTags: ["RecurringPayment"],
          }),

          removeTransaction: builder.mutation({
            query: (data) => ({
              url: "/remove_transaction",
              method: "POST",
              body: data,
            }),
            invalidatesTags: ["RemoveTransaction"],
          }),
        
          walkThroughUpdate: builder.mutation({
            query: (data) => ({
              url: "/walkthrough_update",
              method: "POST",
              body: data,
            }),
            invalidatesTags: ["walkthroughUpdate"],
          }),
    
          ActivityLogs: builder.mutation({
            query: (data) => ({
              url: `/activity-logs/${data}`,
              method: "GET",
            }),
            invalidatesTags: ["ActivityLogs"],
          }),

          DeleteCompanyWithExpense: builder.mutation({
            query: (companyId) => ({
              url: `/deleteCompanyExpense?company_id=${companyId}`,
              method: "DELETE",
            }),
            invalidatesTags: ["DeleteCompanyWithExpense"],
          }),          

    }),

    tagTypes: [
        "User",
        "Property",
        "Group",
        "Propertyprovider",
        "Provider",
        "Company",
        "Field",
        "Income",
        "Category",
        "Password",
        "SubAccount",
        "Setting",
        "Delete",
        "Edit",
        "EditProperty",
        "EditIncome",
        "DeleteIncome",
        "EditGroup",
        "DeletGroup",
        "AddPropertyType",
        "AddOwnershipType",
        "EditSubAccount",
        "Logout",
        "DeleteField",
        "MonthlyIncome",
        "MonthlyExpense",
        "UnassignProperty",
        "DeactivateUser",
        "CategorizePlaid",
        "updateLabel",
        "addRecurring",
        "addTransaction",
        "addRecurringTransaction",
        "addAccessToken",
        "ViewPropertyProviderDetails",
        "deleteExpense",
        "editExpense",
        "detailsByCompany&Provider",
        "addNewCompany",
        "deleteCompany",
        "updateCompany",
        "getNewCategories",
        "addExpense",
        "deleteExpense",
        "importMerchant",
        "PaymentMethods",
        "RecurringPayment",
        "RemoveTransaction",
        "walkthroughUpdate",
        "ActivityLogs",
        "DeleteCompanyWithExpense"
    ],
});

export const {
    useUserProfileMutation,
    useAddPropertyMutation,
    useAddGroupMutation,
    useAddPropertyProviderMutation,
    useAddUtilityMutation,
    useAddCompanyMutation,
    useAddFieldMutation,
    useAddIncomeMutation,
    useAddCategoryMutation,
    useChangePasswordMutation,
    useSubAccountMutation,
    useSettingMutation,
    useDeletePropertyProviderMutation,
    useEditPropertyProviderMutation,
    useEditPropertyMutation,
    useDeletePropertyMutation,
    useEditIncomeMutation,
    useDeleteIncomeMutation,
    useEditGroupMutation,
    useDeleteGroupMutation,
    useAddPropertyTypeMutation,
    useAddOwnerTypeMutation,
    useEditSubAccountMutation,
    useLogoutMutation,
    useDeleteFieldMutation,
    useMonthlyExpenseMutation,
    useMonthlyIncomeMutation,
    useUnassignPropertyMutation,
    useDeactivateUserMutation,
    useUpdateLabelsMutation,
    useAddRecurringDataMutation,
    useAddTransactionMutation,
    useAddRecurringTransactionMutation,
    useAddAccessTokenMutation,
    useGetPropertyProviderByIdMutation,
    useDeleteExpenseByIdMutation,
    useEditExpenseByIdMutation,
    useGetDetailsByCmpanyAndProviderMutation,
    useCreateNewCompanyMutation,
    useDeleteNewCompanyMutation,
    useUpdateCompanyMutation,
    useGetCategoryListingMutation,
    useAddNewExpenseMutation,
    useDeleteExpenseNewMutation, 
    useAddIncomeTypeMutation, 
    useImportMerchantDataMutation,
    useAddPaymentMethodMutation,
    useUpdateRecurringMutation,
    useRemoveTransactionMutation,
    useWalkThroughUpdateMutation,
    useActivityLogsMutation,
    useDeleteCompanyWithExpenseMutation
} = apiSlice;
