import React, { useEffect, useState } from "react";
import { Table, Spin, Select, Tooltip, Button, Modal, Form, Input, Pagination, Switch, Typography } from "antd";
import Title from "../../component/PageTitle";
import { useSelector } from "react-redux";
import { useGetPropertiesQuery, useGetUtilityCategoryQuery, useGetAllExpenseQuery } from "../../slices/getSlice";
import { useOutletContext, useNavigate } from "react-router-dom";
import { FaEdit } from "react-icons/fa";
import { IoIosAddCircleOutline } from "react-icons/io";
import {
    useGetCategoryListingMutation,
    useUpdateRecurringMutation,
    useActivityLogsMutation,
    useDeleteExpenseNewMutation
} from "../../slices/apiSlice";
import TopHeader from "../../component/Header";
import { HomeOutlined } from '@ant-design/icons';
import { Breadcrumb } from 'antd';
import PageSizeOptionsHelper from "../../Helper/PageSizeOptionsHelper";
import dayjs from 'dayjs';
import { InfoCircleOutlined } from "@ant-design/icons";
import swal from "sweetalert";
import { toast } from "react-toastify";
import { BsBuildingCheck } from "react-icons/bs";
import { FaUsersCog } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { setSelectedPropertyData } from "../../slices/PropertySlice";
import { useDispatch } from "react-redux";
import { saveAs } from 'file-saver';
import { GrDocumentCsv } from "react-icons/gr";
import { HiOutlineDocumentText } from "react-icons/hi";
import { RxCrossCircled } from "react-icons/rx";
import { HiOutlineTrash } from "react-icons/hi2";

const Expesne = () => {
    const currencyGlobal = useSelector((state) => state?.persistedReducer?.currency)
    const { loading, setLoading } = useOutletContext();
    const [selectedUtility, setSelectedUtility] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const navigate = useNavigate();
    const [properties, setProperties] = useState();
    const [pageSize, setPageSize] = useState(20);
    const [exportPageSize, setExportPageSize] = useState(null)
    const [utilityCategory, setUtilityCategory] = useState();
    const [isExpenseModal, setIsExpenseModal] = useState(false);
    const colors = useSelector((state) => state?.persistedReducer?.colors);
    const [getCategoryListing, { data }] = useGetCategoryListingMutation();
    const [categoryData, setCategoryData] = useState(null);
    const [localLoading, setLocalLoading] = useState(false)
    const [tableSortBy, setTableSortBy] = useState(null);
    const [columnName, setColumnName] = useState(null);
    const [form] = Form.useForm();
    const startDate = useSelector((state) => state.date.startDate);
    const endDate = useSelector((state) => state.date.endDate);
    const selectedProperties = useSelector((state) => state.Property.selectedProperties);
    const [isFetchingData, setIsFetchingData] = useState(false);
    const [exportLoader, setExportLoader] = useState(false);
    const [updateRecurring, { isLoading: loadingRecurring }] = useUpdateRecurringMutation();
    const [getActivityLogs, { isLoading: loadingActivity }] = useActivityLogsMutation();
    const dispatch = useDispatch();
    const location = useLocation();
    const filterData = location.state;
    const [tableData, setTableData] = useState([]);
    const [debouncedSearch, setDebouncedSearch] = useState("");
    const [searchInput, setSearchInput] = useState("");
    const [ActivityModel, setActivityModel] = useState(false);
    const [ActivityModelData, setActivityModelData] = useState([]);
    const currency = currencyGlobal ? currencyGlobal : '';
    const [deleteExpense, { isLoading: isLoadingDelete }] = useDeleteExpenseNewMutation();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState("");

    const { data: getAllExpense, isLoading, isError, refetch } = useGetAllExpenseQuery({
        property: Array.isArray(selectedProperties?.selectedProperty)
            ? (selectedProperties.selectedProperty.length > 0
                ? selectedProperties.selectedProperty
                : undefined)
            : selectedProperties?.selectedProperty,
        provider_type_id: selectedUtility,
        category_id: selectedCategory,
        page: debouncedSearch || (selectedProperties?.selectedProperty?.length > 0) ? 1 : currentPage, // Reset to 1 if filtering
        limit: pageSize,
        search: debouncedSearch,
        perPage: exportPageSize ? exportPageSize : pageSize,
        sort_by: columnName,
        sort_direction: tableSortBy,
        startDate: startDate,
        endDate: endDate,
    });

    useEffect(() => {
        if (filterData) {
            dispatch(
                setSelectedPropertyData({
                    selectedProperty: filterData?.id,
                })
            );
        }
    }, []);

    useEffect(() => {
        if (data) {
            setCategoryData(data?.categories);
        }
    }, [data]);

    const { data: getProperties, refetch: refetchProperties } = useGetPropertiesQuery();

    const {
        data: getUtilityCategory = [],
    } = useGetUtilityCategoryQuery();

    useEffect(() => {
        refetchProperties();
    }, [refetchProperties]);

    const pageSizeOptions = PageSizeOptionsHelper(getAllExpense?.meta?.total);

    const fetchData = async () => {
        setLocalLoading(true);
        setTableData([]);
        try {
            const response = await refetch();
            setTableData(response?.data?.data || []);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLocalLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [debouncedSearch, selectedUtility, selectedProperties, startDate, endDate, currentPage, pageSize, columnName, tableSortBy]);

    useEffect(() => {
        if (getProperties && getProperties?.data) {
            const transformedOptions = getProperties?.data?.map((item) => ({
                label: item.name_of_property,
                value: item.id,
            }));
            setLoading(false)
            setProperties(transformedOptions);
        }

        if (getUtilityCategory && getUtilityCategory?.data) {
            const transformedOptions = getUtilityCategory?.data?.map((item) => ({
                label: item.name,
                value: item.id,
            }));
            setUtilityCategory(transformedOptions);
        }
    }, [getProperties, getUtilityCategory]);

    const viewExpense = (data) => {
        navigate("/view_property", {
            state: { propertyData: data },
        });
    }

    const editExpense = (data) => {
        navigate("/property_details", {
            state: {
                data: data,
                providerType: data?.provider_type_id,
                providerTypeName: data?.providerType,
                propertyData: data?.property_data,
                isEdit: true,
                activePropertyID: data?.provider_company_id
            },
        });
    }

    const handleToggleChange = (checked, record) => {
        swal({
            title: "Are you sure?",
            text: `If you turn this ${checked ? "on" : "off"}, recurring expense will ${checked ? "automatically be added to this property again." : "no longer be added to this property"} .`,
            dangerMode: true,
            buttons: {
                confirm: {
                    text: "Yes, confirm!",
                    value: true,
                    visible: true,
                    className: "custom-confirm-btn",
                    closeModal: true,
                },
                cancel: {
                    text: "Cancel",
                    value: null,
                    visible: true,
                    className: "custom-cancel-btn",
                    closeModal: true,
                },
            },
        }).then(async (willConfirm) => {
            if (willConfirm) {
                setLocalLoading(true);
                let formData = {
                    type: "expense",
                    id: record?.expense_id,
                    is_recurring_on: checked ? 1 : 0
                };

                try {
                    let changeStatus = await updateRecurring(formData);
                    setTableData([]);
                    if (changeStatus?.error) {
                        toast.error(changeStatus?.error?.message || "Failed to update status.");
                    } else {
                        toast.success(changeStatus?.data?.message || "Status updated successfully.");

                        refetch().then((updatedData) => {
                            if (updatedData?.data) {
                                setTableData(updatedData?.data?.data);
                                setLocalLoading(false);
                            }
                        });
                    }
                } catch (error) {
                    toast.error("Something went wrong! Please try again.");
                    console.error("API Error:", error);
                }
            }
        });
    };

    const handleLogClick = async (ID) => {
        if (!ID) {
            console.error("Invalid ID");
            return;
        }

        setActivityModel(true);

        try {
            let activityData = await getActivityLogs(ID);

            if (activityData && activityData.data && activityData.data.data) {
                setActivityModelData(activityData.data.data);
            } else {
                console.error("Invalid response data format:", activityData);
            }
        } catch (error) {
            console.error("Error fetching activity logs:", error);
        }
    };

    const handleDelete = async () => {
        try {
            if (!selectedRecord) {
                throw new Error("Invalid data: Missing required fields.");
            }

            const res = await deleteExpense(selectedRecord);
            if (res?.data) {
                toast.success(res?.data?.message);
                setIsModalOpen(false);
                fetchData();
            } else {
                toast.error('Failed to delete the expense! Please try again');
            }
        } catch (error) {
            console.error("An error occurred while deleting the expense:", error.message);
        }
    }

    const Columns = [
        {
            title: "S.No.",
            dataIndex: "serial",
            render: (_, record, index) => (
                <span style={{ display: "flex", alignItems: "center", gap: "5px", justifyContent: 'flex-start' }}>
                    {(currentPage - 1) * pageSize + index + 1} {" "}
                    <span className="plaidLogoExpense">
                        {record?.from_plaid && 'Plaid'}
                    </span>
                </span>
            )
        },
        {
            title: "Property Name",
            dataIndex: "name_of_property",
            render: (_, record) => (
                <>
                    <div onClick={() => viewExpense(record)} style={{ cursor: 'pointer' }}>
                        {record?.property}
                    </div>
                </>
            ),
            sorter: true,
        },
        {
            title: "Provider Type",
            dataIndex: "providerType",
            render: (_, record) => {
                return record?.providerType
            },
            sorter: true,
        },
        {
            title: "Category",
            dataIndex: "category",
            render: (_, record) => (
                <>
                    {record?.parent_category + ' - ' + record?.category}
                </>
            ),
            sorter: (a, b) => a.category.localeCompare(b.category),
        },
        {
            title: "Company",
            dataIndex: "company",
            render: (_, record) => {
                return record?.company
            },
            sorter: true,
        },
        {
            title: "Amount",
            dataIndex: "amount",
            render: (value) => new Intl.NumberFormat('en-US', { style: 'currency', currency: currencyGlobal?.currency_name === "Indian Rupee" ? 'INR' : 'USD' }).format(value),
            sorter: true,
        },
        {
            title: "Payment Date",
            dataIndex: "payment_date",
            render: (payment_date) => dayjs(payment_date).format("MM-DD-YYYY"),
            sorter: true,
        },
        {
            title: "Payment Type",
            dataIndex: "payment_type_name",
            render: (_, record) => {
                return record?.payment_type_name
            },
            sorter: true,
        },
        {
            title: (
                <>
                    Status
                    <Tooltip title="Turn on/off recurring payment" className="ms-2">
                        <InfoCircleOutlined className="infoIcon" />
                    </Tooltip>
                </>
            ),
            dataIndex: "status",
            render: (_, record) => (
                <>
                    {record?.parent_id === null && record?.payment_type_name === "Recurring" && (
                        <div className="statusWrapper">
                            <Switch
                                checked={record.is_recurring_on ? true : false}
                                onChange={(checked) => handleToggleChange(checked, record)}
                                className="toggleSwitch"
                            />
                        </div>
                    )}
                </>
            ),
        },
        {
            title: "View / Edit",
            dataIndex: "action",
            render: (_, record) => {
                return (
                    <div className="action-container">
                        <Tooltip title="Edit">
                            <button
                                className="btn editButton me-2"
                                onClick={() => editExpense(record)}
                            >
                                <FaEdit className="iconStroke" />
                            </button>
                        </Tooltip>
                        <Tooltip title="View Activity Logs">
                            <button
                                className="btn logButton me-2"
                                onClick={() => {
                                    handleLogClick(record?.expense_id);
                                }}
                            >
                                <HiOutlineDocumentText />
                            </button>
                        </Tooltip>
                        <Tooltip title="Delete Record">
                            <button
                                className="btn deleteButton me-2"
                                onClick={() => {
                                    setSelectedRecord(record?.id);
                                    setIsModalOpen(true);
                                }}
                            >
                                <HiOutlineTrash />
                            </button>
                        </Tooltip>
                    </div>

                );
            },
        },
    ];

    const handleCancelLogModel = () => {
        setActivityModel(false);
        setActivityModelData([]);
    }

    const openExpenseModel = () => {
        setIsExpenseModal(true);
    }

    const handleCancel = () => {
        setIsExpenseModal(false);
    }

    const addNewExpense = (value) => {
        let findProperty = getProperties?.data?.find((item) => item?.id === value?.property_id);
        let findProvider = utilityCategory?.find((item) => item?.value === value?.provider_id);
        let detail = {
            id: findProvider?.value,
            name: findProvider?.label
        }
        navigate("/property_details", {
            state: {
                data: detail,
                propertyData: findProperty,
                propertyid: value?.property_id,
                propertyName: findProperty?.label,
                isEdit: false
            },
        });
    }

    const handlePropertyValueChange = (value) => {
        form.setFieldsValue({ property_id: value });
        form.validateFields(['property_id']);
    };

    const handleUtilityValueChange = (value) => {
        form.setFieldsValue({ provider_id: value });
        form.validateFields(['provider_id']);
    };

    let totalExpense = getAllExpense?.total_expense;

    const handleSearchChange = (e) => {
        setSearchInput(e.target.value);
    };

    useEffect(() => {
        if (searchInput !== "" && searchInput !== undefined && searchInput !== null) {
            const handler = setTimeout(() => {
                setDebouncedSearch(searchInput);
            }, 1500);

            return () => clearTimeout(handler);
        } else {
            setDebouncedSearch(searchInput);
        }
    }, [searchInput]);


    const handleChangePage = async (page, newPageSize) => {
        setCurrentPage(page);
        setPageSize(newPageSize);
        setIsFetchingData(true);
        await refetch();
        setIsFetchingData(false);
    };

    useEffect(() => {
        if (exportPageSize) {
            handleExpenseDataExport()
        }
    }, [exportPageSize])

    const handleExpenseDataExport = async () => {
        setExportLoader(true);
        try {
            const response = await refetch();
            const expenses = response?.data?.data || [];

            const csvData = expenses.map((expense, index) => {
                const category = `${expense.parent_category} - ${expense.category || ''}`;
                return {
                    "S.NO": index + 1,
                    "Property Name": expense?.property,
                    "Provider Type": expense.providerType,
                    "Category": category,
                    "Company": expense?.company,
                    "Amount": expense?.amount,
                    "Payment Date": dayjs(expense?.payment_date).format("MM-DD-YYYY"),
                    "Payment Type": expense?.payment_type_name,

                };
            });

            const csvRows = [
                ['S.NO', 'Property Name', 'Provider Type', 'Category', 'Company', 'Amount', 'Payment Date', "Payment Type"],
                ...csvData.map(item => [
                    item["S.NO"],
                    item["Property Name"],
                    item["Provider Type"],
                    item["Category"],
                    item["Company"],
                    item["Amount"],
                    item["Payment Date"],
                    item["Payment Type"],
                ])
            ];

            const csvString = csvRows.map(row => row.join(',')).join('\n');
            const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
            saveAs(blob, 'expenses.csv');
        } catch (error) {
            console.error("Error exporting data: ", error);
        }
        setExportLoader(false);
        setExportPageSize(null)
    };

    const logsColumns = [
        {
            title: "Action",
            dataIndex: "event",
            key: "event",
            render: (event) => (
                <div>
                    {event === "Expense Created"
                        ? "Created"
                        : event.includes("Expense Updated")
                            ? "Updated"
                            : event === "Expense Deleted"
                                ? "Deleted"
                                : "Unknown"}
                </div>
            ),
        },
        {
            title: "Details",
            dataIndex: "details",
            key: "details",
            render: (_, record) => {
                const { properties, isUpdated, isCreated, isDeleted } = record;
                if (!properties) return "No details available";

                try {
                    return isUpdated
                        ? Object.keys(properties.new || {})
                            .filter((key) => key !== "updated_at")
                            .filter(
                                (key) =>
                                    !(
                                        (key === "payment_type_id" && properties.new?.payment_type_name) ||
                                        (key === "category_id" && properties.new?.category_name) ||
                                        (key === "company_id" && properties.new?.company_name)
                                    ) || key === "company_name"
                            )
                            .map((key, i) => {
                                const oldValue = properties.old?.[key];
                                const newValue = properties.new?.[key];

                                if (oldValue !== undefined && oldValue === newValue) {
                                    return null;
                                }

                                return (
                                    <div key={i}>
                                        <strong>{key.replace(/_/g, " ")}:</strong> Changed from{" "}
                                        <Typography.Text delete>{oldValue}</Typography.Text> →{" "}
                                        <strong>{newValue}</strong>
                                    </div>
                                );
                            })
                        : isCreated
                            ? [
                                <div key="created">
                                    <strong>Category:</strong> {properties.new?.category_name || "N/A"}
                                </div>,
                                <div key="created2">
                                    <strong>Payment Type:</strong> {properties.new?.payment_type_name || "N/A"}
                                </div>,
                            ]
                            : isDeleted
                                ? [
                                    <div key="deleted">
                                        <Typography.Text type="danger">This record was deleted.</Typography.Text>
                                    </div>,
                                ]
                                : "No changes recorded";
                } catch (error) {
                    console.error("Error parsing properties:", error);
                    return "Invalid data";
                }
            },
        },
        {
            title: "Modified By",
            dataIndex: "modifiedBy",
            key: "modifiedBy",
        },
        {
            title: "Updated On",
            dataIndex: "formattedDate",
            key: "formattedDate",
        },
    ];

    const dataLogSource =
        ActivityModelData?.length > 0 &&
        [...ActivityModelData].reverse().map((log, index) => {
            let parsedProperties = {};
            try {
                parsedProperties = JSON.parse(log.properties);
            } catch (error) {
                console.error("Error parsing log properties:", error);
            }

            const causer = log.causer || {};
            const fullName = `${causer.first_name || ""} ${causer.last_name || ""}`.trim();
            const modifiedBy = fullName ? fullName : `ID: ${causer.id || "Unknown"}`;

            return {
                key: index,
                event: log.description,
                isUpdated: log.description?.includes("Expense Updated") || false,
                isCreated: log.description === "Expense Created",
                isDeleted: log.description === "Expense Deleted",
                modifiedBy: modifiedBy,
                formattedDate: log.updated_at ? dayjs(log.updated_at).format("MM-DD-YYYY hh:mm A") : "N/A",
                createdDate: parsedProperties.new?.created_at
                    ? dayjs(parsedProperties.new.created_at).format("MM-DD-YYYY hh:mm A")
                    : log.created_at
                        ? dayjs(log.created_at).format("MM-DD-YYYY hh:mm A")
                        : "N/A",
                properties: parsedProperties,
            };
        });

    const handleDeleteModalCancel = () => {
        setIsModalOpen(false);
        setSelectedRecord(null);
    }
    return (
        <>
            {loading && (
                <div className="commanLoaderWrapper">
                    <Spin size="large" />
                    <h4>Setting things up..</h4>
                </div>
            )}
            <TopHeader
                title={<Title title="All Expenses" id="expense" />}
                search={
                    <Input.Search
                        placeholder="Search..."
                        allowClear
                        value={searchInput}
                        onChange={handleSearchChange}
                        className="mobWidth"
                    />
                }
                button={
                    <>
                        <Button
                            onClick={openExpenseModel}
                            type="primary"
                            style={{
                                backgroundColor: colors?.primary || "#F47A3A",
                            }}
                            className="customHeaderBtn step-one"
                        >
                            Add New <IoIosAddCircleOutline />
                        </Button>
                    </>
                }
            />
            <div className="customPadding customExpenseWrapper">
                <div className="breadcrumbWrapper">
                    <Breadcrumb
                        items={[
                            {
                                title: (
                                    <span onClick={() => navigate('/dashboard')} style={{ cursor: 'pointer' }}>
                                        <HomeOutlined />
                                    </span>
                                ),
                            },
                            {
                                title: (
                                    <>
                                        <span>Expenses</span>
                                    </>
                                ),
                            },
                        ]}
                    />
                </div>
                <div className="mainWrapper">
                    <div className="card titleCard mb-4">
                        <div className="card-body-wrapper">
                            {isError && getAllExpense?.data?.length < 0 ? (
                                <h4>An error occurred</h4>
                            ) : (
                                <div className="table-responsives">
                                    {getAllExpense?.meta?.total > 0 ? (<><div className="export-data-wrapper d-flex justify-content-end align-items-center"><Button icon={<GrDocumentCsv />} loading={exportLoader} onClick={() => setExportPageSize(getAllExpense?.meta?.total)} className="export-data-button viewButton mb-3">Export</Button></div></>) : (<></>)}
                                    <Table
                                        className={`table-bordered step-two ${isFetchingData ? "table-loading" : ""}`}
                                        rowKey={record => record.id}
                                        rowClassName={() => "editable-row"}
                                        bordered
                                        dataSource={tableData}
                                        columns={Columns}
                                        pagination={false}
                                        loading={isFetchingData || localLoading}
                                        scroll={{ x: 'auto' }}
                                        footer={() => (
                                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                                                <div style={{ fontWeight: "bold" }}>
                                                    Total Expense:
                                                    <span style={{ fontSize: '1rem', marginLeft: '0.2rem' }}>
                                                        {new Intl.NumberFormat(
                                                            currency === "₹" ? "en-IN" : "en-US",
                                                            { style: "currency", currency: currency === "₹" ? "INR" : "USD" }
                                                        ).format(isNaN(totalExpense) || totalExpense === undefined || totalExpense === null ? 0 : totalExpense)}

                                                    </span>
                                                </div>
                                            </div>
                                        )}
                                        onChange={(pagination, filters, sorter) => {
                                            if (sorter.order) {
                                                const columnTitle = Columns.find(col => col.dataIndex === sorter.field)?.dataIndex;
                                                setColumnName(columnTitle);
                                                setTableSortBy(sorter.order === "ascend" ? "asc" : "desc");
                                            }
                                        }}
                                    />
                                    {getAllExpense?.meta?.total > 20 ? (
                                        <Pagination
                                            className="mt-4"
                                            current={currentPage}
                                            pageSize={pageSize}
                                            total={getAllExpense?.meta?.total || 0}
                                            onChange={handleChangePage}
                                            pageSizeOptions={pageSizeOptions}
                                            showSizeChanger
                                            locale={{
                                                items_per_page: `/${getAllExpense?.meta?.total || 0} Records`,
                                            }}
                                        />
                                    ) : (<></>)}

                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                className="addExpenseModal"
                title="Add New Expense"
                open={isExpenseModal}
                onCancel={handleCancel}
                footer={false}
            >
                <div className="modal-body">
                    <Form
                        form={form}
                        onFinish={addNewExpense}
                        initialValues={{
                            property_id: "",
                            provider_id: ""
                        }}
                    >
                        <Form.Item name='property_id' rules={[{ required: true, message: 'Please select a property' }]}>
                            <label className="mb-2">Select Property</label>
                            <Select
                                className="formControl select-custom"
                                placeholder="Select Property"
                                options={properties}
                                onChange={handlePropertyValueChange}
                                showSearch
                                filterOption={(input, option) =>
                                    (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                                }
                                suffixIcon={
                                    <BsBuildingCheck
                                        style={{
                                            color: "#F47939",
                                            fontSize: "24px",
                                        }}
                                    />
                                }
                            />
                        </Form.Item>
                        <Form.Item name='provider_id' rules={[{ required: true, message: 'Please select a provider' }]}>
                            <label className="mb-2">Select Provider</label>
                            <Select
                                className="formControl select-custom"
                                options={utilityCategory}
                                placeholder="Select Provider"
                                onChange={handleUtilityValueChange}
                                suffixIcon={
                                    <FaUsersCog
                                        style={{
                                            color: "#F47939",
                                            fontSize: "24px",
                                        }}
                                    />
                                }
                            />
                        </Form.Item>
                        <div className="submitWrapper">
                            <Button type="primary" htmlType="submit" className="authBtn">Continue</Button>
                        </div>
                    </Form>
                </div>
            </Modal>

            <Modal className="deleteModal customLogsModel" title="Activity Logs" open={ActivityModel} onCancel={handleCancelLogModel} footer={false}>
                <Table columns={logsColumns} dataSource={dataLogSource} pagination={false} loading={loadingActivity} />
            </Modal>

            <Modal
                className="deleteModal"
                title="Confirm Expense Deletion"
                open={isModalOpen}
                onCancel={handleDeleteModalCancel}
                footer={false}
            >
                <div className="iconRow">
                    <RxCrossCircled />
                </div>
                <div className="textRow">
                    <h3>Are you sure you want to delete this expense?</h3>
                    <p>This action cannot be undone. Please confirm if you wish to permanently delete this expense.</p>
                </div>
                <div className="buttonRow">
                    <Button
                        className="btnFilled text-white"
                        style={{ marginRight: '1rem' }}
                        onClick={() => handleDelete()}
                        loading={isLoadingDelete}
                    >
                        Yes, Delete
                    </Button>
                    <Button className="btnOutlined me-2" onClick={handleDeleteModalCancel}>
                        No, Cancel
                    </Button>
                </div>
            </Modal>


        </>
    );
};
export default Expesne;