import React, { useState, useCallback, useEffect } from "react";
import { Spin, Button, Table, Input, Pagination, Alert } from "antd";
import { useNavigate } from "react-router-dom";
import Title from "../../component/PageTitle/index";
import { useGenrateLinkTokenQuery } from "../../slices/getSlice";
import { useSelector } from "react-redux";
import { useAddAccessTokenMutation } from '../../slices/apiSlice';
import { toast } from 'react-toastify';
import { usePlaidLink } from 'react-plaid-link';
import TopHeader from "../../component/Header";
import { HomeOutlined } from '@ant-design/icons';
import { Breadcrumb } from 'antd';
import PageSizeOptionsHelper from "../../Helper/PageSizeOptionsHelper";
import { saveAs } from 'file-saver';
import { GrDocumentCsv } from "react-icons/gr";
import dayjs from "dayjs";

const PlaidLink = () => {
    const [searchText, setSearchText] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(20)
    const [exportPageSize, setExportPageSize] = useState(null)
    const { data: generateToken, refetch, isLoading, error } = useGenrateLinkTokenQuery(
        `?page=${currentPage}&per_page=${pageSize}${searchText ? `&search=${searchText}` : ""}`
    );

    if (isLoading) {
        return (
            <div className="loaderWrapper">
                <Spin />
            </div>
        );
    }

    if (error) {
        return <div>Error generating link token</div>;
    }

    const linkToken = generateToken?.token?.link_token;

    return linkToken ? (
        <ConnectBankAccount currentPage={currentPage} setCurrentPage={setCurrentPage} exportPageSize={exportPageSize} setExportPageSize={setExportPageSize} setPageSize={setPageSize} pageSize={pageSize} searchText={searchText} setSearchText={setSearchText} linkToken={linkToken} generatedToken={generateToken} refetch={refetch} isLoading={isLoading} />
    ) : (
        <div>No link token available</div>
    );
};

const ConnectBankAccount = ({ linkToken, generatedToken, refetch, isLoading, setSearchText, exportPageSize, setExportPageSize, searchText, currentPage, setCurrentPage, pageSize, setPageSize }) => {
    const start_Date = useSelector((state) => state.date.startDate);
    const end_Date = useSelector((state) => state.date.endDate);
    const navigate = useNavigate();
    const colors = useSelector((state) => state?.persistedReducer?.colors);
    const currency = useSelector((state) => state?.persistedReducer?.currency?.currency_symbol || "$");
    const [setAccessToken] = useAddAccessTokenMutation();
    const [loadingData, setLoadingData] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [exportLoader, setExportLoader] = useState(false);


    const onSuccess = useCallback(
        async (public_token) => {
            try {
                setLoadingData(true);
                const response = await setAccessToken({ public_token });

                if (response.error) {
                    toast.error(response.error.data.message);
                } else {
                    toast.success('Account Connected');
                    const aa = await refetch();
                }
            } catch (error) {
                toast.error('Error connecting account');
            } finally {
                setLoadingData(false);
            }
        },
        [setAccessToken, refetch, navigate]
    );

    const config = {
        token: linkToken,
        onSuccess,
    };

    const { open, ready } = usePlaidLink(config);

    const columns = [
        {
            title: "S.No.",
            dataIndex: "serial",
            render: (_, __, index) => (currentPage - 1) * pageSize + index + 1,
        },
        {
            title: "Bank Name",
            dataIndex: "bank_name",
            sorter: (a, b) => a.bank_name.localeCompare(b.bank_name),
        },
        {
            title: "Account Id",
            dataIndex: "account_id",
            sorter: (a, b) => a.account_id.localeCompare(b.account_id),
        },
        {
            title: "Account Name",
            dataIndex: "account_name",
            sorter: (a, b) => a.account_name.localeCompare(b.account_name),
        },
        {
            title: "Created At",
            dataIndex: "created_at",
            render: (text) => {
                const date = new Date(text);
                const options = { month: '2-digit', day: '2-digit', year: 'numeric' };
                return date.toLocaleDateString('en-US', options).replaceAll('/', '-');
            },
            sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
        },
        {
            title: "Updated At",
            dataIndex: "updated_at",
            render: (text) => {
                const date = new Date(text);
                const options = { month: '2-digit', day: '2-digit', year: 'numeric' };
                return date.toLocaleDateString('en-US', options).replaceAll('/', '-');
            },
            sorter: (a, b) => new Date(a.updated_at) - new Date(b.updated_at),
        },
    ];

    const handleChangePage = (page, size) => {
        setCurrentPage(page);
        setPageSize(size);
    };

    const handleSearchChange = (e) => {
        setCurrentPage(1)
        setSearchText(e?.target?.value || null);
    };

    useEffect(() => {
        const filterData = () => {
            const data = generatedToken?.accounts_data || [];
            return data.filter(account => {
                const createdAt = new Date(account.created_at);
                const updatedAt = new Date(account.updated_at);
                // const isWithinDateRange = 
                //     (!start_Date || createdAt >= new Date(start_Date)) && 
                //     (!end_Date || createdAt <= new Date(end_Date));

                // const isUpdatedInDateRange = 
                //     (!start_Date || updatedAt >= new Date(start_Date)) && 
                //     (!end_Date || updatedAt <= new Date(end_Date));

                const matchesSearchText = searchText ?
                    account.bank_name.toLowerCase().includes(searchText.toLowerCase()) ||
                    account.account_id.toLowerCase().includes(searchText.toLowerCase()) ||
                    account.account_name.toLowerCase().includes(searchText.toLowerCase())
                    : true;

                // return isWithinDateRange && isUpdatedInDateRange && matchesSearchText;
                return matchesSearchText;
            });
        };

        setFilteredData(filterData());
    }, [generatedToken, start_Date, end_Date, searchText]);



    const pageSizeOptions = PageSizeOptionsHelper(generatedToken?.accounts_data?.length);


    useEffect(() => {

        if (exportPageSize) {
            handleBankDataExport()
        }

    }, [exportPageSize])

    const handleBankDataExport = async () => {
        setExportLoader(true);
        try {
            const response = await refetch();
            const bankData = response?.data?.accounts_data || [];

            const csvData = bankData.map((bank, index) => {
                return {
                    "S.NO": index + 1,
                    "Bank Name": bank?.bank_name,
                    "Account Id": bank?.account_id,
                    "Created At": dayjs(bank?.created_at).format("MM-DD-YYYY"),
                    "Updated At": dayjs(bank?.updated_at).format("MM-DD-YYYY"),
                };
            });

            const csvRows = [
                ['S.NO', 'Bank Name', "Account Id", 'Created At', 'Updated At'],
                ...csvData.map(item => [
                    item['S.NO'],
                    item["Bank Name"],
                    item["Account Id"],
                    item["Created At"],
                    item["Updated At"],
                ])
            ];

            const csvString = csvRows.map(row => row.join(',')).join('\n');
            const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
            saveAs(blob, 'bank_accounts.csv');
        } catch (error) {
            console.error("Error exporting data: ", error);
        }
        setExportLoader(false);
        setExportPageSize(null)
    };

    return (
        <>
            <TopHeader
                title={<Title title="Connect Accounts" id="connect_bank_account" />}
                search={
                    <Input.Search
                        placeholder="Search..."
                        allowClear
                        value={searchText}
                        onChange={handleSearchChange}
                        className=" me-3 tableSearchInput"
                    />
                }
                button={
                    <Button
                        type="primary"
                        className="link customHeaderBtn step-one"
                        style={{
                            backgroundColor: colors?.primary || "#F47A3A",
                            color: "#FFF",
                        }}
                        onClick={() => open()}
                        disabled={!ready}
                    >
                        {generatedToken?.accounts_data?.length ? "Link More Account" : "Link Account"}
                    </Button>
                }
            />
            <div className="customPadding">
                <div className="breadcrumbWrapper">
                    <Breadcrumb
                        items={[
                            {
                                title: (
                                    <span onClick={() => navigate('/dashboard')} style={{ cursor: 'pointer' }}>
                                        <HomeOutlined />
                                    </span>
                                ),
                            },
                            {
                                title: (
                                    <>
                                        <span>Connect Account</span>
                                    </>
                                ),
                            },
                        ]}
                    />
                </div>
                <div className="mainWrapper">
                    <div className="card titleCard mb-4" style={{ paddingBottom: '3.5rem' }}>
                        <div className="card-body-wrapper">
                            {generatedToken?.total_accounts === 0 ? (
                                <Alert
                                    message="Connect your bank account dynamically to pull and manage data."
                                    type="warning"
                                    showIcon
                                    closable
                                    className="propertyAlert"
                                    action={
                                        <Button size="small" type="text" variant="filled" onClick={() => open()}>
                                            Link Account
                                        </Button>
                                    }
                                />
                            ) : (<></>)}
                            <>
                                {filteredData?.length > 0 ? (<><div className="export-data-wrapper d-flex justify-content-end align-items-center"><Button icon={<GrDocumentCsv />} loading={exportLoader} onClick={() => setExportPageSize(filteredData?.length)} className="export-data-button viewButton mb-3">Export</Button></div></>) : (<></>)}
                                <Table
                                    className={`tableNew table-bordered step-two ${loadingData || isLoading ? "table-loading" : ""}`}
                                    rowKey={(record) => record.id}
                                    rowClassName={() => "editable-row"}
                                    bordered
                                    dataSource={filteredData}
                                    columns={columns}
                                    scroll={{ x: '100%' }}
                                    pagination={false}
                                    loading={loadingData || isLoading}
                                />
                                {filteredData?.length > 20 ? (
                                    <Pagination
                                        className="mt-4"
                                        total={filteredData?.length}
                                        pageSize={pageSize}
                                        current={currentPage}
                                        onChange={handleChangePage}
                                        pageSizeOptions={pageSizeOptions}
                                        showSizeChanger
                                        locale={{
                                            items_per_page: `/${filteredData?.length || 0} Records`,
                                        }}
                                    />
                                ) : (<></>)}

                            </>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PlaidLink;
