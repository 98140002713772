import React, { useState, useEffect } from 'react';
import { Modal, Form, Select, Input, Checkbox, Button, Spin } from 'antd';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';

const TransactionModal = ({
    isModalOpen,
    closeAndReset,
    linkData,
    properties,
    utilityCategory,
    companyList,
    categoryData,
    handleFinish,
    isLoadingForm,
    isSaving,
    isSplitPayment,
    setIsSplitPayment,
    propertyAmounts,
    setPropertyAmounts,
    handleSplitPaymentChange,
    handleCompanyChange,
    handleDropdownVisibleChange,
    handleAddCompany,
    isLoadingAddCompany,
    ExpenseOptions,
    form,
    isLoadingAddExpense,
    selectedRowsData
}) => {
    const [inputValue, setInputValue] = useState('');
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const renderAmount = () => {
        let amount = 0;
        if (linkData && typeof linkData.amount === 'number') {
            amount = linkData.amount.toFixed(2);
        }
        return amount;
    };

    const capitalizeFirstLetter = (string) => {
        if (typeof string !== 'string') {
            console.error('capitalizeFirstLetter: Expected a string but received:', string);
            return '';
        }
        return string.charAt(0).toUpperCase() + string.slice(1);
    };



    return (
        <Modal
            className="deleteModal addTransCustomModal"
            open={isModalOpen}
            onCancel={closeAndReset}
            footer={false}
            title={
                <>
                    Add your transaction
                    <div style={{ marginTop: '8px', fontWeight: 700, width: 'fit-content', fontSize: '1rem' }}>
                        ${renderAmount()}
                    </div>
                </>
            }
        >

            <p className="transaction-name">Transaction Name - {linkData?.name}</p>
            <div className="modal-body">
                {isLoadingForm ? (
                    <Spin style={{ display: "flex", justifyContent: "center", margin: "20px 0" }} />
                ) : (
                    <>
                        <Form
                            form={form}
                            layout="vertical"
                            onFinish={(values) => {
                                const totalSplitAmount = Math.abs(
                                    Object.values(propertyAmounts)
                                        .reduce((sum, amount) => sum + (parseFloat(amount) || 0), 0)
                                ).toFixed(2);

                                const actualAmount = Math.abs(linkData?.amount || 0).toFixed(2);

                                if (isSplitPayment && parseFloat(totalSplitAmount) !== parseFloat(actualAmount)) {
                                    toast.error(`The total split payment ($${totalSplitAmount}) must match the actual amount ($${actualAmount}).`);
                                    return;
                                }

                                const finalValues = {
                                    ...values,
                                    propertyAmounts,
                                };
                                handleFinish(finalValues);
                                setIsSplitPayment(false);
                            }}
                            initialValues={{
                                properties: [],
                                utilityCategory: [],
                                splitPayment: false,
                                future_add: true,
                            }}
                            className="transactionForm"
                        >
                                  {selectedRowsData?.length === 0 && ( 
                                    <>
                                        <div className="accountNumberWrapper">
                                            <Form.Item
                                                label="Card / Account No."
                                                name="mask"
                                            >
                                                <Input disabled className="form-control" />
                                            </Form.Item>
                                        </div>
                                        <div className="accountNumberWrapper">
                                            <Form.Item
                                                label="Billing Descriptor"
                                                name="billing_descriptor"
                                            >
                                                <Input disabled className="form-control" />
                                            </Form.Item>
                                        </div>
                                    </>
                                )}
                            <div className="textRow me-2">
                                {!isSplitPayment ? (
                                    <Form.Item
                                        label="Property Name"
                                        className="firstFormItem"
                                        name="properties"
                                        rules={[{ required: true, message: "Please select at least one property." }]}
                                    >
                                        <Select
                                            className="formControl select-custom"
                                            style={{ width: "100%" }}
                                            placeholder="Select property"
                                            labelInValue
                                            options={properties}
                                            showSearch
                                            filterOption={(input, option) =>
                                                (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                                            }
                                        />
                                    </Form.Item>
                                ) : (
                                    <div className="splitRow">
                                        {properties.map((property) => (
                                            <div className="splitCol" key={property.value}>
                                                <Form.Item label={
                                                    <span style={{
                                                        display: "inline-block",
                                                        maxWidth: "200px",
                                                        whiteSpace: "nowrap",
                                                        overflow: "hidden",
                                                        textOverflow: "ellipsis"
                                                    }}>
                                                        {property.label}
                                                    </span>
                                                } style={{ marginBottom: '16px' }}>
                                                    <Input
                                                        type="number"
                                                        value={propertyAmounts[property.value] || ''}
                                                        onChange={(e) => {
                                                            setPropertyAmounts({
                                                                ...propertyAmounts,
                                                                [property.value]: e.target.value,
                                                            })
                                                        }}
                                                        onKeyPress={(e) => {
                                                            if (!/[0-9.]/.test(e.key) || (e.key === '.' && e.target.value.includes('.'))) {
                                                                e.preventDefault();
                                                            }
                                                        }}
                                                        onPaste={(e) => {
                                                            const paste = (e.clipboardData || window.clipboardData).getData("text");
                                                            if (!/^\d*\.?\d*$/.test(paste)) {
                                                                e.preventDefault();
                                                            }
                                                        }}
                                                        placeholder={`Amount for ${property.label}`}
                                                    />
                                                </Form.Item>
                                            </div>
                                        ))}
                                    </div>
                                )}

                                <div className="splitPaymentButton mt-2">
                                    <Checkbox onChange={handleSplitPaymentChange} checked={isSplitPayment ? true : false}>Split Payment</Checkbox>
                                </div>

                                <Form.Item
                                    label="Provider Type"
                                    name="utilityCategory"
                                    rules={[{ required: true, message: "Please select at least one Provider." }]}
                                >
                                    <Select
                                        className="formControl select-custom"
                                        style={{ width: "100%" }}
                                        placeholder="Provider Type"
                                        labelInValue
                                        options={utilityCategory}
                                        showSearch
                                        filterOption={(input, option) =>
                                            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                                        }
                                    />
                                </Form.Item>

                                <div className="companyWrapper">
                                    <Form.Item
                                        label="Company Name"
                                        name="company_id"
                                        rules={[{ required: true, message: "Please select a company." }]}
                                    >
                                        <Select
                                            className="formControl select-custom"
                                            style={{ width: "100%" }}
                                            placeholder="Select a company"
                                            labelInValue
                                            showSearch
                                            filterOption={(input, option) =>
                                                (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                                            }
                                            onSearch={(value) => setInputValue(value)}
                                            onChange={(value) => {
                                                handleCompanyChange(value);
                                                setDropdownOpen(false);
                                            }}
                                            options={companyList?.map((item) => ({
                                                label: item.name,
                                                value: item.id,
                                            }))}
                                            open={dropdownOpen}
                                            notFoundContent={
                                                companyList?.length === 0 || !inputValue
                                                    ? null
                                                    : "No matching records found!"
                                            }
                                            onDropdownVisibleChange={(visible) => setDropdownOpen(visible)}
                                            optionRender={(option) => (
                                                <span role="img" aria-label={option.label}>
                                                    {option.label}
                                                </span>
                                            )}
                                            dropdownRender={(menu) => (
                                                <>
                                                    {menu}
                                                    {inputValue && !companyList?.some((opt) => opt.name.toLowerCase() === inputValue.toLowerCase()) && (
                                                        <div style={{ display: "flex", justifyContent: "space-between", padding: 8 }}>
                                                            <span>Add "{inputValue}"</span>
                                                            <Button type="link" onClick={handleAddCompany} disabled={isLoadingAddCompany}>
                                                                {isLoadingAddCompany ? <Spin size="small" /> : "Add"}
                                                            </Button>
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                        />
                                    </Form.Item>
                                </div>

                                <div className="serviceWrapper">
                                    <Form.Item
                                        label="Category Name"
                                        name="category_id"
                                        rules={[{ required: true, message: "Please select a category." }]}
                                    >
                                        <Select
                                            className="formControl select-custom"
                                            style={{ width: "100%" }}
                                            placeholder="Start Typing to Search Category"
                                            labelInValue
                                            showSearch
                                            onSearch={handleDropdownVisibleChange}
                                            filterOption={(input, option) =>
                                                (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                                            }
                                            notFoundContent={categoryData?.length === 0 ? null : undefined}
                                            options={categoryData?.map((item) => ({
                                                label: item.name,
                                                value: item.id,
                                            }))}
                                            optionRender={(option) => (
                                                <span role="img" aria-label={option.label}>
                                                    {option.label}
                                                </span>
                                            )}
                                        />
                                    </Form.Item>
                                </div>
                                <div className="checkboxWrapper">
                                    <Form.Item
                                        name="future_add"
                                        valuePropName="checked"
                                        className="customCheckbox"
                                    >
                                        <Checkbox>
                                            Do you want all future transactions to be auto-linked with this billing descriptor?
                                        </Checkbox>
                                    </Form.Item>
                                </div>
                            </div>

                            <Form.Item>
                                <Button type="primary" htmlType="submit" className="authBtn" disabled={isSaving} loading={isLoadingAddExpense || isLoadingAddCompany}>
                                    Submit
                                </Button>
                            </Form.Item>
                        </Form>
                    </>
                )}
            </div>
        </Modal>
    );
};

export default TransactionModal; 