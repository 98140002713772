import React, { useState, useEffect } from "react";
import { Menu, Image, Skeleton } from "antd";
import Sider from "antd/es/layout/Sider";
import { Link, useLocation, NavLink, } from "react-router-dom";
import { saveCurrency } from "../../slices/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { useGetUserDetailQuery } from "../../slices/getSlice";
import { BsBuildingsFill } from "react-icons/bs";
import NewLogo from "../../assets/logo/newLogo.png";
import { IoIosHeart } from "react-icons/io";
import { useLazyGetReleaseVersionQuery } from "../../slices/getSlice";

const Sidebar = ({ loading }) => {
    const location = useLocation();
    const {
        data: getUserDetail,
        isLoading: loadingData,
    } = useGetUserDetailQuery();
    const dispatch = useDispatch();
    const status = useSelector((state) => state.persistedReducer.status);
    const userData = useSelector((state) => state.persistedReducer);
    const parent_id = userData?.user?.user?.parent_id;
    const [menuLabels, setMenuLabels] = useState({});
    const [menuHeading, setMenuHeading] = useState({});
    const [openKeys, setOpenKeys] = useState([]);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [fetchReleaseVersion, { data: releaseVerionData, isFetching }] = useLazyGetReleaseVersionQuery();
    const [releaseVersionNumber, setReleaseVersionNumber] = useState(null);

    useEffect(() => {
        fetchReleaseVersion();
    }, []);

    useEffect(() => {
        if (releaseVerionData?.latest_version) {
            setReleaseVersionNumber(releaseVerionData.latest_version);
        }
    }, [releaseVerionData]);

    useEffect(() => {
        const currentPath = location.pathname;
        let activeParents = [];
        let activeKey = "";

        items.forEach((parent) => {
            if (parent.children) {
                parent.children.forEach((child) => {
                    if (child.key && currentPath.includes(child.key)) {
                        activeParents.push(parent.key);
                        activeKey = child.key;
                    }
                });
            } else {
                if (currentPath.startsWith(`/${parent.key}`)) {
                    activeParents.push(parent.key);
                    activeKey = parent.key;
                }
            }
        });

        const lastOpenMenu = JSON.parse(localStorage.getItem("lastOpenMenu"));

        if (activeParents.length === 0 && lastOpenMenu) {
            activeParents.push(lastOpenMenu.parent);
            activeKey = lastOpenMenu.child || lastOpenMenu.parent;
        }

        setOpenKeys([...new Set(activeParents)]);
        setSelectedKeys(activeKey ? [activeKey] : []);

        if (activeParents.length > 0) {
            localStorage.setItem(
                "lastOpenMenu",
                JSON.stringify({ parent: activeParents[0], child: activeKey })
            );
        }
    }, [location.pathname]);


    const onClick = (e) => {
        setSelectedKeys([e.key]);
        if (e.key === "/sub_account") {
            setOpenKeys((prevKeys) => (prevKeys.length > 0 ? [] : prevKeys));
            localStorage.setItem(
                "lastOpenMenu",
                JSON.stringify({ parent: e.key, child: null })
            );
        }
    };
    useEffect(() => {
        let mydata = getUserDetail?.data?.setting?.custom_data;
        if (typeof mydata !== "undefined") {
            setMenuLabels(mydata?.menu);
        }
    
        let userID = getUserDetail?.data?.id;
        let currencyData = {
            ...getUserDetail?.data?.setting,
            userID, 
        };
    
        dispatch(saveCurrency(currencyData)); 
    
        let heading = getUserDetail?.data?.setting?.custom_data;
        if (typeof heading !== "undefined") {
            setMenuHeading(heading?.menu_headings);
        }
    }, [getUserDetail]);
    

    const capitalizeFirstLetter = (string) => {
        return string?.charAt(0)?.toUpperCase() + string?.slice(1);
    };

    let SecondaryColor =
        getUserDetail?.data?.setting?.custom_data?.colors?.secondary;

    const onOpenChange = (keys) => {
        setOpenKeys(keys);
    };

    const items = [
        {
            key: "analytics",
            disabled: !status,
            icon: <i className="fa-solid fa-chart-column menu-icon"></i>,
            label: capitalizeFirstLetter(menuHeading?.dashboard) || "Analytics",
            className: "custom-sub-menu",
            children: [
                {
                    key: "dashboard",
                    label: status ? (
                        <NavLink className="opacity" to={"/dashboard"}>
                            {capitalizeFirstLetter(menuLabels?.dashboard) || "Overview"}
                        </NavLink>
                    ) : (
                        <span>
                            {capitalizeFirstLetter(menuLabels?.dashboard) || "Overview"}
                        </span>
                    ),
                    disabled: !status,
                    icon: <i className="fa fa-house menu-icon"></i>,
                },
                {
                    key: "report_analysis",
                    label: status ? (
                        <NavLink style={{ textDecoration: 'none' }} to={"/report_analysis"}>
                            {capitalizeFirstLetter(menuLabels?.report_analysis) ||
                                "Report Analysis"}
                        </NavLink>
                    ) : (
                        <span>
                            {capitalizeFirstLetter(menuLabels?.report_analysis) ||
                                "Report Analysis"}
                        </span>
                    ),
                    disabled: !status,
                    icon: <i className="fa-solid fa-chart-pie menu-icon"></i>,
                },
                {
                    key: "overall_reports",
                    icon: <i className="fa-solid fa-chart-simple menu-icon"></i>,
                    disabled: !status,
                    label: status ? (
                        <Link style={{ textDecoration: 'none' }} to={"/overall_reports"}>
                            {capitalizeFirstLetter(menuLabels?.overall_performance) ||
                                "Overall Performance"}
                        </Link>
                    ) : (
                        <span>
                            {capitalizeFirstLetter(menuLabels?.overall_performance) ||
                                "Overall Performance"}
                        </span>
                    ),
                },
            ],
        },
        {
            key: "Portfolio",
            disabled: !status,
            icon: <i className="fa-solid fa-rectangle-list menu-icon"></i>,
            label: capitalizeFirstLetter(menuHeading?.portfolio),
            className: "custom-sub-menu",
            children: [
                {
                    key: "properties",
                    icon: <i className="fa-solid fa-building menu-icon"></i>,
                    disabled: !status,
                    label: status ? (
                        <Link style={{ textDecoration: 'none' }} to={"/properties"}>
                            {capitalizeFirstLetter(menuLabels?.properties) || "Properties"}
                        </Link>
                    ) : (
                        <span>
                            {capitalizeFirstLetter(menuLabels?.properties) || "Properties"}
                        </span>
                    ),
                },
                {
                    key: "income",
                    icon: <i className="fa-solid fa-hand-holding-dollar menu-icon"></i>,
                    disabled: !status,
                    label: status ? (
                        <Link style={{ textDecoration: 'none' }} to={"/income"}>
                            {capitalizeFirstLetter(menuLabels?.income) || "Income"}
                        </Link>
                    ) : (
                        <span>{capitalizeFirstLetter(menuLabels?.income) || "Income"}</span>
                    ),
                },
                {
                    key: "expense",
                    icon: <i className="fa-solid fa-dollar-sign menu-icon"></i>,
                    disabled: !status,
                    label: (
                        <Link style={{ textDecoration: 'none' }} to={"/expense"}>
                            {capitalizeFirstLetter(menuLabels?.expenses) || "All Expenses"}
                        </Link>
                    ),
                },
                {
                    key: "manage_property",
                    icon: <i className="fa-solid fa-layer-group menu-icon"></i>,
                    hidden: parent_id ? true : false,
                    disabled: !status,
                    label: status ? (
                        <Link style={{ textDecoration: 'none' }} to={"/manage_property"}>
                            {capitalizeFirstLetter(menuLabels?.groups) || "Groups"}
                        </Link>
                    ) : (
                        <span>{capitalizeFirstLetter(menuLabels?.groups) || "Groups"}</span>
                    ),
                },
                {
                    key: "companies",
                    icon: <BsBuildingsFill />,
                    disabled: !status,
                    label: status ? (
                        <Link style={{ textDecoration: 'none' }} to={"/companies"}>
                            {capitalizeFirstLetter(menuLabels?.companies)}
                        </Link>
                    ) : (
                        <span>{capitalizeFirstLetter(menuLabels?.companies)}</span>
                    ),
                },
            ],
        },
        {
            key: "financials",
            disabled: !status,
            icon: <i className="fa-solid fa-coins menu-icon"></i>,
            label: capitalizeFirstLetter(menuHeading?.financials),
            className: "custom-sub-menu",
            children: [
                {
                    key: "connect_bank_account",
                    label: status ? (
                        <NavLink style={{ textDecoration: 'none' }} to={"/connect_bank_account"}>
                            {capitalizeFirstLetter(menuLabels?.connect_bank_account) ||
                                "Connect Accounts"}
                        </NavLink>
                    ) : (
                        <span>
                            {capitalizeFirstLetter(menuLabels?.connect_bank_account) ||
                                "Connect Accounts"}
                        </span>
                    ),
                    disabled: !status,
                    hidden: parent_id ? true : false,
                    icon: <i className="fa-solid fa fa-university menu-icon"></i>,
                },
                {
                    key: "all_transactions",
                    label: status ? (
                        <NavLink style={{ textDecoration: 'none' }} to={"/all_transactions"}>
                            {capitalizeFirstLetter(menuLabels?.transactions) ||
                                "All Transactions"}
                        </NavLink>
                    ) : (
                        <span>
                            {capitalizeFirstLetter(menuLabels?.transactions) ||
                                "All Transactions"}
                        </span>
                    ),
                    disabled: !status,
                    icon: <i className="fa-solid fa-money-bill-transfer menu-icon"></i>,
                },
                {
                    key: "recurring_transactions",
                    label: status ? (
                        <NavLink style={{ textDecoration: 'none' }} to={"/recurring_transactions"}>
                            {capitalizeFirstLetter(menuLabels?.recurring_transactions) ||
                                "Recurring Transaction"}
                        </NavLink>
                    ) : (
                        <span>
                            {capitalizeFirstLetter(menuLabels?.recurring_transactions) ||
                                "Recurring Transaction"}
                        </span>
                    ),
                    disabled: !status,
                    icon: <i className="fa-solid fa-arrow-right-arrow-left menu-icon"></i>,
                },

            ],
        },
        {
            key: "/sub_account",
            disabled: !status,
            icon: <i className="fa-solid fa-users menu-icon"></i>,
            label: (
                <Link style={{ textDecoration: "none" }} to={"/sub_account"}>
                    {capitalizeFirstLetter(menuLabels?.sub_accounts) || "Sub Accounts"}
                </Link>
            ),
            className: "custom-menu-item customSubAccountSiderbar",
            parent_id: parent_id,
        },
    ];

    const filteredItems = items.filter(item => item.parent_id == null);

    return (
        <Sider
            breakpoint="lg"
            collapsedWidth="0"
            style={{
                background:
                    loadingData && SecondaryColor === undefined
                        ? "#fff"
                        : SecondaryColor || "#3A54A5",
                height: "100vh",
                position: "fixed",
            }}
            width="280"
            className="mobWidth"
        >

            <div className="logoContainer" onClick={() => {
                window.location.href = "/dashboard"
            }}>
                <Image src={NewLogo} alt="Logo" className="img-fluid" preview={false} />
            </div>
            {loading || loadingData || isFetching ? (
                <>
                    <div style={{ padding: "16px" }}>
                        <Skeleton active paragraph={{ rows: 6 }} />
                    </div>
                </>
            ) : (
                <>
                    <Menu
                        mode="inline"
                        style={{ background: SecondaryColor || "#3A54A5" }}
                        items={filteredItems}
                        onClick={onClick}
                        openKeys={openKeys}
                        selectedKeys={selectedKeys}
                        onOpenChange={onOpenChange}
                    />

                    <div className="versionWrapper">
                        <h3>{releaseVersionNumber || "V"}</h3>
                        <p className="developText">
                            Developed with <IoIosHeart color={"#e31b23 "} /> by <Link to={'https://www.aleaitsolutions.com/'} className="developLink" target="_blank">ALEA</Link>
                        </p>
                    </div>
                </>
            )}

        </Sider>
    );
};
export default Sidebar;
