import { Button, Form, Input, message, Spin, Checkbox, Row, Col } from "antd";
import Title from "../../component/PageTitle";
import { useEffect, useState } from "react";
import { useUpdateLabelsMutation } from "../../slices/apiSlice";
import { useGetLabelsQuery, useGetUserDetailQuery } from "../../slices/getSlice";
import { useDispatch } from 'react-redux';
import { colorsData } from "../../slices/authSlice";
import { useOutletContext } from "react-router-dom";
import TopHeader from "../../component/Header";
import { HomeOutlined } from '@ant-design/icons';
import { Breadcrumb } from 'antd';
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useSettingMutation } from "../../slices/settingSlice";

const MenuLabel = () => {
    const [form] = Form.useForm();
    const [headingForm] = Form.useForm()
    const [colorForm] = Form.useForm();
    const [helpFprm] = Form.useForm();
    const { loading, setLoading } = useOutletContext();
    const [isHeadingSaving, setIsHeadingSaving] = useState(false)
    const [isSidebarSaving, setIsSidebarSaving] = useState(false)
    const [isColorSaving, setIsColorSaving] = useState(false)
    const [labelUpdate] = useUpdateLabelsMutation();
    const [primaryColor, setPrimaryColor] = useState();
    const [secondaryColor, setSecondaryColor] = useState();
    const [helpChecked, setHelpChecked] = useState();
    const [walkthroughChecked, setwalkthroughChecked] = useState();
    const [updateSettings, { isLoading: isLoadingSettings }] = useSettingMutation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {
        data: labelData = {},
        error: labelsError,
        isLoading,
        refetch: refetchLabelData
    } = useGetLabelsQuery('menu');

    const {
        data: getUserDetail,
        refetch,
    } = useGetUserDetailQuery();

    const onFinish = async (value) => {
        const convertedData = {
            layout_data: {
                dashboard: value.dashboard,
                report_analysis: value.report_analysis,
                overall_performance: value.overall_performance,
                properties: value.properties,
                groups: value.groups,
                expenses: value.expenses,
                income: value.income,
                companies: value.companies,
                connect_accounts: value.connect_accounts,
                transactions: value.transactions,
                recurring_transactions: value.recurring_transactions,
                sub_accounts: value.sub_accounts
            }
        };
        setIsSidebarSaving(true);
        try {
            await labelUpdate({ label: 'menu', value: convertedData }).unwrap();
            refetchLabelData()
            message.success('Labels updated successfully!');
            refetch();
        }
        catch {
        } finally {
            setIsSidebarSaving(false);
        }
    };

    const onSubmit = async (value) => {
        const convertedData = {
            layout_data: {

                dashboard: value?.dashboard,
                portfolio: value.portfolio,
                financials: value.financials,
            }
        };
        setIsHeadingSaving(true);
        try {
            await labelUpdate({ label: 'menu_headings', value: convertedData }).unwrap();
            refetchLabelData()

            message.success('Labels updated successfully!');
            refetch();
        }
        catch {
        } finally {
            setIsHeadingSaving(false);
        }
    };

    useEffect(() => {
        if (getUserDetail?.data?.setting?.custom_data) {
            form.setFieldsValue(getUserDetail?.data?.setting?.custom_data?.menu)
            headingForm.setFieldsValue(getUserDetail?.data?.setting?.custom_data?.menu_headings)
        }
        setLoading(false)
    }, [getUserDetail])

    let LabelData = getUserDetail?.data?.setting?.custom_data?.menu;
    let menuHeading = getUserDetail?.data?.setting?.custom_data?.menu_headings;
    let colorDataValues = getUserDetail?.data?.setting?.custom_data?.colors;

    useEffect(() => {
        let helpSectionValue = getUserDetail?.data?.setting?.is_help_section_enabled;
        let WalkthroughValue = getUserDetail?.data?.setting?.is_walkthrough_enabled;
        setHelpChecked(helpSectionValue);
        setwalkthroughChecked(WalkthroughValue);
    }, [getUserDetail?.data, refetch]);

    const capitalizeFirstLetter = (string) => {
        return string?.charAt(0)?.toUpperCase() + string?.slice(1);
    };

    const onSubmitColors = async () => {
        let pColor = primaryColor !== undefined ? primaryColor : colorDataValues.primary;
        let SColor = secondaryColor !== undefined ? secondaryColor : colorDataValues.secondary;

        const colorData = {
            layout_data: {
                primary: pColor,
                secondary: SColor,
            }
        };
        setIsColorSaving(true);
        try {
            await labelUpdate({ label: 'colors', value: colorData }).unwrap();
            refetch();
        }
        catch {

        } finally {
            setIsColorSaving(false);
            dispatch(
                colorsData({
                    primary: pColor,
                    secondary: SColor
                })
            );
        }
    }

    const onSubmitHelpSection = async () => {
        try {
            let data = new FormData();

            data.append("is_help_section_enabled", helpChecked);
            data.append("is_walkthrough_enabled", walkthroughChecked);

            let settingId = getUserDetail?.data?.setting?.id;

            const response = await updateSettings({ data, id: settingId });

            if (response?.error) {
                throw new Error(response.error.message || "Failed to update settings");
            }

            refetch();
            toast.success("Settings Updated!");
        } catch (error) {
            console.error("Error updating settings:", error);
            toast.error("Failed to update settings. Please try again.");
        }
    };

    const setHelpSectionValue = (value) => {
        setHelpChecked(value);
    }
    const setwalkthroughValue = (value) => {
        setwalkthroughChecked(value);
    }

    return (
        <>
            {loading && (
                <div className="commanLoaderWrapper">
                    <Spin size="large" />
                    <h4>Setting things up..</h4>
                </div>
            )}
            <TopHeader
                title={<Title title="Labels" id="settings" />}
            />
            <div className="customPadding">
                <div className="breadcrumbWrapper">
                    <Breadcrumb
                        items={[
                            {
                                title: (
                                    <span onClick={() => navigate('/dashboard')} style={{ cursor: 'pointer' }}>
                                        <HomeOutlined />
                                    </span>
                                ),
                            },
                            {
                                title: (
                                    <>
                                        <span>Settings</span>
                                    </>
                                ),
                            },
                        ]}
                    />
                </div>
                <div className="mainWrapper">
                    <div className="card tableCard mb-4">
                        <div className="card-header">
                            <h5 className="card-title mb-0">
                                Sidebar Labels
                            </h5>
                        </div>
                        <div className="card-body">
                            <Form form={form} layout="vertical" onFinish={onFinish} className="labelsForms">
                                <div className="row">
                                    {LabelData !== undefined && Object.entries(LabelData).map(([key, value]) => (
                                        <div className="col-md-4" key={key}>
                                            <Form.Item
                                                name={key}
                                                label={capitalizeFirstLetter(value) || key}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "This field is required",
                                                    },
                                                    {
                                                        validator: (_, inputValue) => {
                                                            if (!inputValue || /^[A-Z]/.test(inputValue)) {
                                                                return Promise.resolve();
                                                            }
                                                            return Promise.reject(new Error("First letter must be capital"));
                                                        },
                                                    },
                                                ]}                                                
                                            >
                                                <Input className="form-control" type="text" />
                                            </Form.Item>
                                        </div>
                                    ))}
                                </div>

                                <div className="col-md-12 text-center mt-3">
                                    <Button type="primary" htmlType="submit" className="authBtn" disabled={isSidebarSaving}>
                                        {isSidebarSaving ? "Loading.." : "Save"}
                                    </Button>
                                </div>
                            </Form>
                        </div>
                    </div>

                    <div className="card tableCard mb-4">
                        <div className="card-header">
                            <h5 className="card-title mb-0">
                                Menu Heading Labels
                            </h5>
                        </div>
                        <div className="card-body">
                            <Form
                                form={headingForm}
                                layout="vertical"
                                onFinish={onSubmit}
                                className="labelsForms"
                            >
                                <div className="row">
                                    {menuHeading !== undefined && Object.entries(menuHeading).map(([key, value]) => (
                                        <div className="col-md-4" key={key}>
                                            <Form.Item
                                                name={key}
                                                label={capitalizeFirstLetter(value) || key}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "This field is required",
                                                    },
                                                    {
                                                        validator: (_, inputValue) => {
                                                            if (!inputValue || /^[A-Z]/.test(inputValue)) {
                                                                return Promise.resolve();
                                                            }
                                                            return Promise.reject(new Error("First letter must be capital"));
                                                        },
                                                    },
                                                ]}
                                            >
                                                <Input className="form-control" type="text" />
                                            </Form.Item>
                                        </div>
                                    ))}
                                    <div className="col-md-12 text-center mt-3">
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            className={"authBtn"}
                                            disabled={isHeadingSaving}
                                        >
                                            {isHeadingSaving ? "Loading.." : "Save"}
                                        </Button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>

                    <div className="card tableCard mb-4 colorsCard">
                        <div className="card-header">
                            <h5 className="card-title mb-0">Theme Colors</h5>
                        </div>
                        <div className="card-body">
                            <Form
                                form={colorForm}
                                layout="vertical"
                                onFinish={onSubmitColors}
                                className="colorForms"
                            >
                                <div className="formWrapper">
                                    <label>Primary Color</label>
                                    <Form.Item name="primary_color">
                                        <div className="colorPickerContainer">
                                            <input
                                                type="color"
                                                value={primaryColor || colorDataValues?.primary}
                                                onChange={(e) => setPrimaryColor(e.target.value)}
                                                style={{ width: '180px', padding: '8px' }}
                                            />
                                        </div>
                                    </Form.Item>
                                </div>
                                <div className="formWrapper">
                                    <label>Secondary Color</label>
                                    <Form.Item name="secondary_color">
                                        <div className="colorPickerContainer">
                                            <input
                                                type="color"
                                                value={secondaryColor || colorDataValues?.secondary}
                                                onChange={(e) => setSecondaryColor(e.target.value)}
                                                style={{ width: '180px', padding: '8px' }}
                                            />
                                        </div>
                                    </Form.Item>
                                </div>
                                <hr />
                                <div className="text-center mt-3">
                                    <Button type="primary" htmlType="submit" size="medium" className={"authBtn"} disabled={isColorSaving}>
                                        {isColorSaving ? "Loading.." : "Save"}
                                    </Button>
                                </div>
                            </Form>
                        </div>
                    </div>

                    <div className="card tableCard mb-4">
                        <div className="card-header">
                            <h5 className="card-title mb-0">
                                Help Section Customization
                            </h5>
                        </div>
                        <div className="card-body">
                            <Form
                                form={helpFprm}
                                layout="vertical"
                                onFinish={onSubmitHelpSection}
                                className="helpSectionForm"
                            >
                                <Row className="row">
                                    <Col md={8} xs={24}>
                                        <Form.Item name="help" valuePropName="checked">
                                            <div className="colorPickerContainer">
                                                <Checkbox
                                                    className="help-checkbox-change"
                                                    checked={helpChecked}
                                                    onChange={(e) => setHelpSectionValue(e.target.checked)}
                                                >
                                                    <span className="view-help-text">Help Section {helpChecked ? 'Enabled' : 'Disabled'}</span>
                                                </Checkbox>
                                            </div>
                                        </Form.Item>
                                    </Col>
                                    <Col md={8} xs={24}>
                                        <Form.Item name="walkthrough" valuePropName="checked">
                                            <div className="colorPickerContainer">
                                                <Checkbox
                                                    className="help-checkbox-change"
                                                    checked={walkthroughChecked}
                                                    onChange={(e) => setwalkthroughValue(e.target.checked)}
                                                >
                                                    <span className="view-help-text">Walkthrough {walkthroughChecked ? 'Enabled' : 'Disabled'}</span>
                                                </Checkbox>
                                            </div>
                                        </Form.Item>
                                    </Col>
                                    <div className="col-md-12 text-center mt-3">
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            className={"authBtn"}
                                            disabled={isLoadingSettings}
                                        >
                                            {isLoadingSettings ? "Loading.." : "Save"}
                                        </Button>
                                    </div>
                                </Row>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MenuLabel;
