export const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = `${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}-${date.getFullYear()}`;
    return formattedDate;
};

export const capitalizeFirstLetter = (string) => {
    if (typeof string !== 'string') {
        console.error('capitalizeFirstLetter: Expected a string but received:', string);
        return '';
    }
    return string?.charAt(0)?.toUpperCase() + string?.slice(1);
}; 