import React, { useEffect, useState } from "react";
import { Button, Form, Input, Select, Row, Col, Modal, Spin } from "antd";

const CommonModal = ({
    title,
    open,
    close,
    form,
    onFinish,
    loading,
    fields,
    FormInitialValues = {},
    formType,
    className
}) => {
    const [formValues, setFormValues] = useState();
    const [disabledBtn, setDisabledButton] = useState(false);
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        setLoader(true);
        setTimeout(() => {
            form.setFieldsValue({
                id: FormInitialValues.id,
                name: FormInitialValues.name,
                properties: FormInitialValues?.properties?.length > 0 && FormInitialValues.properties.map((data) => ({
                    label: data?.name_of_property,
                    value: data?.id,
                })),
            });
            setLoader(false);
        }, 500);
    }, [FormInitialValues]);

    const [dropdownVisible, setDropdownVisible] = useState(false);

    const handleDropdownVisibleChange = (value) => {
        if (value) {
            setDropdownVisible(true);
        } else {
            setDropdownVisible(false);
        }
    };

    const handleValuesChange = (allValues) => {
        setDisabledButton(false);
        if (title === "Edit Group" || title === "Add Group") {
            setFormValues({ ...allValues });
        } else {
            setFormValues(allValues);
        }
    };

    const handleOnFinish = (values) => {
        setDisabledButton(true);
        onFinish(values, formType);
        setDisabledButton(false);
    };

    return (
        <Modal
            forceRender
            open={open}
            onOk={handleOnFinish}
            title={title}
            onCancel={close}
            footer={null}
            className={className + " assign_model"}
        >
            {loader ? (
                <>
                    <div className="modalLoaderWrapper">
                        <Spin />
                    </div>
                </>
            ) : (
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={handleOnFinish}
                    onValuesChange={handleValuesChange}
                    initialValues={FormInitialValues ? FormInitialValues : formValues}
                >
                    <Row>
                        {fields.map((field, index) => (
                            <Col
                                key={field?.name}
                                md={field?.colSpan || 24}
                                offset={field?.offset || 0}
                            >
                                {field.component === "input" && (
                                    <Form.Item
                                        label={field?.label}
                                        name={field?.name}
                                        rules={field?.rules}
                                    >
                                        {field.type === "password" ? (
                                            <Input.Password
                                                placeholder={field.placeholder}
                                                prefix={field.prefix}
                                                type={field.type}
                                                className="form-control"
                                            />
                                        ) : (
                                            <Input
                                                placeholder={field.placeholder}
                                                prefix={field.prefix}
                                                type={field.type}
                                                className="form-control"
                                            />
                                        )}
                                    </Form.Item>
                                )}
                                {field.component === "select" && (
                                    <Form.Item
                                        label={field.label}
                                        name={field.name}
                                        rules={field.rules}
                                    >
                                        <Select
                                            id={index}
                                            showSearch={field.mode === 'multiple'}
                                            onSelect={() => setDropdownVisible(false)}
                                            onSearch={handleDropdownVisibleChange}
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            className="formControl select-custom"
                                            mode={field.mode || ""}
                                            optionLabelProp="label"
                                            suffixIcon={field.suffixIcon}
                                            placeholder={field.placeholder}
                                            options={field.options}
                                            onChange={(value) => {
                                                setFormValues((prevValues) => ({
                                                    ...prevValues,
                                                    [field.name]: value,
                                                }));
                                                if (field?.onChange) {
                                                    field?.onChange(value);
                                                }
                                            }
                                            }
                                        />
                                    </Form.Item>
                                )}
                            </Col>
                        ))}
                    </Row>
                    <Row>
                        <Col
                            md={24}
                            className="d-flex justify-content-center align-items-center"
                        >
                            <Button type="primary" htmlType="submit" className="authBtn" disabled={disabledBtn === true ? true : false}>
                                {loading ? "Loading..." : "Save"}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            )}

        </Modal>
    );
};

export default CommonModal;