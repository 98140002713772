import React from 'react';
import { useGetLabelsQuery } from "../../slices/getSlice";

const Title = ({ title, subtitle, id, className }) => {
    const {
        data: labelData = {},
        error: labelsError,
        isLoading,
    } = useGetLabelsQuery('menu');

    const capitalizeFirstLetter = (string) => {
        if (typeof string !== 'string') {
            console.error('capitalizeFirstLetter: Expected a string but received:', string);
            return '';
        }
        return string.charAt(0).toUpperCase() + string.slice(1);
    };


    if (isLoading) {
        return <p>Loading...</p>;
    }

    if (labelsError) {
        return <p>Error loading labels</p>;
    }

    const findTitle = labelData[id] || title;

    return (
        <div className={className !== undefined ? 'customTitleWrapper titleWrapper' : 'titleWrapper'}>
            <h2 className='cardTitle'>{capitalizeFirstLetter(findTitle)}</h2>
            <h5 className={subtitle ? 'subtitle' : 'hide'}>{subtitle || ''}</h5>
        </div>
    );
};

export default Title;
