import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "../AuthHelper";

export const getApi = createApi({
  reducerPath: "getApi",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    ownerType: builder.query({
      query: (token) => ({
        url: "owner/type",
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "application/json; charset=UTF-8",
          Accept: "application/json",
        },
      }),
      providesTags: ["Owner"],
    }),
    getProperties: builder.query({
      query: (data) => ({
        url: `properties${data !== undefined && data !== null ? data : ""}`,
        method: "GET",
      }),
      providesTags: ["Property"],
    }),
    getGroup: builder.query({
      query: () => ({
        url: "group/properties",
        method: "GET",
      }),
      providesTags: ["Group"],
    }),
    getUtilityCategory: builder.query({
      query: () => ({
        url: "provider_types",
        method: "GET",
      }),
      providesTags: ["Providertype"],
    }),
    getUtility: builder.query({
      query: (provider_type_id) => {
        if (provider_type_id) {
          return {
            url: `providers/${provider_type_id}`,
            method: "GET",
          }
        }
      },
      providesTags: ["Provider"],
    }),
    getCompany: builder.query({
      query: (provider_id) => {
        if (provider_id) {
          return {
            url: `companies/${provider_id}`,
            method: "GET",
          }
        }
      },
      providesTags: ["Companies"],
    }),
    getFields: builder.query({
      query: (provider_id) => {
        if (provider_id) {
          return {
            url: `fields/${provider_id}`,
            method: "GET",
          }
        }
      },
      providesTags: ["Fields"],
    }),
    getPropertyProviders: builder.query({
      query: (data) => {
        if (data.propertyId && data.providerTypesId && data.skip)
          return {
            url: `property/${data.propertyId}/provider_types/${data.providerTypesId}${data?.query ? data?.query : ""}`,
            method: "GET",
          }
      },
      providesTags: ["Propertyproviders"],
    }),
    getPropertyProvidersByStreamId: builder.query({
      query: (id) => {
        if (id)
          return {
            url: `property_provider/${id}`,
            method: "GET",
          }
      },
      providesTags: ["PropertyProviderStreamId"],
    }),
    propetyType: builder.query({
      query: (token) => ({
        url: "property/type",
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "application/json; charset=UTF-8",
          Accept: "application/json",
        },
      }),
      providesTags: ["Property"],
    }),
    getProviderTypeFromPropertyProvider: builder.query({
      query: ({ property_id, query }) => {
        if (property_id) {
          return {
            url: `provider_types/${property_id}${query}`,
            method: "GET",
          };
        }
      },
      providesTags: ["Providertype"],
      skip: (args) => args.property_id === undefined,
    }),
    getCategory: builder.query({
      query: (type) => {
        if (type) {
          return {
            url: `categories/${type}`,
            method: "GET",
          }
        }
      },
      providesTags: ["Category"],
    }),
    getNewCompanyList: builder.query({
      query: (data) => {
        if (!data || Object.keys(data).length === 0) {
          return {
            url: `get_companies`,
            method: "GET",
          };
        }

        let queryString = Object.entries(data)
          .map(([key, value]) => {
            if (key === "start_date" || key === "end_date") {
              const formattedDate = value?.replace(/%2F/g, "/");
              return `${encodeURIComponent(key)}=${formattedDate}`;
            }
            return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
          })
          .join("&");

        queryString = queryString ? `?${queryString}` : "";

        return {
          url: `get_companies${queryString}`,
          method: "GET",
        };
      },
      providesTags: ["NewCompanyList"],
    }),
    getIncome: builder.query({
      query: (data) => ({
        url: `incomes${data !== undefined && data !== null ? data : ""}`,
        method: "GET",
      }),
      providesTags: ["Income"],
    }),
    getTotalIncome: builder.query({
      query: (query) => ({
        url: `total_income${query}`,
        method: "GET",
      }),
      providesTags: ["TotalIncome"],
    }),
    getTotalExpense: builder.query({
      query: (query) => ({
        url: `total_expense${query}`,
        method: "GET",
      }),
      providesTags: ["TotalExpense"],
    }),
    getProfitLoss: builder.query({
      query: (query) => ({
        url: `profit_loss${query}`,
        method: "GET",
      }),
      providesTags: ["ProfitLoss"],
    }),
    getCurrency: builder.query({
      query: () => ({
        url: `currencies`,
        method: "GET",
      }),
      providesTags: ["Currency"],
    }),
    getUserDetail: builder.query({
      query: () => ({
        url: `user/detail`,
        method: "GET",
      }),
      providesTags: ["Detail"],
    }),
    viewProperty: builder.query({
      query: (id) => {
        if (id) {
          return {
            url: `properties/${id}`,
            method: "GET",
          };
        }
      },
      providesTags: ["ViewProperty"],
    }),
    getSubAccount: builder.query({
      query: (query) => ({
        url: `sub-account${query}`,
        method: "GET",
      }),
      providesTags: ["SubAccount"],
    }),
    getCountries: builder.query({
      query: () => ({
        url: "countries",
        method: "GET",
      }),
      providesTags: ["Country"],
    }),
    getState: builder.query({
      query: (id) => {
        if (id) {
          return {
            url: `states/${id}`,
            method: "GET",
          };
        }
      },
      providesTags: ["State"],
    }),
    getCity: builder.query({
      query: (id) => {
        if (id) {
          return {
            url: `cities/${id}`,
            method: "GET",
          };
        }
      },
      providesTags: ["City"],
    }),
    getAssignProperty: builder.query({
      query: (id) => {
        if (id) {
          return {
            url: `user/${id}/properties`,
            method: "GET",
          };
        }
      },
      providesTags: ["AssignProperty"],
    }),
    categorizePlaidData: builder.query({
      query: (data) => {
        let url = "transactions";
        const params = [];

        if (data?.pageOptions) {
          params.push(data?.pageOptions);
        }
        if (typeof data?.is_recurring !== "undefined") {
          params.push(`is_recurring=${data.is_recurring}`);
        }
        if (data?.start_date) {
          params.push(`start_date=${data.start_date}`);
        }

        if (data?.filter) {
          params.push(`status=${data?.filter}`);
        }

        if (data?.end_date) {
          params.push(`end_date=${data.end_date}`);
        }
        if (data?.query && data?.query !== undefined && data?.query !== null) {
          params.push(data?.query);
        }
        if (data?.searchText && data?.searchText !== undefined && data?.searchText !== null) {
          params.push(data?.searchText);
        }
        if (params.length) {
          url += `?${params.join("&")}`;
        }

        return {
          url,
          method: "GET",
        };
      },
      providesTags: ["CategorizePlaid"],
    }),
    getLabels: builder.query({
      query: (label) => ({
        url: `form-label/${label}`,
        method: "GET",
      }),
      providesTags: ["labelPlaid"],
    }),
    monthlyExpense: builder.query({
      query: (data) => {
        return {
          url: `monthly-expense?type=${data}`,
          method: "GET",
        }
      },
      providesTags: ["MonthlyExpense"],
    }),
    getAllExpense: builder.query({
      query: ({ provider_type_id, property_id, category_id, search, page, perPage, sort_by, sort_direction, startDate, endDate, property } = {}) => {
        let url = 'get-expense';

        const queryParams = [];

        if (provider_type_id?.length > 0) {
          queryParams.push(`provider_type_id=${provider_type_id}`);
        }

        if (property_id?.length > 0) {
          queryParams.push(`property_id=${property_id}`);
        }

        if (category_id?.length > 0) {
          queryParams.push(`category_id=${category_id}`);
        }

        if (search) {
          queryParams.push(`search=${search}`);
        }

        if (page) {
          queryParams.push(`page=${page}`);
        }

        if (perPage) {
          queryParams.push(`per_page=${perPage}`);
        }

        if (sort_direction) {
          queryParams.push(`sort_direction=${sort_direction}`);
        }
        if (sort_by) {
          queryParams.push(`sort_by=${sort_by}`);
        }
        if (startDate) {
          queryParams.push(`start_date=${startDate}`);
        }
        if (endDate) {
          queryParams.push(`end_date=${endDate}`);
        }
        if (property !== undefined) {
          queryParams.push(`property_ids=${property}`);
        }
        if (queryParams.length > 0) {
          url += `?${queryParams.join('&')}`;
        }

        return {
          url,
          method: "GET",
        };
      },

      providesTags: ["AllExpense"],
    }),

    genrateLinkToken: builder.query({
      query: (query) => {
        return {
          url: `generate_plaid_token${query}`,
          method: "GET",
        }
      },
      providesTags: ["GenrateLinkToken"],
    }),
    getStatistics: builder.query({
      query: ({ filter_type, filter_value, property_ids, start_date, end_date } = {}) => {

        let url = `get_statistics`;
        const queryParams = [];

        if (filter_type) queryParams.push(`filter_type=${filter_type}`);
        if (filter_value) queryParams.push(`filter_value=${filter_value}`);
        if (property_ids) queryParams.push(`property_ids=${property_ids}`);
        if (start_date !== undefined && start_date !== 'Invalid Date') queryParams.push(`start_date=${start_date}`);
        if (end_date !== 'Invalid Date' && end_date !== undefined) queryParams.push(`end_date=${end_date}`);

        if (queryParams.length) {
          url += `?${queryParams.join('&')}`;
        }

        return {
          url,
          method: "GET",
        };
      },
      providesTags: ["Statistics"],
    }),
    getTopRevenueProperties: builder.query({
      query: ({ filter_type, filter_value, property_ids, start_date, end_date } = {}) => {
        let url = `top-revenue-properties`;
        const queryParams = [];

        if (filter_type) queryParams.push(`filter_type=${filter_type}`);
        if (filter_value) queryParams.push(`filter_value=${filter_value}`);
        if (property_ids) queryParams.push(`property_ids=${property_ids}`);
        if (start_date !== undefined && start_date !== 'Invalid Date' && start_date !== null) queryParams.push(`start_date=${start_date}`);
        if (end_date !== 'Invalid Date' && end_date !== undefined && end_date !== null) queryParams.push(`end_date=${end_date}`);

        if (queryParams.length) {
          url += `?${queryParams.join('&')}`;
        }

        return {
          url,
          method: "GET",
        };
      },
      providesTags: ["Revenue"],
    }),
    getTopExpenses: builder.query({
      query: ({ filter_type, filter_value, property_ids, start_date, end_date } = {}) => {
        let url = `top-expense-properties`;
        const queryParams = [];

        if (filter_type) queryParams.push(`filter_type=${filter_type}`);
        if (filter_value) queryParams.push(`filter_value=${filter_value}`);
        if (property_ids) queryParams.push(`property_ids=${property_ids}`);
        if (start_date !== undefined && start_date !== 'Invalid Date' && start_date !== null) queryParams.push(`start_date=${start_date}`);
        if (end_date !== 'Invalid Date' && end_date !== undefined && end_date !== null) queryParams.push(`end_date=${end_date}`);

        if (queryParams.length) {
          url += `?${queryParams.join('&')}`;
        }

        return {
          url,
          method: "GET",
        };
      },
      providesTags: ["topExpenseProperties"],
    }),
    getTopProfitableProperties: builder.query({
      query: ({ filter_type, filter_value, property_ids, start_date, end_date } = {}) => {
        let url = `top-profitable-properties`;
        const queryParams = [];

        if (filter_type) queryParams.push(`filter_type=${filter_type}`);
        if (filter_value) queryParams.push(`filter_value=${filter_value}`);
        if (property_ids) queryParams.push(`property_ids=${property_ids}`);
        if (start_date !== 'Invalid Date' && start_date !== undefined && start_date !== null) queryParams.push(`start_date=${start_date}`);
        if (end_date !== 'Invalid Date' && end_date !== undefined && end_date !== null) queryParams.push(`end_date=${end_date}`);

        if (queryParams.length) {
          url += `?${queryParams.join('&')}`;
        }

        return {
          url,
          method: "GET",
        };
      },
      providesTags: ["Profitable"],
    }),

    getTopRevenueGroups: builder.query({
      query: ({ property_ids, start_date, end_date } = {}) => {
        let url = `top-group-revenue`;
        const queryParams = [];

        if (property_ids) queryParams.push(`property_ids=${property_ids}`);
        if (start_date !== 'Invalid Date' && start_date !== undefined) queryParams.push(`start_date=${start_date}`);
        if (end_date !== 'Invalid Date' && end_date !== undefined) queryParams.push(`end_date=${end_date}`);

        if (queryParams.length) {
          url += `?${queryParams.join('&')}`;
        }

        return {
          url,
          method: "GET",
        };
      },
      providesTags: ["topGroups"],
    }),

    getRevenueGrowthComparison: builder.query({
      query: ({ filter_type, filter_value, property_ids, start_Date, end_Date } = {}) => {
        let url = `revenue-growth-comparison`;
        const queryParams = [];

        if (filter_type) queryParams.push(`filter_type=${filter_type}`);
        if (filter_value) queryParams.push(`filter_value=${filter_value}`);
        if (property_ids) queryParams.push(`property_ids=${property_ids}`);
        if (start_Date) queryParams.push(`start_date=${start_Date}`);
        if (end_Date) queryParams.push(`end_Date=${end_Date}`);

        if (queryParams.length) {
          url += `?${queryParams.join('&')}`;
        }

        return {
          url,
          method: "GET",
        };
      },
      providesTags: ["Comparison"],
    }),
    reportAnalysis: builder.query({
      query: ({ property, start_date, end_date, type, sort_by, sort_direction, startDate, endDate } = {}) => {
        let url = `insight_report`;
        const queryParams = [];
        if (typeof property !== 'undefined') {
          queryParams.push(`property_ids=${property}`);
        }
        if (start_date) queryParams.push(`start_date=${start_date}`);
        if (end_date) queryParams.push(`end_date=${end_date}`);
        if (type) queryParams.push(`type=${type}`);
        if (sort_by) queryParams.push(`sort_by=${sort_by}`);
        if (sort_direction) queryParams.push(`sort_direction=${sort_direction}`);
        if (startDate) queryParams.push(`start_date=${startDate}`);
        if (endDate) queryParams.push(`end_date=${endDate}`);

        if (queryParams.length) {
          url += `?${queryParams.join('&')}`;
        }

        return {
          url,
          method: "GET",
        };
      },
      providesTags: ["ReportAnalysis"],
    }),
    categorizePlaid: builder.query({
      query: () => {
        return {
          url: `categorize_plaid_data`,
          method: "GET",
        }
      },
      providesTags: ["CategorizePlaidData"],
    }),
    getAllPaymentData: builder.query({
      query: (type) => {
        if (type) {
          return {
            url: `payment_type/${type}`,
            method: "GET",
          }
        }
      },
      providesTags: ["getPayemntData"],
    }),

    getExpenseDataById: builder.query({
      query: (type) => {
        if (type) {
          return {
            url: `get_expenses/${type}`,
            method: "GET",
          }
        }
      },
      providesTags: ["getExpenseData"],
    }),

    getMerchants: builder.query({
      query: () => {
        return {
          url: `get_merchants`,
          method: "GET",
        }
      },
      providesTags: ["getMerchant"],
    }),

    getPaymentMethods: builder.query({
      query: () => {
        return {
          url: `/payment-modes`,
          method: "GET",
        }
      },
      providesTags: ["paymentModes"],
    }),

    getHelpContent: builder.query({
      query: (slug) => {
        return {
          url: `/cms-page${slug}`,
          method: "GET",
        }
      },
      providesTags: ["getHelpContent"],
    }),

    getReleaseVersion: builder.query({
      query: () => {
        return {
          url: `/release-version`,
          method: "GET",
        }
      },
      providesTags: ["getReleaseVersion"],
    }),

    getIncomeName: builder.query({
      query: () => {
        return {
          url: `/get_income_names`,
          method: "GET",
        }
      },
      providesTags: ["getIncomeName"],
    }),

  }),

  tagTypes: [
    "Owner",
    "Property",
    "Group",
    "Providertype",
    "Fields",
    "Provider",
    "ViewProperty",
    "Companies",
    "Propertyproviders",
    "Providertype",
    "Category",
    "Income",
    "TotalIncome",
    "TotalExpense",
    "ProfitLoss",
    "Detail",
    "Statistics",
    "Revenue",
    "SubAccount",
    "Country",
    "State",
    "City",
    "AssignProperty",
    "CategorizePlaid",
    "labelPlaid",
    "PropertyProviderStreamId",
    "MonthlyExpense",
    "AllExpense",
    "GenrateLinkToken",
    "Comparison",
    "Profitable",
    "Statistics",
    "ReportAnalysis",
    "CategorizePlaidData",
    "NewCompanyList",
    "getAllExpenseData",
    "getPayemntData",
    "getExpenseData",
    "getMerchant",
    "paymentModes",
    "topGroups",
    "topExpenseProperties",
    "getHelpContent",
    "getReleaseVersion",
    "getIncomeName"
  ],
});

export const {
  useOwnerTypeQuery,
  useGetPropertiesQuery,
  useGetGroupQuery,
  usePropetyTypeQuery,
  useGetUtilityCategoryQuery,
  useGetUtilityQuery,
  useGetCompanyQuery,
  useGetFieldsQuery,
  useGetPropertyProvidersQuery,
  useGetPropertyProvidersByStreamIdQuery,
  useGetProviderTypeFromPropertyProviderQuery,
  useGetCategoryQuery,
  useGetIncomeQuery,
  useGetTotalIncomeQuery,
  useGetTotalExpenseQuery,
  useGetProfitLossQuery,
  useGetCurrencyQuery,
  useGetUserDetailQuery,
  useViewPropertyQuery,
  useLazyGetTopRevenuePropertiesQuery,
  useGetSubAccountQuery,
  useGetCountriesQuery,
  useGetStateQuery,
  useGetCityQuery,
  useGetAssignPropertyQuery,
  useCategorizePlaidDataQuery,
  useGetLabelsQuery,
  useMonthlyExpenseQuery,
  useGetAllExpenseQuery,
  useGenrateLinkTokenQuery,
  useLazyGetRevenueGrowthComparisonQuery,
  useLazyGetStatisticsQuery,
  useLazyGetTopProfitablePropertiesQuery,
  useReportAnalysisQuery,
  useCategorizePlaidQuery,
  useGetNewCompanyListQuery,
  useGetAllPaymentDataQuery,
  useGetExpenseDataByIdQuery,
  useGetMerchantsQuery,
  useGetPaymentMethodsQuery,
  useLazyGetTopRevenueGroupsQuery,
  useLazyGetTopExpensesQuery,
  useLazyGetHelpContentQuery,
  useLazyGetReleaseVersionQuery,
  useGetIncomeNameQuery
} = getApi;
