export const view_property = [
    {
        selector: '.step-1',
        content: 'Click on the Add new button to add property.',
    },
    {
        selector: '.step-2',
        content: 'This section will show you the list of all the properties. ',
    },
]

export const sub_account = [
    {
        selector: '.step-one',
        content: 'Click on the Add new button to create sub account.',
    },
    {
        selector: '.step-two',
        content: 'This section will show you the list of all the sub accounts.',
    },
]

export const expense = [
    {
        selector: '.step-one',
        content: 'Click on the Add new button to add expense.',
    },
    {
        selector: '.step-two',
        content: 'This will show the list of all the expenses.',
    },
]

export const income = [
    {
        selector: '.step-one',
        content: 'Click on the Add new button to add income.',
    },
    {
        selector: '.step-two',
        content: 'This will show the list of all the incomes.',
    },
]

export const companies = [
    {
        selector: '.step-one',
        content: 'Click on the Add new button to add company.',
    },
    {
        selector: '.step-two',
        content: 'This will show the list of all the companies.',
    },
]

export const recurring = [
    // {
    //     selector: '.step-one',
    //     content: 'Click on this button to add your recurring transactions from plaid.',
    // },
    {
        selector: '.step-two',
        // content: 'Click on this button to link your plaid account and fetch live transaction.',
        content: 'Click on this button to refresh your transactions.',
    },
    {
        selector: '.step-three',
        content: 'This section will show you the list of all the transactions fetched through plaid.',
    },
]

export const bank_account = [
    {
        selector: '.step-one',
        content: 'Click on this button to link your plaid account and fetch live transaction.',
    },
    {
        selector: '.step-two',
        content: 'This section will show you the list of all the connected bank accounts.',
    },
]

export const all_transaction = [
    {
        selector: '.step-two',
        content: 'Click on this button to refresh your transactions',
    },
    {
        selector: '.step-three',
        content: 'This section will show you the list of all the transactions fetched through plaid.',
    },
]

export const manage_property = [
    {
        selector: '.step-one',
        content: 'Click on this button to add a new group under which you can add multiple properties..',
    },
]

