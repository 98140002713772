import React, { useEffect, useState } from "react";
import { Button, Form, Input, Modal, Spin, message, Select, Row, Col, Space } from "antd";
import Title from "../../component/PageTitle";
import {
    useGetPropertiesQuery,
    useOwnerTypeQuery,
    useGetLabelsQuery
} from "../../slices/getSlice";
import {
    useAddOwnerTypeMutation,
    useEditSubAccountMutation,
    useSubAccountMutation,
} from "../../slices/apiSlice";
import { toast } from "react-toastify";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { useUpdateLabelsMutation } from "../../slices/apiSlice";
import TopHeader from "../../component/Header";
import { HomeOutlined } from '@ant-design/icons';
import { Breadcrumb } from 'antd';
import { FaRegUser, FaRegEnvelope, FaHouseUser } from "react-icons/fa";
import { BsBuildingCheck } from "react-icons/bs";

const CreateSubAccount = () => {
    const { data: getProperties, refetch: refetchProperties } = useGetPropertiesQuery();
    const navigate = useNavigate();
    const location = useLocation();
    const [subAccountForm] = Form.useForm();
    const [labelForm] = Form.useForm();
    const { loading, setLoading } = useOutletContext();
    const [ownerType, setOwnerType] = useState();
    const [properties, setProperties] = useState([]);
    const [labelsModal, setLabelsModal] = useState(false);
    const [FormInitialValues, setFormInitialValues] = useState(location?.state);
    const [labelUpdate] = useUpdateLabelsMutation();
    const [inputValue, setInputValue] = useState("");
    const [newlyAddedOwner, setNewlyAddedOwner] = useState();

    const {
        data: ownertype,
        isLoading,
        isError,
        refetch: refetchOwnerType,
        error
    } = useOwnerTypeQuery();

    const [addOwnerType, { isLoading: isLoadingAddOwnerType }] = useAddOwnerTypeMutation();
    const [editSubAccount, { isLoading: isLoadingEdit }] = useEditSubAccountMutation();
    const [subAccount, { isLoading: isLoadingAdd }] = useSubAccountMutation();

    useEffect(() => {
        refetchProperties()
    }, [])
    useEffect(() => {
        if (ownertype && ownertype.data) {
            const transformedOptions = ownertype.data.map((item) => ({
                label: item.name,
                value: item.id,
            }));
            setOwnerType(transformedOptions);
            setLoading(false)
        }

        if (getProperties && getProperties?.data) {
            const transformedOptions = getProperties?.data?.map((item) => ({
                label: item.name_of_property,
                value: item.id,
            }));
            setProperties(transformedOptions);
        }

    }, [ownertype, getProperties]);


    const {
        data: labelData,
        error: labelsError,
        refetch: refetchLabels
    } = useGetLabelsQuery('sub_account_form');


    useEffect(() => {
        labelForm.setFieldsValue(labelData)
    }, [labelData])

    useEffect(() => {
        if (location?.state) {
            const initialValues = {
                ...location.state,
                property_id: location.state.properties.map(prop => prop.property_id),
            };
            setFormInitialValues(initialValues);
            subAccountForm.setFieldsValue(initialValues);
        }
    }, [location?.state]);

    useEffect(() => {
        if (getProperties && getProperties?.data) {
            const transformedOptions = getProperties.data.map(item => ({
                label: item.name_of_property,
                value: item.id,
            }));
            setProperties(transformedOptions);
        }
    }, [getProperties]);

    useEffect(() => {
        if (newlyAddedOwner) {
            subAccountForm.setFieldsValue({
                "ownership_type_id": newlyAddedOwner
            });
        }
    }, [newlyAddedOwner]);

    useEffect(() => {
        if (properties.length > 0 && FormInitialValues) {
            subAccountForm.setFieldsValue({
                ...FormInitialValues,
                property_id: FormInitialValues.property_id || [],
            });
        }
    }, [properties, FormInitialValues]);

    if (isLoading) return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <Spin />
    </div>;
    if (isError) return <p>Error fetching product.</p>;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    const onFinish = async (values) => {
        try {
            let id = location?.state?.id;
            let formValue = {
                first_name: values?.first_name ? values?.first_name : location?.state?.first_name,
                last_name: values?.last_name ? values?.last_name : location?.state?.last_name,
                ownership_type_id: values?.ownership_type_id ? values?.ownership_type_id : location?.state?.ownership_type_id,
                property_id: values?.property_id ? values?.property_id : location?.state?.property_id,
            }
            if (location?.state?.id) {
                if (typeof formValue?.ownership_type_id == "string") {
                    let ownerTypeData = ownerType.find(
                        (owner) => owner.label === formValue.ownership_type_id
                    );
                    formValue.ownership_type_id = ownerTypeData?.value;
                }
                let filterdProperties = [];
                if (formValue?.property_id?.length) {
                    formValue?.property_id?.map((item) => {
                        if (typeof item === "number") {
                            filterdProperties.push(item);
                        } else {
                            const findProperty = properties?.find((record) => {
                                return item === record.label;
                            });

                            if (findProperty !== undefined) {
                                filterdProperties.push(findProperty.value);
                            }
                        }
                    });
                    formValue.property_id = filterdProperties;
                }
                const response = await editSubAccount({ data: formValue, id });
                if (response.data) {
                    toast.success("SubAccount has been updated successfully");
                    navigate("/sub_account");
                } else {
                    toast.error(`${response?.error?.data?.message}`);
                }
            } else {
                const response = await subAccount(values);
                if (response?.data) {
                    toast.success(`${response?.data?.message}`);
                    navigate("/sub_account");
                } else {
                    toast.error(`${response?.error?.data?.message}`);
                }
            }
        } catch { } finally {
        }

    };

    const AddOwnerType = async (value) => {
        let formData = {
            name: value
        }
        try {
            const res = await addOwnerType(formData);
            if (res?.data) {
                toast.success("Owner Type has been created successfully");
                let newOwnerData = await refetchOwnerType();
                let findNewlyAdded = newOwnerData.data?.data?.find((item) => item?.name === value);
                setNewlyAddedOwner(findNewlyAdded?.id);
            } else {
                toast.error(`${res?.error?.data?.message}`);
            }
        } catch (error) {
            toast.error("An error occurred while adding owner type.");
        } finally {
        }
    };

    const openLabelModal = () => {
        setLabelsModal(true);
    };

    const handleLabelModal = () => {
        setLabelsModal(false);
    };

    const updateLabels = async (value) => {
        const convertedData = {
            layout_data: {
                first_name: value.first_name !== undefined ? value.first_name : labelData.first_name,
                last_name: value.last_name !== undefined ? value.last_name : labelData.last_name,
                email: value.email !== undefined ? value.email : labelData.email,
                owner_type: value.owner_type !== undefined ? value.email : labelData.email,
                select_property: value.select_property !== undefined ? value.select_property : labelData.select_property,
            }
        };
        try {
            await labelUpdate({ label: 'sub_account_form', value: convertedData }).unwrap();
            message.success('Labels updated successfully!');
            refetchLabels();
        }
        catch {
        } finally {
            setLabelsModal(false);
            labelForm.resetFields();
        }
    }

    const handleOwnerFormSubmit = () => {
        AddOwnerType(inputValue);
    }

    const handleSearch = (value) => {
        setInputValue(value);
    };

    const capitalizeFirstLetter = (string) => {
        return string?.charAt(0)?.toUpperCase() + string?.slice(1);
    };

    return (
        <>
            {loading && (
                <div className="commanLoaderWrapper">
                    <Spin size="large" />
                    <h4>Setting things up..</h4>
                </div>
            )}
            <TopHeader
                title={<Title title={FormInitialValues?.id ? 'Edit Sub Account' : "Create Sub Account"} />}
                button={
                    <>
                        {FormInitialValues?.id ? '' : <Button
                            type="primary"
                            className="btnOutlined "
                            onClick={() => {
                                openLabelModal()
                            }}
                        >
                            Change Labels
                        </Button>}
                    </>
                }
            />
            <div className="customPadding">
                <div className="breadcrumbWrapper">
                    <Breadcrumb
                        items={[
                            {
                                title: (
                                    <span onClick={() => navigate('/dashboard')} style={{ cursor: 'pointer' }}>
                                        <HomeOutlined />
                                    </span>
                                ),
                            },
                            {
                                title: (
                                    <>
                                        <span>Sub Accounts</span>
                                    </>
                                ),
                            },
                            {
                                title: (
                                    <>
                                        <span>{FormInitialValues?.id ? 'Edit' : 'Create'} Sub Account
                                        </span>
                                    </>
                                ),
                            },
                        ]}
                    />
                </div>
                <div className="mainWrapper">
                    <div className="card tableCard subAccountCard">
                        <div className="card-body">
                            <Form
                                form={subAccountForm}
                                name="subAccountForm"
                                onFinish={onFinish}
                                initialValues={FormInitialValues}
                                layout="vertical"
                            >
                                <Row gutter={16}>
                                    <Col md={12}>
                                        <Form.Item
                                            name="first_name"
                                            label={capitalizeFirstLetter(labelData?.first_name) || "First Name"}
                                            rules={[
                                                { required: true, message: "Please input your First Name!" },
                                                { pattern: /^(?!\s)[A-Za-z\s]+(?<!\s)$/, message: "First name must only contain letters" },
                                            ]}
                                        >
                                            <Input
                                                placeholder="Enter First Name"
                                                className="form-control"
                                                prefix={<FaRegUser style={{ color: "#F47939", fontSize: "22px" }} />}
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col md={12}>
                                        <Form.Item
                                            name="last_name"
                                            label={capitalizeFirstLetter(labelData?.last_name) || "Last Name"}
                                            rules={[
                                                { required: true, message: "Please input your Last Name!" },
                                                { pattern: /^(?!\s)[A-Za-z\s]+(?<!\s)$/, message: "Last name must only contain letters" },
                                            ]}
                                        >
                                            <Input
                                                placeholder="Enter Last Name"
                                                className="form-control"
                                                prefix={<FaRegUser style={{ color: "#F47939", fontSize: "22px" }} />}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col md={12}>
                                        <Form.Item
                                            name="email"
                                            label={capitalizeFirstLetter(labelData?.email) || "Email"}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please input your email",
                                                },
                                                {
                                                    validator: (_, value) => {
                                                        if (!value) return Promise.resolve();

                                                        const trimmedValue = value.trim();
                                                        if (trimmedValue === "") {
                                                            return Promise.reject(new Error("Spaces are not allowed"));
                                                        }
                                                        if (!emailRegex.test(trimmedValue)) {
                                                            return Promise.reject(new Error("Please enter a valid email address"));
                                                        }
                                                        return Promise.resolve();
                                                    },
                                                },
                                            ]}
                                        >
                                            <Input
                                                placeholder="Enter Email"
                                                className="form-control"
                                                disabled={FormInitialValues?.id ? true : false}
                                                prefix={<FaRegEnvelope style={{ color: "#F47939", fontSize: "22px" }} />}
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col md={12}>
                                        <Form.Item
                                            name="ownership_type_id"
                                            label={capitalizeFirstLetter(labelData?.owner_type) || "Owner Type"}
                                            rules={[{ required: true, message: "Please select Owner Type" }]}
                                        >
                                            <Select
                                                className="form-control custom-form-select"
                                                showSearch
                                                onSearch={handleSearch}
                                                optionLabelProp="label"
                                                notFoundContent={
                                                    ownerType?.length === 0 && inputValue === ""
                                                        ? null
                                                        : "No matching records found!"
                                                }
                                                placeholder="Please select"
                                                options={ownerType}
                                                prefix={<FaHouseUser style={{ color: "#F47939", fontSize: "22px" }} />}
                                                optionRender={(option) => (
                                                    <Space>
                                                        <span role="img" aria-label={option.data.value ? option.data.value : option.value}>
                                                            {option.data.label ? option.data.label : option.label}
                                                        </span>
                                                    </Space>
                                                )}
                                                filterOption={(input, option) =>
                                                    option.label.toLowerCase().includes(input.toLowerCase())
                                                }
                                                dropdownRender={(menu) => (
                                                    <>
                                                        {menu}
                                                        {inputValue &&
                                                            !ownerType.some((opt) =>
                                                                opt.label.toLowerCase().includes(inputValue.toLowerCase())
                                                            ) && (
                                                                <div style={{ display: "flex", justifyContent: "space-between", padding: 8 }}>
                                                                    <span>Add "{inputValue}"</span>
                                                                    <Button type="link" onClick={handleOwnerFormSubmit} disabled={isLoadingAddOwnerType}>
                                                                        {isLoadingAddOwnerType ? <Spin size="small" /> : "Add"}
                                                                    </Button>
                                                                </div>
                                                            )}
                                                    </>
                                                )}
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col md={12}>
                                        <Form.Item
                                            name="property_id"
                                            label={capitalizeFirstLetter(labelData?.select_property) || "Select Property"}
                                            rules={[{ required: true, message: "Please select property" }]}
                                        >
                                            <Select
                                                placeholder="Please select property"
                                                mode="multiple"
                                                className="form-control custom-form-select"
                                                prefix={<BsBuildingCheck style={{ color: "#F47939", fontSize: "22px" }} />}
                                                options={properties}
                                                value={FormInitialValues?.property_id}
                                                showSearch
                                                filterOption={(input, option) =>
                                                    (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                                                }
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row className="formButtonRow">
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        className={"btnFilled ms-3 w-150"}
                                        disabled={FormInitialValues?.id ? isLoadingEdit : isLoadingAdd}
                                    >
                                        {isLoadingEdit && FormInitialValues?.id ? "Loading..." : FormInitialValues ? "Update" : isLoadingAdd ? "Loading..." : "Save"}
                                    </Button>
                                </Row>
                            </Form>

                        </div>
                    </div>

                    <Modal
                        title="Change Form Labels"
                        open={labelsModal}
                        // onOk={handleLabelModal}
                        onCancel={handleLabelModal}
                        className="assign_model"
                        width={720}
                        footer={false}
                    >
                        <div className="modalBody">
                            <Form
                                name="labelForm"
                                className="modalForm"
                                layout="vertical"
                                onFinish={updateLabels}
                                form={labelForm}
                            >
                                <div className="row">
                                    <div className="col-md-6">
                                        <Form.Item
                                            name="first_name"
                                            label={capitalizeFirstLetter(labelData?.first_name) || "First Name"}
                                        >
                                            <Input
                                                type="text"
                                                placeholder="Enter label text here..."
                                                className="form-control"
                                            />
                                        </Form.Item>
                                    </div>
                                    <div className="col-md-6">
                                        <Form.Item
                                            name="last_name"
                                            label={capitalizeFirstLetter(labelData?.last_name) || "Last Name"}
                                        >
                                            <Input
                                                type="text"
                                                placeholder="Enter label text here..."
                                                className="form-control"
                                            />
                                        </Form.Item>
                                    </div>
                                    <div className="col-md-6">
                                        <Form.Item
                                            name="email"
                                            label={capitalizeFirstLetter(labelData?.email) || "Email"}
                                        >
                                            <Input
                                                type="text"
                                                placeholder="Enter label text here..."
                                                className="form-control"
                                            />
                                        </Form.Item>
                                    </div>
                                    <div className="col-md-6">
                                        <Form.Item
                                            name="owner_type"
                                            label={capitalizeFirstLetter(labelData?.owner_type) || "Owner Type"}
                                        >
                                            <Input
                                                type="text"
                                                placeholder="Enter label text here..."
                                                className="form-control"
                                            />
                                        </Form.Item>
                                    </div>
                                    <div className="col-md-6">
                                        <Form.Item
                                            name="select_property"
                                            label={capitalizeFirstLetter(labelData?.select_property) || "Select Properties"}
                                        >
                                            <Input
                                                type="text"
                                                placeholder="Enter label text here..."
                                                className="form-control"
                                            />
                                        </Form.Item>
                                    </div>
                                    <div className="col-md-12 text-center mt-4">
                                        <Form.Item>
                                            <Button type="primary" htmlType="submit" className="authBtn" >
                                                Save
                                            </Button>
                                        </Form.Item>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </Modal>

                </div>
            </div>
        </>
    );

};

export default CreateSubAccount;
