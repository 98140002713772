import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import Login from "../pages/auth/login";
import SignUp from "../pages/auth/signUp";
import Profile from "../pages/User/Profile";
import AddProperty from "../pages/properties/AddProperty";
import Layout from "../component/Layout";
import AllProperties from "../pages/properties/AllProperties";
import ViewProperty from "../pages/properties/ViewProperty";
import CreateSubAccount from "../pages/User/CreateSubAccount";
import PropertyDetails from "../pages/properties/PropertyDetails";
import FinanceProviderDetails from "../pages/Finance/FinanceProviderDetails";
import HouseResources from "../pages/HouseResources/HouseResources";
import ManageProperty from "../pages/Groups/ManageProperty";
import Dashboard from "../pages/Dashboard";
import Income from "../pages/Income/Income";
import Expense from "../pages/Expense/Expense";
import AddIncome from "../pages/Income/AddIncome";
import Report from "../pages/Report";
import ForgotPassword from "../pages/auth/forgotPassword";
import AllSubAccount from "../pages/User/AllSubAccount";
import AuditLog from "../pages/AuditLog";
import ExpenseCategory from "../pages/ExpenseCategory";
import MarketAnalysis from "../pages/MarketAnalysis";
import ReportAnalysis from "../pages/ReportAnalysis";
import AssignProperty from "../pages/User/AssignProperty";
import AllTranscation from "../pages/AllTranscation";
import MenuLabel from "../pages/MenuLabel";
import RecurringTransaction from "../pages/AllTranscation/RecurringTransaction";
import PlaidLink from "../plaid";
import ConnectBankAccount from "../pages/ConnectBankAccount";
import ResetPassword from "../pages/auth/resetPassword";
import SubAccountReset from "../pages/auth/SubAccountReset";
import Company from "../pages/Company";
import AddCompany from "../pages/Company/AddCompany";
import EditCompany from "../pages/Company/EditCompany";
import CategoryListing from "../pages/CategoryListing";

const AppRoute = () => {
  const userData = useSelector((state) => state.persistedReducer);

  const isAuthenticated = userData.isAuthenticated;

  const ProtectedRoute = ({ children, redirectTo }) => {
    const status = useSelector((state) => state.persistedReducer.status);

    return status ? children : <Navigate to={redirectTo} />;
  };

  return (
    <Routes>
      {isAuthenticated ? (
        <>
          <Route path="/" element={<Layout />}>
            <Route path="/" element={<Navigate to={"/dashboard"} replace />} />
            <Route path="/profile" element={<Profile />} />
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute redirectTo="/profile">
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="/add_property"
              element={
                <ProtectedRoute redirectTo="/profile">
                  <AddProperty />
                </ProtectedRoute>
              }
            />
            <Route
              path="/view_property"
              element={
                <ProtectedRoute redirectTo="/profile">
                  <ViewProperty />
                </ProtectedRoute>
              }
            />
            <Route
              path="/properties"
              element={
                <ProtectedRoute redirectTo="/profile">
                  <AllProperties />
                </ProtectedRoute>
              }
            />
            <Route
              path="/create_sub_account"
              element={
                <ProtectedRoute redirectTo="/profile">
                  <CreateSubAccount />
                </ProtectedRoute>
              }
            />
            <Route
              path="/property_details"
              element={
                <ProtectedRoute redirectTo="/profile">
                  <PropertyDetails />
                </ProtectedRoute>
              }
            />
            <Route
              path="/manage_property"
              element={
                <ProtectedRoute redirectTo="/profile">
                  <ManageProperty />
                </ProtectedRoute>
              }
            />
            <Route
              path="/expense"
              element={
                <ProtectedRoute redirectTo="/expense">
                  <Expense />
                </ProtectedRoute>
              }
            />
            <Route
              path="/income"
              element={
                <ProtectedRoute redirectTo="/profile">
                  <Income />
                </ProtectedRoute>
              }
            />
            <Route
              path="/add_income"
              element={
                <ProtectedRoute redirectTo="/profile">
                  <AddIncome />
                </ProtectedRoute>
              }
            />
            <Route
              path="/overall_reports"
              element={
                <ProtectedRoute redirectTo="/profile">
                  <Report />
                </ProtectedRoute>
              }
            />
            <Route
              path="/sub_account"
              element={
                <ProtectedRoute redirectTo="/profile">
                  <AllSubAccount />
                </ProtectedRoute>
              }
            />
            <Route
              path="/audit_log"
              element={
                <ProtectedRoute redirectTo="/profile">
                  <AuditLog />
                </ProtectedRoute>
              }
            />
            <Route
              path="/finance_provider_details"
              element={
                <ProtectedRoute redirectTo="/profile">
                  <FinanceProviderDetails />
                </ProtectedRoute>
              }
            />
            <Route
              path="/house_resources"
              element={
                <ProtectedRoute redirectTo="/profile">
                  <HouseResources />
                </ProtectedRoute>
              }
            />
            <Route
              path="/expense_category"
              element={
                <ProtectedRoute redirectTo="/profile">
                  <ExpenseCategory />
                </ProtectedRoute>
              }
            />
            <Route
              path="/market_analysis"
              element={
                <ProtectedRoute redirectTo="/profile">
                  <MarketAnalysis />
                </ProtectedRoute>
              }
            />
            <Route
              path="/report_analysis"
              element={
                <ProtectedRoute redirectTo="/profile">
                  <ReportAnalysis />
                </ProtectedRoute>
              }
            />
            <Route
              path="/assign_property"
              element={
                <ProtectedRoute redirectTo="/profile">
                  <AssignProperty />
                </ProtectedRoute>
              }
            />
            <Route
              path="/all_transactions"
              element={
                <ProtectedRoute redirectTo="/profile">
                  <AllTranscation />
                </ProtectedRoute>
              }
            />
            <Route
              path="/recurring_transactions"
              element={
                <ProtectedRoute redirectTo="/profile">
                  <RecurringTransaction />
                </ProtectedRoute>
              }
            />
            <Route
              path="/setting"
              element={
                <ProtectedRoute redirectTo="/profile">
                  <MenuLabel />
                </ProtectedRoute>
              }
            />

            <Route
              path="/link_account"
              element={
                <ProtectedRoute redirectTo="/link_account">
                  <PlaidLink />
                </ProtectedRoute>
              }
            />
            <Route
              path="/connect_bank_account"
              element={
                <ProtectedRoute redirectTo="/connect_bank_account">
                  <ConnectBankAccount />
                </ProtectedRoute>
              }
            />
            <Route
              path="/companies"
              element={
                <ProtectedRoute redirectTo="/companies">
                  <Company />
                </ProtectedRoute>
              }
            />
            <Route
              path="/add_company"
              element={
                <ProtectedRoute redirectTo="/add_company">
                  <AddCompany />
                </ProtectedRoute>
              }
            />
            <Route
              path="/edit_company/:id"
              element={
                <ProtectedRoute redirectTo="/edit_company">
                  <EditCompany />
                </ProtectedRoute>
              }
            />
            <Route
              path="/category_listing"
              element={
                <ProtectedRoute redirectTo="/category_listing">
                  <CategoryListing />
                </ProtectedRoute>
              }
            />
          </Route>
        </>
      ) : (
        <>
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/forgot_password" element={<ForgotPassword />} />
          <Route path="/reset_password" element={<ResetPassword />} />
          <Route path="/reset" element={<SubAccountReset />} />
          <Route path="/set_password" element={<ResetPassword />} />
          <Route path="/*" element={<Navigate to="/login" replace />} />
        </>
      )}
    </Routes>
  );
};

export default AppRoute;
