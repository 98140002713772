import { Button, Form, Spin, Select, Input, Row, Col } from "antd";
import Title from "../../component/PageTitle";
import {
    useGetCityQuery,
    useGetCountriesQuery,
    useGetStateQuery,
} from "../../slices/getSlice";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
    RiBuildingLine,
    RiMapPinLine,
    RiUserLine,
    RiGlobalLine,
    RiStickyNoteLine,
} from "react-icons/ri";
import { RiPrinterLine } from "react-icons/ri";
import { toast } from "react-toastify";
import {
    useUpdateCompanyMutation,
    useGetCategoryListingMutation,
} from "../../slices/apiSlice";
import TopHeader from "../../component/Header";
import { TbMapPinCode, TbBuilding, TbCategoryPlus } from "react-icons/tb";
import { GiRotaryPhone } from "react-icons/gi";
import { PiPhoneDisconnect } from "react-icons/pi";
import { HomeOutlined } from '@ant-design/icons';
import { Breadcrumb } from 'antd';
import { BsBuildingsFill, BsGlobeAmericas, BsBuildings } from "react-icons/bs";

const EditCompany = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [countries, setCountries] = useState([])
    const [states, setStates] = useState([])
    const [cities, setCities] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedState, setSelectedState] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);
    const [filteredCountry, setFilteredCountry] = useState([]);
    const [updateCompany, { isLoading: isLoadingDeleteCompany }] = useUpdateCompanyMutation();
    const [getCategoryListing, { data }] = useGetCategoryListingMutation();
    const [categories, setCategories] = useState([]);

    let initialFormData = location.state;

    const { data: getCountries, isLoading: loadingCountries } = useGetCountriesQuery();
    const { data: getStates, isLoading: loadingStates, refetch: refetchStates } = useGetStateQuery(selectedCountry ? selectedCountry : initialFormData.country_id?.value ? initialFormData.country_id?.value : initialFormData.country_id ? initialFormData.country_id : '');
    const { data: getCities, isLoading: loadingCities, refetch: refetchCities } = useGetCityQuery(selectedState ? selectedState : initialFormData.state_id ? initialFormData.state_id : '');

    useEffect(() => {
        if (data) {
            setCategories(data?.categories);
        }
    }, [data]);

    useEffect(() => {
        const fetchData = async () => {
            if (getCountries) {
                const transformedCountries = getCountries.map((item) => ({
                    label: item.name,
                    value: item.id,
                }));
                setCountries(transformedCountries);

                let findCountry = transformedCountries.find((item) => item.value == initialFormData.country_id);
                if (findCountry) {
                    form.setFieldsValue({ country_id: findCountry.value });
                }
            }

            if (getStates) {
                const transformedStates = getStates.map((item) => ({
                    label: item.name,
                    value: item.id,
                }));
                setStates(transformedStates);

                let findState = transformedStates.find((item) => item.value == initialFormData.state_id);
                if (findState) {
                    form.setFieldsValue({ state_id: findState.value });
                }
            }

            if (getCities) {
                const transformedCities = getCities.map((item) => ({
                    label: item.name,
                    value: item.id,
                }));
                setCities(transformedCities);

                let findCity = transformedCities.find((item) => item.value == initialFormData.city_id);
                if (findCity) {
                    form.setFieldsValue({ city_id: findCity.value });
                }
            }

            if (initialFormData?.category) {
                try {
                    const response = await getCategoryListing({ search_term: initialFormData?.category?.name }).unwrap();

                    if (response?.data?.length > 0) {
                        let findCategory = response.data.find((item) => item.id === initialFormData.category.id);

                        if (findCategory) {
                            form.setFieldsValue({ category_id: findCategory.id });
                            setCategories(response.data);
                        }
                    }
                } catch (error) {
                    console.error("Error fetching category listing:", error);
                }
            }

            if (!loadingCountries && !loadingStates && !loadingCities) {
                setLoading(false);
            }
        };

        fetchData();
    }, [getCountries, getStates, getCities, loadingCountries, loadingStates, loadingCities, getCategoryListing]);

    const capitalizeFirstLetter = (string) => {
        return string?.charAt(0)?.toUpperCase() + string?.slice(1);
    };
    const onFinish = async (value) => {
        try {

            let id = initialFormData.id;

            let formData = {
                id: id,
                value: value,
            };

            if (typeof value?.category_id !== "number") {
                value.category_id = initialFormData?.category?.id;
            }

            if (formData?.city_id === undefined && selectedCity === null) {
                formData.value.city_id = null;
            }else {
                formData.value.city_id = selectedCity;
            }

            if (formData?.state_id === undefined && selectedState === null) {
                formData.value.state_id = null;
            } else {
                formData.value.state_id = selectedState;
            }

            const response = await updateCompany(formData);

            if (response?.data) {
                form.resetFields();
                toast.success("Company Updated");
                navigate('/companies');
            } else {
                toast.error(`${response?.error?.data?.message}` || "Failed to update company details!");
            }
        } catch (error) {
            toast.error("Failed to update company details!");
        }
    };

    useEffect(() => {
        if (selectedCountry) {
            refetchStates();
        }
    }, [selectedCountry])

    useEffect(() => {
        if (selectedState) {
            refetchCities();
        }
    }, [selectedState])

    const handleCountryChange = (value) => {
        initialFormData.country_id = value
        initialFormData.state_id = null;
        initialFormData.city_id = null;

        form.setFieldsValue({
            state_id: undefined,
            city_id: undefined
        });

        setSelectedCountry(value);
        setSelectedState(null);
        setStates([]);
        setCities([]);
    };

    const handleStateChange = (value) => {
        setSelectedState(value);
        setCities([]);
        initialFormData.city_id = null
        form.setFieldsValue({
            city_id: undefined
        });
    };

    const handleCityChange = (value) => {
        setSelectedCity(value)
    }

    const onSearch = (value, type) => {
        if (type === "country") {
            setFilteredCountry(getCountries.filter(country =>
                country.name.toLowerCase().includes(value.toLowerCase())
            ));
        } else if (type === "state") {
            setStates(getStates.filter(state =>
                state.name.toLowerCase().includes(value.toLowerCase())
            ));
        } else if (type === "city") {
            setCities(getCities.filter(city =>
                city.name.toLowerCase().includes(value.toLowerCase())
            ));
        }
    };

    const handleCategoryChange = async (value) => {
        if (value) {
            try {
                const response = await getCategoryListing({ search_term: value }).unwrap();
                setCategories(response?.data || []);
            } catch (error) {
                console.error("Error fetching categories:", error);
            }
        }
    };

    const fields = [
        {
            component: "input",
            type: "text",
            label: capitalizeFirstLetter("Name of company"),
            name: "name",
            placeholder: "Enter Company Name",
            prefix: (
                <RiBuildingLine style={{ color: "#F47939", fontSize: "25px" }} />
            ),
            rules: [
                { required: true, message: "Company name is required" },
                { max: 100, message: "Name cannot exceed 100 characters" },
            ],
            colSpan: 8,
            static: true,
        },
        {
            component: "select",
            label: capitalizeFirstLetter("Category (Optional)"),
            name: "category_id",
            placeholder: "Select a category",
            colSpan: 8,
            static: true,
            rules: [{ required: false }],
            options: categories,
            showSearch: true,
            onSearch: (value) => {
                handleCategoryChange(value);
            },
            onChange: (value) => {
                handleCategoryChange(value);
            },
            suffixIcon: (
                <TbCategoryPlus style={{ color: "#F47939", fontSize: "25px" }} />
            ),
        },
        {
            component: "input",
            type: "text",
            label: capitalizeFirstLetter("Address"),
            name: "address",
            placeholder: "Enter Company Address",
            prefix: (
                <RiMapPinLine style={{ color: "#F47939", fontSize: "25px" }} />
            ),
            rules: [{ required: false }],
            colSpan: 8,
            static: true,
        },
        {
            component: "input",
            type: "text",
            label: capitalizeFirstLetter("Address Line 2"),
            name: "address_line_2",
            placeholder: "Enter Company Address Line 2",
            prefix: (
                <RiMapPinLine style={{ color: "#F47939", fontSize: "25px" }} />
            ),
            colSpan: 8,
            static: true,
        },
        {
            component: "select",
            label: capitalizeFirstLetter("Country"),
            name: "country_id",
            placeholder: "Select Country",
            suffixIcon: (
                <BsGlobeAmericas style={{ color: "#F47939", fontSize: "23px" }} />
            ),
            rules: [{ required: false }],
            options: filteredCountry?.length > 0 ? filteredCountry : countries,
            colSpan: 12,
            showSearch: true,
            onSearch: (value) => onSearch({ name: "country", options: countries }, value),
            static: true,
            onChange: (value) => {
                handleCountryChange(value);
            }
        },
        {
            component: "select",
            label: capitalizeFirstLetter("State/Province"),
            name: "state_id",
            placeholder: "Select State",
            suffixIcon: (
                <TbBuilding style={{ color: "#F47939", fontSize: "25px" }} />
            ),
            rules: [{ required: false }],
            options: states,
            colSpan: 12,
            showSearch: true,
            onSearch: (value) => onSearch({ name: "state", options: states }, value),
            static: true,
            disabled: selectedCountry || initialFormData.country_id?.value || initialFormData.country_id ? false : true,
            onChange: (value) => {
                handleStateChange(value);
            }
        },
        {
            component: "select",
            label: capitalizeFirstLetter("City"),
            name: "city_id",
            placeholder: "Select City",
            suffixIcon: (
                <BsBuildings style={{ color: "#F47939", fontSize: "25px" }} />
            ),
            rules: [{ required: false }],
            options: cities,
            colSpan: 12,
            static: true,
            showSearch: true,
            disabled: selectedState || initialFormData.state_id ? false : true,
            onSearch: (value) => onSearch({ name: "city", options: cities }, value),
            onChange: (value) => {
                handleCityChange(value);
            }
        },
        {
            component: "input",
            type: "text",
            label: capitalizeFirstLetter("Zip/Postal Code"),
            name: "zip_postal_code",
            placeholder: "Enter Zip Code",
            prefix: (
                <TbMapPinCode style={{ color: "#F47939", fontSize: "25px" }} />
            ),
            rules: [
                { required: false },
                { pattern: /^[0-9]{1,10}$/, message: "Zip code must be numeric and 10 digits or less" },
            ],
            colSpan: 8,
            static: true,
            onKeyPress: (e) => {
                if (!/[0-9]/.test(e.key)) {
                    e.preventDefault();
                }
            },
            onPaste: (e) => {
                const paste = (e.clipboardData || window.clipboardData).getData("text");
                if (!/^[0-9]+$/.test(paste)) {
                    e.preventDefault();
                }
            },
        },
        {
            component: "input",
            type: "text",
            label: capitalizeFirstLetter("Contact First Name"),
            name: "contact_first_name",
            placeholder: "Enter Contact First Name",
            prefix: (
                <RiUserLine style={{ color: "#F47939", fontSize: "25px" }} />
            ),
            rules: [
                { required: false },
                { max: 50, message: "Name cannot exceed 50 characters" },
            ],
            colSpan: 8,
            static: true,
        },
        {
            component: "input",
            type: "text",
            label: capitalizeFirstLetter("Contact Last Name"),
            name: "contact_last_name",
            placeholder: "Enter Contact Last Name",
            prefix: (
                <RiUserLine style={{ color: "#F47939", fontSize: "25px" }} />
            ),
            rules: [
                { required: false },
                { max: 50, message: "Name cannot exceed 50 characters" },
            ],
            colSpan: 8,
            static: true,
        },
        {
            component: "input",
            type: "text",
            label: capitalizeFirstLetter("Main Phone"),
            name: "main_phone",
            placeholder: "Enter Main Phone",
            prefix: (
                <GiRotaryPhone style={{ color: "#F47939", fontSize: "25px" }} />
            ),
            rules: [
                { required: false },
                { pattern: /^[0-9]{1,10}$/, message: "Phone number must be 10 digits or less" },
            ],
            colSpan: 8,
            static: true,
            onKeyPress: (e) => {
                if (!/[0-9]/.test(e.key)) {
                    e.preventDefault();
                }
            },
            onPaste: (e) => {
                const paste = (e.clipboardData || window.clipboardData).getData("text");
                if (!/^[0-9]+$/.test(paste)) {
                    e.preventDefault();
                }
            },
        },
        {
            component: "input",
            type: "text",
            label: capitalizeFirstLetter("Direct Line"),
            name: "direct_line",
            placeholder: "Enter Direct Line",
            prefix: (
                <PiPhoneDisconnect style={{ color: "#F47939", fontSize: "25px" }} />
            ),
            rules: [
                { required: false },
                { pattern: /^[0-9]{1,10}$/, message: "Direct line must be numeric" },
            ],
            colSpan: 8,
            static: true,
            onKeyPress: (e) => {
                if (!/[0-9]/.test(e.key)) {
                    e.preventDefault();
                }
            },
            onPaste: (e) => {
                const paste = (e.clipboardData || window.clipboardData).getData("text");
                if (!/^[0-9]+$/.test(paste)) {
                    e.preventDefault();
                }
            },
        },
        {
            component: "input",
            type: "text",
            label: capitalizeFirstLetter("Fax"),
            name: "fax",
            placeholder: "Enter Fax",
            prefix: (
                <RiPrinterLine style={{ color: "#F47939", fontSize: "25px" }} />
            ),
            rules: [
                { required: false },
                { pattern: /^[0-9]{1,10}$/, message: "Fax number must be numeric" },
            ],
            colSpan: 8,
            static: true,
            onKeyPress: (e) => {
                if (!/[0-9]/.test(e.key)) {
                    e.preventDefault();
                }
            },
            onPaste: (e) => {
                const paste = (e.clipboardData || window.clipboardData).getData("text");
                if (!/^[0-9]+$/.test(paste)) {
                    e.preventDefault();
                }
            },
        },
        {
            component: "input",
            type: "text",
            label: capitalizeFirstLetter("Website Url"),
            name: "website_url",
            placeholder: "Enter Website URL",
            prefix: (
                <RiGlobalLine style={{ color: "#F47939", fontSize: "25px" }} />
            ),
            rules: [
                { required: false },
                {
                    pattern: /^(https?:\/\/)?([\w\-]+\.)?([\w\-]+)\.([a-z]{2,})(\/[\w\-]*)*$/,
                    message: "Enter a valid URL (e.g., www.demo.com or https://www.demo.com)",
                },
            ],
            colSpan: 8,
            static: true,
        },
        {
            component: "input",
            type: "text",
            label: capitalizeFirstLetter("Notes"),
            name: "notes",
            placeholder: "Enter Notes (Optional)",
            prefix: (
                <RiStickyNoteLine style={{ color: "#F47939", fontSize: "25px" }} />
            ),
            colSpan: 8,
            static: true,
        },

    ];

    useEffect(() => {
        if (initialFormData) {
            form.setFieldsValue({
                category_id: initialFormData?.category?.name
            })
        }
    }, [initialFormData]);

    return (
        <>
            <TopHeader title={<Title title="Edit Company" />} />
            {loading ? (
                <div className="loaderWrapper">
                    <Spin />
                </div>
            ) : (
                <>
                    <div className="customPadding">
                        <div className="breadcrumbWrapper">
                            <Breadcrumb
                                items={[
                                    {
                                        title: (
                                            <span onClick={() => navigate('/dashboard')} style={{ cursor: 'pointer' }}>
                                                <HomeOutlined />
                                            </span>
                                        ),
                                    },
                                    {
                                        title: (
                                            <>
                                                <span>Companies</span>
                                            </>
                                        ),
                                    },
                                    {
                                        title: (
                                            <>
                                                <span>Edit Company</span>
                                            </>
                                        ),
                                    },
                                ]}
                            />
                        </div>
                        <div className="mainWrapper">
                            <div className="card titleCard mb-4">
                                <div className="card-body d-flex justify-content-between align-items-center">
                                    <div className="cardBodyWrapper">
                                        <Form
                                            form={form}
                                            layout="vertical"
                                            autoComplete="off"
                                            onFinish={onFinish}
                                            initialValues={initialFormData}
                                            className="addCompanyForm">
                                            <Row>
                                                {fields?.map((item) => (
                                                    <Col md={12} xs={24}>
                                                        <div className="formWrapper">
                                                            {item.component === 'select' ? (
                                                                <>
                                                                    <label>{item?.label}</label>
                                                                    <Form.Item name={item?.name} rules={item.rules}>
                                                                        <Select
                                                                            className="formControl select-custom"
                                                                            placeholder={item.placeholder}
                                                                            options={item?.options?.map((item) => ({
                                                                                label: item.name ? item.name : item?.label,
                                                                                value: item.id ? item.id : item?.value,
                                                                            }))}
                                                                            showSearch
                                                                            onSearch={item.onSearch}
                                                                            optionLabelProp="label"
                                                                            disabled={item?.disabled}
                                                                            filterOption={(input, option) =>
                                                                                (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                                                                            }
                                                                            onChange={(value) => {
                                                                                item.onChange(value);
                                                                                // form.setFieldsValue({ category_id: value });
                                                                            }}
                                                                            suffixIcon={item.suffixIcon}
                                                                        />
                                                                    </Form.Item>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <label>{item?.label}</label>
                                                                    <Form.Item name={item.name} rules={item.rules}>
                                                                        <Input onKeyPress={item?.onKeyPress} onPaste={item?.onKeyPress} placeholder={item.placeholder} prefix={item.prefix} className="form-control" />
                                                                    </Form.Item>
                                                                </>
                                                            )}
                                                        </div>
                                                    </Col>
                                                ))}
                                            </Row>
                                            <Row>
                                                <div className="btnWrapper">
                                                    <Button htmlType="submit" className="authBtn" loading={isLoadingDeleteCompany}>
                                                        Update
                                                    </Button>
                                                </div>
                                            </Row>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}

        </>
    );
};

export default EditCompany;
