import React, { useEffect, useState } from "react";
import Title from "../../component/PageTitle";
import { Empty, Spin, Table, Pagination, Button } from "antd";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useGetPropertiesQuery, useReportAnalysisQuery } from "../../slices/getSlice";
import Exporting from "highcharts/modules/exporting";
import ExportData from "highcharts/modules/export-data";
import { useSelector } from "react-redux";
import TopHeader from "../../component/Header";
import { HomeOutlined } from '@ant-design/icons';
import { Breadcrumb } from 'antd';
import { useNavigate } from "react-router-dom";
import PageSizeOptionsHelper from "../../Helper/PageSizeOptionsHelper";
import { saveAs } from 'file-saver';
import { GrDocumentCsv } from "react-icons/gr";

Exporting(Highcharts);
ExportData(Highcharts);

export default function ReportAnalysis() {
    const pageSize = 20;
    const [providerTypeData, setProviderTypeData] = useState([]);
    const [companyData, setCompanyData] = useState([]);
    const [serviceData, setServiceData] = useState([]);
    const [properties, setProperties] = useState([])
    const [serviceDataMessage, setServiceDataMessage] = useState("");
    const [providerTypeDataMessage, setProviderTypeDataMessage] = useState("");
    const [companyDataMessage, setCompanyDataMessage] = useState("");
    const [tableSortBy, setTableSortBy] = useState(null);
    const [columnName, setColumnName] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loader, setLoader] = useState(true)
    const startDate = useSelector((state) => state.date?.startDate);
    const endDate = useSelector((state) => state.date?.endDate);
    const selectedProperties = useSelector((state) => state.Property?.selectedProperties);
    const [currentPageProvider, setCurrentPageProvider] = useState(1);
    const [currentPageCompany, setCurrentPageCompany] = useState(1);
    const [currentPageService, setCurrentPageService] = useState(1);
    const [pageSizeProvider, setPageSizeProvider] = useState(pageSize);
    const [pageSizeCompany, setPageSizeCompany] = useState(pageSize);
    const [pageSizeService, setPageSizeService] = useState(pageSize);
    const [localLoading, setLocalLoading] = useState(false);
    const [exportLoader, setExportLoader] = useState(false);
    const navigate = useNavigate();

    const { data: reportAnalysis, isLoading: isReportDataLoading, refetch } = useReportAnalysisQuery({
        property: selectedProperties?.selectedProperty?.length ? selectedProperties.selectedProperty : undefined,
        startDate: startDate,
        endDate: endDate,
        sort_by: columnName,
        sort_direction: tableSortBy,
    });

    const {
        data: getProperties,
    } = useGetPropertiesQuery();

    const fetchData = async () => {
        setLocalLoading(true);
        await refetch();
        setLocalLoading(false);
    };

    useEffect(() => {
        fetchData()
    }, [startDate, endDate, selectedProperties])

    useEffect(() => {
        if (reportAnalysis) {
            const { providerTypes = [], companies = [], categories = [] } = reportAnalysis;
            const formattedProviderTypeData = providerTypes.map(item => ({
                ...item,
                y: Math.abs(item.y)
            }));

            setProviderTypeData(formattedProviderTypeData.length ? formattedProviderTypeData : []);
            setProviderTypeDataMessage(providerTypes.length ? "" : "There is no data available");

            const formattedCategoryTypeData = categories.map(item => ({
                ...item,
                y: Math.abs(item.y)
            }));

            setServiceData(formattedCategoryTypeData.length ? formattedCategoryTypeData : []);
            setServiceDataMessage(categories.length ? "" : "There is no data available");

            const formattedCompanyTypeData = companies.map(item => ({
                ...item,
                y: Math.abs(item.y)
            }));

            setCompanyData(formattedCompanyTypeData.length ? formattedCompanyTypeData : []);
            setCompanyDataMessage(companies.length ? "" : "There is no data available");
            setLoading(false);
        }
        if (loading) setLoading(false);
    }, [reportAnalysis]);

    useEffect(() => {
        if (getProperties && getProperties?.data) {
            const transformedOptions = getProperties?.data?.map((item) => ({
                label: item.name_of_property,
                value: item.id,
            }));
            setProperties(transformedOptions);
            setLoading(false);
        }
    }, [getProperties]);

    useEffect(() => {
        setLoader(false)
    }, [companyData, properties])

    const gridSize = 200;
    const gridInnerSize = gridSize - 200;
    const currencyName = useSelector((state) => state?.persistedReducer?.currency);

    const currencyData = {
        currency: currencyName && currencyName.currency_name,
        symbol: currencyName && currencyName.currency_symbol,
        code: currencyName && currencyName.currency_id === 1 ? "USD" : "INR",
    };

    const locale = currencyData.code === "USD" ? "en-US" : "en-IN";

    const formatCurrency = (amount) => {
        return new Intl.NumberFormat(locale, {
            style: "currency",
            currency: currencyData.code,
        }).format(amount);
    };

    // **Provider Analysis Chart**
    const dataAnalysisProvider = {
        chart: {
            type: "pie",
            events: {
                load() {
                    const chart = this,
                        x = chart.plotSizeX / 2 - chart.plotLeft / 2,
                        y = chart.plotSizeY / 2 + chart.plotTop;
                },
            },
        },
        title: {
            text: "Provider Analysis",
        },
        tooltip: {
            pointFormatter: function () {
                return `<b>${this.name}: ${formatCurrency(this.y)}</b>`;
            },
        },
        plotOptions: {
            series: {
                allowPointSelect: true,
                size: gridSize,
                innerSize: gridInnerSize,
                cursor: "pointer",
                dataLabels: {
                    enabled: true,
                    useHTML: true,
                    formatter: function () {
                        return `
                        <div style="text-align: center;">
                            <span>${this.point.name}</span><br>
                            <span style="color: gray; font-size: 0.75rem;">${formatCurrency(this.point.y)}</span>
                        </div>`;
                    },
                    distance: 20,
                    style: {
                        fontSize: "0.75rem",
                        textOutline: "none",
                    },
                },
            },
        },
        series: [{ colorByPoint: true, data: providerTypeData }],
        exporting: { enabled: true },
        credits: { enabled: false },
    };

    // **Company Analysis Chart**
    const dataAnalysisCompanies = {
        chart: { type: "pie" },
        title: { text: "Company Analysis" },
        tooltip: {
            pointFormatter: function () {
                return `<b>${this.name}: ${formatCurrency(this.y)}</b>`;
            },
        },
        plotOptions: {
            series: {
                allowPointSelect: true,
                size: gridSize,
                innerSize: gridInnerSize,
                cursor: "pointer",
                dataLabels: {
                    enabled: true,
                    useHTML: true,
                    formatter: function () {
                        return `
                        <div style="text-align: center;">
                            <span>${this.point.name}</span><br>
                            <span style="color: gray; font-size: 0.75rem;">${formatCurrency(this.point.y)}</span>
                        </div>`;
                    },
                    distance: 20,
                    style: {
                        fontSize: "0.75rem",
                        textOutline: "none",
                    },
                },
            },
        },
        series: [{ colorByPoint: true, data: companyData }],
        exporting: { enabled: true },
        credits: { enabled: false },
    };

    // **Category Analysis Chart**
    const dataAnalysisCategories = {
        chart: { type: "pie" },
        title: { text: "Category Analysis" },
        tooltip: {
            pointFormatter: function () {
                return `<b>${this.name}: ${formatCurrency(this.y)}</b>`;
            },
        },
        plotOptions: {
            series: {
                allowPointSelect: true,
                size: gridSize,
                innerSize: gridInnerSize,
                cursor: "pointer",
                dataLabels: {
                    enabled: true,
                    useHTML: true,
                    formatter: function () {
                        return `
                        <div style="text-align: center;">
                            <span>${this.point.name}</span><br>
                            <span style="color: gray; font-size: 0.75rem;">${formatCurrency(this.point.y)}</span>
                        </div>`;
                    },
                    distance: 20,
                    style: {
                        fontSize: "0.75rem",
                        textOutline: "none",
                    },
                },
            },
        },
        series: [{ colorByPoint: true, data: serviceData }],
        exporting: { enabled: true },
        credits: { enabled: false },
    };

    const tableColumns = [
        { 
            title: "S.No.", 
            dataIndex: "serial", 
            render: (_, __, index) => (currentPageProvider - 1) * pageSizeProvider + index + 1 
        },
        { 
            title: "Name", 
            dataIndex: "name", 
            sorter: (a, b) => a.name.localeCompare(b.name), 
        },
        {
            title: "Amount",
            dataIndex: "y",
            render: (y) => {
                const formattedAmount = new Intl.NumberFormat(locale, {
                    style: "currency",
                    currency: currencyData.code,
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                }).format(y);
    
                return formattedAmount;
            },
            sorter: (a, b) => a.y - b.y,
        },
    ];
    const tableColumnsProvider = [
        {
            title: "S.No.",
            dataIndex: "serial",
            render: (_, __, index) => {
                return (currentPageCompany - 1) * pageSizeCompany + index + 1;
            },
        },
        { title: "Name", dataIndex: "name", sorter: (a, b) => a.name.localeCompare(b.name), },
        {
            title: "Amount",
            dataIndex: "y",
            render: (y) => {
                const formattedAmount = new Intl.NumberFormat(locale, {
                    style: "currency",
                    currency: currencyData.code,
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                }).format(y);
    
                return formattedAmount;
            },
            sorter: (a, b) => a.y - b.y,
        },
    ];

    const tableColumnsService = [
        {
            title: "S.No.",
            dataIndex: "serial",
            render: (_, __, index) => (currentPageService - 1) * pageSizeService + index + 1,
        },
        { title: "Name", dataIndex: "name", sorter: (a, b) => a.name.localeCompare(b.name), },
        {
            title: "Amount",
            dataIndex: "y",
            render: (y) => {
                const formattedAmount = new Intl.NumberFormat(locale, {
                    style: "currency",
                    currency: currencyData.code,
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                }).format(y);
    
                return formattedAmount;
            },
            sorter: (a, b) => a.y - b.y,
        },
    ];

    const paginatedProviderData = providerTypeData.slice((currentPageProvider - 1) * pageSizeProvider, currentPageProvider * pageSizeProvider);
    const paginatedCompanyData = companyData.slice((currentPageCompany - 1) * pageSizeCompany, currentPageCompany * pageSizeCompany);
    const paginatedServiceData = serviceData.slice((currentPageService - 1) * pageSizeService, currentPageService * pageSizeService);

    const pageSizeOptionsProviderData = PageSizeOptionsHelper(providerTypeData.length);
    const pageSizeOptionsCompanyData = PageSizeOptionsHelper(companyData?.length);
    const pageSizeOptionsServiceData = PageSizeOptionsHelper(serviceData.length);

    const handleProviderDataExport = async (data) => {
        setExportLoader(true);
        try {

            const mapData = data === "provider_type" ? providerTypeData : data === "company_data" ? companyData : serviceData

            const csvData = mapData.map((item, index) => {
                return {
                    "S.NO": index + 1,
                    "Name": item?.name,
                    "Amount": item?.y,


                };
            });

            const csvRows = [
                ['S.NO', 'Name', 'Amount'],
                ...csvData.map(item => [
                    item["S.NO"],
                    item["Name"],
                    item["Amount"],
                ])
            ];

            const csvString = csvRows.map(row => row.join(',')).join('\n');
            const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
            saveAs(blob, `${data === "provider_type" ? "provider_data" : data === "company_data" ? "company_data" : "category_data"}.csv`);
        } catch (error) {
            console.error("Error exporting data: ", error);
        }
        setExportLoader(false);
    };

    return (
        <>
            {loading && (
                <div className="commanLoaderWrapper">
                    <Spin size="large" />
                    <h4>Setting things up..</h4>
                </div>
            )}
            <TopHeader title={<Title title="Report Analysis" id='report_analysis' />} />
            <div className="customPadding">
                <div className="breadcrumbWrapper">
                    <Breadcrumb
                        items={[
                            {
                                title: (
                                    <span onClick={() => navigate('/dashboard')} style={{ cursor: 'pointer' }}>
                                        <HomeOutlined />
                                    </span>
                                ),
                            },
                            {
                                title: (
                                    <>
                                        <span>Report Analysis</span>
                                    </>
                                ),
                            },
                        ]}
                    />
                </div>
                <div className="mainWrapper">
                    {loader || isReportDataLoading || localLoading ? (
                        <div className="loaderWrapper">
                            <Spin />
                        </div>
                    ) : (
                        providerTypeDataMessage && serviceDataMessage ? <Empty /> : <div className="row">
                            <div className="col-md-6 mb-4">
                                <div className="card chartCard mb-4">
                                    <div className="card-body">
                                        {providerTypeDataMessage ? (
                                            <p>{providerTypeDataMessage}</p>
                                        ) : (
                                            <HighchartsReact highcharts={Highcharts} options={dataAnalysisProvider} />
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6 mb-4">
                                <div className="card chartCard mb-4">
                                    <div className="card-header">
                                        <h5 className="card-title">Provider Data</h5>
                                        {paginatedProviderData?.length > 0 ? (<><div className="export-data-wrapper d-flex justify-content-end align-items-center reportExportWrapper"><Button icon={<GrDocumentCsv />} loading={exportLoader} onClick={() => handleProviderDataExport("provider_type")} className="export-data-button viewButton mb-3">Export</Button></div></>) : (<></>)}
                                    </div>
                                    <div className="card-body">
                                        {!providerTypeDataMessage ?
                                            (<>
                                                <Table
                                                    className={`tableNew table-bordered ${loader ? "table-loading" : ""}`}
                                                    rowKey={record => record.id}
                                                    bordered
                                                    dataSource={paginatedProviderData}
                                                    columns={tableColumns}
                                                    scroll={{ x: '100%' }}
                                                    loading={loader}
                                                    pagination={false}
                                                    onChange={(pagination, filters, sorter) => {
                                                        if (sorter.order) {
                                                            const columnTitle = tableColumns.find(col => col.dataIndex === sorter?.field)?.dataIndex;
                                                            setColumnName(columnTitle)

                                                            if (sorter.order === "ascend") {
                                                                setTableSortBy("asc")
                                                            } else {
                                                                setTableSortBy("desc")
                                                            }
                                                        }
                                                    }}
                                                />
                                                {providerTypeData.length > 20 ? (
                                                    <Pagination
                                                        className="mt-4"
                                                        pageSize={pageSizeProvider}
                                                        current={currentPageProvider}
                                                        total={providerTypeData.length}
                                                        showSizeChanger
                                                        pageSizeOptions={pageSizeOptionsProviderData}
                                                        onChange={(page, size) => {
                                                            setCurrentPageProvider(page);
                                                            setPageSizeProvider(size);
                                                        }}
                                                        locale={{
                                                            items_per_page: `/${providerTypeData.length || 0} Records`,
                                                        }}
                                                    />
                                                ) : (<></>)}


                                            </>)
                                            :
                                            <Empty />
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6 mb-4">
                                <div className="card chartCard">
                                    <div className="card-body">
                                        {companyDataMessage ? (
                                            <p>{companyDataMessage}</p>
                                        ) : (
                                            <HighchartsReact highcharts={Highcharts} options={dataAnalysisCompanies} />
                                        )
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6 mb-4">
                                <div className="card chartCard">
                                    <div className="card-header">
                                        <h5 className="card-title">Companies Data</h5>
                                    </div>
                                    <div className="card-body">
                                        {!companyDataMessage ?
                                            (
                                                <>
                                                    {paginatedCompanyData?.length > 0 ? (<><div className="export-data-wrapper d-flex justify-content-end align-items-center reportExportWrapper"><Button icon={<GrDocumentCsv />} loading={exportLoader} onClick={() => handleProviderDataExport("company_data")} className="export-data-button viewButton mb-3">Export</Button></div></>) : (<></>)}
                                                    <Table
                                                        className={`tableNew table-bordered ${loader ? "table-loading" : ""}`}
                                                        rowKey={record => record.id}
                                                        bordered
                                                        loading={loader}
                                                        dataSource={paginatedCompanyData}
                                                        columns={tableColumnsProvider}
                                                        scroll={{ x: '100%' }}
                                                        pagination={false}
                                                        onChange={(pagination, filters, sorter) => {
                                                            if (sorter.order) {
                                                                const columnTitle = tableColumnsProvider.find(col => col.dataIndex === sorter.field)?.dataIndex;
                                                                setColumnName(columnTitle)

                                                                if (sorter.order === "ascend") {
                                                                    setTableSortBy("asc")
                                                                } else {
                                                                    setTableSortBy("desc")
                                                                }
                                                            }
                                                        }}
                                                    />
                                                    {companyData.length > 20 ? (
                                                        <Pagination
                                                            className="mt-4"
                                                            pageSize={pageSizeCompany}
                                                            current={currentPageCompany}
                                                            total={companyData.length}
                                                            showSizeChanger
                                                            pageSizeOptions={pageSizeOptionsCompanyData}
                                                            onChange={(page, size) => {
                                                                setCurrentPageCompany(page);
                                                                setPageSizeCompany(size);
                                                            }}
                                                            locale={{
                                                                items_per_page: `/${companyData.length || 0} Records`,
                                                            }}
                                                        />
                                                    ) : (<></>)}
                                                </>) : <Empty />
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6 mb-4">
                                <div className="card chartCard mb-4">
                                    <div className="card-body">
                                        {serviceDataMessage ? (
                                            <p>{serviceDataMessage}</p>
                                        ) : (
                                            <HighchartsReact highcharts={Highcharts} options={dataAnalysisCategories} />
                                        )
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6 mb-4">
                                <div className="card chartCard mb-4">
                                    <div className="card-header">
                                        <h5 className="card-title">Category Data</h5>
                                    </div>
                                    <div className="card-body">
                                        {!serviceDataMessage ?
                                            (
                                                <>
                                                    {paginatedServiceData?.length > 0 ? (<><div className="export-data-wrapper d-flex justify-content-end align-items-center reportExportWrapper"><Button icon={<GrDocumentCsv />} loading={exportLoader} onClick={() => handleProviderDataExport()} className="export-data-button viewButton mb-3">Export</Button></div></>) : (<></>)}
                                                    <Table
                                                        className={`tableNew table-bordered ${loader ? "table-loading" : ""}`}
                                                        rowKey={record => record.id}
                                                        bordered
                                                        dataSource={paginatedServiceData}
                                                        columns={tableColumnsService}
                                                        scroll={{ x: '100%' }}
                                                        loading={loader}
                                                        pagination={false}
                                                        onChange={(pagination, filters, sorter) => {
                                                            if (sorter.order) {
                                                                const columnTitle = tableColumnsService.find(col => col.dataIndex === sorter.field)?.dataIndex;
                                                                setColumnName(columnTitle)

                                                                if (sorter.order === "ascend") {
                                                                    setTableSortBy("asc")
                                                                } else {
                                                                    setTableSortBy("desc")
                                                                }
                                                            }
                                                        }}
                                                    />
                                                    {serviceData.length > 20 ? (
                                                        <Pagination
                                                            className="mt-4"
                                                            pageSize={pageSizeService}
                                                            current={currentPageService}
                                                            total={serviceData.length}
                                                            showSizeChanger
                                                            pageSizeOptions={pageSizeOptionsServiceData}
                                                            onChange={(page, size) => {
                                                                setCurrentPageService(page);
                                                                setPageSizeService(size);
                                                            }}
                                                            locale={{
                                                                items_per_page: `/${serviceData.length || 0} Records`,
                                                            }}
                                                        />
                                                    ) : (<></>)}

                                                </>) : <Empty />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}
