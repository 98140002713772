import React, { useEffect, useState, useCallback } from "react";
import { Button, Checkbox, Form, Modal, Select, Table, Tooltip, Input, Row, Col, Spin, Space, Pagination, Alert } from "antd";
import Title from '../../component/PageTitle';
import {
    useCategorizePlaidDataQuery,
    useGetPropertiesQuery,
    useGetUtilityCategoryQuery,
    useGetNewCompanyListQuery,
    useGetAllPaymentDataQuery,
    useGetMerchantsQuery,
    useGenrateLinkTokenQuery
} from "../../slices/getSlice";
import { useAddTransactionMutation, useRemoveTransactionMutation } from "../../slices/apiSlice";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import swal from "sweetalert";
import { FaMinus, FaPlus } from "react-icons/fa";
import { useOutletContext, useNavigate } from "react-router-dom";
import {
    useGetCategoryListingMutation,
    useAddNewExpenseMutation,
    useCreateNewCompanyMutation,
    useImportMerchantDataMutation,
    useAddAccessTokenMutation
} from "../../slices/apiSlice";
import dayjs from "dayjs";
import TopHeader from '../../component/Header/index';
import { HomeOutlined } from '@ant-design/icons';
import { Breadcrumb } from 'antd';
import PageSizeOptionsHelper from "../../Helper/PageSizeOptionsHelper";
import { usePlaidLink } from 'react-plaid-link';
import { FaCircleInfo } from "react-icons/fa6";
import { saveAs } from 'file-saver';
import { GrDocumentCsv } from "react-icons/gr";
import TransactionModal from '../../component/TransactionModal';
import CompanyModal from '../../component/CompanyModal';
import { formatDate, capitalizeFirstLetter } from '../../Helper/formatting';

function AllTranscation() {
    const navigate = useNavigate();
    const currencyGlobal = useSelector((state) => state?.persistedReducer?.currency)
    const [form] = Form.useForm();
    const [pageSize, setPageSize] = useState(20)
    const [exportPageSize, setExportPageSize] = useState(null)
    const [searchText, setSearchText] = useState(null);
    const [tableSortBy, setTableSortBy] = useState(null);
    const [columnName, setColumnName] = useState(null);
    const [filter, setFilter] = useState(null);
    const { loading, setLoading } = useOutletContext();
    const { data: getProperties } = useGetPropertiesQuery();
    const { data: getUtilityCategory } = useGetUtilityCategoryQuery();
    const [addTransaction] = useAddTransactionMutation();
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedRowsData, setSelectedRowsData] = useState([])
    const [properties, setProperties] = useState([]);
    const [linkData, setLinkData] = useState([]);
    const [utilityCategory, setUtilityCategory] = useState();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const colors = useSelector((state) => state?.persistedReducer?.colors);
    const [isSplitPayment, setIsSplitPayment] = useState(false);
    const [selectedProviderType, setSelectedProviderType] = useState(null);
    const [propertyAmounts, setPropertyAmounts] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [isSaving, setIsSaving] = useState(false);
    const [addNewExpense, { isLoading: isLoadingAddExpense }] = useAddNewExpenseMutation();
    const [importExpenseData, { isLoading: isLoadingImportExpense }] = useImportMerchantDataMutation();
    const [inputValue, setInputValue] = useState("");
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const startDate = useSelector((state) => state.date.startDate);
    const endDate = useSelector((state) => state.date.endDate);
    const [isCompanyModalOpen, setIsCompanyModalOpen] = useState(false);
    const [transaction_id, setTransactionId] = useState(null);
    const [selectedCompanies, setSelectedCompanies] = useState([]);
    const [companyAutoLink, setCompanyAutoLink] = useState([]);
    const [localLoading, setLocalLoading] = useState(false);
    const [isLoadingForm, setIsLoadingForm] = useState(false);
    const [splitTransactionId, setSplitTransactionId] = useState(null);
    const [exportLoader, setExportLoader] = useState(false);
    const [removeTransaction, { isLoading: loadingRecurring }] = useRemoveTransactionMutation();
    const userData = useSelector((state) => state.persistedReducer);
    const parent_id = userData?.user?.user?.parent_id;
    const [debouncedSearch, setDebouncedSearch] = useState("");
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [removeForFuture, setRemoveForFuture] = useState(true);
    const [selectedRecord, setSelectedRecord] = useState(null);

    const { data: generateToken, refetch: transactionRefetch, isLoading, error } = useGenrateLinkTokenQuery(
        `?page=${currentPage}&per_page=${pageSize}${debouncedSearch ? `&search=${debouncedSearch}` : ""}`
    );

    const linkToken = generateToken?.token?.link_token;

    const [setAccessToken] = useAddAccessTokenMutation();

    const {
        data: categorizePlaidData,
        refetch,
        isLoading: isLoadingPlaid
    } = useCategorizePlaidDataQuery({
        query: (tableSortBy && columnName ? `sort_direction=${tableSortBy}&sort_by=${columnName}` : null),
        filter: filter?.length > 0 ? filter : null,
        pageOptions: `page=${currentPage}&per_page=${exportPageSize ? exportPageSize : pageSize}`,
        searchText: (debouncedSearch ? `search=${debouncedSearch}` : null),
        start_date: startDate || null,
        end_date: endDate || null,
    });

    const {
        data: getCompanyList,
        refetch: refetchCompanyList,
        isLoading: isLoadingCompany,
    } = useGetNewCompanyListQuery(transaction_id ? { transaction_id } : undefined);

    const {
        data: getExpenseList,
    } = useGetAllPaymentDataQuery('expense');

    const { data: getMerchantsList } = useGetMerchantsQuery();
    const [getCategoryListing, { data }] = useGetCategoryListingMutation();
    const [categoryData, setCategoryData] = useState([]);
    const [companyList, setCompanyList] = useState([]);
    const [ExpenseOptions, setExpenseOptions] = useState([]);
    const [merchantData, setMerchantData] = useState([]);
    const [addNewCompany, { isLoading: isLoadingAddCompany }] = useCreateNewCompanyMutation();

    useEffect(() => {
        if (data) {
            setCategoryData(data?.categories);
        }
    }, [data]);

    const fetchData = async () => {
        setLocalLoading(true);
        await refetch();
        setLocalLoading(false);
    };

    useEffect(() => {
        fetchData()
    }, [refetch, startDate, endDate]);

    useEffect(() => {
        if (getMerchantsList?.data?.length > 0) {
            getMerchantsList.data.forEach((merchant) => {
                if (merchant?.auto_link === true) {
                    setCompanyAutoLink((prev) => [...prev, merchant]);
                }
            });
        }
    }, [getMerchantsList]);

    useEffect(() => {
        if (getExpenseList && getExpenseList?.data) {
            const transformedOptions = getExpenseList.data.map((item) => ({
                label: item.name,
                value: item.id,
            }));
            const filterOptions = transformedOptions?.filter((item) => item?.label !== "Recurring");
            setExpenseOptions(filterOptions);
        }
    }, [getExpenseList]);

    useEffect(() => {
        if (getMerchantsList?.data?.length > 0) {
            setIsCompanyModalOpen(true);
            setMerchantData(getMerchantsList?.data);

            const importedCompanies = getMerchantsList.data
                .filter(company => company.imported)
                .map(company => ({ ...company, auto_link: true }));

            setSelectedCompanies(importedCompanies);
        }
    }, [getMerchantsList]);

    const dataSource = categorizePlaidData?.data
        ?.map((transaction) => {
            const parsedData = JSON.parse(transaction.json_data);
            const filteredItems = [parsedData].filter((item) => {
                const itemDate = new Date(item.date);
                const start = new Date(startDate);
                const end = new Date(endDate);

                itemDate.setHours(0, 0, 0, 0);
                start.setHours(0, 0, 0, 0);
                end.setHours(0, 0, 0, 0);
                if (startDate != null && endDate !== null) {
                    return itemDate >= start && itemDate <= end;
                } else if (filter?.length > 0) {
                    let findImported = filter?.find((item) => item === 'imported');
                    if (findImported !== undefined) {
                        return transaction;
                    } else {
                        return transaction?.is_imported !== true;
                    }
                } else {
                    return transaction?.is_imported !== true;
                }

            });

            if (filteredItems.length > 0) {
                return {
                    key: filteredItems[0]?.transaction_id,
                    amount: filteredItems[0]?.amount,
                    date: filteredItems[0]?.date,
                    name: filteredItems[0]?.name,
                    transaction_id: transaction?.id,
                    property_id: null,
                    provider_type_id: null,
                    category_id: null,
                    category_name: transaction?.category_name,
                    status: transaction?.status,
                    mask: transaction?.mask,
                    billing_descriptor: transaction?.billing_descriptor
                };
            }

            return null;
        })
        .filter((item) => item !== null);

    const pageSizeOptions = PageSizeOptionsHelper(categorizePlaidData?.meta?.total || 0);
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const formattedDate = `${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}-${date.getFullYear()}`;
        return formattedDate;
    };

    useEffect(() => {
        if (getProperties && getProperties?.data) {
            const transformedOptions = getProperties?.data?.map((item) => ({
                label: item.name_of_property,
                value: item.id,
            }));
            setLoading(false)
            setProperties(transformedOptions);
        }
        if (getUtilityCategory && getUtilityCategory?.data) {
            const transformedOptions = getUtilityCategory?.data?.map((item) => ({
                label: item.name,
                value: item.id,
            }));
            setUtilityCategory(transformedOptions);
        }

    }, [getProperties, getUtilityCategory]);

    const columns = [
        {
            title: "Transaction Name",
            dataIndex: "name",
            render: (text, record) => (
                <div className="category-data-container">
                    <p className="category-data-text">{record.name}</p>
                </div>
            ),
            sorter: true,
        },
        {
            title: "Amount",
            dataIndex: "amount",
            render: (text, record) =>
                record.amount ? (
                    <div className="amount-data-container">

                        {/* <p className="amount-data-text">{new Intl.NumberFormat('en-US', { style: 'currency', currency: currencyGlobal?.currency_name === "Indian Rupee" ? 'INR' : 'USD' }).format(record.amount?.toFixed(2))}</p> */}
                        <p className="amount-data-text"> {new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: currencyGlobal?.currency_name === "Indian Rupee" ? 'INR' : 'USD'
                        }).format(Number(record.amount?.toFixed(2).replace(/,/g, '').trim()) || 0)}</p>
                    </div>
                ) : (
                    ""
                ),
            sorter: true,
        },
        {
            title: "Category",
            dataIndex: "category_name",
            render: (text, record) => record?.category_name,
            sorter: true,
        },
        {
            title: "Transaction Date",
            dataIndex: "date",
            render: (text, record) =>
                record.date ? (
                    <div className="date-data-container">
                        <p className="date-data-text">{formatDate(record.date)}</p>
                    </div>
                ) : (
                    ""
                ),
            sorter: true,
        },
        {
            title: "Card/Account Number",
            dataIndex: "mask",
            render: (text, record) => (
                <>
                    {record?.mask}
                </>
            ),
            sorter: true,
        },
        {
            title: "Billing Descriptor",
            dataIndex: "billing_descriptor",
            render: (text, record) => (
                <>
                    {record?.billing_descriptor}
                </>
            ),
            sorter: true,
        },
        {
            title: "Status",
            dataIndex: "status",
            render: (text, record) =>
                record.date ? (
                    <div className="date-data-container">
                        <p className="date-data-text">{capitalizeFirstLetter(record?.status)}</p>
                    </div>
                ) : (
                    ""
                ),
            filters: [
                {
                    text: 'Removed',
                    value: 'removed',
                },
                {
                    text: 'Uncategorized',
                    value: 'uncategorized',
                },
                {
                    text: 'Imported',
                    value: 'imported',
                },
            ],
        },
        {
            title: "Action",
            dataIndex: "action",
            render: (_, record) => {
                return (
                    <div className="action-container step-one" >
                        {record?.status === "removed" || record?.status === "imported" ? (<></>) : (
                            <>
                                <Tooltip title="Add Transaction">
                                    <button onClick={() => { handleAddTransactionCLick(record) }} className="btn btn-add me-2">
                                        <FaPlus />
                                    </button>
                                </Tooltip>

                                <Tooltip title="Remove Transaction">
                                    <button onClick={() => { handleTransactionRemove(record); }} className="btn btn-remove me-2">
                                        <FaMinus />
                                    </button>
                                </Tooltip>
                            </>
                        )}


                    </div>
                );
            },
        },
    ];

    const handleSelect = (record, selected) => {
        if (selected) {
            setSelectedRowKeys((prev) => [...prev, record.key]);
            setSelectedRowsData((prev) => [...prev, record]);
        } else {
            setSelectedRowKeys((prev) => prev.filter((key) => key !== record.key));
            setSelectedRowsData((prev) => prev.filter((row) => row.key !== record.key));
        }
    };

    const toggleSelectAll = (e) => {
        if (e.target.checked) {
            setSelectedRowKeys(dataSource.map((r) => r.key));
            setSelectedRowsData(dataSource);
        } else {
            setSelectedRowKeys([]);
            setSelectedRowsData([]);
        }
    };

    const allRecordsImported = dataSource?.every((record) => record.status === "imported");

    const headerCheckbox = (
        <Checkbox
            checked={
                selectedRowKeys.length > 0 &&
                selectedRowKeys.length === dataSource?.filter(row => row.status !== "imported").length
            }
            indeterminate={
                selectedRowKeys.length > 0 &&
                selectedRowKeys.length < dataSource?.filter(row => row.status !== "imported").length
            }
            onChange={(e) => {
                if (e.target.checked) {
                    const nonImportedKeys = dataSource
                        .filter(row => row.status !== "imported")
                        .map(row => row.key);
    
                    setSelectedRowKeys(nonImportedKeys);
                    setSelectedRowsData(dataSource.filter(row => row.status !== "imported"));
                } else {
                    setSelectedRowKeys([]);
                    setSelectedRowsData([]);
                }
            }}
            className="custom-check"
            disabled={allRecordsImported}  
        />
    );
    
    const rowSelection = {
        selectedRowKeys,
        onChange: setSelectedRowKeys,
        columnTitle: headerCheckbox,
        onSelect: (record, selected) => {
            if (!record.status === "imported") {
                setSelectedRowKeys(prev =>
                    selected ? [...prev, record.key] : prev.filter(key => key !== record.key)
                );
            }
        },
        onSelectAll: (selected) => {
            if (selected) {
                const nonImportedRows = dataSource.filter(row => row.status !== "imported");
                setSelectedRowKeys(nonImportedRows.map(row => row.key));
                setSelectedRowsData(nonImportedRows);
            } else {
                setSelectedRowKeys([]);
                setSelectedRowsData([]);
            }
        },
        getCheckboxProps: (record) => ({
            disabled: record.status === "imported",
        }),
    };
    
    const handleFinish = async (value) => {
        setIsSaving(true);
        try {
            if (isSplitPayment) {
                const propertyAmount = Object.entries(propertyAmounts).map(([key, amount]) => ({
                    property_id: parseInt(key, 10),
                    amount: parseFloat(amount) || 0,
                    payment_type_id: value.expense_type?.value
                }));
                const finalValues = {
                    category_id: value?.category_id?.value !== undefined ? value.category_id.value : value.category_id,
                    company_id: value.company_id?.value !== undefined ? value.company_id?.value : value?.company_id,
                    is_split: 1,
                    start_date: dayjs(linkData?.date).format("DD-MM-YY"),
                    propertyAmount,
                    provider_type_id: value.utilityCategory?.value,
                    transaction_id: splitTransactionId
                };

                delete finalValues.propertyAmounts;

                const response = await addNewExpense(finalValues);
                if (response.data) {
                    toast.success(response.data.message);
                    refetch();
                    form.resetFields();
                    setPropertyAmounts({});
                    setIsModalOpen(false);
                    setIsSplitPayment(false);
                } else {
                    setIsSplitPayment(false);
                    toast.error(`${response?.error?.data?.message}`);
                }
                setSplitTransactionId(null)
            } else {
                let rowData = Array.isArray(selectedRowsData) && selectedRowsData.length > 0 ? selectedRowsData : [linkData];

                let expense = rowData?.map((item) => ({
                    amount: item.amount,
                    start_date: item.start_date
                        ? dayjs(item.start_date).format("MM-DD-YYYY")
                        : dayjs(item.date).format("MM-DD-YYYY"),
                    payment_type_id: ExpenseOptions[0]?.value,
                    payment_type: ExpenseOptions[0]?.value,
                    transaction_id: item?.transaction_id
                }));

                let formData = {
                    provider_type_id: value.utilityCategory.value,
                    property_id: value.properties.value,
                    company_id: value.company_id?.value !== undefined ? value.company_id?.value : value?.company_id,
                    expenses: expense,
                    category_id: value?.category_id?.key ? value?.category_id?.key : value?.category_id,
                    future_add: value?.future_add === true ? 1 : 0
                };

                const response = await addNewExpense(formData);
                if (response.data) {
                    toast.success(response?.data?.message);
                    refetch();
                    form.resetFields();
                    setIsModalOpen(false);
                    setIsSplitPayment(false);
                } else {
                    toast.error(`${response?.error?.data?.message}`);
                    setIsSaving(false);
                }
            }
        } catch (error) {
            console.error(error);
            toast.error("An error occurred. Please try again.");
            setIsSaving(false);
        } finally {
            setLinkData()
            setIsSaving(false)
        }
    };

    const handleSplitPaymentChange = (e) => {
        setIsSplitPayment(e.target.checked);
        if (!e.target.checked) {
            setPropertyAmounts({});
        }
    };

    const getTransactions = async () => {
        try {
            const response = await addTransaction();
            if (response?.data?.data?.length === 0) {
                swal({
                    title: "Alert",
                    text: `${response.data.message}`,
                    // icon: "warning",
                    dangerMode: true,
                    buttons: {
                        confirm: {
                            text: "Ok",
                            value: true,
                            visible: true,
                            className: "custom-confirm-btn",
                            closeModal: true,
                        },
                    },
                })
            }
            refetch()
        } catch (error) {
            console.error(error);
        }
    };

    const onChangeProvider = (data) => {
        setSelectedProviderType(data.value);
    };

    const closeAndReset = () => {
        form.resetFields();
        setSelectedProviderType(null);
        setIsModalOpen(false);
        setIsSplitPayment(false);
    }

    const handleDropdownVisibleChange = (value) => {
        let transaction_id = linkData?.transaction_id;
        if (value) {
            getCategoryListing({ search_term: value, transaction_id });
        }
    };

    const handleCompanyChange = async (data) => {
        let findSelectedCategory = companyList?.find((item) => data.value === item.id);
        if (findSelectedCategory && findSelectedCategory.category) {
            let categoryData = await getCategoryListing({ search_term: findSelectedCategory.category.name });
            if (categoryData?.data?.categories?.length > 0) {
                let findRealCateogory = categoryData?.data?.categories?.find((item) => item?.id === findSelectedCategory?.category.id);
                if (findRealCateogory) {
                    form.setFieldsValue({
                        'category_id': findRealCateogory.id,
                    });
                } else {
                    form.setFieldsValue({
                        'category_id': null,
                    });
                }
            }
        }
    }

    const renderAmount = () => {
        let amount = 0;

        if (selectedRowsData?.length > 0) {
            amount = selectedRowsData.reduce((sum, row) => sum + (row.amount || 0), 0).toFixed(2);
        } else {
            amount = linkData?.amount?.toFixed(2);
        }

        return amount;
    };

    const handleAddCompany = async () => {
        let formData = {
            name: inputValue
        }
        try {
            const response = await addNewCompany(formData);
            if (response.data) {
                let newCompanyData = await refetchCompanyList();
                setCompanyList(newCompanyData?.data?.data?.companies);
                let findNewCompany = newCompanyData?.data?.data?.companies?.find((item) => item?.name === inputValue);
                form.setFieldsValue({
                    "company_id": findNewCompany?.id,
                    "category_id": null
                });
                setDropdownOpen(false);
                toast.success("Company Added");
            } else {
                toast.error(`${response?.error?.data?.message}` || "Failed to add new company!");
            }
        } catch (error) {
            console.error(error);
            toast.error(error);
        }
    }

    const handleCompanySelect = (companyId) => {
        setSelectedCompanies((prevSelected) => {
            const isAlreadySelected = prevSelected?.some((company) => company.id === companyId);
            const selectedCompany = merchantData.find((company) => company.id === companyId);

            if (!selectedCompany) return prevSelected;

            const updatedSelectedCompanies = isAlreadySelected
                ? prevSelected.filter((company) => company.id !== companyId)
                : [...prevSelected, { ...selectedCompany, auto_link: selectedCompany.auto_link || false }];

            return updatedSelectedCompanies;
        });
    };

    const handleAutofillChange = (e, companyId) => {
        const isChecked = e.target.checked;

        setCompanyAutoLink((prevSelected) => {
            if (isChecked) {
                if (!prevSelected?.some((company) => company.id === companyId)) {
                    const selectedCompany = merchantData.find((company) => company.id === companyId);
                    return [...prevSelected, { ...selectedCompany, auto_link: true }];
                }
                return prevSelected.map((company) =>
                    company.id === companyId ? { ...company, auto_link: true } : company
                );
            } else {
                return prevSelected.filter((company) => company.id !== companyId);
            }
        });
    };

    const handleSearchChange = (e) => {
        setCurrentPage(1)
        setSearchText(e?.target?.value || null);
    };

    useEffect(() => {
        if (searchText !== "" && searchText !== undefined && searchText !== null) {
            const handler = setTimeout(() => {
                setDebouncedSearch(searchText);
            }, 1500);

            return () => clearTimeout(handler);
        } else {
            setDebouncedSearch(searchText);
        }
    }, [searchText]);

    const handleImportCompanies = async () => {
        setIsCompanyModalOpen(false)
        swal({
            title: "Are you sure you want to add these companies?",
            text: "All future transactions linked to these companies will be automatically fetched.",
            buttons: {
                confirm: {
                    text: "Confirm",
                    value: true,
                    visible: true,
                    className: "custom-confirm-btn",
                    closeModal: true,
                },
                cancel: {
                    text: "Cancel",
                    value: null,
                    visible: true,
                    className: "custom-cancel-btn",
                    closeModal: true,
                },
            },
        }).then(async (willImport) => {
            if (willImport) {

                const formattedData = {
                    merchants: selectedCompanies?.map(company => ({
                        id: company.id,
                        name: company.name,
                        auto_link: companyAutoLink?.some((item) => {
                            if (item?.id === company?.id) {
                                return 1;
                            } else {
                                return 0
                            }
                        }),
                    })) || []
                };
                try {
                    const response = await importExpenseData(formattedData);
                    if (response?.data) {
                        await fetchData();
                        toast.success("Company data imported!");
                        setSelectedCompanies([]);
                    }
                }
                catch (error) {
                    console.error(error);
                    toast.error(error);
                }
            } else {
                setIsCompanyModalOpen(true);
            }
        });
    };

    const onSuccess = useCallback(
        async (public_token) => {
            try {
                const response = await setAccessToken({ public_token });

                if (response.error) {
                    toast.error(response.error.data.message);
                } else {
                    fetchData()
                    toast.success('Account Connected');
                }
            } catch (error) {
                toast.error('Error connecting account');
            }
        },
        [setAccessToken, refetch, navigate]
    );

    const config = {
        token: linkToken,
        onSuccess,
    };

    const { open, ready } = usePlaidLink(config);

    const handleAddTransactionCLick = async (record) => {
        setIsModalOpen(true);
        setLinkData(record);

        const id = record?.transaction_id;
        setIsLoadingForm(true);
        if (id) {
            setTransactionId(id);
            setSplitTransactionId(id)

            setTimeout(async () => {
                let fetchedCompaniesData = await refetchCompanyList({ transaction_id: id });

                let companyListing = fetchedCompaniesData?.data?.data?.companies;
                setCompanyList(companyListing);

                let selectedCompany = fetchedCompaniesData?.data?.data?.selected_company;
                if (selectedCompany !== undefined) {
                    form.setFieldsValue({
                        company_id: selectedCompany?.id
                    });

                    let getCategory = await getCategoryListing({ search_term: "", transaction_id: id });
                    setCategoryData(getCategory?.data?.categories);

                    let selectedCategory = getCategory?.data?.selected_category;
                    form.setFieldsValue({
                        category_id: selectedCategory?.id,
                        mask: record?.mask,
                        billing_descriptor: record?.billing_descriptor
                    });

                    setTransactionId(null);
                    setIsLoadingForm(false);
                } else {
                    setIsLoadingForm(false);
                }
            }, 0);
        }
    };

    const handleTransactionRemove = (record) => {
        setSelectedRecord(record);
        setIsModalVisible(true);
    };

    const handleConfirmRemove = async () => {
        if (!selectedRecord) return;

        let formData = {
            transaction_id: selectedRecord?.transaction_id,
            future_remove: removeForFuture ? 1 : 0,
        };

        try {
            let changeStatus = await removeTransaction(formData);

            if (changeStatus?.error) {
                toast.error(changeStatus?.error?.message || "Failed to update status.");
            } else {
                toast.success(changeStatus?.data?.message || "Status updated successfully.");
                refetch();
            }
        } catch (error) {
            toast.error("Something went wrong! Please try again.");
            console.error("API Error:", error);
        }

        setIsModalVisible(false);
    };

    const handleChangePage = (page, size) => {
        setCurrentPage(page);
        setPageSize(size);
    };

    const handleAutoLinkAllChange = (e) => {
        const isChecked = e.target.checked;
        setCompanyAutoLink((prevSelected) => {
            if (isChecked) {
                return merchantData.map((company) => ({ ...company, auto_link: true }));
            } else {
                return [];
            }
        });
    };

    useEffect(() => {
        if (exportPageSize) {
            handleTransactionsDataExport()
        }
    }, [exportPageSize])


    const handleTransactionsDataExport = async () => {
        setExportLoader(true);
        try {
            const response = await refetch();
            const transactions = response?.data?.data || [];

            const csvData = transactions.map((transaction, index) => {
                return {
                    "S.NO": index + 1,
                    "Transaction Name": transaction?.name,
                    "Amount": transaction?.amount,
                    "Category": transaction?.category_name,
                    "Transaction Date": dayjs(transaction?.date).format("MM-DD-YYYY"),
                    "Status": capitalizeFirstLetter(transaction?.status),
                    "Card/Account No.": transaction?.mask
                };
            });

            const csvRows = [
                ['S.NO', 'Transaction Name', "Amount", 'Category', 'Transaction Date', 'Status'],
                ...csvData.map(item => [
                    item['S.NO'],
                    item["Transaction Name"],
                    item["Amount"],
                    item["Category"],
                    item["Transaction Date"],
                    item["Status"],
                ])
            ];

            const csvString = csvRows.map(row => row.join(',')).join('\n');
            const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
            saveAs(blob, 'transactions.csv');
        } catch (error) {
            console.error("Error exporting data: ", error);
        }
        setExportLoader(false);
        setExportPageSize(null)
    };

    return (
        <>
            {loading && (
                <div className="commanLoaderWrapper">
                    <Spin size="large" />
                    <h4>Setting things up..</h4>
                </div>
            )}
            <TopHeader
                title={<Title title="Transactions" id="transaction" />}
                search={
                    <Input.Search
                        placeholder="Search..."
                        allowClear
                        value={searchText}
                        onChange={handleSearchChange}
                        className=" me-3 tableSearchInput"
                    />
                }
                button={
                    <>
                        {dataSource?.length ? (
                            selectedRowKeys?.length > 1 ||
                                selectedRowKeys?.length === dataSource?.length ? (
                                <Button
                                    className="me-3"
                                    type="primary"
                                    style={{
                                        backgroundColor: colors?.primary || "#F47A3A",
                                    }}
                                    onClick={async () => {
                                        setIsModalOpen(true);
                                        const fetchedCompaniesData = await refetchCompanyList();
                                        const companyListing = fetchedCompaniesData?.data?.data?.companies;
                                        setCompanyList(companyListing);
                                    }}
                                >
                                    {"Add your transaction"}
                                </Button>

                            ) : (
                                ""
                            )
                        ) : (
                            ""
                        )}
                        <Button
                            type="primary"
                            style={{
                                backgroundColor: colors?.primary || "#F47A3A",
                            }}
                            onClick={() => getTransactions()}
                            className="customHeaderBtn step-two"
                        >
                            {"Refresh transactions"}
                        </Button>
                    </>
                }
            />
            <div className="customPadding">
                <div className="breadcrumbWrapper">
                    <Breadcrumb
                        items={[
                            {
                                title: (
                                    <span onClick={() => navigate('/dashboard')} style={{ cursor: 'pointer' }}>
                                        <HomeOutlined />
                                    </span>
                                ),
                            },
                            {
                                title: (
                                    <>
                                        <span>Transactions</span>
                                    </>
                                ),
                            },
                        ]}
                    />
                </div>
                <div className="mainWrapper">
                    <div className="card titleCard mb-4">
                        <div className="card-body-wrapper">
                            <div className="table-responsives" style={{ paddingBottom: '3.5rem' }}>
                                {categorizePlaidData?.total_transactions === 0 && parent_id === null ? (
                                    <Alert
                                        message="Transactions are associated with your bank accounts, since you have not connected your bank account. Let's connect accounts now."
                                        type="warning"
                                        showIcon
                                        closable
                                        className="propertyAlert"
                                        action={
                                            <Button size="small" type="text" variant="filled" onClick={() => open()}>
                                                Link Account
                                            </Button>
                                        }
                                    />
                                ) : (<></>)}
                                {dataSource?.length > 0 ? (<><div className="export-data-wrapper d-flex justify-content-end align-items-center"><Button icon={<GrDocumentCsv />} loading={exportLoader} onClick={() => setExportPageSize(dataSource?.length)} className="export-data-button viewButton mb-3">Export</Button></div></>) : (<></>)}
                                <Table
                                    className="table-bordered step-three"
                                    rowKey={(record) => record.key}
                                    rowSelection={rowSelection}
                                    columns={columns}
                                    dataSource={dataSource ? dataSource : ""}
                                    scroll={{ x: '100%' }}
                                    pagination={false}
                                    loading={isLoading || isLoadingPlaid || localLoading}
                                    onChange={(pagination, filters, sorter) => {
                                        if (filters?.status) {
                                            setFilter(filters?.status)
                                        } else {
                                            setFilter(null)
                                        }
                                        if (sorter.order) {
                                            const columnTitle = columns.find(col => col.dataIndex === sorter.field)?.dataIndex;
                                            setColumnName(columnTitle);

                                            if (sorter.order === "ascend") {
                                                setTableSortBy("asc")
                                            } else {
                                                setTableSortBy("desc")
                                            }
                                        }
                                    }}
                                />
                                {categorizePlaidData?.meta?.total > 20 ? (
                                    <Pagination
                                        className="mt-4"
                                        current={currentPage}
                                        pageSize={pageSize}
                                        total={categorizePlaidData?.meta?.total}
                                        onChange={handleChangePage}
                                        pageSizeOptions={pageSizeOptions}
                                        showSizeChanger
                                        locale={{
                                            items_per_page: `/${categorizePlaidData?.meta?.total || 0} Records`,
                                        }}
                                    />
                                ) : (<></>)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <TransactionModal
                isModalOpen={isModalOpen}
                closeAndReset={closeAndReset}
                linkData={linkData}
                properties={properties}
                utilityCategory={utilityCategory}
                companyList={companyList}
                categoryData={categoryData}
                handleFinish={handleFinish}
                isLoadingForm={isLoadingForm}
                isSaving={isSaving}
                isSplitPayment={isSplitPayment}
                setIsSplitPayment={setIsSplitPayment}
                propertyAmounts={propertyAmounts}
                setPropertyAmounts={setPropertyAmounts}
                handleSplitPaymentChange={handleSplitPaymentChange}
                handleCompanyChange={handleCompanyChange}
                handleDropdownVisibleChange={handleDropdownVisibleChange}
                handleAddCompany={handleAddCompany}
                isLoadingAddCompany={isLoadingAddCompany}
                ExpenseOptions={ExpenseOptions}
                form={form}
                selectedRowsData={selectedRowsData}
            />

            <CompanyModal
                isCompanyModalOpen={isCompanyModalOpen}
                setIsCompanyModalOpen={setIsCompanyModalOpen}
                merchantData={merchantData}
                selectedCompanies={selectedCompanies}
                setSelectedCompanies={setSelectedCompanies}
                companyAutoLink={companyAutoLink}
                setCompanyAutoLink={setCompanyAutoLink}
                handleCompanySelect={handleCompanySelect}
                handleAutofillChange={handleAutofillChange}
                handleImportCompanies={handleImportCompanies}
                isLoadingCompany={isLoadingCompany}
            />

            <Modal
                title="Are you sure you want to remove this transaction?"
                open={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                footer={false}
                okText="Remove"
                cancelText="Cancel"
                okButtonProps={{ danger: true }}
                className="importModel"
            >
                <Checkbox
                    checked={removeForFuture}
                    onChange={(e) => setRemoveForFuture(e.target.checked)}
                    style={{ color: "#ff8241" }}
                >
                    <div className="labelCLass">Remove this transaction for all future transactions</div>
                </Checkbox>
                <div className="btnWrapper">
                    <Button onClick={() => handleConfirmRemove()} className="btn authBtn ">
                        Remove
                    </Button>
                </div>
            </Modal>

        </>
    );
}

export default AllTranscation;