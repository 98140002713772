import { Button, Form, Spin, Modal, Input, message, Breadcrumb, Space } from "antd";
import Title from "../../component/PageTitle";
import { IoLocationOutline } from "react-icons/io5";
import { TbBuilding, TbMapPinCode } from "react-icons/tb";
import { BsBuildings, BsGlobeAmericas, BsBuildingFillGear } from "react-icons/bs";
import AddPropertyForm from "../../component/Form/AddPropertyForm";
import {
    useAddPropertyMutation,
    useAddPropertyTypeMutation,
    useEditPropertyMutation,
    useUpdateLabelsMutation,
} from "../../slices/apiSlice";
import { toast } from "react-toastify";
import {
    useGetCityQuery,
    useGetCountriesQuery,
    useGetStateQuery,
    usePropetyTypeQuery,
    useGetLabelsQuery,
} from "../../slices/getSlice";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { useForm } from "antd/es/form/Form";
import TopHeader from "../../component/Header";
import { HomeOutlined } from '@ant-design/icons';
import { error } from "highcharts";
import { FaBuildingUser } from "react-icons/fa6";
import { RxInfoCircled } from "react-icons/rx";

const AddProperty = () => {
    const [form] = Form.useForm();
    const [modal] = Form.useForm();
    const navigate = useNavigate();
    const location = useLocation();
    const { loading, setLoading } = useOutletContext();
    const [FormInitialValues, setFormInitialValues] = useState(location?.state?.data);
    const [ModalOpen, setModalOpen] = useState(false);
    const [labelsModal, setLabelsModal] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState()
    const [selectedState, setSelectedState] = useState()
    const [labelUpdate, { isLoading: isLoadingUpdate }] = useUpdateLabelsMutation();
    const [countries, setCountries] = useState([])
    const [states, setStates] = useState()
    const [cities, setCities] = useState()
    const [propertyType, setPropertyType] = useState();
    const [addProperty, { isLoading: isaddLoading }] =
        useAddPropertyMutation();
    const [editProperty, { isLoading: iseditLoading }] =
        useEditPropertyMutation();
    const [addPropertyType, { isLoading: isLoadingPropertyAdd }] = useAddPropertyTypeMutation();
    const [isSaving, setIsSaving] = useState(false);
    const [newSelectedProperty, setNewSelectedProperty] = useState();
    const [labelForm] = useForm();
    const [isInfoModal, setIsModalInfo] = useState(false);
    let formType = location?.state?.type;

    const {
        data: propetytype,
        isLoading: ispropertyTypeLoading,
        isError: ispropertyTypeError,
        refetch: refetchPropertyType
    } = usePropetyTypeQuery();

    const {
        data: labelData,
        refetch,
    } = useGetLabelsQuery('property_form');
    const { data: getCountries } = useGetCountriesQuery()
    const { data: getStates } = useGetStateQuery(selectedCountry ? selectedCountry : location?.state?.data?.country_id)
    const { data: getCities } = useGetCityQuery(selectedState ? selectedState : location?.state?.data?.state_id)

    useEffect(() => {
        if (propetytype && propetytype.data) {
            const transformedOptions = propetytype.data.map((item) => ({
                label: item.name,
                value: item.id,
            }));
            setPropertyType(transformedOptions);
            setLoading(false)
        }
        if (getCountries && getCountries?.length > 0) {
            const transformedOptions = getCountries?.map((item) => ({
                label: item.name,
                value: item.id,
            }));
            setCountries(transformedOptions);
        }
        if (getStates?.length > 0) {
            const transformedOptions = getStates?.map((item) => ({
                label: item.name,
                value: item.id,
            }));
            setStates(transformedOptions);
        }
        if (getCities?.length > 0) {
            const transformedOptions = getCities?.map((item) => ({
                label: item.name,
                value: item.id,
            }));
            setCities(transformedOptions);
        }

    }, [propetytype, getCountries, getStates, getCities]);

    const capitalizeFirstLetter = (string) => {
        return string?.charAt(0)?.toUpperCase() + string?.slice(1);
    };

    useEffect(() => {
        if (FormInitialValues) {
            form.setFieldsValue(FormInitialValues);
        }
    }, [FormInitialValues, form]);

    useEffect(() => {
        if (labelData && typeof labelData === 'object') {
            const updatedLabelData = Object.fromEntries(
                Object.entries(labelData).map(([key, value]) => [key, capitalizeFirstLetter(value)])
            );
            labelForm.setFieldsValue(updatedLabelData);
        }
    }, [labelData]);

    const fields = [
        {
            component: "input",
            type: "text",
            label: capitalizeFirstLetter(labelData?.name_of_property) || "Name of Property",
            name: "name_of_property",
            placeholder: "Enter name of property ",
            prefix: (
                <FaBuildingUser style={{ color: "#F47939", fontSize: "25px" }} />
            ),
            rules: [
                {
                    required: true,
                    message: "Please enter the name of property",
                },
                // {
                //     pattern: /^(?!\s)[A-Za-z\s]+(?<!\s)$/,
                //     message: 'Property Name should contain only letters',
                // },
            ],
            colSpan: 12,
        },
        {
            component: "select",
            label: (
                <Space>
                    {capitalizeFirstLetter(labelData?.property_type) || "Property Type"}
                    <RxInfoCircled style={{ cursor: "pointer", color: '#f47a3a' }} onClick={() => setIsModalInfo(true)} />
                </Space>
            ),
            name: "property_type_id",
            placeholder: "Please select property",
            suffixIcon: (
                <BsBuildingFillGear style={{ color: "#F47939", fontSize: "25px" }} />
            ),
            rules: [
                {
                    required: true,
                    message: "Please select property type",
                },
            ],
            options: propertyType,
            colSpan: 12,
        },
        {
            component: "input",
            type: "text",
            label: capitalizeFirstLetter(labelData?.address) || "address",
            name: "address",
            placeholder: "Enter address",
            prefix: (
                <IoLocationOutline style={{ color: "#F47939", fontSize: "25px" }} />
            ),
            rules: [
                {
                    required: true,
                    message: "Please input your address",
                },

            ],
            colSpan: 12,
        },
        {
            component: "select",
            type: "text",
            label: capitalizeFirstLetter(labelData?.Country) || "Country",
            name: "country_id",
            placeholder: "Search your country",
            suffixIcon: (
                <BsGlobeAmericas style={{ color: "#F47939", fontSize: "23px" }} />
            ),
            rules: [
                {
                    required: true,
                    message: "Please select your country",
                },
            ],
            onChange: (value) => {
                setSelectedCountry(value);
                setFormInitialValues({
                    state_id: null,
                    city_id: null
                });
                setSelectedState(null);
            },
            options: countries,
            colSpan: 12,
        },
        {
            component: "select",
            type: "text",
            label: capitalizeFirstLetter(labelData?.state) || "State / Province",
            name: "state_id",
            placeholder: "Search your state",
            onChange: (value) => {
                setSelectedState(value);
            },
            suffixIcon: <TbBuilding style={{ color: "#F47939", fontSize: "25px" }} />,
            options: states,
            colSpan: 12,
            rules: [
                {
                    required: true,
                    message: "Please select property type",
                },
            ],
        },
        {
            component: "select",
            type: "text",
            label: capitalizeFirstLetter(labelData?.city) || "City",
            name: "city_id",
            placeholder: "Search your city",
            suffixIcon: <BsBuildings style={{ color: "#F47939", fontSize: "25px" }} />,
            colSpan: 12,
            options: cities,
            rules: [
                {
                    required: true,
                    message: "Please select property type",
                },
            ],
        },
        {
            component: "input",
            type: "text",
            label: capitalizeFirstLetter(labelData?.zip_code) || "Zip / Postal Code",
            name: "zip_code",
            placeholder: "Enter zip code",
            prefix: (
                <TbMapPinCode style={{ color: "#F47939", fontSize: "25px" }} />
            ),
            onKeyPress: (e) => {
                if (!/[0-9]/.test(e.key)) {
                    e.preventDefault();
                }
            },
            onPaste: (e) => {
                const paste = (e.clipboardData || window.clipboardData).getData("text");
                if (!/^[0-9]+$/.test(paste)) {
                    e.preventDefault();
                }
            },
            rules: [
                {
                    required: true,
                    message: "Please input your zip code",
                },
            ],
            colSpan: 12,
            id: `input-${Math.random().toString(36).substr(2, 9)}`,
            inputMode: "none",
            onPaste: (e) => e.preventDefault(),
            onCopy: (e) => e.preventDefault(),
            onCut: (e) => e.preventDefault(),
        },
    ];

    useEffect(() => {
        if (propetytype && propetytype.data) {
            const transformedOptions = propetytype.data.map((item) => ({
                label: item.name,
                value: item.id,
            }));
            setPropertyType(transformedOptions);
        }
    }, [propetytype]);

    if (ispropertyTypeLoading) return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <Spin />
    </div>;

    if (ispropertyTypeError) return <p>Error fetching product.</p>;

    const onFinish = async (value) => {

        setIsSaving(true);
        try {

            const id = location?.state !== null ? location?.state.data.id : null;

            const finalData = {
                address: value?.address || location?.state?.address,
                city_id: value?.city_id ?? location?.state?.city ?? null,
                name_of_property: value?.name_of_property || location?.state?.name_of_property,
                property_type_id: value?.property_type_id || location?.state?.property_typeId,
                state_id: value?.state_id ?? location?.state?.state ?? null,
                zip_code: value?.zip_code || location?.state?.zip_code,
                country_id: value?.country_id || location?.state?.country,
            };

            if (id !== null) {
                if (typeof finalData.property_type_id == "string") {
                    let prop_type = propetytype.data.find(
                        (item) => item.name === finalData.property_type_id
                    );
                    finalData.property_type_id = prop_type.id;
                }
                if (typeof finalData.country_id == "string") {
                    let country = getCountries?.find(
                        (item) => item.name === finalData.country_id
                    );
                    finalData.country_id = country?.id;
                }
                if (typeof finalData.state_id == "string") {
                    let state = getStates?.find(
                        (item) => item.name === finalData.state_id
                    );
                    finalData.state_id = state?.id;
                }
                if (typeof finalData.city_id == "string") {
                    let city = getCities?.find(
                        (item) => item.name === finalData.city_id
                    );
                    finalData.city_id = city?.id;
                }

                const response = await editProperty({ data: finalData, id });
                if (response.data) {
                    navigate("/properties");
                    toast.success("Property has been updated successfully");
                    form.resetFields();
                } else {
                    toast.error("Please fill correct information for edit property");
                }
            } else {
                try {

                    const response = await addProperty(value);
                    if (response.data) {
                        toast.success(formType === "edit" ? "Property details updated!" : "Property added!");
                        navigate("/properties");
                        form.resetFields();
                    } else {
                        toast.error(`${response?.error?.data?.message}`);
                    }
                } catch (error) {

                    toast.error("Failed to add property");
                }
            }
        } catch {

        } finally {
            setIsSaving(false)
            localStorage.setItem('property_updated', true)
        }
    };

    const AddPropertyType = async (value) => {
        setIsSaving(true);
        let formData = {
            name: value
        }
        try {
            const res = await addPropertyType(formData);
            if (res?.data) {
                toast.success("Property Type has been created successfully");
                let newData = await refetchPropertyType();
                let findNewlyAdded = newData.data?.data?.find((item) => item?.name === value);

                setFormInitialValues((prevValues) => ({
                    ...prevValues,
                    property_type_id: findNewlyAdded?.id
                }));
                setPropertyType(newData?.data?.data);
                setNewSelectedProperty(value);
                setModalOpen(false);
            } else {
                toast.error(`${res?.error?.data?.message}`);
            }
        } catch {
            console.error(error);
        } finally {
            setIsSaving(false)
        }
    };

    const openLabelModal = () => {
        setLabelsModal(true);
    };

    const handleLabelModal = () => {
        setLabelsModal(false);
    };

    const updateLabels = async (value) => {
        const convertedData = {
            layout_data: {
                zip_code: value.zip_code !== undefined ? value.zip_code : labelData.zip_code,
                property_type: value.property_type !== undefined ? value.property_type : labelData.property_type,
                address: value.address !== undefined ? value.address : labelData.address,
                Country: value.Country !== undefined ? value.Country : labelData.Country,
                state: value.state !== undefined ? value.state : labelData.state,
                city: value.city !== undefined ? value.city : labelData.city,
                name_of_property: value.name_of_property !== undefined ? value.name_of_property : labelData.name_of_property
            }
        };

        setIsSaving(true);
        try {
            await labelUpdate({ label: 'property_form', value: convertedData }).unwrap();
            message.success('Labels updated successfully!');
            refetch();
        } catch (error) {
            console.error(error);
        } finally {
            setIsSaving(false);
            setLabelsModal(false);
            labelForm.resetFields();
        }
    };

    const closeInfoModal = () => {
        setIsModalInfo(false);
    }

    return (
        <>
            {loading && (
                <div className="commanLoaderWrapper">
                    <Spin size="large" />
                    <h4>Setting things up..</h4>
                </div>
            )}
            <TopHeader
                title={
                    formType === 'edit' ? (
                        <Title title="Edit Property" />
                    ) : (
                        <Title title="Add Property" />
                    )
                }
                search={''}
                button={
                    <>
                        {formType === 'edit' ? ''
                            : <Button
                                type="primary"
                                className="btnOutlined"
                                onClick={() => {
                                    openLabelModal()
                                }}
                            >
                                Change Labels
                            </Button>
                        }
                    </>
                }
            />
            <div className="customPadding">
                <div className="breadcrumbWrapper">
                    <Breadcrumb
                        items={[
                            {
                                title: (
                                    <span onClick={() => navigate('/dashboard')} style={{ cursor: 'pointer' }}>
                                        <HomeOutlined />
                                    </span>
                                ),
                            },
                            {
                                title: (
                                    <>
                                        <span>Properties</span>
                                    </>
                                ),
                            },
                            {
                                title: (
                                    <>
                                        <span>{formType === 'edit' ? 'Edit' : 'Add'} Property</span>
                                    </>
                                ),
                            },
                        ]}
                    />
                </div>
                <div className="mainWrapper">
                    <div className="card titleCard">
                    </div>
                    <div className="card tableCard">
                        <div className="card-body">
                            <AddPropertyForm
                                form={form}
                                fields={fields}
                                onFinish={onFinish}
                                buttonName={location?.state?.id ? "Save" : "Add"}
                                loading={location?.state?.id ? iseditLoading : isaddLoading}
                                FormInitialValues={FormInitialValues}
                                ButtonDisable={isSaving}
                                formType={formType}
                                handleAddNewProperty={AddPropertyType}
                                newSelectedProperty={newSelectedProperty}
                                propertyAddLoading={isLoadingPropertyAdd}
                            />
                        </div>
                    </div>

                    {/* Lables Modal */}
                    <Modal
                        title="Change Form Labels"
                        open={labelsModal}
                        // onOk={handleLabelModal}
                        onCancel={handleLabelModal}
                        className="assign_model"
                        width={720}
                        footer={false}
                        form={labelForm}
                    >
                        <div className="modalBody">
                            <Form
                                name="labelForm"
                                className="modalForm"
                                layout="vertical"
                                onFinish={updateLabels}
                                form={labelForm}
                            >
                                <div className="row">
                                    <div className="col-md-6">
                                        <Form.Item
                                            name="name_of_property"
                                            label={capitalizeFirstLetter(labelData?.name_of_property) || "Name of Property"}
                                        >
                                            <Input
                                                type="text"
                                                placeholder="Enter label text here..."
                                                className="form-control"
                                            />
                                        </Form.Item>
                                    </div>
                                    <div className="col-md-6">
                                        <Form.Item
                                            name="property_type"
                                            label={capitalizeFirstLetter(labelData?.property_type) || "Property Type"}
                                        >
                                            <Input
                                                type="text"
                                                placeholder="Enter label text here..."
                                                className="form-control"
                                            />
                                        </Form.Item>
                                    </div>
                                    <div className="col-md-6">
                                        <Form.Item
                                            name="address"
                                            label={capitalizeFirstLetter(labelData?.address) || "Address"}
                                        >
                                            <Input
                                                type="text"
                                                placeholder="Enter label text here..."
                                                className="form-control"
                                            />
                                        </Form.Item>
                                    </div>
                                    <div className="col-md-6">
                                        <Form.Item
                                            name="Country"
                                            label={capitalizeFirstLetter(labelData?.Country) || "Country"}
                                        >
                                            <Input
                                                type="text"
                                                autoComplete="off"
                                                placeholder="Enter label text here..."
                                                className="form-control"
                                            />
                                        </Form.Item>
                                    </div>
                                    <div className="col-md-6">
                                        <Form.Item
                                            name="state"
                                            autoComplete="off"
                                            label={capitalizeFirstLetter(labelData?.state) || "State"}
                                        >
                                            <Input
                                                type="text"
                                                placeholder="Enter label text here..."
                                                className="form-control"
                                            />
                                        </Form.Item>
                                    </div>
                                    <div className="col-md-6">
                                        <Form.Item
                                            name="city"
                                            autoComplete="off"
                                            label={capitalizeFirstLetter(labelData?.city) || "City"}
                                        >
                                            <Input
                                                type="text"
                                                autoComplete="off"
                                                placeholder="Enter label text here..."
                                                className="form-control"
                                            />
                                        </Form.Item>
                                    </div>
                                    <div className="col-md-6">
                                        <Form.Item
                                            name="zip_code"
                                            label={capitalizeFirstLetter(labelData?.zip_code) || "Zip Code"}
                                        >
                                            <Input
                                                type="text"
                                                autoComplete="off"
                                                placeholder="Enter label text here..."
                                                className="form-control"
                                            />
                                        </Form.Item>
                                    </div>
                                    <div className="col-md-12 text-center mt-4">
                                        <Form.Item>
                                            <Button type="primary" htmlType="submit" className="authBtn" >
                                                {isLoadingUpdate ? "Loading.." : "Save"}
                                            </Button>
                                        </Form.Item>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </Modal>
                </div>
            </div>

            <Modal
                open={isInfoModal}
                onCancel={closeInfoModal}
                footer={null}
                className="custominfoModal"
                title="Information"
            >
                <div className="modal-body">
                    <div className="text">
                        Choose a Property Type from the available options or create a new one by entering it in the input field. Your selection will be automatically updated in the Property Type field.
                    </div>
                </div>
            </Modal>

        </>
    );
};

export default AddProperty;
