import React from 'react';
import { Modal, Table, Checkbox, Button, Spin, Tooltip, Pagination } from 'antd';
import { FaCircleInfo } from "react-icons/fa6";

const CompanyModal = ({
    isCompanyModalOpen,
    setIsCompanyModalOpen,
    merchantData,
    selectedCompanies,
    setSelectedCompanies, 
    companyAutoLink,
    setCompanyAutoLink,
    handleCompanySelect,
    handleAutofillChange,
    handleImportCompanies,
    isLoadingCompany
}) => {
    return (
        <Modal
            title="Link Companies"
            open={isCompanyModalOpen}
            onCancel={() => setIsCompanyModalOpen(false)}
            footer={false}
            className="importModel"
        >
            <div className="modal-body">
                {isLoadingCompany ? (
                    <Spin size="large" style={{ display: "flex", justifyContent: "center", margin: "20px 0" }} />
                ) : merchantData.length > 0 ? (
                    <>
                        <Table
                            className="table-bordered"
                            rowKey="id"
                            dataSource={merchantData}
                            columns={[
                                {
                                    title: (
                                        <Checkbox
                                            onChange={(e) => {
                                                const isChecked = e.target.checked;
                                                setSelectedCompanies(
                                                    isChecked
                                                        ? merchantData.map((c) => ({ ...c, auto_link: true }))
                                                        : []
                                                );
                                            }}
                                            checked={selectedCompanies.length === merchantData.length && merchantData.length > 0}
                                            indeterminate={selectedCompanies.length > 0 && selectedCompanies.length < merchantData.length}
                                        />
                                    ),
                                    dataIndex: "id",
                                    render: (id, record) => (
                                        <>
                                            <Checkbox
                                                checked={selectedCompanies?.some((company) => company.id === id) || record.imported}
                                                onChange={() => handleCompanySelect(id)}
                                                disabled={record.imported}
                                            />
                                        </>
                                    ),
                                },
                                {
                                    title: "Company Name",
                                    dataIndex: "name",
                                },
                                {
                                    title: (
                                        <div className="d-flex">
                                            <Checkbox
                                                onChange={(e) => {
                                                    const isChecked = e.target.checked;
                                                    setCompanyAutoLink(
                                                        isChecked
                                                            ? merchantData.map((company) => ({ ...company, auto_link: true }))
                                                            : []
                                                    );
                                                }}
                                                checked={companyAutoLink.length === merchantData.length}
                                                indeterminate={companyAutoLink.length > 0 && companyAutoLink.length < merchantData.length}
                                            />
                                            <Tooltip title="Auto Link companies for future transactions">
                                                <span style={{ marginLeft: '8px' }}>Auto link</span>
                                                <FaCircleInfo style={{ marginLeft: '8px' }} />
                                            </Tooltip>
                                        </div>
                                    ),
                                    dataIndex: "auto_link",
                                    render: (id, record) => (
                                        <>
                                            <Checkbox
                                                checked={companyAutoLink?.some((company) => company.id === record?.id)}
                                                onChange={(e) => handleAutofillChange(e, record.id)}
                                            />
                                        </>
                                    ),
                                    width: "40%",
                                },
                            ]}
                            pagination={false}
                        />
                        {merchantData.length > 20 ? (
                            <Pagination
                                className="mt-4 mb-4"
                                pageSize={20}
                            />
                        ) : (<></>)}
                    </>
                ) : (
                    <p>No new companies available.</p>
                )}
            </div>

            <div className="btnWrapper mt-3">
                <Button
                    key="import"
                    type="primary"
                    onClick={handleImportCompanies}
                    style={{ backgroundColor: "#F47A3A" }}
                    disabled={selectedCompanies?.length === 0}
                >
                    Link
                </Button>
                <Button key="cancel" onClick={() => setIsCompanyModalOpen(false)}>
                    Cancel
                </Button>
            </div>
        </Modal>
    );
};

export default CompanyModal; 