import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Drawer, FloatButton, Spin } from 'antd';
import { FileTextOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import { useLazyGetHelpContentQuery } from '../../slices/getSlice';
import { useSelector } from "react-redux";

const HelpDrawer = () => {
    const [open, setOpen] = useState(false);
    const isDrawerOpen = useRef(false);
    const [content, setContent] = useState('<p>Loading help content...</p>');
    const location = useLocation();
    const slug = location.pathname.split('/').pop();
    const helpSectionEnable = useSelector((state) => state?.persistedReducer?.currency?.is_help_section_enabled);

    const [getHelpContent, { data: getHelpContentData, isLoading, error }] = useLazyGetHelpContentQuery();

    const FetchData = async () => {
        setContent('<p>Loading help content...</p>');
        try {
            const result = await getHelpContent(`?slug=${slug}`).unwrap();
            setContent(result?.data?.content || '<p>No content available.</p>');
        } catch (err) {
            console.error(err);
            if (err.status === 422) {
                setContent(`
                    <h2>Welcome to ShackBox!</h2>
                    <p>This help section provides guidance on using different features efficiently.</p>
                    <h3>📌 Quick Guide:</h3>
                    <ul>
                        <li>🔍 <strong>Navigation:</strong> Use the sidebar to switch between sections.</li>
                        <li>📊 <strong>Dashboard:</strong> Get an overview of reports and analytics.</li>
                        <li>📜 <strong>Reports:</strong> View detailed reports of activities and performance.</li>
                        <li>⚙️ <strong>Settings:</strong> Customize your experience in the settings menu.</li>
                    </ul>
                `);
            } else {
                setContent('<p>Error fetching help content. Please try again later.</p>');
            }
        }
    };

    useEffect(() => {
        const excludeSlugs = ['login', 'forgot_password', 'signup', 'reset', 'set_password'];

        if (slug === 'reset_password' || !excludeSlugs.includes(slug)) {
            FetchData();
        }
    }, [slug]);

    const showDrawer = useCallback(() => {
        if (!isDrawerOpen.current) {
            setOpen(true);
            isDrawerOpen.current = true;
        }
    }, []);

    const onClose = useCallback(() => {
        if (isDrawerOpen.current) {
            setOpen(false);
            isDrawerOpen.current = false;
        }
    }, []);

    return (
        <>
            {helpSectionEnable !== undefined && helpSectionEnable != false && (
                <FloatButton
                    icon={<FileTextOutlined />}
                    description="Help"
                    shape="square"
                    onClick={showDrawer}
                    className="helpFloatBtn"
                />
            )}

            <Drawer title="Help" onClose={onClose} open={open} className="helpDrawer">
                {isLoading ? <Spin tip="Loading help content..." /> : (
                    <div dangerouslySetInnerHTML={{ __html: content }} />
                )}
            </Drawer>
        </>
    );
};

export default HelpDrawer;
