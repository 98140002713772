import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    selectedProperties: null,
};

const PropertySlice = createSlice({
    name: "property",
    initialState,
    reducers: {
        setSelectedPropertyData: (state, action) => {
            state.selectedProperties = action.payload;
        },
    },
});

export const { setSelectedPropertyData } = PropertySlice.actions;
export default PropertySlice.reducer;
