import React, { useEffect, useState } from "react";
import {
	Button,
	Form,
	Input,
	Select,
	Row,
	Col,
	Checkbox,
	Space,
	Spin,
	DatePicker,
	Tooltip,
	InputNumber
} from "antd";
import { RiContactsLine } from "react-icons/ri";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { HiOutlineTrash } from "react-icons/hi";
import { IoMdAddCircle } from "react-icons/io";
import { AiFillDelete } from "react-icons/ai";
import { GiExpense } from "react-icons/gi";
import { TbBrandDaysCounter } from "react-icons/tb";
import dayjs from 'dayjs';
import { GiMoneyStack } from "react-icons/gi";
import { FaRegCalendarCheck } from "react-icons/fa6";
import {
	useGetCategoryListingMutation,
} from "../../slices/apiSlice";
import { toast } from "react-toastify";
import { SlCalender } from "react-icons/sl";

const PropertyDetailsForm = ({
	form,
	fields,
	onFinish,
	buttonName,
	onUtilityTypeChange,
	anotherButton,
	saveAndExit,
	loading,
	dynamicfields,
	formType,
	ButtonDisable,
	handleDelete,
	isDeleteSave,
	FormInitialValues = {},
	propertyProvider,
	initialData,
	isDataLoading,
	buttonLabel,
	handleAddNewCompany,
	newSelectedCompnay,
	companyAddLoading,
	setRemind,
	remind,
	remindDays
}) => {
	const [formValues, setFormValues] = useState({});
	const [dropdownVisible, setDropdownVisible] = useState(false);
	const location = useLocation()
	const colors = useSelector((state) => state?.persistedReducer?.colors);
	const [loader, setLoader] = useState(false)
	const [expenseData, setExpenseData] = useState([
		{ id: Date.now(), amount: "", start_date: "", payment_type: null },
	]);
	const [inputValue, setInputValue] = useState("");
	const [getCategoryListing, { data }] = useGetCategoryListingMutation();
	const [categoryData, setCategoryData] = useState([]);
	const [originalProviderId, setOriginalProviderId] = useState();
	const [remindVisible, setRemindVisible] = useState(false);
	const [error, setError] = useState("");
	const [loaderValue, setLoaderValue] = useState(false);

	let hiddenField = true;

	const handleDropdownVisibleChange = (value) => {
		setDropdownVisible(value);
		if (value) {
			getCategoryListing({ search_term: value });
		}
	};

	useEffect(() => {
		if (expenseData?.length > 0) {
			const containsPaymentTypeId5 = expenseData.some(item => item.payment_type_id === 5);
			setRemindVisible(containsPaymentTypeId5)
		}

	}, [expenseData])

	useEffect(() => {
		if (data) {
			setCategoryData(data?.categories);
		}
	}, [data]);

	const handleValuesChange = (changedValues, allValues) => {
		if (changedValues?.provider_id) {
			dynamicfields = null;
		}

		if (allValues) {
			setFormValues(allValues);
		}
	};

	useEffect(() => {
		if (FormInitialValues?.company_id == null) {
			form.resetFields(["company_id"]);
		}
		if (FormInitialValues?.state_id == null) {
			form.resetFields(["state_id"]);
			form.resetFields(["city_id"]);
		}
		if (remindDays) {
			form.setFieldsValue({ reminder_days: remindDays });
		}
	}, [FormInitialValues, remindDays]);

	const mainField = fields?.filter((item) => item.name === "provider_id" || item.name === "company_id" || item.name === "account_number");
	const otherFields = fields?.filter((item) => item.name !== "provider_id" && item.name !== "company_id");
	hiddenField = propertyProvider ? false : initialData !== undefined ? false : otherFields[0]?.hidden;

	useEffect(() => {
		if (initialData) {
			form.setFieldsValue({
				provider_id: initialData?.category?.name,
				company_id: initialData?.company?.id,
				account_number: initialData?.account?.account_number,
			});
			if (initialData?.expenses?.length > 0) {
				setExpenseData(initialData?.expenses);
			}
			setOriginalProviderId(initialData?.company?.category?.id);
			setTimeout(() => {
				setLoader(false);
			}, 700);
		}
	}, [initialData, form]);

	const handleFinish = (allValues) => {
		const staticValues = {};
		const dynamicValues = {};
		const errors = {};
		let errorMessages = [];

		const updatedValues = {
			...allValues,
			provider_id: originalProviderId || allValues?.provider_id
		};

		if (expenseData?.length > 0) {
			const formattedExpenseData = expenseData.map((row) => {
				const startDate = row.start_date
					? dayjs(row.start_date, ["YYYY-MM-DD", "DD-MM-YY"]).isValid()
						? dayjs(row.start_date, ["YYYY-MM-DD", "DD-MM-YY"])
						: null
					: null;

				if (row?.payment_type === 5 && (!row?.frequency || row?.frequency === "")) {
					toast.error("Please add the frequency before proceeding.");
					return null;
				}

				const formattedRow = {
					...row,
					start_date: startDate ? startDate.format("MM-DD-YYYY") : "",
					payment_type: row?.payment_type_id ?? row.payment_type,
					payment_type_id: row?.payment_type_id ?? row.payment_type,
					amount: Math.abs(parseFloat(row.amount)),
				};

				if (row?.type !== "new" && formType !== 'add') {
					formattedRow.id = row.id;
				} else {
					delete formattedRow.id;
				}

				return formattedRow;
			});

			formattedExpenseData.forEach((row, index) => {
				const rowErrors = {};
				let message = "These fields are required if you are adding an expense:\n";
				let requiredFields = [];

				if (!row.payment_type && !row.payment_type_id) {
					requiredFields.push("Expense Type");
				}
				if (!row.amount) {
					requiredFields.push("Amount");
				}
				if (!row.start_date) {
					requiredFields.push("Payment Date");
				}

				// If there are any required fields, format the message
				if (requiredFields.length > 0) {
					message += requiredFields.map(field => `- ${field}`).join("\n"); // Join with a newline
					errorMessages.push(message + "\n"); // Add the message to the errorMessages array
				}

				// Additional validation logic can be added here
			});

			if (errorMessages.length > 0) {
				toast.error(errorMessages[0]);
				return;
			}

			if (dynamicfields && !propertyProvider) {
				for (const field of allFields) {
					const value = formValues[field?.name];
					if (field?.static) {
						staticValues[field?.name] = value;
					} else {
						dynamicValues[field?.name] = value !== undefined ? value : field?.defaultValue;
					}
				}

				if (mainField?.length > 0 && otherFields?.length > 0) {
					staticValues["expenses"] = formattedExpenseData;
				}

				onFinish(staticValues, dynamicValues, formType, formValues);
			} else {
				for (const field of allFields) {
					const value = formValues[field?.name];

					if (field?.static) {
						staticValues[field?.name] = value;

						if (field?.name === "provider_id") {
							staticValues[field?.name] = updatedValues?.provider_id;
						}

						if (field?.name === "company_id") {
							staticValues[field?.name] = updatedValues?.company_id;
						}
					} else {
						if (field?.name !== "provider_id" && field?.name !== "company_id") {
							dynamicValues[field?.name] = value !== undefined ? value : field?.defaultValue;
						}
					}

					if (formType === "add") {
						staticValues["provider_type_id"] =
							propertyProvider?.provider_type_id ?? location.state.propertyData?.property_typeId;
						staticValues["property_id"] =
							propertyProvider?.property_id ?? location.state.propertyid;
					}
				}

				for (const key in updatedValues) {
					if (key !== "provider_id" && key !== "company_id") {
						dynamicValues[key] = updatedValues[key];
					}
				}

				if (mainField?.length > 0 && otherFields?.length > 0) {
					staticValues["expenses"] = formattedExpenseData;
				}
				onFinish(staticValues, dynamicValues, formType, formValues);
			}
		} else {
			toast.error("Please add atleast one expense value before saving!");
		}

	};

	const allFields = dynamicfields ? [...mainField, ...dynamicfields, ...otherFields] : [...mainField, ...otherFields];

	const onDelete = (fieldId) => {
		handleDelete(fieldId);
	};

	const FrequencyOptions = [
		{ label: "Daily", value: "daily" },
		{ label: "Weekly", value: "weekly" },
		{ label: "Bi-Weekly", value: "biweekly" },
		{ label: "Monthly", value: "monthly" },
		{ label: "Yearly", value: "yearly" },
	];

	const renderExpense = () => {
		const addRow = () => {
			setExpenseData((prev) => [...(prev || []), { id: Date.now(), amount: "", start_date: "", type: "new", payment_type: null, formattedAmount: "" }]);
		};

		const removeRow = (id) => {
			setExpenseData(expenseData.filter((row) => row.id !== id));
		};

		const handleSelectChange = (value, id) => {

			setExpenseData(
				expenseData.map((row) =>
					row.id === id
						? {
							...row,
							payment_type: value,
							payment_type_id: value,
							...(value === 5 && { next_payment_date: "", frequency: "" }),
						}
						: row
				)
			);
		};

		const handleInputChange = (value, id) => {
			const updatedExpenseData = expenseData.map((row) => {
				if (row.id === id) {
					return { ...row, amount: value };
				}
				return row;
			});
			setExpenseData(updatedExpenseData);
		};

		const formatAmount = (amount) => {
			return new Intl.NumberFormat('en-US', {
				style: 'currency',
				currency: 'USD',
			}).format(amount);
		};

		const handleBlur = (row, id) => {
			const formattedAmount = formatAmount(row.amount);
			const updatedExpenseData = expenseData.map((r) => {
				if (r.id === id) {
					return { ...r, formattedAmount };
				}
				return r;
			});
			setExpenseData(updatedExpenseData);
		};

		const handleDateChange = (dateString, id) => {
			const formattedDate = dateString ? dayjs(dateString, "MM/DD/YYYY").format("YYYY-MM-DD") : null;

			setExpenseData(
				expenseData.map((row) =>
					row.id === id ? { ...row, start_date: formattedDate } : row
				)
			);
		};

		const formatter = (value) => {
			if (!value || value === '') return '';
			value = value?.replace(/[^\d.]/g, '');
			const parts = value.split('.');
			const integer = parts[0] ? parseInt(parts[0], 10).toLocaleString() : '0';
			const decimal = parts[1] ? `.${parts[1].slice(0, 2)}` : '';
			return `$${integer}${decimal}`;
		};

		const parser = (value) => {
			if (!value) return '';
			return value?.replace(/[$,]/g, '');
		};

		return (
			<>
				{expenseData &&
					expenseData.length > 0 &&
					expenseData.map((row) => {
						const dateValue = row.start_date
							? dayjs(row.start_date.replace(/^00/, "20"), "YYYY-MM-DD")
							: null;

						const disabledDate = (current) => {
							return current && current.year() > dayjs().year();
						};

						const expenseOptions = otherFields?.find((item) => item?.name === "category_id").options;
						expenseOptions?.length > 0 && expenseOptions?.forEach(option => {
						});

						return (
							<Col key={row.id} span={24}>
								<div className="row" align="middle">
									<div className="col-md-4 mb-1">
										<Form.Item label="Expense Type">
											<Select
												value={row.payment_type ? row.payment_type : row.payment_type_id}
												onChange={(value) => handleSelectChange(value, row.id)}
												placeholder="Select Expense Type"
												className="formControl select-custom"
												options={expenseOptions}
												style={{ width: "100%" }}
												suffixIcon={
													<GiExpense
														style={{
															color: "#F47939",
															fontSize: "24px",
														}}
													/>
												}
											/>
										</Form.Item>
									</div>

									<div className="col-md-4 mb-1">
										<Form.Item label="Amount">
											<Input
												value={row.amount || ""}
												onChange={(e) => handleInputChange(e.target.value, row.id)}
												onBlur={() => handleBlur(row, row.id)}
												placeholder="Enter Amount"
												style={{ width: "100%" }}
												className="form-control customDropdown"
												prefix={<GiMoneyStack style={{ color: "#F47939", fontSize: "25px" }} />}
											/>
											{/* <InputNumber
												value={row.amount || ''}
												onChange={(value) => handleInputChange(value, row.id)}
												onBlur={() => handleBlur(row, row.id)}
												placeholder="Enter Amount"
												style={{ width: '100%' }}
												step={0.1}
												min={null}
												formatter={formatter}
												parser={parser}
												className="form-control customDropdown"
												prefix={<GiMoneyStack style={{ color: "#F47939", fontSize: "25px" }} />}
											/> */}
										</Form.Item>
									</div>

									<div className="col-md-3 col-10 mb-1">
										<Form.Item label="Payment Date">
											<DatePicker
												key={row.id}
												value={dateValue}
												onChange={(date, dateString) => handleDateChange(dateString, row.id)}
												format="MM-DD-YYYY"
												className="form-control"
												placeholder="Select Payment Date"
												disabledDate={disabledDate}
												prefix={
													<SlCalender
														style={{ color: "#F47939", fontSize: "25px", height: "20px" }}
													/>
												}
											/>
										</Form.Item>
									</div>

									<div className="col-md-1 col-2" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
										<AiFillDelete
											onClick={() => removeRow(row?.id)}
											style={{ fontSize: "20px", color: "#ff0000", cursor: "pointer" }}
										/>
									</div>
									{row.payment_type === 5 || row?.payment_type_id === 5 ? (
										<>
											{/* <div className="col-md-4 mb-1">
												<Form.Item label="Next Payment Date">
													<DatePicker
														value={row.next_payment_date
															? dayjs(row.next_payment_date.replace(/^00/, "20"), "YYYY-MM-DD")
															: null}
														onChange={(date, dateString) =>
															setExpenseData(
																expenseData.map((r) =>
																	r.id === row.id
																		? { ...r, next_payment_date: dateString ? dayjs(dateString, "MM-DD-YYYY").format("YYYY-MM-DD") : null }
																		: r
																)
															)
														}
														format="MM-DD-YYYY"
														placeholder="Select Next Payment Date"
														className="form-control"
														disabledDate={(current) => {
															if (!row.start_date) return false;
															const paymentDate = dayjs(row.start_date.replace(/^00/, "20"), "YYYY-MM-DD");
															return current && current.isBefore(paymentDate, "day");
														}}
													/>
												</Form.Item>

											</div> */}
											<div className="col-md-4 mb-1">
												<Form.Item label="Frequency"
													rules={[
														{
															required: true,
															message: "Please select Frequency!",
														},
													]}
												>
													<Select
														value={row.frequency || undefined}
														onChange={(value) =>
															setExpenseData((prevData) =>
																prevData.map((r) =>
																	r.id === row.id ? { ...r, frequency: value } : r
																)
															)
														}
														placeholder="Select Frequency"
														className="formControl select-custom"
														options={FrequencyOptions}
														suffixIcon={
															<FaRegCalendarCheck
																style={{
																	color: "#F47939",
																	fontSize: "24px",
																}}
															/>
														}
													/>
												</Form.Item>
											</div>
										</>
									) : ''}
								</div>
							</Col>
						);
					})}

				<div className="addMoreWrapper">
					<Button
						type="dashed"
						style={{ width: "100%", marginTop: "16px", backgroundColor: "#f47a3a", color: "#fff" }}
						icon={<IoMdAddCircle />}
						onClick={addRow}
					>
						{expenseData !== undefined && expenseData.length > 0
							? "Add more expenses"
							: "Add expense"}
					</Button>
				</div>
			</>
		);
	};

	const checkExistingProviderID = (data, fields, updatedFormValues, setFormValues) => {
		const newFormValues = { ...updatedFormValues };
		setFormValues(newFormValues);
	};

	const handleFieldChange = async (field, value, onUtilityTypeChange, setFormValues) => {
		if (field?.label === "Company") {
			let allCompanies = field?.options;
			let findCategory = allCompanies?.find((item) => item.id === value);
			if (findCategory?.category) {
				let categoryData = await getCategoryListing({ search_term: findCategory?.category?.name });
				if (categoryData?.data?.categories?.length > 0) {
					let findRealCateogory = categoryData?.data?.categories?.find((item) => item?.id === findCategory?.category.id);
					if (findRealCateogory) {
						const updatedFormValues = {
							...formValues,
							['provider_id']: findRealCateogory.id,
						};
						setFormValues(updatedFormValues);
						onUtilityTypeChange(findRealCateogory.id);
						form.setFieldsValue({
							'provider_id': findRealCateogory.id,
						});
					} else {
						form.setFieldsValue({
							'provider_id': null,
						});
					}
				}
			} else {
				form.setFieldsValue({
					'provider_id': null,
				});
			}
		}

		const updatedFormValues = {
			...formValues,
			[field?.name]: value,
		};

		setFormValues(updatedFormValues);

		if (field?.onChange) {
			field.onChange(value);
		}

		if (field?.name === "provider_id" && onUtilityTypeChange) {
			onUtilityTypeChange(value);
		}

		checkExistingProviderID(value, field, updatedFormValues, setFormValues);
	};

	const handleInputChangeNew = (e) => {
		let value = e.target.value;

		value = value.replace(/[^0-9]/g, '');

		value = value.replace(/^0+/, '');

		if (value === '') value = '0';

		if (parseInt(value) > 90) {
			value = '90';
		}

		e.target.value = value;

		if (!value || value === "0") {
			setError("Reminder days cannot be 0 or empty.");
		} else {
			setError("");
		}
	};

	const handleAddCompany = async () => {
		handleAddNewCompany(inputValue);
		setLoaderValue(true);
		showLoader();
	}

	useEffect(() => {
		if (newSelectedCompnay) {
			let companyField = mainField?.find((item) => item?.label === "Company");
			let options = companyField?.options;
			if (options && options?.length > 0) {
				let findNewCompany = options?.find((item) => item?.name === newSelectedCompnay);
				if (findNewCompany) {
					form.setFieldsValue({
						"company_id": findNewCompany?.id,
						"provider_id": null
					})
				}
			}
		}
	}, [newSelectedCompnay]);

	const showLoader = () => {
		setTimeout(() => {
			setLoaderValue(false);
		}, 2000);
	}

	return (
		<>
			{loader || isDataLoading ? (
				<div className="d-flex justify-content-center align-items-center">
					<Spin />
				</div>
			) : (
				<>
					{mainField?.length > 0 && otherFields?.length > 0 ? (
						<Form
							form={form}
							layout="vertical"
							onFinish={handleFinish}
							onValuesChange={handleValuesChange}
							className="customExpenseForm"
							initialValues={FormInitialValues ? FormInitialValues : formValues}
						>
							<Row gutter={16}>
								{mainField.map((field, index) => {
									const isHidden = propertyProvider ? false : field?.hidden
										? typeof field?.hidden === "function"
											? field?.hidden()
											: field?.hidden
										: "";

									const shouldDelete =
										location.pathname === "/property_details" && !field?.static && formType == "add";

									return (
										!isHidden && (
											<>
												<Col
													key={field?.name}
													md={field?.colSpan || 24}
													offset={field?.offset || 0}
												>
													{((field?.component === "input" || !field?.component) && field?.type === "phone") &&
														<>
															<Form.Item
																label={field?.label}
																name={field?.name}
																rules={[
																	{
																		required: true,
																		message: "Please enter phone number.",
																	},
																	({ getFieldValue }) => ({
																		validator(_, value) {
																			const initialPhoneNumber = getFieldValue(`${field?.name}`);
																			if (initialPhoneNumber === value) {
																				return Promise.resolve();
																			}
																			if (value && value.length === 12) {
																				return Promise.resolve();
																			}

																			return Promise.reject(new Error("Phone number must be exactly 12 digits."));
																		},
																	}),
																]}
																dependencies={field?.dependencies ? [field?.dependencies] : undefined}
															>
																<Input
																	placeholder={field?.placeholder}
																	defaultValue={field?.defaultValue}
																	className="form-control"
																	prefix={field?.prefix}
																	disabled={field?.disabled || ""}
																	type="text"
																	maxLength={12}
																	onKeyPress={(e) => {
																		if (!/[0-9+]/.test(e.key) || (e.key === "+" && e.target.value.includes("+"))) {
																			e.preventDefault();
																		}
																	}}
																	onPaste={(e) => {
																		const paste = (e.clipboardData || window.clipboardData).getData("text");
																		if (!/^[0-9+]+$/.test(paste) || (paste.indexOf("+") > 0)) {
																			e.preventDefault();
																		}
																	}}
																/>
															</Form.Item>
															{shouldDelete ? (
																<span className="deleteIcon">
																	<HiOutlineTrash
																		style={{ cursor: isDeleteSave ? "not-allowed" : "pointer", fontSize: "20px" }}
																		onClick={!isDeleteSave ? () => {
																			onDelete(field?.id);
																		} : null}
																	/>
																</span>
															) : ''}
														</>
													}

													{((field?.component === "input" || !field?.component) && field?.type !== "phone") &&
														<>
															<Tooltip title="Optional: Enter a unique account number to associate with this expense">
																<Form.Item
																	label={field?.label}
																	name={field?.name}
																	rules={field?.rules}
																	dependencies={
																		field?.dependencies
																			? [field?.dependencies]
																			: undefined
																	}
																>
																	{field?.type === "password" ? (
																		<Input.Password
																			placeholder={field?.placeholder}
																			defaultValue={field?.defaultValue}
																			className="form-control"
																			prefix={field?.prefix}
																			disabled={field?.disabled || ""}
																			type={field?.type}
																		/>
																	) : (
																		<Input
																			placeholder={field?.placeholder}
																			className="form-control"
																			defaultValue={field?.defaultValue}
																			disabled={field?.disabled || ""}
																			prefix={
																				field?.prefix ? (
																					field?.prefix
																				) : (
																					<RiContactsLine
																						style={{
																							color: colors?.primary || "#F47A3A",
																							fontSize: "25px",
																						}}
																					/>
																				)
																			}
																			type={field?.type}
																			min={0}
																		/>
																	)}
																</Form.Item>
															</Tooltip >
															{shouldDelete ? (
																<span className="deleteIcon">
																	<HiOutlineTrash
																		style={{ cursor: isDeleteSave ? "not-allowed" : "pointer", fontSize: "20px" }}
																		onClick={!isDeleteSave ? () => {
																			onDelete(field?.id);
																		} : null}
																	/>
																</span>
															) : ''}
														</>
													}

													{field?.component === "select" && (
														<>
															{field?.label !== 'Company' ? (
																<Form.Item
																	label={field?.label}
																	name={field?.name}
																	rules={field?.rules}
																>
																	<Select
																		id={index}
																		mode={field.mode || ""}
																		optionLabelProp="label"
																		placeholder={field?.placeholder}
																		suffixIcon={field?.suffixIcon}
																		className="formControl select-custom"
																		disabled={field.disabled || ""}
																		showSearch
																		onSelect={() => setDropdownVisible(false)}
																		onSearch={handleDropdownVisibleChange}
																		filterOption={(input, option) =>
																			(option?.label ?? "").toLowerCase().includes(input.toLowerCase())
																		}
																		options={categoryData?.map((item) => ({
																			label: item.name,
																			value: item.id,
																		}))}
																		optionRender={(option) => (
																			<Space>
																				<span role="img" aria-label={option.label}>
																					{option.label}
																				</span>
																			</Space>
																		)}
																		notFoundContent={categoryData?.length === 0 ? null : undefined}
																		onChange={(value) =>
																			handleFieldChange(field, value, onUtilityTypeChange, setFormValues)
																		}
																	/>
																</Form.Item>
															) : (
																<>
																	{loaderValue ? (
																		<div className="ownerLoaderWrapper">
																			<Spin />
																		</div>
																	) : ('')}
																	<Form.Item
																		label={field?.label}
																		name={field?.name}
																		rules={field?.rules}
																	>
																		<Select
																			id={index}
																			className="formControl select-custom"
																			disabled={field.disabled || ""}
																			showSearch
																			onSearch={(value) => setInputValue(value)}
																			filterOption={(input, option) =>
																				(option?.label ?? "").toLowerCase().includes(input.toLowerCase())
																			}
																			optionLabelProp="label"
																			placeholder={field?.placeholder}
																			suffixIcon={field?.suffixIcon}
																			onChange={(value) =>
																				handleFieldChange(field, value, onUtilityTypeChange, setFormValues)
																			}
																			onKeyDown={(e) => {
																				if (e.key === "Enter") {
																					const matchedOption = field?.options.find(
																						(opt) => opt.name.toLowerCase() === inputValue.toLowerCase()
																					);
																					if (matchedOption === undefined && inputValue !== "") {
																						handleAddCompany();
																					}
																				}
																			}}
																			options={
																				field.options?.length > 0
																					? field.options.map((item) => ({
																						label: item.name,
																						value: item.id,
																					}))
																					: []
																			}
																			notFoundContent={
																				field.options?.length === 0 && inputValue === ""
																					? null
																					: "No matching records found!"
																			}
																			optionRender={(option) => (
																				<Space>
																					<span role="img" aria-label={option.label}>
																						{option.label}
																					</span>
																				</Space>
																			)}
																			dropdownRender={(menu) => (
																				<>
																					{menu}
																					{inputValue &&
																						!field.options.some((opt) => opt.name.toLowerCase() === inputValue.toLowerCase()) && (
																							<div style={{ display: "flex", justifyContent: "space-between", padding: 8 }}>
																								<span>Add "{inputValue}"</span>
																								<Button type="link" onClick={handleAddCompany} disabled={companyAddLoading}>
																									{companyAddLoading ? <Spin size="small" /> : "Add"}
																								</Button>
																							</div>
																						)}
																				</>
																			)}
																		/>
																	</Form.Item>
																</>
															)}
														</>
													)}

													{field?.component === "checkbox" && (
														<Row justify="space-between">
															<Col span={12}>
																<Form.Item name={field?.name} valuePropName="checked">
																	<Checkbox
																		className="rememberMe"
																		onChange={(value) => {
																			setFormValues((prevValues) => ({
																				...prevValues,
																				[field.name]: value,
																			}));
																			if (field?.onChange) {
																				field?.onChange(value);
																			}
																		}}
																	>
																		{field?.label}
																	</Checkbox>
																</Form.Item>
															</Col>
														</Row>
													)}

												</Col>
											</>
										)
									);
								})}
							</Row>

							<Row gutter={16}>
								{dynamicfields && dynamicfields !== '' && dynamicfields?.map((field, index) => {
									const isHidden = propertyProvider ? false : field?.hidden
										? typeof field?.hidden === "function"
											? field?.hidden()
											: field?.hidden
										: "";

									const shouldDelete =
										location.pathname === "/property_details" && !field?.static && formType == "add";

									return (
										!isHidden && (
											<>
												<Col
													key={field?.name}
													md={field?.colSpan || 24}
													offset={field?.offset || 0}
												>

													{((field?.component === "input" || !field?.component) && field?.type === "phone") &&
														<>
															<Form.Item
																label={field?.label}
																name={field?.name}
																rules={[
																	{
																		required: true,
																		message: "Please enter phone number.",
																	},
																	({ getFieldValue }) => ({
																		validator(_, value) {
																			const initialPhoneNumber = getFieldValue(`${field?.name}`);
																			if (initialPhoneNumber === value) {
																				return Promise.resolve();
																			}
																			if (value && value.length === 12) {
																				return Promise.resolve();
																			}

																			return Promise.reject(new Error("Phone number must be exactly 12 digits."));
																		},
																	}),
																]}
																dependencies={field?.dependencies ? [field?.dependencies] : undefined}
															>
																<Input
																	placeholder={field?.placeholder}
																	defaultValue={field?.defaultValue}
																	className="form-control"
																	prefix={field?.prefix}
																	disabled={field?.disabled || ""}
																	type="text"
																	maxLength={12}
																	onKeyPress={(e) => {
																		if (!/[0-9+]/.test(e.key) || (e.key === "+" && e.target.value.includes("+"))) {
																			e.preventDefault();
																		}
																	}}
																	onPaste={(e) => {
																		const paste = (e.clipboardData || window.clipboardData).getData("text");
																		if (!/^[0-9+]+$/.test(paste) || (paste.indexOf("+") > 0)) {
																			e.preventDefault();
																		}
																	}}
																/>
															</Form.Item>

															{shouldDelete ? (
																<span className="deleteIcon">
																	<HiOutlineTrash
																		style={{ cursor: isDeleteSave ? "not-allowed" : "pointer", fontSize: "20px" }}
																		onClick={!isDeleteSave ? () => {
																			onDelete(field?.id);
																		} : null}
																	/>
																</span>
															) : ''}
														</>
													}

													{((field?.component === "input" || !field?.component) && field?.type !== "phone") &&
														<>
															<Form.Item
																label={field?.label}
																name={field?.name}
																rules={field?.rules}
																dependencies={
																	field?.dependencies
																		? [field?.dependencies]
																		: undefined
																}
															>
																{field?.type === "password" ? (
																	<Input.Password
																		placeholder={field?.placeholder}
																		defaultValue={field?.defaultValue}
																		className="form-control"
																		prefix={field?.prefix}
																		disabled={field?.disabled || ""}
																		type={field?.type}
																	/>
																) : (
																	<Input
																		placeholder={field?.placeholder}
																		className="form-control"
																		defaultValue={field?.defaultValue}
																		disabled={field?.disabled || ""}
																		prefix={
																			field?.prefix ? (
																				field?.prefix
																			) : (
																				<RiContactsLine
																					style={{
																						color: colors?.primary || "#F47A3A",
																						fontSize: "25px",
																					}}
																				/>
																			)
																		}
																		type={field?.type}
																	/>
																)}
															</Form.Item>

															{shouldDelete ? (
																<span className="deleteIcon">
																	<HiOutlineTrash
																		style={{ cursor: isDeleteSave ? "not-allowed" : "pointer", fontSize: "20px" }}
																		onClick={!isDeleteSave ? () => {
																			onDelete(field?.id);
																		} : null}
																	/>
																</span>
															) : ''}

														</>
													}

													{field?.component === "select" && (
														<Form.Item
															label={field?.label}
															name={field?.name}
															rules={field?.rules}
														>
															<Select
																id={index}
																dropdownRender={(originNode) => (
																	<div
																		style={{
																			padding: "0px",
																			display:
																				field?.name === "category_id" ||
																					field?.name === "frequency" ||
																					field?.name === "provider_id" ||
																					field?.name === "company_id"
																					? "block"
																					: dropdownVisible
																						? "block"
																						: "none",
																		}}
																	>
																		{originNode}
																	</div>
																)}
																className="formControl select-custom "
																disabled={field.disabled || ""}
																showSearch
																onSelect={() => setDropdownVisible(false)}
																onSearch={handleDropdownVisibleChange}
																filterOption={(input, option) =>
																	(option?.label ?? "")
																		.toLowerCase()
																		.includes(input.toLowerCase())
																}
																mode={field.mode || ""}
																optionLabelProp="label"
																placeholder={field?.placeholder}
																options={field?.options}
																suffixIcon={field?.suffixIcon}
																onChange={(value) => {
																	setFormValues((prevValues) => ({
																		...prevValues,
																		[field?.name]: value,
																	}));
																	if (field?.onChange) {
																		field?.onChange(value);
																	}
																	if (
																		field?.name === "provider_id" &&
																		onUtilityTypeChange
																	) {
																		onUtilityTypeChange(value);
																	}
																}}
																optionRender={(option) => (
																	<Space>
																		<span role="img" aria-label={option.data.value}>
																			{option.data.label}
																		</span>
																	</Space>
																)}
															/>
														</Form.Item>
													)}

													{field?.component === "checkbox" && (
														<Row justify="space-between">
															<Col span={12}>
																<Form.Item name={field?.name} valuePropName="checked">
																	<Checkbox
																		className="rememberMe"
																		onChange={(value) => {
																			setFormValues((prevValues) => ({
																				...prevValues,
																				[field.name]: value,
																			}));
																			if (field?.onChange) {
																				field?.onChange(value);
																			}
																		}}
																	>
																		{field?.label}
																	</Checkbox>
																</Form.Item>
															</Col>
														</Row>
													)}
												</Col>
											</>
										)
									);
								})}
							</Row>

							<Row gutter={[16, 16]}>
								{hiddenField ? (<></>) : (
									<>
										{renderExpense()}
									</>
								)}
							</Row>

							{remindVisible ? (
								<>
									<Checkbox
										className="mt-4"
										checked={remind}
										onChange={(e) => {
											const isChecked = e.target.checked;
											setRemind(isChecked);
											setFormValues(prevValues => ({
												...prevValues,
												is_remind: isChecked ? 1 : 0,
											}));
										}}
									>
										Remind
									</Checkbox>
									{remind ? (
										<Row className="formButtons">
											<Col md={12} className="text-center mt-3">
												<Form.Item
													label="Reminder (Please specify the day the reminder is needed. Max 90)"
													name="reminder_days"
													rules={[
														{ required: true, message: "Please enter reminder days." }
													]}
													validateStatus={error ? "error" : ""}
													help={error}
												>
													<Input
														type="text"
														onInput={handleInputChangeNew}
														prefix={<TbBrandDaysCounter style={{
															color: colors?.primary || "#F47A3A",
															fontSize: "25px",
														}} />}
														placeholder="Enter Reminder (Max 90 days)"
														className="form-control"
													/>
												</Form.Item>
											</Col>
										</Row>
									) : (
										<></>
									)}</>
							) : (<></>)}

							<Row className="formButtons">
								<Col md={24} className="text-center mt-3">
									{anotherButton && <>{anotherButton}</>}
									<Button
										type="primary"
										htmlType="submit"
										className={anotherButton ? "btnFilled ms-3 w-150" : "authBtn"}
										disabled={loading ? loading : ButtonDisable}
									>
										{loading ? "Loading..." : buttonLabel}
									</Button>
								</Col>
							</Row>
						</Form>
					) : (
						<Form
							form={form}
							layout="vertical"
							onFinish={handleFinish}
							onValuesChange={handleValuesChange}
							initialValues={FormInitialValues ? FormInitialValues : formValues}
						>
							<Row gutter={16}>
								{allFields.map((field, index) => {
									const isHidden = field?.hidden
										? typeof field?.hidden === "function"
											? field?.hidden()
											: field?.hidden
										: "";

									const shouldDelete =
										location.pathname === "/property_details" && !field?.static && formType == "add";
									return (
										!isHidden && (
											<Col
												key={field?.name}
												md={field?.colSpan || 24}
												offset={field?.offset || 0}
											>
												{((field?.component === "input" || !field?.component) && field?.type === "phone") &&
													<>
														<Form.Item
															label={field?.label}
															name={field?.name}
															rules={[
																{
																	required: true,
																	message: "Please enter phone number.",
																},
																({ getFieldValue }) => ({
																	validator(_, value) {
																		const initialPhoneNumber = getFieldValue(`${field?.name}`);
																		if (initialPhoneNumber === value) {
																			return Promise.resolve();
																		}
																		if (value && value.length === 12) {
																			return Promise.resolve();
																		}

																		return Promise.reject(new Error("Phone number must be exactly 12 digits."));
																	},
																}),
															]}
															dependencies={field?.dependencies ? [field?.dependencies] : undefined}
														>
															<Input
																placeholder={field?.placeholder}
																defaultValue={field?.defaultValue}
																className="form-control"
																prefix={field?.prefix}
																disabled={field?.disabled || ""}
																type="text"
																maxLength={12}
																onKeyPress={(e) => {
																	if (!/[0-9+]/.test(e.key) || (e.key === "+" && e.target.value.includes("+"))) {
																		e.preventDefault();
																	}
																}}
																onPaste={(e) => {
																	const paste = (e.clipboardData || window.clipboardData).getData("text");
																	if (!/^[0-9+]+$/.test(paste) || (paste.indexOf("+") > 0)) {
																		e.preventDefault();
																	}
																}}
															/>
														</Form.Item>
														{shouldDelete ? (
															<span className="deleteIcon">
																<HiOutlineTrash
																	style={{ cursor: isDeleteSave ? "not-allowed" : "pointer", fontSize: "20px" }}
																	onClick={!isDeleteSave ? () => {
																		onDelete(field?.id);
																	} : null}
																/>
															</span>
														) : ''}
													</>
												}

												{((field?.component === "input" || !field?.component) && field?.type !== "phone") &&
													<>
														<Form.Item
															label={field?.label}
															name={field?.name}
															rules={field?.rules}
															dependencies={
																field?.dependencies
																	? [field?.dependencies]
																	: undefined
															}
														>
															{field?.type === "password" ? (
																<Input.Password
																	placeholder={field?.placeholder}
																	defaultValue={field?.defaultValue}
																	className="form-control"
																	prefix={field?.prefix}
																	disabled={field?.disabled || ""}
																	type={field?.type}
																/>
															) : (
																<>
																	<Input
																		placeholder={field?.placeholder}
																		className="form-control"
																		defaultValue={field?.defaultValue}
																		disabled={field?.disabled || ""}
																		prefix={
																			field?.prefix ? (
																				field?.prefix
																			) : (
																				<RiContactsLine
																					style={{
																						color: colors?.primary || "#F47A3A",
																						fontSize: "25px",
																					}}
																				/>
																			)
																		}
																		type={field?.type}
																		min={0}
																	/>
																</>
															)}
														</Form.Item>
														{shouldDelete ? (
															<span className="deleteIcon">
																<HiOutlineTrash
																	style={{ cursor: isDeleteSave ? "not-allowed" : "pointer", fontSize: "20px" }}
																	onClick={!isDeleteSave ? () => {
																		onDelete(field?.id);
																	} : null}
																/>
															</span>
														) : ''}
													</>
												}

												{field?.component === "select" && (
													<Form.Item
														label={field?.label}
														name={field?.name}
														rules={field?.rules}
													>
														<Select
															id={index}
															dropdownRender={(originNode) => (
																<div
																	style={{
																		padding: "0px",
																		display:
																			field?.name === "category_id" ||
																				field?.name === "frequency" ||
																				field?.name === "provider_id" ||
																				field?.name === "company_id"
																				? "block"
																				: dropdownVisible
																					? "block"
																					: "none",
																	}}
																>
																	{originNode}
																</div>
															)}
															className="formControl select-custom "
															disabled={field.disabled || ""}
															showSearch
															onSelect={() => setDropdownVisible(false)}
															onSearch={handleDropdownVisibleChange}
															filterOption={(input, option) =>
																(option?.label ?? "")
																	.toLowerCase()
																	.includes(input.toLowerCase())
															}
															mode={field.mode || ""}
															optionLabelProp="label"
															placeholder={field?.placeholder}
															options={field?.options}
															suffixIcon={field?.suffixIcon}
															onChange={(value) => {
																setFormValues((prevValues) => ({
																	...prevValues,
																	[field?.name]: value,
																}));
																if (field?.onChange) {
																	field?.onChange(value);
																}
																if (
																	field?.name === "provider_id" &&
																	onUtilityTypeChange
																) {
																	onUtilityTypeChange(value);
																}
															}}
															optionRender={(option) => (
																<Space>
																	<span role="img" aria-label={option.data.value}>
																		{option.data.label}
																	</span>
																</Space>
															)}
														/>
													</Form.Item>
												)}

												{field?.component === "checkbox" && (
													<Row justify="space-between">
														<Col span={12}>
															<Form.Item name={field?.name} valuePropName="checked">
																<Checkbox
																	className="rememberMe"
																	onChange={(value) => {
																		setFormValues((prevValues) => ({
																			...prevValues,
																			[field.name]: value,
																		}));
																		if (field?.onChange) {
																			field?.onChange(value);
																		}
																	}}
																>
																	{field?.label}
																</Checkbox>
															</Form.Item>
														</Col>
													</Row>
												)}
											</Col>
										)
									);
								})}
							</Row>

							<Row className="formButtons">
								<Col md={24} className="text-center mt-3">
									{anotherButton && <>{anotherButton}</>}
									<Button
										type="primary"
										htmlType="submit"
										className={anotherButton ? "btnFilled ms-3 w-150" : "authBtn"}
										disabled={loading ? loading : ButtonDisable}
									>
										{loading ? "Loading..." : buttonName}
									</Button>
									{saveAndExit && <>{saveAndExit}</>}
								</Col>
							</Row>
						</Form>
					)}
				</>
			)}
		</>
	);
};

export default PropertyDetailsForm;