import React, { useContext, useEffect, useRef, useState } from 'react';
import { Form, Input, Table, Row, Col, Pagination } from 'antd';
const EditableContext = React.createContext(null);
const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};
const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
}) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const form = useContext(EditableContext);
    useEffect(() => {
        if (editing) {
            inputRef.current?.focus();
        }
    }, [editing]);
    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({
            [dataIndex]: record[dataIndex],
        });
    };
    const save = async () => {
        try {
            const values = await form.validateFields();
            toggleEdit();
            handleSave({
                ...record,
                ...values,
            });
        } catch (errInfo) {
        }
    };
    let childNode = children;
    if (editable) {
        childNode = editing ? (
            <Form.Item
                style={{
                    margin: 0,
                }}
                name={dataIndex}
                rules={[
                    {
                        required: true,
                        message: `${title} is required.`,
                    },
                ]}
            >
                <Input ref={inputRef} onPressEnter={save} onBlur={save} />
            </Form.Item>
        ) : (
            <div
                className="editable-cell-value-wrap"
                style={{
                    paddingRight: 24,
                }}
                onClick={toggleEdit}
            >
                {children}
            </div>
        );
    }
    return <td {...restProps}>{childNode}</td>;
};
const FinanceProviderDetails = () => {
    const [dataSource, setDataSource] = useState([
        {
            key: '0',
            name: 'Company Name',
            utilitytype: 'Primary Mortgage',
            amount: '$9999',
        },
        {
            key: '1',
            name: 'Company Name',
            utilitytype: 'Second Mortgage',
            amount: '$9999',
        },
        {
            key: '2',
            name: 'Company Name',
            utilitytype: 'Bank Account',
            amount: '$9999',
        },
        {
            key: '3',
            name: 'Company Name',
            utilitytype: 'Credit Card',
            amount: '$9999',
        },
        {
            key: '4',
            name: 'Company Name',
            utilitytype: 'Primary Mortgage',
            amount: '$9999',
        },
        {
            key: '5',
            name: 'Company Name',
            utilitytype: 'Primary Mortgage',
            amount: '$9999',
        },
        {
            key: '6',
            name: 'Company Name',
            utilitytype: 'Primary Mortgage',
            amount: '$9999',
        },
        {
            key: '7',
            name: 'Company Name',
            utilitytype: 'Primary Mortgage',
            amount: '$9999',
        },
        {
            key: '8',
            name: 'Company Name',
            utilitytype: 'Primary Mortgage',
            amount: '$9999',
        },
    ]);

    const [currentPage, setCurrentPage] = useState(1);
    const handleChangePage = (page) => {
        setCurrentPage(page);
    };
    const totalItems = dataSource ? dataSource.length : 0;
    const pageSize = 20;
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize, totalItems);
    const displayedItems = dataSource.slice(startIndex, endIndex);
    const defaultColumns = [
        {
            title: 'Utility Company Name',
            dataIndex: 'name',
            width: '30%',
            editable: true,
        },
        {
            title: 'Utility Type',
            dataIndex: 'utilitytype',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
        },
    ];

    const handleSave = (row) => {
        const newData = [...dataSource];
        const index = newData.findIndex((item) => row.key === item.key);
        const item = newData[index];
        newData.splice(index, 1, {
            ...item,
            ...row,
        });
        setDataSource(newData);
    };
    const Components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };
    const columns = defaultColumns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave,
            }),
        };
    });

    return (
        <>
            <div>
                <div className='overlayGroup financeProviderHeader'>
                    <Row>
                        <Col md={12} className='leftSection'>
                            <div className='bluwBannersection'>
                                <div className='blueBanner'>
                                    <h5>Finance Provider Details</h5>
                                </div>
                                <div className='infoText'>
                                    <p>Property Profile</p>
                                    <span>Uptown Sedona - 123 abbot st.</span>
                                </div>
                            </div>
                        </Col>
                        <Col md={12} className='RightSection text-center'>
                            <div className='header-imgSection'>
                                <div className='image'>
                                    <img class="img-fluid" src="assets/images/finance.png" />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="custom-table-container create-user mt-4 financeProviderTable">
                    {displayedItems.length > 0 ? (
                        <>
                            <Table
                                Components={Components}
                                rowClassName={() => 'editable-row'}
                                bordered
                                dataSource={displayedItems}
                                columns={columns}
                            />
                            <Pagination
                                className="mt-4"
                                current={currentPage}
                                pageSize={pageSize}
                                total={totalItems}
                                onChange={handleChangePage}
                                locale={{
                                    items_per_page: `/${totalItems || 0} Records`,
                                }}
                            />
                        </>
                    ) : (
                        <h3>no data found</h3>
                    )}

                </div>
            </div>
        </>
    );
};
export default FinanceProviderDetails;