import React, { useEffect, useState } from "react";
import {
	Button,
	Form,
	Input,
	Select,
	Row,
	Col,
	Space,
	Spin,
} from "antd";
import { RiContactsLine } from "react-icons/ri";
import { useSelector } from "react-redux";

const AddPropertyForm = ({
	form,
	fields,
	onFinish,
	buttonName,
	loading,
	ButtonDisable,
	FormInitialValues = {},
	formType,
	propertyAddLoading,
	handleAddNewProperty,
	newSelectedProperty,
}) => {
	const [formValues, setFormValues] = useState({});
	const [dropdownVisible, setDropdownVisible] = useState(false);
	const colors = useSelector((state) => state?.persistedReducer?.colors);
	const [inputValue, setInputValue] = useState("");
	const [loader, setLoader] = useState(false);
	const [loaderValue, setLoaderValue] = useState(false);

	const handleDropdownVisibleChange = (value) => {
		if (value) {
			setDropdownVisible(true);
		} else {
			setDropdownVisible(false);
		}
	};

	const handleValuesChange = (allValues) => {
		if (allValues) {

			setFormValues((prevValues) => ({
				...prevValues,
				...allValues,
			}));
		}
	};

	const handleFinish = (allValues) => {
		allValues.state_id = formValues?.state_id || FormInitialValues?.state_id;
		allValues.city_id = formValues?.city_id || FormInitialValues?.city_id;
		onFinish(allValues);
	};

	const allFields = fields;

	const handleFieldChange = (field, value, setFormValues) => {
		const updatedFormValues = {
			...formValues,
			[field?.name]: value,
		};

		if (field?.name === 'country_id') {
			form.setFieldsValue({
				'state_id': null,
				'city_id': null
			})
		}
		setFormValues(updatedFormValues);

		if (field?.onChange) {
			field.onChange(value);
		}
	};

	useEffect(() => {
		setLoader(true);
		if (FormInitialValues) {
			form.setFieldsValue({
				'state_id': FormInitialValues?.state,
				'city_id': FormInitialValues?.city
			});
			setLoader(false);
		} else {
			setLoader(false);
		}
	}, [FormInitialValues]);

	const showLoader = () => {
		setTimeout(() => {
			setLoaderValue(false);
		}, 2000);
	}

	const handleAddProperty = async () => {
		handleAddNewProperty(inputValue);
		setLoaderValue(true);
		showLoader();
	}

	useEffect(() => {
		if (newSelectedProperty) {
			let companyField = allFields?.find((item) => item?.name === "property_type_id");
			let options = companyField?.options;
			if (options && options?.length > 0) {
				let findNewCompany = options?.find((item) => item?.label === newSelectedProperty);
				if (findNewCompany) {
					form.setFieldsValue({
						"property_type_id": findNewCompany?.value,
					})
				}
			}
		}
	}, [newSelectedProperty]);

	return (
		<>
			{loader ? (
				<>
					<div className="loaderWrapper">
						<Spin />
					</div>
				</>
			) : (
				<Form
					form={form}
					layout="vertical"
					autoComplete="off"
					onFinish={handleFinish}
					onValuesChange={handleValuesChange}
					initialValues={FormInitialValues ? FormInitialValues : formValues}
					className="customPropertyForm"
				>
					<input type="text" style={{ display: 'none' }} autoComplete="off" />
					<Row gutter={16}>
						{allFields.map((field, index) => {
							const isHidden = field?.hidden
								? typeof field?.hidden === "function"
									? field?.hidden()
									: field?.hidden
								: "";

							return (
								!isHidden && (
									<>
										<Col
											key={field?.name}
											md={field?.colSpan || 24}
											offset={field?.offset || 0}
										>
											{((field?.component === "input" || !field?.component) && field?.type === "phone") &&
												<>
													<Form.Item
														label={field?.label}
														name={field?.name}
														rules={[
															{
																required: true,
																message: "Please enter phone number.",
															},
															({ getFieldValue }) => ({
																validator(_, value) {
																	const initialPhoneNumber = getFieldValue(`${field?.name}`);
																	if (initialPhoneNumber === value) {
																		return Promise.resolve();
																	}
																	if (value && value.length === 12) {
																		return Promise.resolve();
																	}

																	return Promise.reject(new Error("Phone number must be exactly 12 digits."));
																},
															}),
														]}
														dependencies={field?.dependencies ? [field?.dependencies] : undefined}
													>
														<Input
															placeholder={field?.placeholder}
															defaultValue={field?.defaultValue}
															className="form-control"
															prefix={field?.prefix}
															disabled={field?.disabled || ""}
															type="text"
															autoComplete="new-field"
															maxLength={12}
															onKeyPress={field?.onKeyPress ? field?.onKeyPress : (e) => {
																if (!/[0-9+]/.test(e.key) || (e.key === "+" && e.target.value.includes("+"))) {
																	e.preventDefault();
																}
															}}
															onPaste={field?.onPaste ? field?.onPaste : (e) => {
																const paste = (e.clipboardData || window.clipboardData).getData("text");
																if (!/^[0-9+]+$/.test(paste) || (paste.indexOf("+") > 0)) {
																	e.preventDefault();
																}
															}}
														/>
													</Form.Item>
												</>
											}
											{((field?.component === "input" || !field?.component) && field?.type !== "phone") &&
												<>
													<Form.Item
														label={field?.label}
														name={field?.name}
														rules={field?.rules}
														dependencies={
															field?.dependencies
																? [field?.dependencies]
																: undefined
														}
													>
														<Input
															placeholder={field?.placeholder}
															className="form-control"
															defaultValue={field?.defaultValue}
															autoComplete="new-field"
															prefix={
																field?.prefix ? (
																	field?.prefix
																) : (
																	<RiContactsLine
																		style={{
																			color: colors?.primary || "#F47A3A",
																			fontSize: "25px",
																		}}
																	/>
																)
															}
															onKeyPress={field?.onKeyPress}
															onPaste={field?.onPaste}
															type={field?.type}
															id={field?.id}
															inputMode={field?.inputMode}
															onCopy={field?.onCopy}
															onCut={field?.onCut}
														/>
													</Form.Item>
												</>
											}

											{field?.component === "select" && (
												<>
													{field?.name === "property_type_id" ? (
														<>
															{loaderValue ? (
																<div className="ownerLoaderWrapper">
																	<Spin />
																</div>
															) : ('')}
															<Form.Item
																label={field?.label}
																name={field?.name}
																rules={field?.rules}
															>
																<Select
																	id={index}
																	className="formControl select-custom"
																	disabled={field.disabled || ""}
																	showSearch
																	onSearch={(value) => setInputValue(value)}
																	filterOption={(input, option) =>
																		(option?.label ?? "").toLowerCase().includes(input?.toLowerCase() || "")
																	}
																	optionLabelProp="label"
																	placeholder={field?.placeholder}
																	suffixIcon={field?.suffixIcon}
																	onChange={(value) => handleFieldChange(field, value, setFormValues)}
																	onKeyDown={(e) => {
																		if (e.key === "Enter") {
																			const matchedOption = field?.options.find(
																				(opt) => opt.label.toLowerCase() === inputValue.toLowerCase()
																			);

																			if (matchedOption) {
																				handleFieldChange(field, matchedOption.value, setFormValues);
																			} else {
																				handleAddProperty();
																			}
																		}
																	}}
																	options={
																		Array.isArray(field.options) && field.options.length > 0
																			? field.options.map((item) => ({
																				label: item.label || item?.name || "",
																				value: item.value || item?.id || "",
																			}))
																			: []
																	}
																	notFoundContent={
																		field.options?.length === 0 && inputValue === ""
																			? null
																			: "No matching records found!"
																	}
																	optionRender={(option) => (
																		<Space>
																			<span role="img" aria-label={option.data.value ? option.data.value : option.value}>
																				{option.data.label ? option.data.label : option.label}
																			</span>
																		</Space>
																	)}
																	dropdownRender={(menu) => (
																		<>
																			{menu}
																			{inputValue && Array.isArray(field.options) && field.options.length > 0 &&
																				!field.options.some((opt) => opt.label?.toLowerCase() === inputValue?.toLowerCase()) && (
																					<div style={{ display: "flex", justifyContent: "space-between", padding: 8 }}>
																						<span>Add "{inputValue}"</span>
																						<Button type="link" onClick={handleAddProperty} disabled={propertyAddLoading}>
																							{propertyAddLoading ? <Spin size="small" /> : "Add"}
																						</Button>
																					</div>
																				)}
																		</>
																	)}
																/>
															</Form.Item>
														</>
													) : (
														<Form.Item
															label={field?.label}
															name={field?.name}
															rules={field?.rules}
														>
															<Select
																id={index}
																dropdownRender={(originNode) => (
																	<div
																		style={{
																			padding: "0px",
																			display: "block",
																		}}
																	>
																		{originNode}
																	</div>
																)}
																className="formControl select-custom "
																disabled={
																	(field?.label === "State" && !formValues?.country_id && formType !== 'edit') ||
																	(field?.label === "City" && formType !== 'edit' && (!formValues?.country_id || !formValues?.state_id))
																}
																showSearch
																autoComplete="new-field"
																onSelect={() => setDropdownVisible(false)}
																onSearch={handleDropdownVisibleChange}
																filterOption={(input, option) =>
																	(option?.label ?? "")
																		.toLowerCase()
																		.includes(input.toLowerCase())
																}
																mode={field.mode || ""}
																optionLabelProp="label"
																placeholder={field?.placeholder}
																options={field?.options}
																suffixIcon={field?.suffixIcon}
																onChange={(value) => handleFieldChange(field, value, setFormValues)}
																optionRender={(option) => (
																	<Space>
																		<span role="img" aria-label={option.data.value ? option.data.value : option.value}>
																			{option.data.label ? option.data.label : option.label}
																		</span>
																	</Space>
																)}
															/>
														</Form.Item>
													)}
												</>
											)}
										</Col>
									</>
								)
							);
						})}
					</Row>
					<Row className="formButtons">
						<Col md={24} className="text-center mt-3">
							<Button
								type="primary"
								htmlType="submit"
								className="authBtn"
								disabled={loading ? loading : ButtonDisable}
							>
								{loading ? "Loading..." : formType ? 'Update' : "Save"}
							</Button>
						</Col>
					</Row>
				</Form>
			)}
		</>
	);
};

export default AddPropertyForm;
