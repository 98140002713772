import { useEffect, useState, useCallback } from "react";
import { useTour } from "@reactour/tour";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useWalkThroughUpdateMutation } from "./slices/apiSlice.js";
import {
    view_property, sub_account, expense, income, companies,
    recurring, bank_account, all_transaction, manage_property
} from "./tourSteps.js";

const routeMappings = {
    "/sub_account": "sub_account",
    "/properties": "properties",
    "/expense": "expense",
    "/income": "income",
    "/companies": "companies",
    "/recurring_transactions": "recurring_transactions",
    "/connect_bank_account": "connect_bank_account",
    "/all_transactions": "all_transactions",
    "/manage_property": "manage_property",
};

const tourStepsMappings = {
    sub_account,
    properties: view_property,
    expense,
    income,
    companies,
    recurring_transactions: recurring,
    connect_bank_account: bank_account,
    all_transactions: all_transaction,
    manage_property
};

const TourHandler = () => {
    const { isOpen, setIsOpen, setSteps, setCurrentStep, Close } = useTour();
    const location = useLocation();
    const stateData = useSelector((state) => state?.persistedReducer?.currency?.is_walkthrough_enabled);
    const walkThroughLabels = useSelector((state) => state?.persistedReducer?.walkthroughData);
    const [currentPage, setCurrentPage] = useState(null);
    const [wasTourOpened, setWasTourOpened] = useState(false);
    const [walkthroughLabels] = useWalkThroughUpdateMutation();

    const handleTourClose = useCallback(async () => {
        if (!currentPage || !wasTourOpened) return;

        try {
            const res = await walkthroughLabels({ page: currentPage });

            if (!res || res.error) {
                throw new Error(res?.error || "Unknown API error");
            }
        } catch (error) {
            console.error("Error closing tour:", error?.message || error);
        }
    }, [currentPage, wasTourOpened, walkthroughLabels]);

    useEffect(() => {
        if (!stateData || !walkThroughLabels) return;

        const matchedRoute = Object.keys(routeMappings).find(route => location.pathname.includes(route));

        if (matchedRoute) {
            const pageKey = routeMappings[matchedRoute];
            if (walkThroughLabels[pageKey] === false) {
                setCurrentPage(pageKey);
                setSteps(tourStepsMappings[pageKey]);
                setCurrentStep(0);
                setWasTourOpened(true);
                setTimeout(() => setIsOpen(true), 1500);
            }
        }
    }, [location.pathname, location.key, stateData, walkThroughLabels, setIsOpen, setSteps, setCurrentStep]);

    useEffect(() => {
        if (!isOpen && wasTourOpened) {
            handleTourClose();
        }
    }, [isOpen, wasTourOpened, handleTourClose]);

    return null;
};

export default TourHandler;
