import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Provider } from "react-redux";
import { store } from "./app/store";
import { ToastContainer } from "react-toastify";
import { TourProvider } from '@reactour/tour'
import { view_property } from "./tourSteps.js";

const root = ReactDOM.createRoot(document.getElementById("root"));
const radius = 10

root.render(
    <TourProvider 
        steps={view_property    }
        styles={{
            popover: (base) => ({
              ...base,
              '--reactour-accent': '#3a54a5',
              borderRadius: radius,
              top: '20px',
              backgroundColor: '#dedede'
            }),
            maskArea: (base) => ({ ...base, rx: radius }),
            badge: (base) => ({ ...base, right: 'auto', left: '-0.8125em' }),
            close: (base) => ({ ...base, left: 'auto', right: 10, top: 10 }),
        }}
    >
        <Provider store={store}>
            <App />
            <ToastContainer position="top-center" autoClose={2500} />
        </Provider>
    </TourProvider>

);

