import React, { useState } from "react";
import { Layout } from "antd";
import Sidebar from "../Sidebar";
import TopHeader from "../Header";
import { Outlet } from "react-router-dom";

const { Content } = Layout;

const App = () => {
  const [loading, setLoading] = useState(true);

  return (
    <div style={{ position: "relative", height: "100vh" }}>
      <Layout style={{ height: "100vh" }}>
        <>
          <Sidebar />
          <Layout className="layout">
            <Content className="layoutContent">
              <Outlet context={{ loading, setLoading }} />
            </Content>
          </Layout>
        </>
      </Layout>
    </div>
  );
};

export default App;
