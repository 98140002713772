import React, { useEffect, useRef, useState } from "react";
import Title from "../../component/PageTitle";
import { Button, Tooltip, Space, Input, Table, Modal, Switch, Collapse, Spin, Pagination, Tabs } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import {
    useGetProviderTypeFromPropertyProviderQuery,
    useGetPropertyProvidersQuery,
    useGetUtilityCategoryQuery,
    useGetPropertyProvidersByStreamIdQuery,
    useGetIncomeQuery,
    useLazyGetStatisticsQuery,
} from "../../slices/getSlice";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { useDeleteExpenseNewMutation } from "../../slices/apiSlice";
import { FaEdit } from "react-icons/fa";
import { RxCrossCircled } from "react-icons/rx";
import { HiOutlineTrash } from "react-icons/hi2";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment";
import TopHeader from "../../component/Header";
import { HomeOutlined } from '@ant-design/icons';
import { Breadcrumb } from 'antd';
import swal from "sweetalert";
import { InfoCircleOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { useDeleteIncomeMutation, useUpdateRecurringMutation } from "../../slices/apiSlice";

const ViewProperty = () => {
    const start_Date = useSelector((state) => state.date.startDate);
    const end_Date = useSelector((state) => state.date.endDate);
    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const searchInput = useRef(null);
    const location = useLocation();
    const navigate = useNavigate();
    const { loading, setLoading } = useOutletContext();
    let propertyDataState = location?.state?.propertyData;
    const propertyId =
        Number.isInteger(propertyDataState?.property_id)
            ? propertyDataState.property_id
            : propertyDataState?.propertyId ? propertyDataState?.propertyId : propertyDataState.id;
    const propertyName = propertyDataState?.providerType ? propertyDataState?.providerType : location?.state?.propertyData?.name_of_property;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDeleteIncomeModal, setIsDeleteIncomeModal] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState("");
    const [selectedIncomeRecord, setSelectedIncomeRecord] = useState("");
    const [deleteExpense, { isLoading: isLoadingDelete }] = useDeleteExpenseNewMutation();
    const {
        data: getUtilityCategory = [],
    } = useGetUtilityCategoryQuery();
    const [isRefetching, setIsRefetching] = useState(false);
    const currencyGlobal = useSelector((state) => state?.persistedReducer?.currency)
    const currency = currencyGlobal ? currencyGlobal : '';
    const [isClicked, setIsClicked] = useState(propertyDataState?.providerType && propertyDataState?.provider_type_id ? true : false);
    const [tableData, setTableData] = useState([]);
    const [UtilityCategory, setUtilityCategory] = useState(propertyDataState?.provider_type_id ? propertyDataState?.provider_type_id : '');
    const [UtilityCategoryName, setUtiltityCategoryName] = useState(propertyDataState?.providerType ? propertyDataState?.providerType : '');
    const [tableLoading, setTableLoading] = useState(false);
    const startDate = useSelector((state) => state.date.startDate);
    const endDate = useSelector((state) => state.date.endDate);
    const [currentPage, setCurrentPage] = useState(1);
    const [tableSortBy, setTableSortBy] = useState(null);
    const [columnName, setColumnName] = useState(null);
    const [localLoading, setLocalLoading] = useState(false);
    const [totalIncome, setTotalIncome] = useState(null);
    const [updateRecurring, { isLoading: loadingRecurring }] = useUpdateRecurringMutation();
    const [pagesize, setPageSize] = useState(20);
    const [statistics, setStatistics] = useState(null);
    const [deleteIncome, { }] = useDeleteIncomeMutation();
    const [fetchStatistics, { isLoading: isStatisticsLoading, isError: isStatisticsError }] = useLazyGetStatisticsQuery();

    const {
        data: getProviderType = [],
        isLoading: isProviderTypeLoading,
        refetch: refetchProvider,
    } = useGetProviderTypeFromPropertyProviderQuery({
        property_id: propertyId || location?.state?.property_id,
        query: start_Date && end_Date ? `?start_date=${start_Date}&end_date=${end_Date}` : ""
    });

    const queryParams = [
        `page=${currentPage}`,
        `per_page=${pagesize}`,
        searchText ? `search=${searchText}` : "",
        tableSortBy && columnName ? `sort_direction=${tableSortBy}&sort_by=${columnName}` : "",
        startDate ? `start_date=${startDate}` : "",
        endDate ? `end_date=${endDate}` : "",
        `property_ids=${propertyId}`
    ]
        .filter(Boolean)
        .join("&");

    const {
        data: getIncome,
        isLoading,
        isError,
        refetch,
        error
    } = useGetIncomeQuery(`?${queryParams}`);

    const propertyData = location?.state?.propertyData;

    const data = React.useMemo(() => ({
        skip: isClicked,
        propertyId: propertyId || location?.state?.property_id,
        providerTypesId: UtilityCategory || location?.state?.provider_type_id,
        propertyName: propertyName,
        query: start_Date && end_Date ? `?start_date=${start_Date}&end_date=${end_Date}` : null,
    }), [isClicked, propertyId, UtilityCategory, location?.state?.provider_type_id, propertyName, start_Date, end_Date]);

    const {
        data: getPropertyProviders,
        isLoading: isPropertyProvidersLoading,
        refetch: refetchPropertyProvider,
        isError: isPropertyProviderError,
    } = useGetPropertyProvidersQuery(data);

    const dataSource = getIncome?.data ? getIncome.data.map((item) => ({
        property_id: item.property.name_of_property,
        propertyId: item.property.id,
        amount: item.amount,
        start_date: item.start_date,
        end_date: item.end_date,
        id: item.id,
        created_at: item.created_at,
        payment_type_id: item.payment_type_id,
        payment_type_name: item.payment_type_name,
        frequency: item?.frequency,
        notes: item?.notes,
        payment_mode_id: item?.payment_mode_id,
        propertyData: item?.property,
        parent_id: item?.parent_id,
        is_recurring_on: item?.is_recurring_on
    })) : [];

    const formatDate = (date) => {
        if (date) {
            return dayjs(date, "MM/DD/YYYY").format("YYYY-MM-DD");
        }
    };

    const fetchData = async () => {
        setLocalLoading(true);
        await refetch();
        const formattedStartDate = formatDate(startDate);
        const formattedEndDate = formatDate(endDate);
        let statsData = await fetchStatistics({ start_date: formattedStartDate, end_date: formattedEndDate, property_ids: propertyId });
        setStatistics(statsData?.data);
        setLocalLoading(false);
    };

    useEffect(() => {
        setTableLoading(true);
        if (getIncome) {
            setLoading(false);
            setTableLoading(false);
            setTotalIncome(getIncome?.total_income);
        }
        fetchData().finally(() => setTableLoading(false));
    }, [getIncome, startDate, endDate]);


    useEffect(() => {
        refetchProvider();
        if (getProviderType?.provider_types?.length > 0) {
            setIsRefetching(true);
            const firstProviderTypeId = getProviderType?.provider_types[0]?.provider_type_id || null;
            setUtilityCategory(firstProviderTypeId);
        }
        setLoading(false)
    }, [getProviderType]);

    useEffect(() => {
        if (getPropertyProviders) {
            setTableData(getPropertyProviders);
        }
    }, [getPropertyProviders]);

    useEffect(() => {
        if (UtilityCategory) {
            setIsRefetching(true);
            refetchPropertyProvider().finally(() => {
                setIsRefetching(false);
            });
        }
    }, [UtilityCategory, refetchPropertyProvider]);

    const handleCollapseChange = (key) => {
        setUtilityCategory(key);
        setIsClicked(true);
    };

    const handleToggleChange = (checked, record) => {
        if (!record?.id) {
            toast.error("Invalid record. Please try again.");
            return;
        }

        swal({
            title: "Are you sure?",
            text: `If you turn this ${checked ? "on" : "off"}, recurring income will ${checked ? "automatically be added to this property again." : "no longer be added to this property"} .`,
            // icon: "warning",
            // buttons: ["Cancel", "Yes, confirm!"],
            dangerMode: true,
            buttons: {
                confirm: {
                    text: "Yes, confirm!",
                    value: true,
                    visible: true,
                    className: "custom-confirm-btn",
                    closeModal: true,
                },
                cancel: {
                    text: "Cancel",
                    value: null,
                    visible: true,
                    className: "custom-cancel-btn",
                    closeModal: true,
                },
            },
        }).then(async (willConfirm) => {
            if (willConfirm) {
                let formData = {
                    type: "income",
                    id: record?.id,
                    is_recurring_on: checked ? 1 : 0
                };

                try {
                    let changeStatus = await updateRecurring(formData);

                    if (changeStatus?.error) {
                        toast.error(changeStatus?.error?.message || "Failed to update status.");
                    } else {
                        toast.success(changeStatus?.data?.message || "Status updated successfully.");
                        refetch();
                    }
                } catch (error) {
                    toast.error("Something went wrong! Please try again.");
                    console.error("API Error:", error);
                }
            }
        });
    };

    const Columns = [
        {
            title: "S.No.",
            dataIndex: "serial",
            render: (_, record, index) =>
                (currentPage - 1) * pagesize + index + 1,
        },
        {
            title: "Property Name",
            dataIndex: "name_of_property",
            render: (_, record) => (
                <>
                    {record?.property_id}
                </>
            ),
            sorter: true,
        },
        {
            title: "Income",
            dataIndex: "amount",
            render: (amount) => {
                const currencySymbol = currency?.currency_symbol || "$";
                const currencyName = currency?.currency_name || "USD";

                return new Intl.NumberFormat(currencyName === "Indian Rupee" ? "en-IN" : "en-US", {
                    style: "currency",
                    currency: currencyName === "Indian Rupee" ? "INR" : "USD",
                }).format(amount || 0);
            },
            sorter: true,
        },
        {
            title: "Income Date",
            dataIndex: "start_date",
            sorter: true,
            render: (text) => (text ? dayjs(text).format("MM/DD/YYYY") : "N/A"),
        },
        {
            title: "Income Name",
            dataIndex: "notes",
            sorter: true,
            render: (notes) => (
                <Tooltip title={notes}>
                    <div className="income-name">
                        {notes ? notes : '-'}
                    </div>
                </Tooltip>
            ),
        },
        {
            title: "Income Type",
            dataIndex: "payment_type_name",
            render: (_, record) => record?.payment_type_name,
            sorter: true,
        },
        {
            title: (
                <>
                    Status{" "}
                    <Tooltip title="Turn on/off recurring payment">
                        <InfoCircleOutlined className="infoIcon" />
                    </Tooltip>
                </>
            ),
            dataIndex: "status",
            render: (_, record) => (
                <div className="statusWrapper">
                    {record?.parent_id === null && record?.payment_type_name === "Recurring" && (
                        <>
                            <Switch
                                checked={record.is_recurring_on === true ? true : false}
                                onChange={(checked) => handleToggleChange(checked, record)}
                                className="toggleSwitch"
                            />
                        </>

                    )}
                </div>
            ),
        },
        {
            title: "Action",
            dataIndex: "action",
            render: (_, record) => (
                <div className="action-container">
                    <Tooltip title="Edit">
                        <button
                            className="btn editButton me-2"
                            onClick={() => navigate("/add_income", { state: record })}
                        >
                            <FaEdit />
                        </button>
                    </Tooltip>
                    <Tooltip title="Delete">
                        <button
                            className="btn deleteButton me-2"
                            onClick={() => {
                                setSelectedIncomeRecord(record.id);
                                setIsDeleteIncomeModal(true);
                            }}
                        >
                            <HiOutlineTrash />
                        </button>
                    </Tooltip>
                </div>
            ),
        },
    ];

    const handleOk = async (id) => {
        const res = await deleteExpense(selectedRecord);
        if (res?.data?.message) {
            refetchPropertyProvider();
            refetchProvider()
            setIsModalOpen(false);
            toast.success(res?.data?.message)
        }
    };

    const handleOkIncome = async () => {
        const res = await deleteIncome(selectedIncomeRecord);
        if (res?.data?.message) {
            const totalPages = Math.ceil((dataSource.length - 1) / pagesize);
            if (currentPage > totalPages) {
                setCurrentPage(totalPages);
            }
            refetch();
            setIsDeleteIncomeModal(false);
            toast.success(res?.data?.message);
        }
        else {
            toast.error("Not able to delete income")
        }
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setSelectedRecord("");
    };

    const handleIncomeCancel = () => {
        setIsDeleteIncomeModal(false);
        setSelectedIncomeRecord("");
    };

    const [showGroup, setShowGroup] = useState(false);

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText("");
    };

    useEffect(() => {
        refetchPropertyProvider();
    }, [isClicked,])

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            close,
        }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: "block",
                    }}
                />
                <Space>
                    <Button
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                        className=""
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Clear Search
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? "#1677ff" : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: "#ffc069",
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ""}
                />
            ) : (
                text
            ),
    });

    const showGroupSection = () => {
        setShowGroup(!showGroup);
    };

    const handleClick = (detail) => {
        navigate("/property_details", {
            state: {
                data: detail,
                propertyData: propertyDataState,
                propertyid: propertyId,
                propertyName: propertyName,
                isEdit: false
            },
        });
    };

    const utilityColumns = [
        {
            title: "S.No.",
            dataIndex: "serial",
            key: "serial",
            render: (text, record, index) => {
                return index + 1;
            },
        },
        {
            title: "Company",
            dataIndex: "company_id",
            key: "company",
            render: (text, record, index) => (
                <>
                    <span>{record?.company}</span>
                </>
            ),
            sorter: (a, b) => (a.company).localeCompare(b.company),
        },
        {
            title: "Total Expenses",
            dataIndex: "amount",
            render: (text, record, index) => (
                <>
                    <span> {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    }).format(record?.total_expenses)}</span>
                </>
            ),
            key: "amount",
            sorter: {
                compare: (a, b) => (a.total_expenses || 0) - (b.total_expenses || 0),
            },
        },
        {
            title: "Expense date",
            dataIndex: "expense_date",
            key: "expense_date",
            render: (text, record, index) => (
                <>
                    <span>{moment(record.expense_date).format('MM-DD-YYYY')}</span>
                </>
            ),

        },
        {
            title: "Account No",
            dataIndex: "account_number",
            render: (text, record, index) => (
                <>
                    <span>{record?.account_number}</span>
                </>
            ),
            key: "provider",
            sorter: (a, b) => (a.account_number).localeCompare(b.account_number),
        },
        {
            title: "Category",
            dataIndex: "provider",
            render: (text, record, index) => (
                <>
                    <span>{record?.category}</span>
                </>
            ),
            key: "provider",
            sorter: (a, b) => (a.company).localeCompare(b.company),
        },
        {
            title: "Action",
            dataIndex: "action",
            render: (_, record) => {
                return (
                    <div className="action-container">
                        <Tooltip title="Edit">
                            <button
                                className="btn editButton me-2"
                                onClick={() => {
                                    navigate("/property_details", {
                                        state: {
                                            data: record,
                                            providerType: UtilityCategory,
                                            providerTypeName: UtilityCategoryName,
                                            propertyData: propertyDataState,
                                            isEdit: true,
                                            activePropertyID: record?.provider_company_id
                                        },
                                    });
                                }}
                            >
                                <FaEdit />
                            </button>
                        </Tooltip>

                        <Tooltip title="Delete">
                            <button
                                className="btn deleteButton me-2"
                                onClick={() => {
                                    setSelectedRecord(record?.provider_company_id);
                                    setIsModalOpen(true);
                                }}
                            >
                                <HiOutlineTrash />
                            </button>
                        </Tooltip>
                    </div>
                );
            },
        },
    ];

    const ExpandedRow = ({ record, mainIndex }) => {
        const {
            data: PropertyProviders,
            isLoading: isPropertyProvidersLoading,
        } = useGetPropertyProvidersByStreamIdQuery(record.stream_id);

        const columns = [
            {
                title: "S.No.",
                dataIndex: "serial",
                key: "serial",
                render: (text, record, index) => {
                    return mainIndex + "." + (index + 1);
                },
            },

            {
                title: "Utility company",
                dataIndex: "company_id",
                key: "company",
                ...getColumnSearchProps("company_id"),
                sorter: (a, b) => (a.company_id || '').localeCompare(b.company_id || ''),
            },
            {
                title: "Amount",
                dataIndex: "amount",
                render: (amount) => `${currency} ${amount || 0}`,
                key: "amount",
                sorter: {
                    compare: (a, b) => (a.amount || 0) - (b.amount || 0),
                },
            },

            {
                title: "Payment Date",
                dataIndex: "start_date",
                render: (_, record) => {
                    if (record.category_id === "One time charges" || record.category_id === "Old data" || record.category_id === "Recurring") {
                        return record.start_date
                            ? moment(record.start_date).format('MM/DD/YYYY')
                            : "-";
                    }
                    return "-";
                },
                sorter: (a, b) => {
                    const dateA = new Date(a.start_date);
                    const dateB = new Date(b.start_date);
                    return dateA - dateB;
                },
            },

            {
                title: "Next Payment Date",
                dataIndex: "next_payment_date",
                render: (_, record) => {
                    if (record?.next_payment_date) {
                        return record.next_payment_date
                            ? moment(record.next_payment_date).format('MM/DD/YYYY')
                            : "-";
                    }
                },
                sorter: {
                    compare: (a, b) => a.record - b.record,
                },
            },
            {
                title: "Action",
                dataIndex: "action",
                render: (_, record) => {
                    return (
                        <div className="action-container">
                            <Tooltip title="Edit">
                                <button
                                    className="btn editButton me-2"
                                    onClick={() => {
                                        navigate("/property_details", {
                                            state: {
                                                data: record,
                                                providerType: UtilityCategory,
                                                providerTypeName: UtilityCategoryName,
                                                propertyData: propertyDataState,
                                                isEdit: true,
                                                activePropertyID: record?.provider_company_id,
                                            },
                                        });
                                    }}
                                >
                                    <FaEdit />
                                </button>
                            </Tooltip>

                            <Tooltip title="Delete">
                                <button
                                    className="btn deleteButton me-2"
                                    onClick={() => {
                                        setSelectedRecord(record.id);
                                        setIsModalOpen(true);
                                    }}
                                >
                                    <HiOutlineTrash />
                                </button>
                            </Tooltip>
                        </div>

                    );
                },
            },
        ];

        const transformedData = PropertyProviders?.data?.flatMap((item, index) => {
            return {
                key: index * 100 + index,
                provider_id: item?.provider?.provider_name,
                company_id: item?.company?.company_name,
                companyId: item?.company_id,
                amount: item?.amount,
                due_date: item?.due_date,
                id: item?.id,
                provider_id: item?.provider?.provider_name,
                frequency: item?.frequency,
                property_id: item?.property_id,
                provider_type_id: item?.provider_type_id,
                providerId: item?.provider_id,
                form_fields: item?.form_fields,
                start_date: item?.start_date,
                category_id: item?.category?.name,
                stream_id: item?.stream_id,
                payment_date: item.payment_date,
                next_payment_date: item.next_payment_date
            }

        });

        return isPropertyProvidersLoading ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <Spin />
            </div>
        ) : (
            <Table className="table-bordered" tableLayout="fixed" columns={columns} dataSource={transformedData} pagination={false} showHeader={false} />
        );
    };

    const expandedRowRender = (record, index) => <ExpandedRow record={record} mainIndex={index + 1} />;

    const expandIcon = ({ expanded, onExpand, record }) => {
        if (record.stream_id) {
            return (
                <span
                    onClick={(e) => onExpand(record, e)}
                    style={{ cursor: 'pointer' }}
                >
                    {expanded ? '-' : '+'}
                </span>
            );
        }
        return null;
    };


    const items = getProviderType ? (
        getProviderType?.provider_types?.map((property) => {
            const totalAmount = parseFloat(property.total_amount) || 0;
            const providerType = property?.provider_type_name ? property?.provider_type_name : property.provider_type ? property.provider_type.name : "Unknown";

            return {
                key: property.provider_type_id,
                // label:providerType,
                label: <div className="provider-type-name-amount" onClick={() => {
                    setUtilityCategory(property.provider_type_id);
                    setUtiltityCategoryName(providerType);
                }}><p className="provider-type-name">{providerType}</p><p className="provider-type-ammount">Expenses: {new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: currencyGlobal && currencyGlobal?.currency_name === "Indian Rupee" ? 'INR' : 'USD'
                }).format(Number((property?.total_amount)) || 0)}</p></div>,
                // extra: (
                //     <div style={{ display: "flex", alignItems: "center" }}>
                //         <Button
                //             className="btnFilled text-white"
                //         >
                //             {"Manage Expense"}
                //         </Button>
                //         {/* {totalAmount > 0 && !isPropertyProvidersLoading && !isPropertyProviderError ? (
                //             <span style={{ marginLeft: '10px', fontWeight: 'bold' }}>
                //                 Total: ${totalAmount.toFixed(2)}
                //             </span>
                //         ) : ''} */}
                //     </div>
                // ),
                children: isPropertyProvidersLoading || isRefetching ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <Spin />
                    </div>
                ) : (
                    <>
                        <Table
                            tableLayout="fixed"
                            className={`tableFixed table-bordered ${isRefetching ? "table-loading" : ""}`}
                            rowKey={(record) => record?.id}
                            bordered
                            dataSource={tableData}
                            expandable={{
                                expandedRowRender,
                                defaultExpandedRowKeys: ["0"],
                                expandIcon,
                                expandIconColumnIndex: -1,
                            }}
                            loading={isRefetching}
                            scroll={{ x: '100%' }}
                            columns={utilityColumns}
                            showSorterTooltip={false}
                            pagination={false}
                        />
                        {tableData?.length > 20 ? (
                            <Pagination
                                className="mt-4"
                                total={tableData?.length}
                                pageSize={pagesize}
                                onChange={(page, pageSize) => {
                                    setPageSize(pageSize);
                                }}
                                locale={{
                                    items_per_page: `/${tableData?.length || 0} Records`,
                                }}
                            />
                        ) : null}
                    </>
                ),
            };
        })
    ) : (
        <p>No data found</p>
    );

    const redirectToIncome = () => {
        navigate('/add_income');
    }

    const onChange = (key) => {
        console.log(key);
    };

    const tabItems = [
        {
            key: '1',
            label: 'Expense',
            children: (
                <>
                    <div className="card collapseCard">
                        <div className="card-header">
                            <h6 className="total-expense-property">Total Expense:  {new Intl.NumberFormat('en-US', {
                                style: 'currency',
                                currency: currencyGlobal && currencyGlobal?.currency_name === "Indian Rupee" ? 'INR' : 'USD'
                            }).format(Number((getProviderType?.total_expenses)) || 0)}</h6>
                        </div>
                        <div className="card-body">
                            {isProviderTypeLoading ? (
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                    <Spin />
                                </div>
                            ) : getProviderType?.provider_types?.length ? (
                                <Collapse
                                    accordion
                                    items={items}
                                    onChange={handleCollapseChange}
                                    showArrow={false}
                                    expandIcon={false}
                                />
                            ) : (
                                <p>No data Found</p>
                            )}
                        </div>
                    </div>
                </>
            ),
        },
        {
            key: '2',
            label: 'Income',
            children: (
                <>
                    {isLoading || localLoading ? (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            <Spin />
                        </div>
                    ) : (
                        <div className="card collapseCard">
                            <div className="card-header">
                                <h6 className="total-expense-property">Total Income:    {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(totalIncome || 0)}</h6>
                            </div>
                            <div className="card-body">
                                <Table
                                    className={`table-bordered step-two ${tableLoading ? "table-loading" : ""}`}
                                    rowKey={(record) => record.id}
                                    rowClassName={() => "editable-row"}
                                    bordered
                                    dataSource={dataSource}
                                    columns={Columns}
                                    scroll={{ x: "100%" }}
                                    pagination={false}
                                    loading={tableLoading}
                                    onChange={(pagination, filters, sorter) => {
                                        if (sorter.order) {
                                            const columnTitle = Columns.find(
                                                (col) => col.dataIndex === sorter.field
                                            )?.dataIndex;
                                            setColumnName(columnTitle);
                                            setTableSortBy(sorter.order === "ascend" ? "asc" : "desc");
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    )}
                </>
            ),
        },
    ]

    function absString(n) {
        let numberString = n.toString();
        if (numberString[0] === '-') {
            return numberString.substring(1);
        }
        else {
            return numberString;
        }
    }

    const netProfit = statistics?.net_profit || 0;
    const numberWithoutCommas = netProfit ? netProfit?.replace(/,/g, '') : '';
    let netProfitNumber = absString(netProfit);

    return (
        <>
            {loading && (
                <div className="commanLoaderWrapper">
                    <Spin size="large" />
                    <h4>Setting things up..</h4>
                </div>
            )}
            <TopHeader title={<Title title="View Property" className='custom' />} />
            <div className="customPadding">
                <div className="breadcrumbWrapper">
                    <Breadcrumb
                        items={[
                            {
                                title: (
                                    <span onClick={() => navigate('/dashboard')} style={{ cursor: 'pointer' }}>
                                        <HomeOutlined />
                                    </span>
                                ),
                            },
                            {
                                title: (
                                    <>
                                        <span>Properties</span>
                                    </>
                                ),
                            },
                            {
                                title: (
                                    <>
                                        <span>View Property</span>
                                    </>
                                ),
                            },
                        ]}
                    />
                </div>
                <div className="mainWrapper">
                    <div className="card viewCard mb-4">
                        <div className="card-body d-block">
                            <Title
                                title={
                                    !Number.isInteger(propertyDataState?.property_id) && propertyDataState?.property_id
                                        ? propertyDataState.property_id
                                        : propertyData?.property || propertyData?.name_of_property
                                }
                                className='customClass'
                                subtitle={
                                    `${propertyData?.property_data?.address ? propertyData?.property_data?.address : propertyData?.address}, ` +
                                    `${propertyData?.city && propertyData?.city?.name ? propertyData?.city.name : propertyData?.property_data?.city ? propertyData?.property_data?.city : propertyData?.city ? propertyData?.city : propertyData?.city_id}, ` +
                                    `${propertyData?.state && !propertyData?.state?.name ? propertyData?.state : propertyData?.state?.name ? propertyData?.state?.name : propertyData?.property_data?.state ? propertyData?.property_data?.state : propertyData?.state ? propertyData?.state : propertyData?.state_id}`
                                }
                            />
                            <div className="propertyDetail mt-2">
                                <span className="colLabel">
                                    Property Type: {propertyData?.property_type_name?.provider_name ? propertyData?.property_type_name?.provider_name : propertyData?.property_type_name}
                                </span>
                            </div>

                        </div>
                    </div>

                    <div className="card tableCard">
                        <div
                            className={
                                numberWithoutCommas < 0
                                    ? "RedNumbers"
                                    : "greenNumbers"
                            }
                        >
                            <div>
                                {isStatisticsError && <div>Error loading statistics.</div>}
                                {!isStatisticsLoading && !isStatisticsError && (
                                    <>
                                        <h4>
                                            {isStatisticsLoading ? 'Loading..' : numberWithoutCommas == 0 ? 'No Profit or Loss yet' : numberWithoutCommas > 0
                                                ? `Total Profit: ${new Intl.NumberFormat('en-US', {
                                                    style: 'currency',
                                                    currency: currencyGlobal && currencyGlobal?.currency_name === "Indian Rupee" ? 'INR' : 'USD'
                                                }).format(Number(netProfit.replace(/,/g, '').trim()) || 0)}`
                                                : `Total Loss:  ${new Intl.NumberFormat('en-US', {
                                                    style: 'currency',
                                                    currency: currencyGlobal && currencyGlobal?.currency_name === "Indian Rupee" ? 'INR' : 'USD'
                                                }).format(Number(netProfitNumber.replace(/,/g, '').trim()) || 0)}`}
                                        </h4>
                                    </>
                                )}
                            </div>
                        </div>

                        <div className="card-header d-flex align-items-center justify-content-between border-0">
                            <Tabs
                                defaultActiveKey="1"
                                items={tabItems.map((tab) => ({
                                    ...tab,
                                    children: !showGroup ? tab.children : null,
                                }))}
                                onChange={onChange}
                            />
                        </div>

                        {showGroup && (
                            <div className="card-body p-3">
                                {getUtilityCategory?.data?.map((utility) => (
                                    <div className="provider-content" key={utility.id}>
                                        <div>{utility.name}</div>
                                        <Button className="add-new" onClick={() => handleClick(utility)}>
                                            Add New
                                        </Button>
                                    </div>
                                ))}
                            </div>
                        )}

                        <div className="customBtnWrapperProeprty">
                            <Button
                                type="primary"
                                htmlType="submit"
                                className="btnOutlined me-3"
                                onClick={redirectToIncome}
                            >
                                Add Income
                            </Button>
                            <Button
                                type="primary"
                                htmlType="submit"
                                className="btnOutlined me-3"
                                onClick={showGroupSection}
                            >
                                Add Expense
                            </Button>
                            {showGroup && (
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    className="btnOutlined me-3"
                                    onClick={() => setShowGroup(false)}
                                >
                                    Hide Expense
                                </Button>
                            )}
                        </div>

                    </div>
                </div>

                <Modal
                    className="deleteModal"
                    title="Delete Expense"
                    open={isModalOpen}
                    onCancel={handleCancel}
                    footer={false}
                >
                    <div className="iconRow">
                        <RxCrossCircled />
                    </div>
                    <div className="textRow">
                        <h3>Are you sure?</h3>
                        <p>Do you really want to delete this expense?</p>
                    </div>
                    <div className="buttonRow">
                        <Button className="btnFilled text-white" style={{ marginRight: '1rem' }} onClick={() => handleOk()} loading={isLoadingDelete}>
                            Delete
                        </Button>
                        <Button className="btnOutlined me-2" onClick={handleCancel}>
                            Cancel
                        </Button>
                    </div>
                </Modal>

                <Modal
                    className="deleteModal"
                    title="Delete Expense"
                    open={isDeleteIncomeModal}
                    onCancel={handleIncomeCancel}
                    footer={false}
                >
                    <div className="iconRow">
                        <RxCrossCircled />
                    </div>
                    <div className="textRow">
                        <h3>Are you sure?</h3>
                        <p>Do you really want to delete this income?</p>
                    </div>
                    <div className="buttonRow">
                        <Button className="btnFilled text-white" style={{ marginRight: '1rem' }} onClick={() => handleOkIncome()} loading={isLoadingDelete}>
                            Delete
                        </Button>
                        <Button className="btnOutlined me-2" onClick={handleIncomeCancel}>
                            Cancel
                        </Button>
                    </div>
                </Modal>

            </div>
        </>
    );
};

export default ViewProperty;