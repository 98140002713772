import React, { useCallback, useState } from 'react';
import { usePlaidLink } from 'react-plaid-link';
import { useGenrateLinkTokenQuery } from '../slices/getSlice';
import { useAddAccessTokenMutation } from '../slices/apiSlice';
import { toast } from 'react-toastify';
import { useNavigate } from "react-router";
import { Button, Spin, Modal, Table } from 'antd';
import { useSelector } from 'react-redux';

const PlaidLink = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 20;
    const [tableSortBy, setTableSortBy] = useState(null);
    const [columnName, setColumnName] = useState(null);
    const [searchText, setSearchText] = useState("");

    const { data: generateToken, refetch, isLoading, error } = useGenrateLinkTokenQuery(
        `?page=${currentPage}&per_page=${pageSize}${(tableSortBy && columnName) ? `&sort_direction=${tableSortBy}&sort_by=${columnName}` : ""}${searchText ? `&search=${searchText}` : ""}`
    );

    if (isLoading) {
        return <Spin size='14' />;
    }

    if (error) {
        return <div>Error generating link token</div>;
    }

    return generateToken?.token?.link_token ? (
        <LinkAccount
            linkToken={generateToken.token.link_token}
            generatedToken={generateToken}
            refetch={refetch}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            pageSize={pageSize}
            setTableSortBy={setTableSortBy}
            setColumnName={setColumnName}
            setSearchText={setSearchText}
        />
    ) : (
        <div>No link token available</div>
    );
};

const LinkAccount = ({ linkToken, generatedToken, refetch, currentPage, setCurrentPage, pageSize, setTableSortBy, setColumnName, setSearchText }) => {
    const navigate = useNavigate();
    const [setAccessToken] = useAddAccessTokenMutation();
    const [modalOpen, setModalOpen] = useState(false);
    const currency = useSelector((state) => state?.persistedReducer?.currency?.currency_symbol);

    const onSuccess = useCallback(
        async (public_token) => {
            try {
                const response = await setAccessToken({ public_token });

                if (response.error) {
                    toast.error(response.error.data.message);
                } else {
                    await refetch()
                    toast.success('Account Connected');
                    navigate('/dashboard');
                }
            } catch (error) {
                toast.error('Error connecting account');
            }
        },
        [setAccessToken, navigate]
    );

    const config = {
        token: linkToken,
        onSuccess,
    };

    const { open, ready } = usePlaidLink(config);

    const columns = [
        {
            title: "S.No.",
            dataIndex: "serial",
            render: (_, __, index) => (currentPage - 1) * pageSize + index + 1,
        },
        {
            title: "Bank Name",
            dataIndex: "bank_name",
        },
        {
            title: "Account Id",
            dataIndex: "account_id",
        },
        {
            title: "Account Name",
            dataIndex: "account_name",
        },
        {
            title: "Created At",
            dataIndex: "created_at",
            render: (text) => new Date(text).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' }),
        },
        {
            title: "Updated At",
            dataIndex: "updated_at",
            render: (text) => new Date(text).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' }),
        },
    ];

    return (
        <>
            <Button
                type="primary"
                className="btnOutlined"
                onClick={() => {
                    if (!generatedToken?.total_accounts) {
                        open();
                    } else {
                        setModalOpen(true);
                    }
                }}
                disabled={!ready}
            >
                {generatedToken?.total_accounts !==0 ? 'Linked Account' : 'Link Account'}
            </Button>
            <Modal
                title={
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <span>Linked Account</span>
                        <Button type="primary" className="btnOutlined" onClick={() => { open(); setModalOpen(false); }}>
                            Link More Account
                        </Button>
                    </div>
                }
                open={modalOpen}
                onCancel={() => setModalOpen(false)}
                className="assign_model"
                width={720}
                footer={false}
            >
                <div className="modalBody">
                    <Table
                        rowKey={(record) => record.id}
                        rowClassName={() => "editable-row"}
                        bordered
                        dataSource={generatedToken?.accounts_data}
                        columns={columns}
                        scroll={{ x: '100%' }}
                        pagination={{
                            total: generatedToken?.meta?.total,
                            pageSize,
                            current: currentPage,
                            onChange: (page) => setCurrentPage(page),
                            locale: {
                                items_per_page: `/${generatedToken?.total_accounts || 0} Records`,
                            },
                        }}
                        onChange={(pagination, filters, sorter) => {
                            if (sorter.order) {
                                const columnTitle = columns.find(col => col.dataIndex === sorter.field)?.dataIndex;
                                setColumnName(columnTitle)

                                if (sorter.order === "ascend") {
                                    setTableSortBy("asc")
                                } else {
                                    setTableSortBy("desc")
                                }
                            }
                        }}
                    />
                </div>
            </Modal>
        </>
    );
};

export default PlaidLink;
