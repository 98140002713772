import { UserOutlined } from "@ant-design/icons/lib";
import React, { useEffect, useState } from "react";
import { Form, Spin, Tooltip, } from "antd";
import { RiUser2Line } from "react-icons/ri";
import { HiOutlineBuildingOffice } from "react-icons/hi2";
import UpdateProfileForm from "../../component/Form/UpdateProfileForm";
import {
    useAddOwnerTypeMutation,
    useUserProfileMutation,
} from "../../slices/apiSlice";
import {
    useGetUserDetailQuery,
    useOwnerTypeQuery,
} from "../../slices/getSlice";
import { toast } from "react-toastify";
// import DummyImage from "../../assets/images/profile-icon-9.png";
import ChangePassword from "../auth/changePassword";
import { useDispatch, useSelector } from "react-redux";
import Setting from "./Setting";
import CommonModal from "../../component/CommonModal";
import { LiaUserEditSolid } from "react-icons/lia";
import { userDetail, userStatus } from "../../slices/authSlice";
import PlaidLink from "../../plaid";
import { useNavigate, useOutletContext } from "react-router-dom";
import TopHeader from "../../component/Header";
import { BsInfoCircleFill } from "react-icons/bs";
import { HomeOutlined } from '@ant-design/icons';
import { Breadcrumb } from 'antd';
import Title from "../../component/PageTitle";

const Profile = () => {
    const dispatch = useDispatch();
    const {
        data: getUserDetail,
        refetch,
    } = useGetUserDetailQuery();

    const [ownerType, setOwnerType] = useState();
    const [form] = Form.useForm();
    const [modal] = Form.useForm();
    const navigate = useNavigate()
    const [ModalOpen, setModalOpen] = useState(false);
    const { loading, setLoading } = useOutletContext();
    const [addOwnerType, { isLoading: isLoadingAddOwnerType }] = useAddOwnerTypeMutation();
    const [FormInitialValuesDiff, setFormInitialValuesDiff] = useState();
    const [newlyAddedOwner, setNewlyAddedOwner] = useState();
    const [isSaving, setIsSaving] = useState(false);
    const sub_account_user = useSelector((state) => state.persistedReducer);
    const parent_id = sub_account_user?.user?.user?.parent_id;
    const currencyGlobal = useSelector(
        (state) => state?.persistedReducer?.currency
    );
    const currency = currencyGlobal ? currencyGlobal : "";
    const [data, setData] = useState();
    const colors = useSelector((state) => state?.persistedReducer?.colors);

    const modalField = [
        {
            component: "input",
            type: "text",
            label: (
                <>
                    Owner Type{" "}
                    <Tooltip title="Choose an existing Owner Type or create a new one. Your selection will be updated in the Owner Type field">
                        <BsInfoCircleFill className="informationIcon" />
                    </Tooltip>
                </>
            ),
            name: "name",
            placeholder: `Enter Owner Type`,
            prefix: (
                <LiaUserEditSolid style={{ color: colors?.primary || "#F47A3A", fontSize: "25px" }} />
            ),
            rules: [
                {
                    required: true,
                    message: `Please input your Owner Type!`,
                },
            ],
        },
    ];

    useEffect(() => {
        if (getUserDetail?.data) {
            setData(getUserDetail?.data);
        }
        setLoading(false)

    }, [getUserDetail]);

    useEffect(() => {
        if (ownerType) {
            form.resetFields();
            form.setFieldsValue(data);
        }
    }, [ownerType, data]);

    const fields = [
        {
            component: "input",
            type: "text",
            label: "First Name",
            name: "first_name",
            placeholder: "Enter First Name",
            prefix: <UserOutlined style={{ color: colors?.primary || "#F47A3A", fontSize: "25px" }} />,
            rules: [
                {
                    required: true,
                    message: "Please input first name",
                },
                {
                    pattern: /^[A-Za-z\s]+$/,
                    message: 'First name can only contain letters and spaces',
                },
                {
                    max: 25,
                    message: 'First name cannot exceed 25 characters',
                },
            ],
            colSpan: 12,
        },
        {
            component: "input",
            type: "text",
            label: "Last Name",
            name: "last_name",
            placeholder: "Enter Last Name",
            prefix: <UserOutlined style={{ color: colors?.primary || "#F47A3A", fontSize: "25px" }} />,
            rules: [
                {
                    required: true,
                    message: "Please input last name",
                },
                {
                    pattern: /^[A-Za-z\s]+$/,
                    message: 'Last name  must only contain letters',
                },
                {
                    max: 25,
                    message: 'Last name cannot exceed 25 characters',
                },
            ],
            colSpan: 12,
        },
        {
            component: "input",
            type: "text",
            label: "Company Name",
            name: "company_name",
            placeholder: "Enter company name",
            prefix: (
                <HiOutlineBuildingOffice
                    style={{ color: colors?.primary || "#F47A3A", fontSize: "25px" }}
                />
            ),
            rules: [
                {
                    required: true,
                    message: "Please input your company name",
                },
                {
                    pattern: /^(?!\s)[A-Za-z\s]+(?<!\s)$/,
                    message: '  Company name  must only contain letters',
                },
            ],
            colSpan: 12,
        },
        {
            component: "select",
            label: "Owner Type",
            name: "ownership_type_id",
            placeholder: "Please select",
            suffixIcon: (
                <RiUser2Line style={{ color: colors?.primary || "#F47A3A", fontSize: "25px" }} />
            ),
            options: ownerType,
            rules: [
                {
                    required: true,
                    message: "Please select owner type",
                },
            ],
            colSpan: 12,
        },
    ];

    const [userProfile, { isLoading: isLoadingAdd }] = useUserProfileMutation();

    const {
        data: ownertype,
        isLoading,
        isError,
        refetch: refetchOwnerType,
    } = useOwnerTypeQuery();

    useEffect(() => {
        if (ownertype && ownertype.data) {
            const transformedOptions = ownertype.data.map((item) => ({
                label: item.name,
                value: item.id,
            }));
            setOwnerType(transformedOptions);
        }
    }, [ownertype]);

    if (isLoading)
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <Spin />
            </div>
        );
    if (isError) return <p>Error fetching product.</p>;

    const onFinish = async (values) => {
        setIsSaving(true);
        const value = {
            first_name: values?.first_name ?? data?.first_name,
            last_name: values?.last_name ?? data?.last_name,
            company_name: values?.company_name ?? data?.company_name,
            ownership_type_id: values?.ownership_type_id ?? data?.ownership_type_id,
        }
        try {
            const response = await userProfile(value);
            if (response?.data?.data) {
                if (response?.data?.data?.status === "1" || response?.data?.data?.status === 1) {

                    if (response?.data?.data?.status === "1" || response?.data?.data?.status === 1) {
                        if (!parent_id) {
                            navigate("/add_property");
                        }
                        refetch();
                        toast.success("Profile has been updated successfully");
                        dispatch(userStatus(true));
                        dispatch(userDetail({ user: response?.data }));
                    }
                } else {
                    refetch();
                    toast.success("Profile has been updated successfully");
                    dispatch(userStatus(true));
                    dispatch(
                        userDetail({
                            user: response?.data,
                        })
                    );
                }
            } else {
                toast.error(`${response?.error?.data?.message}`);
            }
        } catch (error) {
            console.error("Error updating profile:", error);
        } finally {
            setIsSaving(false);
        }
    };

    const AddOwnerType = async (value) => {
        let formType = {
            name: value
        }
        const res = await addOwnerType(formType);
        if (res.data) {
            toast.success("Owner Type has been created successfully");
            let NewOwnerData = await refetchOwnerType();
            let findNewlyAdded = NewOwnerData.data?.data?.find((item) => item?.name === value);
            setNewlyAddedOwner(findNewlyAdded?.id);
            const transformedOptions = NewOwnerData.data?.data.map((item) => ({
                label: item.name,
                value: item.id,
            }));

            setData((prevValues) => ({
                ...prevValues,
                ownership_type_id: findNewlyAdded?.id
            }));
            setOwnerType(transformedOptions);
        } else {
            toast.error(`${res?.error?.data?.message}`);
        }
    };

    return (
        <>
            {loading && (
                <div className="commanLoaderWrapper">
                    <Spin size="large" />
                    <h4>Setting things up..</h4>
                </div>
            )}
            <TopHeader
                title={<Title title="Profile" id="profile" />}
            />
            <div className="customPadding">
                <div className="breadcrumbWrapper">
                    <Breadcrumb
                        items={[
                            {
                                title: (
                                    <span onClick={() => navigate('/dashboard')} style={{ cursor: 'pointer' }}>
                                        <HomeOutlined />
                                    </span>
                                ),
                            },
                            {
                                title: (
                                    <>
                                        <span>Profile</span>
                                    </>
                                ),
                            },
                        ]}
                    />
                </div>
                <div className="mainWrapper">
                    <div className="card accountCard">
                        <div className="card-body p-4">
                            <div className="row">
                                <div className="col-md-4 mb-4">
                                    <div className="card profileCard">
                                        <div className="card-body p-4">
                                            <div className="profileWrap">
                                                <div className="profileDetailsSection">
                                                    <div className="profileImgSection">
                                                        <HiOutlineBuildingOffice />
                                                    </div>
                                                    <p className="companyName">
                                                        {data?.company_name
                                                            ? data?.company_name
                                                            : "Your company name"
                                                        }
                                                    </p>
                                                </div>
                                                <div className="profileInfoSection">
                                                    <h4 className="infoTitle">Information</h4>
                                                    <div className="inforWrap">
                                                        <div className="infoItems">
                                                            <p className="infoLabel">Name</p>
                                                            <p className="infoData">
                                                                {data?.first_name || data?.last_name
                                                                    ? `${data?.first_name || data?.last_name}`
                                                                    : "Your name"}
                                                            </p>
                                                        </div>
                                                        <div className="infoItems">
                                                            <p className="infoLabel">Email</p>
                                                            <p className="infoData">
                                                                {data?.email}
                                                            </p>
                                                        </div>
                                                        <div className="infoItems">
                                                            <p className="infoLabel">Owner Type</p>
                                                            <p className="infoData">
                                                                {data?.ownership_type?.name
                                                                    ? data?.ownership_type?.name
                                                                    : "Your ownership type"}
                                                            </p>
                                                        </div>
                                                        <div className="infoItems">
                                                            <p className="infoLabel">Currency</p>
                                                            <p className="infoData">{`${currency ? currency?.currency_name : ''}${" "}${currency ? currency.currency_symbol : ''}`}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <div className="profileFormWrap">
                                        <div className=" button-head">
                                            <p className="sectionTitle custom">Update Profile</p>
                                            <div className="buttonsWrap">
                                                <PlaidLink />
                                            </div>
                                        </div>
                                        {data &&
                                            <>
                                                <UpdateProfileForm
                                                    form={form}
                                                    fields={fields}
                                                    onFinish={onFinish}
                                                    buttonName={'Save'}
                                                    FormInitialValues={data ? data : null}
                                                    ButtonDisable={isSaving}
                                                    loading={isLoadingAdd}
                                                    addNewOwnerType={AddOwnerType}
                                                    newlyAddedOwner={newlyAddedOwner}
                                                />
                                            </>
                                        }
                                    </div>
                                    <div className="profileFormWrap mt-4">
                                        <p className="sectionTitle">Change Password</p>
                                        <ChangePassword />
                                    </div>
                                    <div className="profileFormWrap mt-4">
                                        <p className="sectionTitle">Settings</p>
                                        <Setting />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <CommonModal
                        open={ModalOpen}
                        close={() => setModalOpen(false)}
                        form={modal}
                        FormInitialValues={FormInitialValuesDiff}
                        onFinish={AddOwnerType}
                        fields={modalField}
                        title={"Add Owner Type"}
                        loading={isLoadingAddOwnerType}
                        className="ownerModal"
                    />
                </div>
            </div>
        </>
    );
};

export default Profile;