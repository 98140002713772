import { useEffect, useState } from 'react';
import { theme, Avatar, DatePicker, Select, Dropdown, Modal } from "antd";
import { Header } from "antd/es/layout/layout";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
    useGetUserDetailQuery,
    useGetPropertiesQuery
} from "../../slices/getSlice";
import { colorsData } from "../../slices/authSlice";
import { Walkthrough } from "../../slices/authSlice";
import { RxHamburgerMenu } from "react-icons/rx";
import CustomDrawer from '../Drawer/drawer';
import images from '../../constant/images';
import dayjs from "dayjs";
import { setDateRange } from "../../slices/dateSlice";
import { setSelectedPropertyData } from "../../slices/PropertySlice";
import { useLocation } from "react-router-dom";
import { UserOutlined } from "@ant-design/icons";
import { logout, resetUserState } from "../../slices/authSlice";
import { toast } from "react-toastify";
import { FaArrowLeft } from "react-icons/fa";

const { RangePicker } = DatePicker;

const TopHeader = ({ loading, button, title, search }) => {
    const userData = useSelector((state) => state?.persistedReducer?.user?.user?.user);
    const user = useSelector((state) => state?.persistedReducer?.detail?.user?.data?.first_name);
    const colors = useSelector((state) => state?.persistedReducer?.colors);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { Logo } = images;
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [selectedProperty, setSelectedProperty] = useState([]);
    const [properties, setProperties] = useState([]);
    const location = useLocation();
    const selectedProperties = useSelector((state) => state.Property.selectedProperties);

    let isPropertyUpdated = localStorage.getItem("property_updated");

    const {
        data: getProperties,
        refetch: refetchProperties,
    } = useGetPropertiesQuery();

    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const {
        data: getUserDetail, refetch
    } = useGetUserDetailQuery();

    const isValidHexColor = (color) => {
        return /^#([0-9A-F]{3}){1,2}$/i.test(color);
    };

    const openDrawer = () => setIsDrawerOpen(true);
    const closeDrawer = () => setIsDrawerOpen(false);

    useEffect(() => {
        if ((selectedProperties?.selectedProperty !== undefined && properties?.length > 0)) {
            setTimeout(() => {
                let findProperty = properties?.find((item) => item?.value === selectedProperties?.selectedProperty);
                if (findProperty !== undefined) {
                    setSelectedProperty(findProperty?.value);
                }
            }, 500);
        }
    }, [selectedProperties?.selectedProperty, properties]);

    useEffect(() => {
        const localStorageProperty = localStorage.getItem("defaultProperty");

        setTimeout(() => {
            if (localStorageProperty && properties?.length > 0) {
                try {
                    let parsedProperties;

                    if (localStorageProperty.startsWith("[") || localStorageProperty.startsWith("{")) {
                        parsedProperties = JSON.parse(localStorageProperty);
                    } else {
                        parsedProperties = localStorageProperty.split(",").map(item => item.trim());
                    }

                    if (Array.isArray(parsedProperties)) {
                        const matchedProperties = properties
                            .filter(item => parsedProperties.includes(item.value) || parsedProperties.includes(String(item.value)))
                            .map(item => item.value);

                        if (matchedProperties.length > 0) {
                            setSelectedProperty(matchedProperties);
                            dispatch(setSelectedPropertyData({ selectedProperty: matchedProperties }));
                        }
                    } else if (typeof parsedProperties === "string" || typeof parsedProperties === "number") {
                        const matchedProperty = properties.find(item => String(parsedProperties) === String(item.value));

                        if (matchedProperty) {
                            setSelectedProperty(matchedProperty.value);
                            dispatch(setSelectedPropertyData({ selectedProperty: matchedProperty.value }));
                        }
                    }
                } catch (error) {
                    console.error("Error parsing localStorageProperty:", error);
                    localStorage.removeItem("defaultProperty");
                }
            }
        }, 500);
    }, [location.pathname, properties, dispatch]);

    useEffect(() => {
        if (getUserDetail) {
            const apiData = getUserDetail?.data?.setting?.custom_data?.colors || {};
            let walkThroughData = getUserDetail?.data?.walkthrough;
            const isValidColor = (color) => typeof color === 'string' && isValidHexColor(color);

            const primary = isValidColor(apiData.primary) ? apiData.primary : '#f47939';
            const secondary = isValidColor(apiData.secondary) ? apiData.secondary : '#3a54a5';

            if (primary !== document.documentElement.style.getPropertyValue('--primary-color')) {
                document.documentElement.style.setProperty('--primary-color', primary);
            }
            if (secondary !== document.documentElement.style.getPropertyValue('--secondary-color')) {
                document.documentElement.style.setProperty('--secondary-color', secondary);
            }

            if (!colors || colors.primary !== primary || colors.secondary !== secondary) {
                dispatch(
                    colorsData({
                        primary,
                        secondary
                    })
                );
            }

            if (walkThroughData) {
                dispatch(Walkthrough(walkThroughData));
            }

            if (getUserDetail?.data && !getUserDetail.isFetching) {
                refetch();
            }
        }
    }, [getUserDetail, colors, dispatch, refetch]);

    const storedDateRange = JSON.parse(localStorage.getItem("dateRange"));
    const checkManualChange = localStorage.getItem("DateChanges");

    const defaultStartDate =
        storedDateRange && storedDateRange.startDate
            ? dayjs(storedDateRange.startDate, "MM/DD/YYYY")
            : checkManualChange !== "true" && checkManualChange !== true ? dayjs().subtract(30, "days") : null;

    const defaultEndDate =
        storedDateRange && storedDateRange.endDate
            ? dayjs(storedDateRange.endDate, "MM/DD/YYYY")
            : checkManualChange !== "true" && checkManualChange !== true ? dayjs() : null;

    const [dateRange, setDateRanges] = useState([defaultStartDate, defaultEndDate]);

    useEffect(() => {
        if (!dateRange[0] || !dateRange[1]) {

            dispatch(setDateRange({
                startDate: null,
                endDate: null,
            }));
        } else {
            dispatch(setDateRange({
                startDate: dayjs(dateRange[0]).format("MM/DD/YYYY"),
                endDate: dayjs(dateRange[1]).format("MM/DD/YYYY"),
            }));
        }

        if (selectedProperty?.length > 0) {
            dispatch(setSelectedPropertyData({
                selectedProperty: selectedProperty
            }));
        }
    }, [dateRange, selectedProperty, dispatch]);

    useEffect(() => {
        if (!storedDateRange || (storedDateRange.startDate === null && storedDateRange.endDate === null)) {
            setDateRanges([defaultStartDate, defaultEndDate]);
        }
    }, [location.pathname]);

    const handleDateChange = (dates, dateStrings) => {
        if (dates) {
            localStorage?.setItem('DateChanges', true);
            setDateRanges(dates);
            localStorage.setItem(
                "dateRange",
                JSON.stringify({
                    startDate: dateStrings[0],
                    endDate: dateStrings[1],
                })
            );
            dispatch(
                setDateRange({
                    startDate: dateStrings[0],
                    endDate: dateStrings[1],
                })
            );
        } else {
            setDateRanges([null, null]);
            localStorage?.setItem('DateChanges', true);
            localStorage.setItem(
                "dateRange",
                JSON.stringify({
                    startDate: null,
                    endDate: null,
                })
            );
            dispatch(
                setDateRange({
                    startDate: null,
                    endDate: null,
                })
            );
        }
    };
    const handlePropertyChange = (value) => {
        const updatedProperties = value.filter((item) => item !== "All");
        setSelectedProperty(updatedProperties);
        localStorage?.removeItem('selectedProperty');
        localStorage.setItem("defaultProperty", updatedProperties);
        dispatch(
            setSelectedPropertyData({
                selectedProperty: updatedProperties,
            })
        );
    };

    const getPropertiesData = () => {
        if (getProperties && getProperties?.data) {
            const transformedOptions = getProperties?.data?.map((item) => ({
                label: item.name_of_property,
                value: item.id,
            }));
             setProperties(transformedOptions);
        }
    }

    useEffect(()=>{
          if(isPropertyUpdated === 'true'){
            refetchProperties();
            localStorage.removeItem("property_updated");
          }
    },[isPropertyUpdated])

    useEffect(() => {
        getPropertiesData();
    }, [getProperties]);

    const excludedPaths = [
        "/dashboard",
        "/companies",
        "/manage_property",
        "/add_property",
        "/report",
        "/all_transactions",
        "/recurring_transactions",
        "/connect_bank_account",
        "/sub_account",
        "/create_sub_account",
        "/profile",
        "/setting",
        "/view_property",
        "/property_details",
        "/add_income",
        "/add_company",
        "/edit_company",
    ];

    const handleLogout = () => {
        dispatch(logout());
        dispatch(resetUserState());
        localStorage.removeItem("dataFetched");
        localStorage.removeItem("dateRange");
        localStorage.clear();
        navigate("/login");
        toast.success("Logout successfully");
    };

    const changeRoute = (route) => {
        navigate(route);
    }

    const items = [
        {
            key: '1',
            label: (
                <span onClick={() => changeRoute('/profile')}><i className="fa-solid fa-user menu-icon" style={{ marginRight: '0.6rem' }}></i>Profile</span>
            ),
        },
        {
            key: '2',
            label: (
                <span onClick={() => changeRoute('/setting')}><i className="fa-solid fa-gear menu-icon" style={{ marginRight: '0.6rem' }}></i>Settings</span>
            ),
        },
        {
            key: '3',
            label: (
                <span onClick={handleLogout}><i className="fa-solid fa-right-from-bracket menu-icon" style={{ marginRight: '0.6rem' }}></i>Logout</span>

            ),
        },
    ];

    return (
        loading ? (
            <>
            </>
        ) : (
            <>
                <Header
                    style={{
                        position: "sticky",
                        top: 0,
                        zIndex: 9,
                        width: "100%",
                        background: colorBgContainer,
                    }}
                >
                    <div className="row tab-align customRow">
                        <div className="col-md-12">
                            <div className='dFlex h-100'>
                                <div className="mobileLogo">
                                    <Logo onClick={() => {
                                        navigate('/dashboard');
                                    }} />
                                </div>
                                <div className="drawerIcon">
                                    <button type='button' className='btn menuBtn' onClick={openDrawer}>
                                        <RxHamburgerMenu />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col custom-col customHeaderColumn">
                            {location.pathname !== "/dashboard" ? (
                                <>
                                    <div className='backBtnWrapper' onClick={() => navigate(-1)}>
                                        <FaArrowLeft />
                                    </div>
                                </>
                            ) : ('')}

                            <div className="header-right-section">
                                {title}
                                <div className="search-button-container">
                                    {search}
                                </div>
                            </div>
                        </div>
                        <div className="col custom-col h-100">
                            <div className="rightButtonSection h-100">
                                {
                                    location.pathname !== "/manage_property" &&
                                        location.pathname !== "/add_property" &&
                                        location.pathname !== "/profile" &&
                                        location.pathname !== "/setting" &&
                                        location.pathname !== "/property_details" &&
                                        location.pathname !== "/add_income" &&
                                        location.pathname !== "/add_company" &&
                                        !location.pathname.startsWith("/edit_company/") &&
                                        !location.pathname.startsWith("/connect_bank_account")
                                        ? (
                                            <div className='dateWrapper'>
                                                <RangePicker
                                                    format="MM/DD/YYYY"
                                                    value={dateRange}
                                                    ranges={{
                                                        Today: [dayjs(), dayjs()],
                                                        "This Week": [dayjs().startOf("week"), dayjs().endOf("week")],
                                                        "This Month": [dayjs().startOf("month"), dayjs().endOf("month")],
                                                        "Last Month": [
                                                            dayjs().subtract(1, "month").startOf("month"),
                                                            dayjs().subtract(1, "month").endOf("month"),
                                                        ],
                                                        "Last 6 Months": [
                                                            dayjs().subtract(6, "month").startOf("month"),
                                                            dayjs().endOf("month"),
                                                        ],
                                                        "Last 30 Days": [dayjs().subtract(30, "day"), dayjs()],
                                                    }}
                                                    onChange={handleDateChange}
                                                // onChange={(dates, dateStrings) => {
                                                //     if (dates) {
                                                //         setDateRanges(dates);
                                                //         localStorage.setItem("dateRange", JSON.stringify({
                                                //             startDate: dateStrings[0],
                                                //             endDate: dateStrings[1]
                                                //         }));
                                                //         dispatch(setDateRange({
                                                //             startDate: dateStrings[0],
                                                //             endDate: dateStrings[1],
                                                //         }));
                                                //     } else {
                                                //         setDateRanges([null, null]);
                                                //         localStorage.removeItem("dateRange");
                                                //         dispatch(setDateRange({
                                                //             startDate: null,
                                                //             endDate: null,
                                                //         }));
                                                //     }
                                                // }}

                                                />
                                            </div>
                                        ) : ''}
                                {!excludedPaths.includes(location.pathname) && !location.pathname.startsWith("/edit_company/") && (
                                    <div
                                        className="dropdownWrapper"
                                    >
                                        <Select
                                            mode="multiple"
                                            value={selectedProperty}
                                            placeholder="Filter by Properties"
                                            onChange={handlePropertyChange}
                                            options={properties}
                                            className="mobWidth propertyFilter"
                                            maxTagCount={1}
                                            style={{ width: 200 }}
                                            showSearch
                                            filterOption={(input, option) =>
                                                (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                                            }
                                        />
                                    </div>
                                )}
                                {
                                    location.pathname === "/properties" ||
                                        location.pathname === "/manage_property" ||
                                        location.pathname === "/add_property" ||
                                        location.pathname === "/connect_bank_account" ||
                                        location.pathname === "/all_transactions" ||
                                        location.pathname === "/recurring_transactions" ||
                                        location.pathname === "/sub_account" ||
                                        location.pathname === "/create_sub_account" ||
                                        location.pathname === "/expense" ||
                                        location.pathname === "/income" ||
                                        location.pathname === "/add_income" ||
                                        location.pathname === "/dashboard" ||
                                        location.pathname === "/companies" ?
                                        <div className="buttonWrap">
                                            {button}
                                        </div> : ""
                                }
                                <div className="divider"></div>

                                <Dropdown
                                    className="d-flex align-items-center customLogoutWrapper"
                                    menu={{
                                        items,
                                        className: 'customLogoutMenu'
                                    }}
                                    placement="bottomLeft"
                                    arrow
                                >
                                    <div>
                                        <Avatar
                                            style={{
                                                backgroundColor: colors?.primary || "#F47939",
                                                cursor: 'pointer'
                                            }}
                                            icon={<UserOutlined />}
                                        />
                                        <span className="username link" style={{ marginLeft: "10px", cursor: 'pointer' }}>
                                            {user
                                                ? user.split(" ")[0]
                                                : userData?.split(" ")[0]
                                            }
                                        </span>
                                    </div>
                                </Dropdown>
                            </div>
                        </div>
                    </div>
                </Header>
                <CustomDrawer isOpen={isDrawerOpen} onClose={closeDrawer} />
            </>)
    )
}
export default TopHeader;