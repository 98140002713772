import React, { useEffect, useState } from "react";
import {
    Button,
    Form,
    Input,
    Select,
    Spin,
    Row,
    Col,
    Space,
    Modal,
} from "antd";
import { RiContactsLine } from "react-icons/ri";
import { useSelector } from "react-redux";
import { RxInfoCircled } from "react-icons/rx";
const { Item: FormItem } = Form;

const UpdateProfileForm = ({
    form,
    fields,
    onFinish,
    buttonName,
    ButtonDisable,
    loading,
    FormInitialValues = {},
    ownerLoader,
    addNewOwnerType,
    newlyAddedOwner
}) => {
    const [formValues, setFormValues] = useState({});
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const colors = useSelector((state) => state?.persistedReducer?.colors);
    const [inputValue, setInputValue] = useState("");
    const [isInfoModal, setIsModalInfo] = useState(false);
    const [loaderValue, setLoaderValue] = useState(false);

    const handleDropdownVisibleChange = (value) => {
        if (value !== undefined && value !== true) {
            setInputValue(value);
        }
        if (value) {
            setDropdownVisible(true);
        } else {
            setDropdownVisible(false);
        }
    };

    const handleValuesChange = (allValues) => {
        if (allValues) {
            setFormValues(allValues);
        }
    };

    const handleFinish = (allValues) => {
        onFinish(allValues);
    };

    const handleFieldChange = (field, value, setFormValues) => {
        const updatedFormValues = {
            ...formValues,
            [field?.name]: value,
        };

        setFormValues(updatedFormValues);

        if (field?.onChange) {
            field.onChange(value);
        }
    };

    const showLoader = () => {
        setTimeout(() => {
            setLoaderValue(false);
        }, 2000);
    }

    const handleOwnerFormSubmit = () => {
        addNewOwnerType(inputValue);
        setLoaderValue(true);
        showLoader();
    }

    useEffect(() => {
        if (newlyAddedOwner) {
            form.setFieldsValue({
                "ownership_type_id": newlyAddedOwner
            });
        }
    }, [newlyAddedOwner]);

    const closeInfoModal = () => {
        setIsModalInfo(false);
    }

    return (
        <>
            <Form
                form={form}
                layout="vertical"
                onFinish={handleFinish}
                onValuesChange={handleValuesChange}
                initialValues={FormInitialValues ? FormInitialValues : formValues}
            >
                <Row gutter={16}>
                    {fields.map((field, index) => {
                        return (
                            <>
                                <Col
                                    key={field?.name}
                                    md={field?.colSpan || 24}
                                    offset={field?.offset || 0}
                                >
                                    {((field?.component === "input" || !field?.component) && field?.type !== "phone") &&
                                        <>
                                            <FormItem
                                                label={field?.label}
                                                name={field?.name}
                                                rules={field?.rules}
                                                dependencies={
                                                    field?.dependencies
                                                        ? [field?.dependencies]
                                                        : undefined
                                                }
                                            >
                                                <Input
                                                    placeholder={field?.placeholder}
                                                    className="form-control"
                                                    defaultValue={field?.defaultValue}
                                                    disabled={field?.disabled || ""}
                                                    prefix={
                                                        field?.prefix ? (
                                                            field?.prefix
                                                        ) : (
                                                            <RiContactsLine
                                                                style={{
                                                                    color: colors?.primary || "#F47A3A",
                                                                    fontSize: "25px",
                                                                }}
                                                            />
                                                        )
                                                    }
                                                    type={field?.type}
                                                />
                                            </FormItem>
                                        </>
                                    }

                                    {field?.component === "select" && field?.name !== "ownership_type_id" && (
                                        <FormItem
                                            label={field?.label}
                                            name={field?.name}
                                            rules={field?.rules}
                                        >
                                            <Select
                                                id={index}
                                                dropdownRender={(originNode) => (
                                                    <div
                                                        style={{
                                                            padding: "0px",
                                                            display: "block",
                                                        }}
                                                    >
                                                        {originNode}
                                                    </div>
                                                )}
                                                className="formControl select-custom"
                                                disabled={field.disabled || ""}
                                                showSearch
                                                onSelect={() => setDropdownVisible(false)}
                                                onSearch={handleDropdownVisibleChange}
                                                filterOption={(input, option) =>
                                                    (option?.label ?? "")
                                                        .toLowerCase()
                                                        .includes(input.toLowerCase())
                                                }
                                                mode={field.mode || ""}
                                                optionLabelProp="label"
                                                placeholder={field?.placeholder}
                                                options={field?.options}
                                                suffixIcon={field?.suffixIcon}
                                                onChange={(value) => handleFieldChange(field, value, setFormValues)}
                                                optionRender={(option) => (
                                                    <Space>
                                                        <span role="img" aria-label={option.data.value ? option.data.value : option.value}>
                                                            {option.data.label ? option.data.label : option.label}
                                                        </span>
                                                    </Space>
                                                )}
                                            />
                                        </FormItem>
                                    )}

                                    {field?.name === "ownership_type_id" && (
                                        <>
                                            {loaderValue ? (
                                                <div className="ownerLoaderWrapper">
                                                    <Spin />
                                                </div>
                                            ) : ('')}
                                            <FormItem
                                                label={
                                                    <Space className="customSpaceItem">
                                                        {field?.label}
                                                        <RxInfoCircled style={{ cursor: "pointer", color: '#f47a3a' }} onClick={() => setIsModalInfo(true)} />
                                                    </Space>
                                                }
                                                name={field?.name}
                                                rules={field?.rules}
                                            >
                                                <Select
                                                    id={index}
                                                    className="formControl select-custom"
                                                    disabled={field.disabled || ""}
                                                    showSearch
                                                    onSelect={() => setDropdownVisible(false)}
                                                    onSearch={handleDropdownVisibleChange}
                                                    mode={field.mode || ""}
                                                    optionLabelProp="label"
                                                    notFoundContent={
                                                        field.options?.length === 0 && inputValue === ""
                                                            ? null
                                                            : "No matching records found!"
                                                    }
                                                    placeholder={field?.placeholder}
                                                    options={field?.options}
                                                    suffixIcon={field?.suffixIcon}
                                                    onChange={(value) => handleFieldChange(field, value, setFormValues)}
                                                    onKeyDown={(e) => {
                                                        if (e.key === "Enter") {
                                                            const matchedOption = field?.options.find(
                                                                (opt) => opt.label.toLowerCase() === inputValue.toLowerCase()
                                                            );

                                                            if (matchedOption) {
                                                                handleFieldChange(field, matchedOption.value, setFormValues);
                                                            } else {
                                                                handleOwnerFormSubmit();
                                                            }
                                                        }
                                                    }}
                                                    optionRender={(option) => (
                                                        <Space>
                                                            <span role="img" aria-label={option.data.value ? option.data.value : option.value}>
                                                                {option.data.label ? option.data.label : option.label}
                                                            </span>
                                                        </Space>
                                                    )}
                                                    dropdownRender={(menu) => (
                                                        <>
                                                            {menu}
                                                            {inputValue &&
                                                                !field.options.some((opt) => opt.label.toLowerCase() === inputValue.toLowerCase()) && (
                                                                    <div style={{ display: "flex", justifyContent: "space-between", padding: 8 }}>
                                                                        <span>Add "{inputValue}"</span>
                                                                        <Button type="link" onClick={handleOwnerFormSubmit} disabled={ownerLoader}>
                                                                            {ownerLoader ? <Spin size="small" /> : "Add"}
                                                                        </Button>
                                                                    </div>
                                                                )}
                                                        </>
                                                    )}
                                                />
                                            </FormItem>
                                        </>

                                    )}
                                </Col>
                            </>
                        );
                    })}
                </Row>

                <Row className="formButtons">
                    <Col md={24} className="text-center mt-3">
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="authBtn"
                            disabled={loading ? loading : ButtonDisable}
                        >
                            {loading ? "Loading..." : buttonName ? buttonName : "Save"}
                        </Button>
                    </Col>
                </Row>
            </Form>

            <Modal
                open={isInfoModal}
                onCancel={closeInfoModal}
                footer={null}
                className="custominfoModal"
                title="Information"
            >
                <div className="modal-body">
                    <div className="text">
                        Select an existing Owner Type or create a new one by entering a value in the input field.
                        Your selection will automatically update the Owner Type field.
                    </div>
                </div>
            </Modal>

        </>
    );
};

export default UpdateProfileForm;
