import React, { useEffect, useState } from "react";
import { Button, Pagination, Table, Tooltip, Spin, Modal, Input, Empty } from "antd";
import { IoIosAddCircleOutline } from "react-icons/io";
import Title from "../../component/PageTitle";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useGetSubAccountQuery } from "../../slices/getSlice";
import { FaEdit } from "react-icons/fa";
import { LuEye } from "react-icons/lu";
import { MdBlockFlipped } from "react-icons/md";
import { FaCircleInfo } from "react-icons/fa6";
import { toast } from "react-toastify";
import { useDeactivateUserMutation } from "../../slices/apiSlice";
import { useSelector } from "react-redux";
import { FaRegCheckCircle } from "react-icons/fa";
import TopHeader from "../../component/Header";
import { HomeOutlined } from '@ant-design/icons';
import { Breadcrumb } from 'antd';
import PageSizeOptionsHelper from "../../Helper/PageSizeOptionsHelper";
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import { GrDocumentCsv } from "react-icons/gr";

const AllSubAccount = () => {
    const [pageSize, setPageSize] = useState(20)
    const [exportPageSize, setExportPageSize] = useState(null)
    const [currentPage, setCurrentPage] = useState(1);
    const [searchText, setSearchText] = useState(null);
    const [debouncedSearch, setDebouncedSearch] = useState("");
    const [tableSortBy, setTableSortBy] = useState(null);
    const [columnName, setColumnName] = useState(null);
    const { loading, setLoading } = useOutletContext();
    const {
        data: getSubAccount,
        isLoading,
        isError,
        refetch,
    } = useGetSubAccountQuery(`?page=${currentPage}&per_page=${exportPageSize ? exportPageSize : pageSize}${(tableSortBy && columnName) ? `&sort_direction=${tableSortBy}&sort_by=${columnName}` : ""}${debouncedSearch ? `&search=${debouncedSearch}` : ""}`);

    const [deactivateUser, { }] = useDeactivateUserMutation()
    const navigate = useNavigate();
    const [selectedRecord, setSelectedRecord] = useState()
    const colors = useSelector((state) => state?.persistedReducer?.colors);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isloadingBtn, setIsLoadingBtn] = useState(false);
    const [exportLoader, setExportLoader] = useState(false);

    const dataSource = getSubAccount ? getSubAccount.map((item) => {
        return {
            id: item.id,
            name: item?.first_name + " " + item?.last_name,
            first_name: item.first_name,
            last_name: item.last_name,
            email: item?.email,
            owner_type: item?.ownership_type_name,
            ownership_type_id: item.ownership_type_name,
            properties: item.properties,
            property_id: item.properties.map((data) => {
                return data?.property_name;
            }),
            status: item?.status,
            created_at: item?.created_at,
            ownership_type_name: item?.ownership_type_name
        }

    })
        : [];

    useEffect(() => {
        refetch()
        setLoading(false)
    }, []);

    const handleChangePage = (page, size) => {
        setCurrentPage(page);
        setPageSize(size);
    };

    const totalItems = dataSource ? dataSource.length : 0;
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize, totalItems);
    const displayedItems = dataSource.slice(startIndex, endIndex);

    const pageSizeOptions = PageSizeOptionsHelper(displayedItems?.length);

    const Columns = [
        {
            title: "S.No.",
            dataIndex: "serial",
            render: (_, record, index) => (currentPage - 1) * pageSize + index + 1,
        },
        {
            title: "Name",
            dataIndex: "first_name",
            render: (_, record, index) => record?.first_name + " " + record?.last_name,
            sorter: true,

        },
        {
            title: "Email",
            dataIndex: "email",
            sorter: true,
        },
        {
            title: "Owner Type",
            dataIndex: "ownership_type_id",
            sorter: true,
        },
        {
            title: "Created On",
            dataIndex: "created_at",
            render: (created_at) => dayjs(created_at).format("MM-DD-YYYY"),
            sorter: true,
        },
        {
            title: "Status",
            dataIndex: "status",
            render: ((_, record) => (
                <>
                    {record?.status === "0" ? <span className="disabledUser">Email verification pending</span> : record?.status === "4" ? <span className="disabledUser">Disabled</span> : <span className="activeUser">Active</span>}
                </>
            ))
        },
        {
            title: "Action",
            dataIndex: "action",
            render: (_, record) => {
                return (
                    <div className="action-container">
                        <Tooltip title="View assign proeprty">
                            <button className="btn viewButton me-2" onClick={() => { navigate('/assign_property', { state: record }) }}>
                                <LuEye />
                            </button>
                        </Tooltip>
                        <Tooltip title="Edit">
                            <button
                                className="btn editButton me-2"
                                onClick={() => { navigate('/create_sub_account', { state: record }) }}
                            >
                                <FaEdit />
                            </button>
                        </Tooltip>

                        {
                            record?.status === "3" ? (
                                <Tooltip title="Disable user">
                                    <button
                                        className="btn blockButton me-2"
                                        onClick={() => { setIsModalOpen(true); setSelectedRecord(record); }}
                                    >
                                        <MdBlockFlipped />
                                    </button>
                                </Tooltip>
                            ) : record?.status === "0" ? (
                                <></>
                            ) : (
                                <Tooltip title="Enable user">
                                    <button
                                        className="btn enableButton me-2"
                                        onClick={() => { setIsModalOpen(true); setSelectedRecord(record); }}
                                    >
                                        <FaRegCheckCircle />
                                    </button>
                                </Tooltip>
                            )
                        }
                    </div>

                );
            },
        },
    ];

    const handleOk = async () => {
        setIsLoadingBtn(true);
        try {
            const res = await deactivateUser(selectedRecord?.id);
            if (res?.data?.message) {
                refetch();
                setIsModalOpen(false);
                toast.success(res.data.message);
            } else {
                toast.error("Not able to delete");
            }
        } catch (error) {
            toast.error("Something went wrong");
        } finally {
            setIsLoadingBtn(false);
        }
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleSearchChange = (e) => {
        setCurrentPage(1)
        setSearchText(e?.target?.value || null);
    };
    
    useEffect(() => {
        if (searchText !== "" && searchText !== undefined && searchText !== null) {
            const handler = setTimeout(() => {
                setDebouncedSearch(searchText);
            }, 1500);

            return () => clearTimeout(handler);
        } else {
            setDebouncedSearch(searchText);
        }
    }, [searchText]);

    useEffect(() => {
        if (exportPageSize) {
            handleSubAccountDataExport()
        }
    }, [exportPageSize])

    const handleSubAccountDataExport = async () => {
        setExportLoader(true);
        try {
            const response = await refetch();
            const subAccounts = response?.data || [];

            const csvData = subAccounts.map((subAccount, index) => {
                return {
                    "S.NO": index + 1,
                    "Name": subAccount?.name,
                    "Email": subAccount?.email,
                    "Owner Type": subAccount?.ownership_type_name,
                    "Created On": dayjs(subAccount?.created_at).format("MM-DD-YYYY"),
                    "Status": subAccount?.status === "0" ? "Email verification pending" : subAccount?.status === "1" || subAccount?.status === "3" ? "Active" : "Disabled",

                };
            });

            const csvRows = [
                ['S.NO', 'Name', "Email", 'Owner Type', 'Created On', 'Status'],
                ...csvData.map(item => [
                    item['S.NO'],
                    item["Name"],
                    item["Email"],
                    item["Owner Type"],
                    item["Created On"],
                    item["Status"],
                ])
            ];

            const csvString = csvRows.map(row => row.join(',')).join('\n');
            const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
            saveAs(blob, 'sub_accounts.csv');
        } catch (error) {
            console.error("Error exporting data: ", error);
        }
        setExportLoader(false);
        setExportPageSize(null)
    };

    return (
        <>
            {loading && (
                <div className="commanLoaderWrapper">
                    <Spin size="large" />
                    <h4>Setting things up..</h4>
                </div>
            )}
            <TopHeader
                title={<Title title="Sub Accounts" id="sub_account" />}
                search={
                    <Input.Search
                        placeholder="Search..."
                        allowClear
                        value={searchText}
                        onChange={handleSearchChange}
                        className=" me-3 tableSearchInput"
                    />
                }
                button={
                    <Button
                        type="primary"
                        style={{
                            backgroundColor: colors?.primary || "#F47A3A",
                        }}
                        onClick={() => navigate("/create_sub_account")}
                        className="customHeaderBtn step-one"
                    >
                        {"Create Sub Account"}{" "}
                        <IoIosAddCircleOutline />
                    </Button>
                }
            />
            <div className="customPadding">
                <div className="breadcrumbWrapper">
                    <Breadcrumb
                        items={[
                            {
                                title: (
                                    <span onClick={() => navigate('/dashboard')} style={{ cursor: 'pointer' }}>
                                        <HomeOutlined />
                                    </span>
                                ),
                            },
                            {
                                title: (
                                    <>
                                        <span>Sub Accounts</span>
                                    </>
                                ),
                            },
                        ]}
                    />
                </div>
                <div className="mainWrapper">
                    <div className="card titleCard mb-4">
                        <div className="card-body-wrapper">
                            {isLoading ? (
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                    <Spin />
                                </div>
                            ) : isError || displayedItems.length < 0 ? (
                                <h4>An error occurred</h4>
                            ) : (

                                <div className="table-responsives">
                                    <>
                                        {displayedItems?.length > 0 ? (<><div className="export-data-wrapper d-flex justify-content-end align-items-center"><Button icon={<GrDocumentCsv />} loading={exportLoader} onClick={() => setExportPageSize(displayedItems?.length)} className="export-data-button viewButton mb-3">Export</Button></div></>) : (<></>)}
                                        <Table
                                            className="table-bordered step-two"
                                            rowKey={(record) => record.id}
                                            rowClassName={(record) => {
                                                return record.status === "4" ? "disabled-row" : "";
                                            }}
                                            bordered
                                            dataSource={displayedItems}
                                            columns={Columns}
                                            pagination={false}
                                            scroll={{ x: '100%' }}
                                            onChange={(pagination, filters, sorter) => {
                                                if (sorter.order) {
                                                    const columnTitle = Columns.find(col => col.dataIndex === sorter.field)?.dataIndex;
                                                    setColumnName(columnTitle)

                                                    if (sorter.order === "ascend") {
                                                        setTableSortBy("asc")
                                                    } else {
                                                        setTableSortBy("desc")
                                                    }
                                                }
                                            }}
                                        />
                                        {displayedItems?.length > 20 ? (
                                            <Pagination
                                                className="mt-4"
                                                current={currentPage}
                                                pageSize={pageSize}
                                                total={displayedItems?.length}
                                                onChange={handleChangePage}
                                                pageSizeOptions={pageSizeOptions}
                                                showSizeChanger
                                                locale={{
                                                    items_per_page: `/${displayedItems?.length || 0} Records`,
                                                }}
                                            />
                                        ) : (<></>)}

                                    </>

                                </div>
                            )}
                        </div>
                    </div>

                    <Modal
                        className="deleteModal"
                        title={selectedRecord?.status === "0" ? "Verify Account" : selectedRecord?.status === "4" ? "Activate Account" : "Disable Account"}
                        open={isModalOpen}
                        onCancel={handleCancel}
                        footer={null}
                    >
                        <div className="iconRow">
                            <FaCircleInfo />
                        </div>
                        <div className="textRow">
                            {selectedRecord?.status === "0" ? (<h3>Email Verified?</h3>) : (<h3>Are you sure?</h3>)}

                            <p>
                                {selectedRecord?.status === "0" ? "Please verify your email." :
                                    selectedRecord?.status === "4"
                                        ? "Are you sure you want to activate this user?"
                                        : "Are you sure you want to disable this user?"}
                            </p>
                        </div>
                        {selectedRecord?.status === "0" ? (<></>) : (
                            <div className="buttonRow">
                                <Button className="btnOutlined me-2" onClick={handleCancel}>
                                    Cancel
                                </Button>
                                <Button
                                    className="btnFilled text-white"
                                    onClick={handleOk}
                                    disabled={isloadingBtn}
                                >
                                    {isloadingBtn ? <Spin size="small" /> : selectedRecord?.status === "4" ? "Activate" : "Disable"}
                                </Button>
                            </div>
                        )}

                    </Modal>

                </div>
            </div>
        </>
    );
};
export default AllSubAccount;
