import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const ExpenseBarChart = ({ data,currency }) => {
  if (!data || data.length === 0) {
    return <p>No data available</p>;
  }

  const categories = data.map((item) => item.name_of_property);
  const seriesData = data.map((item) => parseFloat(item.total_expense));

  const options = {
    chart: {
      type: "column",
    },
    title: {
      text: "Most Expenses Incurred Properties",
    },
    xAxis: {
      categories: categories,
      title: {
        text: "Properties",
      },
    },
    yAxis: {
      title: {
        text: `Total Expense (${currency?.currency_symbol || '$'})`,
      },
      labels: {
        formatter: function () {
          return `${currency?.currency_symbol || '$'}${this.value.toLocaleString("en-US", { minimumFractionDigits: 2 })}`;
        },
      },
    },
    series: [
      {
        name: "Total Expense",
        data: seriesData,
        // color: "#007bff",
      },
    ],
    plotOptions: {
      column: {
        dataLabels: {
          enabled: true,
          formatter: function () {
            return `${currency?.currency_symbol || '$'}${this.y.toLocaleString("en-US", { minimumFractionDigits: 2 })}`;
          },
        },
      },
    },
    tooltip: {
      pointFormat: "Total Expense: <b>${point.y:,.2f}</b>",
    },
    credits: {
        enabled: false,
      },
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default ExpenseBarChart;
