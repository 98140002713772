export default function PageSizeOptionsHelper(total) {
    const options = [];
    const maxPageSize = Math.min(total, 100);

    // Add options in increments of 5
    for (let i = 5; i <= maxPageSize; i += 5) {
        options.push(i);
    }

    // Ensure 10 and 15 are included if applicable
    if (total >= 10 && !options.includes(10)) {
        options.push(10);
    }

    if (total >= 15 && !options.includes(15)) {
        options.push(15);
    }

    if(!options?.includes(total)){
        options.push(total);
    }

    // Sort options before returning
    return options.sort((a, b) => a - b);
}