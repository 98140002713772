import React from "react";
import { BrowserRouter } from "react-router-dom";
import AppRoute from "../src/routes/routes";
import "./styles/style.scss";
import TourHandler from "./topHandler";
import HelpDrawer from "./component/Drawer/sideDrawer";

const App = () => {
    return (
        <>
            <BrowserRouter>
                <TourHandler />
                <AppRoute />
                <HelpDrawer />
            </BrowserRouter>
        </>
    );
}

export default App; 