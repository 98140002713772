import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const GroupChart = ({ data, currency }) => {
  if (!Array.isArray(data)) {
    console.error("Invalid data: Expected an array", data);
    return <p>Error: Invalid data format</p>;
  }

  const validData = data
    .filter((item) => item && typeof item.name === "string" && !isNaN(parseFloat(item.total_income)))
    .map((item) => ({
      name: item.name,
      total_income: parseFloat(item.total_income) || 0,
    }));

  if (validData.length === 0) {
    return <p>No valid data available for the chart</p>;
  }

  // Map currency name to ISO 4217 currency code
  const currencyCodeMap = {
    "Indian Rupee": "INR",
    "US Dollar": "USD",
    "Euro": "EUR",
    "British Pound": "GBP",
    "Japanese Yen": "JPY",
    // Add more mappings as needed
  };
  const currencyCode = currencyCodeMap[currency?.currency_name] || "USD"; // Default to USD
  const currencySymbol = currency?.currency_symbol || "$"; // Default symbol

  const formatCurrency = (value, showDecimals = true) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: currencyCode,
      minimumFractionDigits: showDecimals ? 2 : 0,
      maximumFractionDigits: showDecimals ? 2 : 0,
    }).format(value);
  };

  const categories = validData.map((item) => item.name);
  const seriesData = validData.map((item) => item.total_income);

  const options = {
    chart: {
      type: "column",
    },
    title: {
      text: `Total Revenue per Group (${currencySymbol})`,
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    xAxis: {
      categories,
      title: {
        text: "Groups",
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: `Total Income (${currencySymbol})`,
      },
      labels: {
        formatter: function () {
          return formatCurrency(this.value, false);
        },
      },
    },
    tooltip: {
      shared: true,
      formatter: function () {
        return `<b>${this.x}</b><br/>Total Income: <b>${formatCurrency(this.y)}</b>`;
      },
    },
    plotOptions: {
      column: {
        dataLabels: {
          enabled: true,
          formatter: function () {
            return formatCurrency(this.y, false);
          },
        },
      },
    },
    series: [
      {
        name: "Total Income",
        data: seriesData,
        colorByPoint: true,
      },
    ],
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default GroupChart;
