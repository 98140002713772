import React, { useEffect } from "react";
import { Button, Pagination, Spin, Table, } from "antd";
import Title from "../../component/PageTitle";
import { useLocation, useOutletContext } from "react-router-dom";
import { useState } from "react";
import { useGetAssignPropertyQuery } from "../../slices/getSlice";
import { useUnassignPropertyMutation } from "../../slices/apiSlice";
import { toast } from "react-toastify";
import TopHeader from "../../component/Header";

const AssignProperty = () => {
	const user = useLocation()
	const { loading, setLoading } = useOutletContext();
	const { data: assignProperty, error, isLoading: assignPropertyLoading, refetch } = useGetAssignPropertyQuery(user.state.id);
	const [unassignProperty, { isLoading: unAssignedLoading }] = useUnassignPropertyMutation()
	const pageSize = 20;
	const [currentPage, setCurrentPage] = useState(1);
	const [displayedItems, setDisplayedItems] = useState([])
	const [totalItems, setTotalItems] = useState(0)
	const [selectedRedord, setSelected] = useState()

	const handleChangePage = (page) => {
		setCurrentPage(page);
	};

	const fetchItems = () => {
		if (assignProperty?.length > 0) {
			const dataSource = assignProperty.map((item) => {
				return {
					id: item.id,
					property: item.name_of_property,
					user_id: item.user_id,
					property_type_id: item.property_type_id
				}
			});
			const totalItems = dataSource ? dataSource?.length : 0
			setTotalItems(totalItems);
			const startIndex = (currentPage - 1) * pageSize;
			const endIndex = Math.min(startIndex + pageSize, totalItems);
			const displayedItems = dataSource.slice(startIndex, endIndex);
			setDisplayedItems(displayedItems)
		} else {
			setDisplayedItems([])
		}
	}

	useEffect(() => {
		fetchItems()
		setLoading(false)
	}, [assignProperty])


	const removeProperty = async (record) => {
		setSelected(record?.id)
		const res = await unassignProperty({ userId: user.state.id, recordId: record.id });
		if (res?.data?.message) {
			toast.success(res?.data?.message)
		}
		else {
			toast.error(`${res?.error?.data?.message}`)
		}
		refetch()
		setSelected()
	};
	const Columns = [
		{
			title: "S.No.",
			dataIndex: "serial",
			render: (_, record, index) => (currentPage - 1) * pageSize + index + 1,
		},
		{
			title: "Property Name",
			dataIndex: "property",
		},

		{
			title: "Action",
			dataIndex: "action",
			render: (_, record) => {
				return (
					<div className="action-container">
						{selectedRedord === record?.id && unAssignedLoading ? (<Spin />) : (
							<Button type="primary" onClick={() => removeProperty(record)}>
								Unassign
							</Button>
						)}
					</div>

				);
			},
		},
	];

	return (
		<>
			{loading && (
				<div className="commanLoaderWrapper">
					<Spin size="large" />
					<h4>Setting things up..</h4>
				</div>
			)}
			<TopHeader title={<Title title="All Assign Property" />} />
			<div className="customPadding">
				<div className="mainWrapper">
					<div className="card tableCard">
						<div className="card-body">
							<div className="table-responsives">
								{
									assignPropertyLoading ? (
										<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
											<Spin />
										</div>
									) : (
										<>
											<Table
												className="table-bordered"
												rowKey={(record) => record.id}
												rowClassName={() => "editable-row"}
												bordered
												dataSource={displayedItems}
												columns={Columns}
												pagination={false}
											/>
											{totalItems > 0 && (
												<Pagination
													className="mt-4"
													current={currentPage}
													pageSize={pageSize}
													total={totalItems}
													onChange={handleChangePage}
													locale={{
                                                        items_per_page: `/${totalItems || 0} Records`,
                                                    }}
												/>
											)}
										</>
									)
								}

							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default AssignProperty;
