import React, { useEffect, useState } from "react";
import { Button, Modal, Table, Tooltip, Spin, Input, Pagination, Switch, Typography } from "antd";
import { IoIosAddCircleOutline } from "react-icons/io";
import Title from "../../component/PageTitle";
import { useNavigate, useOutletContext } from "react-router-dom";
import { FaEdit, } from "react-icons/fa";
import { useGetIncomeQuery } from "../../slices/getSlice";
import { useSelector } from "react-redux";
import { RxCrossCircled } from "react-icons/rx";
import { useDeleteIncomeMutation, useUpdateRecurringMutation, useActivityLogsMutation } from "../../slices/apiSlice";
import { HiOutlineTrash } from "react-icons/hi2";
import { toast } from "react-toastify";
import TopHeader from "../../component/Header";
import { HomeOutlined } from '@ant-design/icons';
import { Breadcrumb } from 'antd';
import dayjs from "dayjs";
import PageSizeOptionsHelper from "../../Helper/PageSizeOptionsHelper";
import swal from "sweetalert";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setSelectedPropertyData } from "../../slices/PropertySlice";
import { saveAs } from 'file-saver';
import { GrDocumentCsv } from "react-icons/gr";
import { HiOutlineDocumentText } from "react-icons/hi";

const Income = () => {
    const currencyGlobal = useSelector((state) => state?.persistedReducer?.currency?.currency_symbol)
    const { loading, setLoading } = useOutletContext();
    const [searchText, setSearchText] = useState(null);
    const currency = currencyGlobal ? currencyGlobal : '';
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState("");
    const [pageSize, setPageSize] = useState(20)
    const [exportPageSize, setExportPageSize] = useState(null)
    const navigate = useNavigate()
    const [currentPage, setCurrentPage] = useState(1);
    const [tableSortBy, setTableSortBy] = useState(null);
    const [columnName, setColumnName] = useState(null);
    const colors = useSelector((state) => state?.persistedReducer?.colors);
    const startDate = useSelector((state) => state.date.startDate);
    const endDate = useSelector((state) => state.date.endDate);
    const selectedProperties = useSelector((state) => state.Property.selectedProperties);
    const [deleteIncome, { }] = useDeleteIncomeMutation();
    const [updateRecurring, { isLoading: loadingRecurring }] = useUpdateRecurringMutation();
    const [getActivityLogs, { isLoading: loadingActivity }] = useActivityLogsMutation();
    const [tableLoading, setTableLoading] = useState(false);
    const [totalIncome, setTotalIncome] = useState(null);
    // const [localLoading, setLocalLoading] = useState(false);
    const [exportLoader, setExportLoader] = useState(false);
    const dispatch = useDispatch();
    const location = useLocation();
    const filterData = location.state;
    const [debouncedSearch, setDebouncedSearch] = useState("");
    const [ActivityModel, setActivityModel] = useState(false);
    const [ActivityModelData, setActivityModelData] = useState([]);

    useEffect(() => {
        if (filterData) {
            dispatch(
                setSelectedPropertyData({
                    selectedProperty: filterData?.id,
                })
            );
        }
    }, []);

    const queryParams = [
        `page=${debouncedSearch || (selectedProperties?.selectedProperty?.length > 0) ? 1 : currentPage}`,
        `per_page=${exportPageSize ? exportPageSize : pageSize}`,
        debouncedSearch ? `search=${debouncedSearch}` : "",
        tableSortBy && columnName ? `sort_direction=${tableSortBy}&sort_by=${columnName}` : "",
        startDate ? `start_date=${startDate}` : "",
        endDate ? `end_date=${endDate}` : "",
        selectedProperties?.selectedProperty
            ? Array.isArray(selectedProperties.selectedProperty) && selectedProperties.selectedProperty.length > 1
                ? `property_ids=${selectedProperties.selectedProperty.join(",")}`
                : `property_ids=${selectedProperties.selectedProperty}`
            : ""
    ]
        .filter(Boolean)
        .join("&");

    const {
        data: getIncome,
        isLoading,
        isError,
        refetch,
        error
    } = useGetIncomeQuery(`?${queryParams}`);

    const fetchData = async () => {
        setTableLoading(true);
        await refetch();
        setTableLoading(false);
    };

    useEffect(() => {
        setTableLoading(true);
        if (getIncome) {
            setLoading(false);
            setTableLoading(false);
            setTotalIncome(getIncome?.total_income);
        }
        fetchData().finally(() => setTableLoading(false));
    }, [getIncome, selectedProperties, startDate, endDate]);

    const handleOk = async () => {
        const res = await deleteIncome(selectedRecord);
        if (res?.data?.message) {
            const totalPages = Math.ceil((dataSource.length - 1) / pageSize);
            if (currentPage > totalPages) {
                setCurrentPage(totalPages);
            }
            refetch();
            setIsModalOpen(false);
            toast.success(res?.data?.message)
        }
        else {
            toast.error("Not able to delete income")
        }
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setSelectedRecord("");
    };

    const dataSource = getIncome?.data ? getIncome.data.map((item) => ({
        property_id: item.property.name_of_property,
        propertyId: item.property.id,
        amount: item.amount,
        start_date: item.start_date,
        end_date: item.end_date,
        id: item.id,
        created_at: item.created_at,
        payment_type_id: item.payment_type_id,
        payment_type_name: item.payment_type_name,
        frequency: item?.frequency,
        notes: item?.notes,
        payment_mode_id: item?.payment_mode_id,
        propertyData: item?.property,
        parent_id: item?.parent_id,
        is_recurring_on: item?.is_recurring_on
    })) : [];

    const pageSizeOptions = PageSizeOptionsHelper(getIncome?.meta?.total || 0);

    const handleToggleChange = (checked, record) => {
        if (!record?.id) {
            toast.error("Invalid record. Please try again.");
            return;
        }

        swal({
            title: "Are you sure?",
            text: `If you turn this ${checked ? "on" : "off"}, recurring income will ${checked ? "automatically be added to this property again." : "no longer be added to this property"} .`,
            // icon: "warning",
            // buttons: ["Cancel", "Yes, confirm!"],
            dangerMode: true,
            buttons: {
                confirm: {
                    text: "Yes, confirm!",
                    value: true,
                    visible: true,
                    className: "custom-confirm-btn",
                    closeModal: true,
                },
                cancel: {
                    text: "Cancel",
                    value: null,
                    visible: true,
                    className: "custom-cancel-btn",
                    closeModal: true,
                },
            },
        }).then(async (willConfirm) => {
            if (willConfirm) {
                let formData = {
                    type: "income",
                    id: record?.id,
                    is_recurring_on: checked ? 1 : 0
                };

                try {
                    let changeStatus = await updateRecurring(formData);

                    if (changeStatus?.error) {
                        toast.error(changeStatus?.error?.message || "Failed to update status.");
                    } else {
                        toast.success(changeStatus?.data?.message || "Status updated successfully.");
                        refetch();
                    }
                } catch (error) {
                    toast.error("Something went wrong! Please try again.");
                    console.error("API Error:", error);
                }
            }
        });
    };

    const handlePropertyClick = (data) => {
        navigate("/add_income", { state: data })
    }

    const handleLogClick = async (ID) => {
        if (!ID) {
            console.error("Invalid ID");
            return;
        }

        setActivityModel(true);

        try {
            let activityData = await getActivityLogs(ID);

            if (activityData && activityData.data && activityData.data.data) {
                setActivityModelData(activityData.data.data);
            } else {
                console.error("Invalid response data format:", activityData);
            }
        } catch (error) {
            console.error("Error fetching activity logs:", error);
        }
    };

    const Columns = [
        {
            title: "S.No.",
            dataIndex: "serial",
            render: (_, record, index) =>
                (currentPage - 1) * pageSize + index + 1,
        },
        {
            title: "Property Name",
            dataIndex: "name_of_property",
            render: (_, record) => (
                <div onClick={() => handlePropertyClick(record)} style={{ cursor: 'pointer' }}>
                    {record?.property_id}
                </div>
            ),
            sorter: true,
        },
        {
            title: "Income",
            dataIndex: "amount",
            render: (amount) =>
                `${currency} ${new Intl.NumberFormat("en-US").format(amount || 0)}`,
            sorter: true,
        },
        {
            title: "Income Date",
            dataIndex: "start_date",
            sorter: true,
            render: (text) => (text ? dayjs(text).format("MM/DD/YYYY") : "N/A"),
        },
        {
            title: "Income Name",
            dataIndex: "notes",
            sorter: true,
            render: (notes) => (
                <Tooltip title={notes}>
                    <div className="income-name">
                        {notes}
                    </div>
                </Tooltip>
            ),
        },
        {
            title: "Income Type",
            dataIndex: "payment_type_name",
            render: (_, record) => record?.payment_type_name,
            sorter: true,
        },
        {
            title: (
                <>
                    Status{" "}
                    <Tooltip title="Turn on/off recurring payment">
                        <InfoCircleOutlined className="infoIcon" />
                    </Tooltip>
                </>
            ),
            dataIndex: "status",
            render: (_, record) => (
                <div className="statusWrapper">
                    {record?.parent_id === null && record?.payment_type_name === "Recurring" && (
                        <>
                            <Switch
                                checked={record.is_recurring_on === true ? true : false}
                                onChange={(checked) => handleToggleChange(checked, record)}
                                className="toggleSwitch"
                            />
                        </>

                    )}
                </div>
            ),
        },
        {
            title: "Action",
            dataIndex: "action",
            render: (_, record) => (
                <div className="action-container">
                    <Tooltip title="Edit">
                        <button
                            className="btn editButton me-2"
                            onClick={() => navigate("/add_income", { state: record })}
                        >
                            <FaEdit />
                        </button>
                    </Tooltip>
                    <Tooltip title="View Activity Logs">
                        <button
                            className="btn logButton me-2"
                            onClick={() => {
                                handleLogClick(record?.id);
                            }}
                        >
                            <HiOutlineDocumentText />
                        </button>
                    </Tooltip>
                    <Tooltip title="Delete">
                        <button
                            className="btn deleteButton me-2"
                            onClick={() => {
                                setSelectedRecord(record.id);
                                setIsModalOpen(true);
                            }}
                        >
                            <HiOutlineTrash />
                        </button>
                    </Tooltip>
                </div>
            ),
        },
    ];

    const handleSearchChange = (e) => {
        setCurrentPage(1);
        setSearchText(e.target.value || null);
    };

    useEffect(() => {
        if (searchText !== "" && searchText !== undefined && searchText !== null) {
            const handler = setTimeout(() => {
                setDebouncedSearch(searchText);
            }, 1500);

            return () => clearTimeout(handler);
        } else {
            setDebouncedSearch(searchText);
        }
    }, [searchText]);

    const handlePageChange = (page, size) => {
        setPageSize(size)
        setTableLoading(true);
        setCurrentPage(page);
    };

    useEffect(() => {
        if (exportPageSize) {
            handleIncomeDataExport()
        }
    }, [exportPageSize])

    const handleIncomeDataExport = async () => {
        setExportLoader(true);
        try {
            const response = await refetch();
            const incomes = response?.data?.data || [];

            const csvData = incomes.map((income, index) => {
                return {
                    "S.NO": index + 1,
                    "Property Name": income?.property?.name_of_property,
                    "Income": income?.amount,
                    "Income Date": dayjs(income?.start_date).format("MM-DD-YYYY"),
                    "Income Name": income?.notes,
                    "Income Type": income?.payment_type_name,

                };
            });

            const csvRows = [
                ['S.NO', 'Property Name', 'Income', 'Income Date', 'Income Name', 'Income Type'],
                ...csvData.map(item => [
                    item["S.NO"],
                    item["Property Name"],
                    item["Income"],
                    item["Income Date"],
                    item["Income Name"],
                    item["Income Type"],
                ])
            ];

            const csvString = csvRows.map(row => row.join(',')).join('\n');
            const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
            saveAs(blob, 'income.csv');
        } catch (error) {
            console.error("Error exporting data: ", error);
        }
        setExportLoader(false);
        setExportPageSize(null)
    };

    const handleCancelLogModel = () => {
        setActivityModel(false);
        setActivityModelData([]);
    }

    const logsColumns = [
        {
            title: "Action",
            dataIndex: "event",
            key: "event",
            render: (description) => (
                <div>
                    {description === "Income Created"
                        ? "Created"
                        : description === "Income Updated"
                            ? "Updated"
                            : description === "Income Deleted"
                                ? "Deleted"
                                : ""}
                </div>
            ),
        },
        {
            title: "Details",
            dataIndex: "details",
            key: "details",
            render: (_, record) => {
                const { attributes, old } = record.properties;
                const changes = [];
                const skippedKeys = new Set();

                if (record.isUpdated) {
                    Object.keys(attributes).forEach((key) => {
                        if (key !== "updated_at" && old?.[key] !== undefined) {
                            let oldValue = old[key];
                            let newValue = attributes[key];

                            const nameMapping = {
                                payment_mode_id: "payment_mode_name",
                                payment_type_id: "payment_type_name",
                            };

                            if (nameMapping[key] && attributes[nameMapping[key]]) {
                                skippedKeys.add(key);
                            }

                            if (!skippedKeys.has(key)) {
                                if (nameMapping[key]) {
                                    const oldName = old[nameMapping[key]];
                                    const newName = attributes[nameMapping[key]];

                                    oldValue = oldName || `ID: ${oldValue}`;
                                    newValue = newName || `ID: ${newValue}`;
                                }

                                if (oldValue !== newValue) {
                                    changes.push(
                                        <div key={key}>
                                            <strong>{key.replace(/_/g, " ")}:</strong> Changed from{" "}
                                            <Typography.Text delete>{oldValue}</Typography.Text> →{" "}
                                            <strong>{newValue}</strong>
                                        </div>
                                    );
                                }
                            }
                        }
                    });
                }

                return changes.length > 0 ? changes : <div>No significant changes</div>;
            },
        },
        {
            title: "Modified By",
            dataIndex: "modifiedBy",
            key: "modifiedBy",
        },
        {
            title: "Updated On",
            dataIndex: "formattedDate",
            key: "formattedDate",
        },
    ];

    const dataLogSource =
        ActivityModelData?.length > 0
            ? [...ActivityModelData]
                .filter((log) => log.description !== "Income Deleted" && log.description !== null)
                .reverse()
                .map((log, index) => {
                    const parsedProperties = JSON.parse(log.properties || "{}");

                    return {
                        key: index,
                        event: log.description,
                        isUpdated: log.description === "Income Updated",
                        isCreated: log.description === "Income Created",
                        isDeleted: log.description === "Income Deleted",
                        modifiedBy: `${log.causer?.first_name || "Unknown"} ${log.causer?.last_name || ""}`.trim(),
                        formattedDate: log.updated_at
                            ? dayjs(log.updated_at).format("MM-DD-YYYY hh:mm A")
                            : "N/A",
                        createdDate: parsedProperties?.attributes?.created_at
                            ? dayjs(parsedProperties.attributes.created_at).format("MM-DD-YYYY hh:mm A")
                            : log.created_at
                                ? dayjs(log.created_at).format("MM-DD-YYYY hh:mm A")
                                : "N/A",
                        properties: parsedProperties,
                    };
                })
            : [];


    return (
        <>
            {loading && (
                <div className="commanLoaderWrapper">
                    <Spin size="large" />
                    <h4>Setting things up..</h4>
                </div>
            )}
            <TopHeader
                title={<Title title="All Income" id="income" />}
                search={
                    <Input.Search
                        placeholder="Search..."
                        allowClear
                        value={searchText}
                        onChange={handleSearchChange}
                        className="tableSearchInput ms-3"
                        style={{ width: '100%', marginRight: '1rem' }}
                    />
                }
                button={
                    <Button
                        type="primary"
                        style={{
                            backgroundColor: colors?.primary || "#F47A3A",
                            width: 'fit-content'
                        }}
                        className="customHeaderBtn step-one"
                        onClick={() => (navigate('/add_income'))}
                    >
                        {"Add New"}{" "}
                        <IoIosAddCircleOutline />
                    </Button>
                }
            />
            <div className="customPadding">
                <div className="breadcrumbWrapper">
                    <Breadcrumb
                        items={[
                            {
                                title: (
                                    <span onClick={() => navigate('/dashboard')} style={{ cursor: 'pointer' }}>
                                        <HomeOutlined />
                                    </span>
                                ),
                            },
                            {
                                title: (
                                    <>
                                        <span>Income</span>
                                    </>
                                ),
                            },
                        ]}
                    />
                </div>

                <div className="mainWrapper">
                    <div className="card titleCard mb-4">
                        <div className="card-body-wrapper">
                            {isError && dataSource.length < 0 ? (
                                <h4>An error occurred</h4>
                            ) : (
                                <div className="table-responsives">
                                    <>
                                        {getIncome?.meta?.total > 0 ? (<><div className="export-data-wrapper d-flex justify-content-end align-items-center"><Button icon={<GrDocumentCsv />} loading={exportLoader} onClick={() => {
                                            setExportPageSize(getIncome?.meta?.total)
                                        }} className="export-data-button viewButton mb-3">Export</Button></div></>) : (<></>)}
                                        <Table
                                            className={`table-bordered step-two ${tableLoading ? "table-loading" : ""}`}
                                            rowKey={(record) => record.id}
                                            rowClassName={() => "editable-row"}
                                            bordered
                                            dataSource={dataSource}
                                            columns={Columns}
                                            scroll={{ x: "100%" }}
                                            pagination={false}
                                            loading={tableLoading || loadingRecurring}
                                            footer={() => (
                                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                                                    <div style={{ fontWeight: "bold" }}>
                                                        Total Income:
                                                        <span style={{ fontSize: '1rem', marginLeft: '0.2rem' }}>
                                                            {new Intl.NumberFormat(
                                                                currency === "₹" ? "en-IN" : "en-US",
                                                                { style: "currency", currency: currency === "₹" ? "INR" : "USD" }
                                                            ).format(isNaN(totalIncome) || totalIncome === undefined || totalIncome === null ? 0 : totalIncome)}

                                                        </span>
                                                    </div>
                                                </div>
                                            )}
                                            onChange={(pagination, filters, sorter) => {
                                                if (sorter.order) {
                                                    const columnTitle = Columns.find(
                                                        (col) => col.dataIndex === sorter.field
                                                    )?.dataIndex;
                                                    setColumnName(columnTitle);
                                                    setTableSortBy(sorter.order === "ascend" ? "asc" : "desc");
                                                }
                                            }}
                                        />
                                        {getIncome?.meta?.total > 20 ? (
                                            <Pagination
                                                className="mt-4"
                                                total={getIncome?.meta?.total}
                                                pageSize={pageSize}
                                                current={currentPage}
                                                onChange={handlePageChange}
                                                pageSizeOptions={pageSizeOptions}
                                                showSizeChanger
                                                locale={{
                                                    items_per_page: `/${getIncome?.meta?.total || 0} Records`,
                                                }}
                                            />
                                        ) : (<></>)}

                                    </>
                                </div>
                            )}
                        </div>
                    </div>

                    <Modal
                        className="deleteModal"
                        title="Confirm Deletion"
                        open={isModalOpen}
                        onCancel={handleCancel}
                        footer={false}
                    >
                        <div className="iconRow">
                            <RxCrossCircled />
                        </div>
                        <div className="textRow">
                            <h3>Are you sure you want to proceed?</h3>
                            <p>This action will permanently remove the selected income entry. This cannot be undone.</p>
                        </div>
                        <div className="buttonRow">
                            <Button className="btnFilled text-white me-2" onClick={handleOk}>
                                Yes, Delete
                            </Button>
                            <Button className="btnOutlined" onClick={handleCancel}>
                                No, Keep It
                            </Button>
                        </div>
                    </Modal>


                    <Modal className="deleteModal customLogsModel" title="Activity Logs" open={ActivityModel} onCancel={handleCancelLogModel} footer={false}>
                        <Table columns={logsColumns} dataSource={dataLogSource} pagination={false} loading={loadingActivity} />
                    </Modal>

                </div>
            </div>
        </>
    );
};
export default Income;