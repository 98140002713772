import React, { useEffect, useState } from "react";
import Title from "../../component/PageTitle";
import { Table, Spin, Select } from "antd";

const { Option } = Select;

const CateogryListing = () => {
    const [loading, setLoading] = useState(false);
    const [pageSize, setPageSize] = useState(20);
    const [filteredData, setFilteredData] = useState([]);
    const [selectedProperty, setSelectedProperty] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState([]);
    const [selectedSubCategory, setSelectedSubCategory] = useState([]);

    const data = [
        { key: "1", property_name: "Apartment 21", category: "Utilities", subcategory: "Fan", company: "Khaitaan", total_expense: 500 },
        { key: "2", property_name: "Villa 5B", category: "Utilities", subcategory: "WiFi", company: "Airtel", total_expense: 1000 },
        { key: "3", property_name: "Condo 9C", category: "Entertainment", subcategory: "WiFi", company: "Airtel", total_expense: 750 },
        { key: "4", property_name: "Penthouse 3A", category: "Utilities", subcategory: "Fan", company: "Khaitaan", total_expense: 1200 },
    ];

    const properties = [...new Set(data.map(item => item.property_name))].map(value => ({ label: value, value }));
    const categories = [...new Set(data.map(item => item.category))].map(value => ({ label: value, value }));
    const subCategories = [...new Set(data.map(item => item.subcategory))].map(value => ({ label: value, value }));
    const companies = [...new Set(data.map(item => item.company))].map(value => ({ label: value, value }));

    const columns = [
        { title: "S No.", render: (text, record, index) => index + 1 },
        { title: "Property Name", dataIndex: "property_name" },
        { title: "Category", dataIndex: "category" },
        { title: "Subcategory", dataIndex: "subcategory" },
        { title: "Company", dataIndex: "company" },
        {
            title: "Total Expense",
            dataIndex: "total_expense",
            sorter: (a, b) => a.total_expense - b.total_expense,
            sortDirections: ["ascend", "descend"],
            render: (record) => `$${record}`
        },
    ];

    useEffect(() => {
        let filtered = data.filter(item =>
            (selectedProperty.length === 0 || selectedProperty.includes(item.property_name)) &&
            (selectedCompany.length === 0 || selectedCompany.includes(item.company)) &&
            (selectedCategory.length === 0 || selectedCategory.includes(item.category)) &&
            (selectedSubCategory.length === 0 || selectedSubCategory.includes(item.subcategory))
        );
        setFilteredData(filtered);
    }, [selectedProperty, selectedCompany, selectedCategory, selectedSubCategory]);

    const totalExpense = filteredData.reduce((sum, item) => sum + item.total_expense, 0);

    return (
        <>
            {loading && (
                <div className="commanLoaderWrapper">
                    <Spin size="large" />
                    <h4>Setting things up..</h4>
                </div>
            )}
            <div className="customPadding">
                <div className="mainWrapper mainCategoryListWrapper">
                    <div className="card titleCard mb-4">
                        <div className="card-body">
                            <div className="pageTitleWrapper">
                                <Title title="Categories" id="categories" />

                                <div className="dropdownContainer">
                                    <Select
                                        mode="multiple"
                                        value={selectedProperty}
                                        placeholder="Filter by Properties"
                                        style={{ marginRight: "1rem" }}
                                        onChange={setSelectedProperty}
                                        options={properties}
                                        className="mobWidth"
                                        maxTagCount={1}
                                        allowClear
                                        showSearch
                                        filterOption={(input, option) =>
                                            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                                        }
                                    />
                                    <Select
                                        mode="multiple"
                                        value={selectedCategory}
                                        placeholder="Filter by Categories"
                                        style={{ marginRight: "1rem" }}
                                        onChange={setSelectedCategory}
                                        options={categories}
                                        className="mobWidth"
                                        maxTagCount={1}
                                        allowClear
                                    />
                                    <Select
                                        mode="multiple"
                                        value={selectedSubCategory}
                                        placeholder="Filter by Sub-Categories"
                                        style={{ marginRight: "1rem" }}
                                        onChange={setSelectedSubCategory}
                                        options={subCategories}
                                        className="mobWidth"
                                        maxTagCount={1}
                                        allowClear
                                    />
                                    <Select
                                        mode="multiple"
                                        value={selectedCompany}
                                        placeholder="Filter by Companies"
                                        onChange={setSelectedCompany}
                                        options={companies}
                                        className="mobWidth"
                                        maxTagCount={1}
                                        allowClear
                                    />
                                </div>
                            </div>

                            <div className="card-table-wrapper">
                                <Table
                                    columns={columns}
                                    dataSource={filteredData}
                                    pagination={{ pageSize}}
                                    showSorterTooltip={{ target: "sorter-icon" }}
                                    footer={() => (
                                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                                            <div>
                                                <span style={{ marginRight: "8px" }}>Show:</span>
                                                <Select
                                                    defaultValue={5}
                                                    onChange={(value) => setPageSize(value)}
                                                    style={{ width: 60 }}
                                                >
                                                    <Option value={5}>5</Option>
                                                    <Option value={10}>10</Option>
                                                    <Option value={20}>20</Option>
                                                </Select>
                                            </div>

                                            <div style={{ fontWeight: "bold" }}>
                                                Total Expense: ${totalExpense}
                                            </div>
                                        </div>
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CateogryListing;
