import React, { useEffect, useState } from "react";
import ChartRevenue from "../../HelperCharts/RevenueChart";
import Title from "../../component/PageTitle";
import { BsBuildings } from "react-icons/bs";
import { GiReceiveMoney, GiExpense } from "react-icons/gi";
import { Table, Select, Spin, Button, Modal, Form, Input, message, Empty } from "antd";
import ChartProfit from "../../HelperCharts/ProfitChart";
import GroupChart from "../../HelperCharts/GroupChart";
import { useSelector } from "react-redux";
import {
    useUpdateLabelsMutation,
} from "../../slices/apiSlice";
import {
    useGetPropertiesQuery,
    useGetUserDetailQuery,
    useGetLabelsQuery,
    useLazyGetStatisticsQuery,
    useLazyGetTopRevenuePropertiesQuery,
    useLazyGetTopProfitablePropertiesQuery,
    useLazyGetTopRevenueGroupsQuery,
    useLazyGetTopExpensesQuery
} from "../../slices/getSlice";
import { useNavigate, useOutletContext } from "react-router-dom";
import TopHeader from "../../component/Header";
import dayjs from "dayjs";
import ExpenseBarChart from "../../HelperCharts/ExpenseChart";
import { saveAs } from 'file-saver';
import { GrDocumentCsv } from "react-icons/gr";
import { useLocation } from "react-router-dom";

const Dashboard = () => {
    const currencyGlobal = useSelector(
        (state) => state?.persistedReducer?.currency
    );
    const { loading, setLoading } = useOutletContext();
    const {
        data: getUserDetail,
    } = useGetUserDetailQuery();
    const defaultCurrency = getUserDetail?.data.setting.currency_symbol;
    const colors = useSelector((state) => state?.persistedReducer?.colors);
    const currency = currencyGlobal ? currencyGlobal : defaultCurrency;
    const [properties, setProperties] = useState();
    const [selectedProperty, setSelectedProperty] = useState([]);
    const [groupData, setGroupData] = useState([]);
    const [statistics, setStatistics] = useState();
    const [revenueProperty, setRevenueProperty] = useState("");
    const [expenseProperties, setExpenseProperties] = useState("");
    const [profitProperty, setProfitProperty] = useState();
    const [isEditModal, setIsEditModal] = useState(false);
    const [exportTopRevenuePropertiesLoader, setExportTopRevenuePropertiesLoader] = useState(false);
    const [profitablePropertyLoader, setProfitablePropertyLoader] = useState(false);
    const [expensivePropertyLoader, setExpensivePropertyLoader] = useState(false);
    const [incomeGeneratingGroupsLoader, setIncomeGeneratingGroupsLoader] = useState(false);
    const [labelForm] = Form.useForm();
    const [isSaving, setIsSaving] = useState(false);
    const [loader, setLoader] = useState(true);
    const [labelUpdate, { isLoading: isLoadingUpdate }] = useUpdateLabelsMutation();
    const start_Date = useSelector((state) => state.date.startDate);
    const end_Date = useSelector((state) => state.date.endDate);
    const navigate = useNavigate();
    const location = useLocation();
    const userData = useSelector((state) => state.persistedReducer);
    const parent_id = userData?.user?.user?.parent_id;

    const [data, setData] = useState({
        filter_type: "",
        filter_value: "",
        property_ids: " "
    });

    const {
        data: labelData,
        refetch,
    } = useGetLabelsQuery('dashboard_labels');

    const [fetchStatistics, { isLoading: isStatisticsLoading, isError: isStatisticsError }] = useLazyGetStatisticsQuery();
    const [fetchTopRevenueProperties, { isLoading: revenueLoader }] = useLazyGetTopRevenuePropertiesQuery();
    const [getTopExpenses, { isLoading: expenseLoader }] = useLazyGetTopExpensesQuery();
    const [fetchTopProfitableProperties, { isLoading: profitLoader }] = useLazyGetTopProfitablePropertiesQuery();
    const [fetchTopRevenueGroups, { isLoading: topGroupLoader }] = useLazyGetTopRevenueGroupsQuery();

    const { data: getProperties } = useGetPropertiesQuery(undefined, {
        refetchOnMountOrArgChange: true,
    });

    const currencyName = useSelector((state) => state?.persistedReducer?.currency);

    const formatDate = (date) => {
        if (date) {

            return dayjs(date, "MM/DD/YYYY").format("YYYY-MM-DD");
        }
    };

    const handleFetchData = async (startDate, endDate, property) => {
        const formattedStartDate = formatDate(startDate);
        const formattedEndDate = formatDate(endDate);

        let statsData = await fetchStatistics({ start_date: formattedStartDate, end_date: formattedEndDate, property_ids: property });
        setStatistics(statsData?.data);
        let fetchRevenueProperties = await fetchTopRevenueProperties({ start_date: formattedStartDate, end_date: formattedEndDate, property_ids: property });
        setRevenueProperty(fetchRevenueProperties?.data);
        let fetchExpenseProperties = await getTopExpenses({ start_date: formattedStartDate, end_date: formattedEndDate, property_ids: property });
        setExpenseProperties(fetchExpenseProperties?.data);
        let topProperties = await fetchTopProfitableProperties({ start_date: formattedStartDate, end_date: formattedEndDate, property_ids: property });
        setProfitProperty(topProperties?.data);
        let topGroups = await fetchTopRevenueGroups({ start_date: formattedStartDate, end_date: formattedEndDate, property_ids: property });
        setGroupData(topGroups?.data);
        setLoading(false);
        setLoader(false);
    };

    useEffect(() => {
        setLoader(true)
        handleFetchData(start_Date, end_Date, selectedProperty);
    }, [start_Date, end_Date, selectedProperty]);

    useEffect(() => {
        if (getProperties && getProperties?.data) {
            const transformedOptions = getProperties?.data?.map((item) => ({
                label: item.name_of_property,
                value: item.id,
            }));
            setProperties(transformedOptions);
        } else {

        }
    }, [getProperties]);

    useEffect(() => {
        refetch();
    }, []);

    function absString(n) {
        let numberString = n.toString();
        if (numberString[0] === '-') {
            return numberString.substring(1);
        }
        else {
            return numberString;
        }
    }

    const netProfit = statistics?.net_profit || 0;
    const numberWithoutCommas = netProfit ? netProfit?.replace(/,/g, '') : '';
    let netProfitNumber = absString(netProfit);

    const columns = [
        {
            title: "S.No.",
            dataIndex: "serial",
            key: "serial",
            render: (text, record, index) => {
                return index + 1;
            },
        },
        {
            title: "Property Name",
            dataIndex: "name_of_property",
            key: "name",
            render: (text, record, index) => (
                <>
                    <span onClick={() => redirectToIncome(record)} style={{ cursor: 'pointer' }}>{text}</span>
                </>
            ),
        },
        {
            title: "Total Profit",
            dataIndex: "total_profit",
            key: "total_profit",
            render: (value) => new Intl.NumberFormat('en-US', { style: 'currency', currency: currencyGlobal?.currency_name === "Indian Rupee" ? 'INR' : 'USD' }).format(value),
        },
    ];

    const redirectToIncome = (data) => {
        navigate('/income', { state: data });
    }

    const redirectToGroup = (data) => {
        navigate('/manage_property', { state: data });
    }

    const redirectToExpense = (data) => {
        navigate('/expense', { state: data });
    }

    const revenue_columns = [
        {
            title: "S.No.",
            dataIndex: "serial",
            key: "serial",
            render: (text, record, index) => {
                return index + 1;
            },
        },
        {
            title: "Property Name",
            dataIndex: "name_of_property",
            key: "name",
            render: (text, record, index) => (
                <>
                    <span onClick={() => redirectToIncome(record)} style={{ cursor: 'pointer' }}>{text}</span>
                </>
            ),
        },
        {
            title: "Total Revenue",
            dataIndex: "total_revenue",
            key: "name",
            render: (value) => `${new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: currencyGlobal && currencyGlobal?.currency_name === "Indian Rupee" ? 'INR' : 'USD'
            }).format(Number(value?.replace(/,/g, '').trim()) || 0)}`,
        },
    ];

    const expense_columns = [
        {
            title: "S.No.",
            dataIndex: "serial",
            key: "serial",
            render: (text, record, index) => {
                return index + 1;
            },
        },
        {
            title: "Property Name",
            dataIndex: "name_of_property",
            key: "name",
            render: (text, record, index) => (
                <>
                    <span onClick={() => redirectToExpense(record)} style={{ cursor: 'pointer' }}>{text}</span>
                </>
            ),
        },
        {
            title: "Total Expense",
            dataIndex: "total_expense",
            key: "expense",
            render: (value) => `${new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: currencyGlobal && currencyGlobal?.currency_name === "Indian Rupee" ? 'INR' : 'USD'
            }).format(Number(value?.replace(/,/g, '').trim()) || 0)}`,
        },
    ];

    const GroupsColumn = [
        {
            title: "S.No.",
            dataIndex: "serial",
            key: "serial",
            render: (text, record, index) => {
                return index + 1;
            },
        },
        {
            title: "Group Name",
            dataIndex: "name",
            key: "name",
            render: (text, record, index) => (
                <>
                    <span onClick={() => redirectToGroup(record)} style={{ cursor: 'pointer' }}>{text}</span>
                </>
            ),
        },
        {
            title: "Total Revenue",
            dataIndex: "total_income",
            key: "total_income",
            render: (value) => {
                const numericValue = typeof value === "string"
                    ? Number(value.replace(/,/g, '').trim()) || 0
                    : Number(value) || 0;

                return new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: currencyGlobal?.currency_name === "Indian Rupee" ? 'INR' : 'USD'
                }).format(numericValue);
            }
        },
    ];

    const handleChange = (value) => {
        localStorage?.setItem('defaultProperty', JSON.stringify(value));
        setSelectedProperty(value);
    };

    useEffect(() => {
        const localStorageProperty = localStorage.getItem('defaultProperty');

        if (localStorageProperty && properties?.length > 0) {
            try {
                const parsedProperties = JSON.parse(localStorageProperty);

                if (Array.isArray(parsedProperties)) {
                    const matchedProperties = properties
                        .filter(item => parsedProperties.includes(item.value))
                        .map(item => item.value);

                    if (matchedProperties.length > 0) {
                        setSelectedProperty(matchedProperties);
                    }
                } else {
                    const matchedProperties = properties
                        .find(item => item.value === parsedProperties)

                    if (matchedProperties) {
                        setSelectedProperty(matchedProperties.value);
                    }
                }
            } catch (error) {
                console.error("Error parsing localStorageProperty:", error);
            }
        }
    }, [location.pathname, properties]);

    const handleCancel = () => {
        setIsEditModal(false);
    }

    const capitalizeFirstLetter = (string) => {
        return string?.charAt(0)?.toUpperCase() + string?.slice(1);
    };

    const updateLabels = async (value) => {
        const convertedData = {
            layout_data: {
                Top_5_profitable_chart: value.Top_5_profitable_chart !== undefined ? value.Top_5_profitable_chart : labelData.Top_5_profitable_chart,
                Top_5_profitable_table: value.Top_5_profitable_table !== undefined ? value.Top_5_profitable_table : labelData.Top_5_profitable_table,
                revenue_growth_comparison: value.revenue_growth_comparison !== undefined ? value.revenue_growth_comparison : labelData.revenue_growth_comparison,
                top_5_revenue: value.top_5_revenue !== undefined ? value.top_5_revenue : labelData.top_5_revenue,
                total_expense: value.total_expense !== undefined ? value.total_expense : labelData.total_expense,
                total_properties: value.total_properties !== undefined ? value.total_properties : labelData.total_properties,
                total_revenue: value.total_revenue !== undefined ? value.total_revenue : labelData.total_revenue,
            }
        };
        setIsSaving(true);
        try {
            await labelUpdate({ label: 'dashboard_labels', value: convertedData }).unwrap();
            message.success('Labels updated successfully!');
            refetch();
        }
        catch {
        } finally {
            setIsSaving(false);
            setIsEditModal(false);
            labelForm.resetFields();
        }
    }

    const handleRevenueGeneratingData = async () => {
        setExportTopRevenuePropertiesLoader(true);
        try {

            const csvData = revenueProperty?.topProperties?.map((revenue, index) => {
                return {
                    "S.NO": index + 1,
                    "Property Name": revenue?.name_of_property,
                    "Total Revenue": revenue?.total_revenue,
                };
            });

            const csvRows = [
                ['S.NO', 'Property Name', "Total Revenue"],
                ...csvData.map(item => [
                    item['S.NO'],
                    item["Property Name"],
                    item["Total Revenue"],
                ])
            ];

            const csvString = csvRows.map(row => row.join(',')).join('\n');
            const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
            saveAs(blob, 'top_revenue_generating_properties.csv');
        } catch (error) {
            console.error("Error exporting data: ", error);
        }
        setExportTopRevenuePropertiesLoader(false);
    };

    const handleProfitablePropertyData = async () => {
        setProfitablePropertyLoader(true);
        try {

            const csvData = profitProperty?.topProperties?.map((revenue, index) => {
                return {
                    "S.NO": index + 1,
                    "Property Name": revenue?.name_of_property,
                    "Total Profit": revenue?.total_profit,
                };
            });

            const csvRows = [
                ['S.NO', 'Property Name', "Total Profit"],
                ...csvData.map(item => [
                    item['S.NO'],
                    item["Property Name"],
                    item["Total Profit"],
                ])
            ];

            const csvString = csvRows.map(row => row.join(',')).join('\n');
            const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
            saveAs(blob, 'top_profitable_properties.csv');
        } catch (error) {
            console.error("Error exporting data: ", error);
        }
        setProfitablePropertyLoader(false);
    };

    const handleExpensiveData = async () => {
        setExpensivePropertyLoader(true);
        try {

            const csvData = expenseProperties?.map((revenue, index) => {
                return {
                    "S.NO": index + 1,
                    "Property Name": revenue?.name_of_property,
                    "Total Expense": revenue?.total_expense,
                };
            });

            const csvRows = [
                ['S.NO', 'Property Name', "Total Expense"],
                ...csvData.map(item => [
                    item['S.NO'],
                    item["Property Name"],
                    item["Total Expense"],
                ])
            ];

            const csvString = csvRows.map(row => row.join(',')).join('\n');
            const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
            saveAs(blob, 'top_expensive_properties.csv');
        } catch (error) {
            console.error("Error exporting data: ", error);
        }
        setExpensivePropertyLoader(false);
    };

    const handleIncomeGeneratingGroupsData = async () => {
        setIncomeGeneratingGroupsLoader(true);
        try {

            const csvData = groupData?.map((group, index) => {
                return {
                    "S.NO": index + 1,
                    "Group Name": group?.name,
                    "Total Revenue": group?.total_income,
                };
            });

            const csvRows = [
                ['S.NO', 'Group Name', "Total Revenue"],
                ...csvData.map(item => [
                    item['S.NO'],
                    item["Group Name"],
                    item["Total Revenue"],
                ])
            ];

            const csvString = csvRows.map(row => row.join(',')).join('\n');
            const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
            saveAs(blob, 'top_income_generating_groups.csv');
        } catch (error) {
            console.error("Error exporting data: ", error);
        }
        setIncomeGeneratingGroupsLoader(false);
    };

    return (
        <>
            {loading && (
                <div className="commanLoaderWrapper">
                    <Spin size="large" />
                    <h4>Setting things up..</h4>
                </div>
            )}
            <TopHeader
                title={<Title title="Overview" id='overview' />}
                button={
                    <>
                        <Select
                            className="mobWidth"
                            mode="multiple"
                            value={selectedProperty}
                            placeholder="Filter by Properties"
                            style={{
                                width: 180,
                            }}
                            onChange={(value) => handleChange(value)}
                            options={properties}
                            showSearch
                            filterOption={(input, option) =>
                                (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                            }
                            maxTagCount={1}
                        />
                    </>
                }
            />
            <div className="customPadding">
                {loader ? (
                    <div className="loaderWrapper">
                        <Spin />
                    </div>
                ) : (
                    <div className="mainWrapper">
                        <div className="chartWrapper">

                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-6">
                                    <div className="card chartCard mb-4">
                                        <div className="card-body" style={{ cursor: "pointer" }} onClick={() => navigate("/properties")}>
                                            <div className="topBox">
                                                <span className="dashIcon" style={{ background: colors?.primary }}>
                                                    <BsBuildings />
                                                </span>
                                                <div className="dashText" >
                                                    <p>{labelData?.total_properties || 'Total Properties'}</p>
                                                    {isStatisticsError && <div>Error loading statistics.</div>}
                                                    {!isStatisticsLoading && !isStatisticsError && (
                                                        <>
                                                            <h4>{statistics?.total_properties}</h4>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <div className="card chartCard mb-4">
                                        <div className="card-body" style={{ cursor: "pointer" }} onClick={() => navigate("/income")}>
                                            <div className="topBox">
                                                <span className="dashIcon" style={{ background: colors?.primary }}>
                                                    <GiReceiveMoney />
                                                </span>
                                                <div className="dashText" >
                                                    <p>{labelData?.total_revenue || 'Total Revenue'}</p>
                                                    {isStatisticsError && <div>Error loading statistics.</div>}
                                                    {!isStatisticsLoading && !isStatisticsError && (
                                                        <>
                                                            <h4> {new Intl.NumberFormat('en-US', {
                                                                style: 'currency',
                                                                currency: currencyGlobal && currencyGlobal?.currency_name === "Indian Rupee" ? 'INR' : 'USD'
                                                            }).format(Number(statistics?.total_revenue.replace(/,/g, '').trim()) || 0)}</h4>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <div className="card chartCard mb-4">
                                        <div className="card-body" style={{ cursor: "pointer" }} onClick={() => navigate("/expense")}>
                                            <div className="topBox">
                                                <span className="dashIcon" style={{ background: colors?.primary }}>
                                                    <GiExpense />
                                                </span>
                                                <div className="dashText">
                                                    <p>{labelData?.total_expense || 'Total Expense'}</p>
                                                    {isStatisticsError && <div>Error loading statistics.</div>}
                                                    {!isStatisticsLoading && !isStatisticsError && (
                                                        <>
                                                            <h4>
                                                                {new Intl.NumberFormat('en-US', {
                                                                    style: 'currency',
                                                                    currency: currencyGlobal && currencyGlobal?.currency_name === "Indian Rupee" ? 'INR' : 'USD'
                                                                }).format(Number(statistics?.total_expense.replace(/,/g, '').trim()) || 0)}
                                                            </h4>

                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-md-12">
                                    <div
                                        className={
                                            numberWithoutCommas < 0
                                                ? "card chartCard netLossCard"
                                                : "card chartCard netProfiteCard"
                                        }
                                    >
                                        <div className="card-body">
                                            {isStatisticsError && <div>Error loading statistics.</div>}
                                            {!isStatisticsLoading && !isStatisticsError && (
                                                <>
                                                    <h2>
                                                        {numberWithoutCommas == 0 ? 'No Profit or Loss yet' : numberWithoutCommas > 0
                                                            ? `Total Profit: ${new Intl.NumberFormat('en-US', {
                                                                style: 'currency',
                                                                currency: currencyGlobal && currencyGlobal?.currency_name === "Indian Rupee" ? 'INR' : 'USD'
                                                            }).format(Number(netProfit.replace(/,/g, '').trim()) || 0)}`
                                                            : `Total Loss:  ${new Intl.NumberFormat('en-US', {
                                                                style: 'currency',
                                                                currency: currencyGlobal && currencyGlobal?.currency_name === "Indian Rupee" ? 'INR' : 'USD'
                                                            }).format(Number(netProfitNumber.replace(/,/g, '').trim()) || 0)}`}
                                                    </h2>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-md-4">
                                    <div className="card chartCard h-100">
                                        <div className="card-header">
                                            <h5 className="card-title">
                                                {'Top Revenue Generating Properties'}
                                                {revenueProperty?.topProperties?.length > 0 ? (<><div className="export-data-wrapper d-flex justify-content-end align-items-center dashboardWrapper"><Button icon={<GrDocumentCsv />} loading={exportTopRevenuePropertiesLoader} onClick={() => handleRevenueGeneratingData()} className="export-data-button viewButton mb-3"></Button></div></>) : (<></>)}
                                            </h5>
                                        </div>
                                        <div className="card-body">
                                            <Table
                                                className={`table-bordered ${revenueLoader ? "table-loading" : ""}`}
                                                rowKey={(record) => record.id}
                                                bordered
                                                pagination={false}
                                                dataSource={revenueProperty?.topProperties}
                                                columns={revenue_columns}
                                                loading={revenueLoader}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <div className="card chartCard h-100">
                                        <div className="card-body">
                                            <ChartRevenue data={data} label={"Revenue Growth Comparison for Properties"} currency={currencyName} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row  mb-4">
                                <div className="col-md-8">
                                    <div className="card chartCard h-100">
                                        <div className="card-body">
                                            {profitProperty?.topProperties?.length > 0 ? (
                                                <ChartProfit
                                                    data={data}
                                                    label={"Top Profitable Properties"}
                                                    profitableProperty={profitProperty}
                                                    currency={currencyName}
                                                />
                                            ) : (
                                                <Empty description="No data found" />
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="card chartCard h-100">
                                        <div className="card-header">
                                            <h5 className="card-title">{'Top Profitable Properties'}</h5>
                                            {profitProperty?.topProperties?.length > 0 ? (<><div className="export-data-wrapper d-flex justify-content-end align-items-center dashboardWrapper"><Button icon={<GrDocumentCsv />} loading={profitablePropertyLoader} onClick={() => handleProfitablePropertyData()} className="export-data-button viewButton mb-3"></Button></div></>) : (<></>)}
                                        </div>
                                        <div className="card-body">
                                            <Table
                                                className={`table-bordered ${profitLoader ? "table-loading" : ""}`}
                                                rowKey={(record) => record.id}
                                                bordered
                                                pagination={false}
                                                dataSource={profitProperty?.topProperties}
                                                columns={columns}
                                                loading={profitLoader}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row  mb-4">
                                <div className="col-md-4">
                                    <div className="card chartCard h-100">
                                        <div className="card-header">
                                            <h5 className="card-title">{'Most Expenses Incurred Properties'}</h5>
                                            {expenseProperties?.length > 0 ? (<><div className="export-data-wrapper d-flex justify-content-end align-items-center dashboardWrapper"><Button icon={<GrDocumentCsv />} loading={expensivePropertyLoader} onClick={() => handleExpensiveData()} className="export-data-button viewButton mb-3"></Button></div></>) : (<></>)}
                                        </div>
                                        <div className="card-body">
                                            <Table
                                                className={`table-bordered ${profitLoader ? "table-loading" : ""}`}
                                                rowKey={(record) => record.id}
                                                bordered
                                                pagination={false}
                                                dataSource={expenseProperties}
                                                columns={expense_columns}
                                                loading={profitLoader}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <div className="card chartCard h-100">
                                        <div className="card-body">
                                            {expenseProperties?.length > 0 ? (
                                                <ExpenseBarChart data={expenseProperties} label={"Most Expenses Encord Properties"} currency={currencyName} />
                                            ) : (
                                                <Empty description="No data found" />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {parent_id === null || parent_id === undefined && (
                                <div className="row mb-4">
                                    <div className="col-md-8 mb-4">
                                        <div className="card chartCard h-100">
                                            <div className="card-body">
                                                {groupData?.length > 0 ? (
                                                    <GroupChart data={groupData} currency={currencyName} />
                                                ) : (
                                                    <Empty description="No data found" />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mb-4">
                                        <div className="card chartCard h-100">
                                            <div className="card-header">
                                                <h5 className="card-title">
                                                    {'Income Generating Groups'}
                                                    {groupData?.length > 0 ? (<><div className="export-data-wrapper d-flex justify-content-end align-items-center dashboardWrapper"><Button icon={<GrDocumentCsv />} loading={incomeGeneratingGroupsLoader} onClick={() => handleIncomeGeneratingGroupsData()} className="export-data-button viewButton mb-3"></Button></div></>) : (<></>)}
                                                </h5>
                                            </div>
                                            <div className="card-body">
                                                <Table
                                                    className={`table-bordered ${revenueLoader ? "table-loading" : ""}`}
                                                    rowKey={(record) => record.id}
                                                    bordered
                                                    pagination={false}
                                                    dataSource={groupData}
                                                    columns={GroupsColumn}
                                                    loading={topGroupLoader}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>

            <Modal
                title="Change Form Labels"
                open={isEditModal}
                onCancel={handleCancel}
                className="assign_model"
                width={700}
                footer={false}
                form={labelForm}
            >
                <div className="modalBody">
                    <Form
                        name="labelForm"
                        className="modalForm"
                        layout="vertical"
                        onFinish={updateLabels}
                        form={labelForm}
                    >
                        <div className="row">
                            <div className="col-md-6">
                                <Form.Item
                                    name="total_properties"
                                    label={capitalizeFirstLetter(labelData?.total_properties) || "Total Properties"}
                                >
                                    <Input
                                        type="text"
                                        placeholder="Enter label text here..."
                                        className="form-control"
                                    />
                                </Form.Item>
                            </div>
                            <div className="col-md-6">
                                <Form.Item
                                    name="total_expense"
                                    label={capitalizeFirstLetter(labelData?.total_expense) || "Total Expense"}
                                >
                                    <Input
                                        type="text"
                                        placeholder="Enter label text here..."
                                        className="form-control"
                                    />
                                </Form.Item>
                            </div>
                            <div className="col-md-6">
                                <Form.Item
                                    name="total_revenue"
                                    label={capitalizeFirstLetter(labelData?.total_revenue) || "Total Revenue"}
                                >
                                    <Input
                                        type="text"
                                        placeholder="Enter label text here..."
                                        className="form-control"
                                    />
                                </Form.Item>
                            </div>
                            <div className="col-md-6">
                                <Form.Item
                                    name="top_5_revenue"
                                    label={"Revenue Generating Properties"}
                                >
                                    <Input
                                        type="text"
                                        placeholder="Enter label text here..."
                                        className="form-control"
                                    />
                                </Form.Item>
                            </div>
                            <div className="col-md-6">
                                <Form.Item
                                    name="revenue_growth_comparison"
                                    label={"Revenue Growth Comparison for Properties"}
                                >
                                    <Input
                                        type="text"
                                        placeholder="Enter label text here..."
                                        className="form-control"
                                    />
                                </Form.Item>
                            </div>
                            <div className="col-md-6">
                                <Form.Item
                                    name="Top_5_profitable_table"
                                    label={"Profitable Properties"}
                                >
                                    <Input
                                        type="text"
                                        placeholder="Enter label text here..."
                                        className="form-control"
                                    />
                                </Form.Item>
                            </div>
                            <div className="col-md-6">
                                <Form.Item
                                    name="Top_5_profitable_chart"
                                    label={"Profitable Properties"}
                                >
                                    <Input
                                        type="text"
                                        placeholder="Enter label text here..."
                                        className="form-control"
                                    />
                                </Form.Item>
                            </div>
                            <div className="col-md-12 text-center mt-4">
                                <Form.Item>
                                    <Button type="primary" htmlType="submit" className="authBtn" disabled={isSaving}>
                                        {isLoadingUpdate ? "Loading.." : "Save"}
                                    </Button>
                                </Form.Item>
                            </div>
                        </div>
                    </Form>
                </div>
            </Modal>
        </>
    )
}

export default Dashboard;
