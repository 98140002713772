import React, { useEffect, useState } from "react";
import { Button, Modal, Table, Tooltip, Pagination, Spin, Input } from "antd";
import { IoIosAddCircleOutline } from "react-icons/io";
import Title from "../../component/PageTitle";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import { FaEdit } from "react-icons/fa";
import { HiOutlineTrash } from "react-icons/hi2";
import { useDeleteCompanyWithExpenseMutation } from "../../slices/apiSlice";
import { RxCrossCircled } from "react-icons/rx";
import { useSelector } from "react-redux";
import { useGetNewCompanyListQuery } from "../../slices/getSlice";
import { toast } from "react-toastify";
import dayjs from 'dayjs';
import TopHeader from "../../component/Header";
import { HomeOutlined } from '@ant-design/icons';
import { Breadcrumb } from 'antd';
import PageSizeOptionsHelper from "../../Helper/PageSizeOptionsHelper";
import { saveAs } from 'file-saver';
import { GrDocumentCsv } from "react-icons/gr";

const AllCompanies = () => {
    const [pageSize, setPageSize] = useState(20)
    const [exportPageSize, setExportPageSize] = useState(null)
    const [currentPage, setCurrentPage] = useState(1);
    const [searchText, setSearchText] = useState(null);
    const [debouncedSearch, setDebouncedSearch] = useState("");
    const [tableSortBy, setTableSortBy] = useState(null);
    const [columnName, setColumnName] = useState(null);
    // const userData = useSelector((state) => state.persistedReducer);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState('');
    const { loading, setLoading } = useOutletContext();
    const colors = useSelector((state) => state?.persistedReducer?.colors);
    const navigate = useNavigate();
    const [companyListing, setCompanyListing] = useState([]);
    const [totalCompany, setTotalCompany] = useState(0);
    const [deleteCompany, { isLoading: isLoadingDeleteCompany }] = useDeleteCompanyWithExpenseMutation();
    const startDate = useSelector((state) => state.date.startDate);
    const endDate = useSelector((state) => state.date.endDate);
    const [isLoadingTable, setIsLoadingTable] = useState(false);
    const [localLoading, setLocalLoading] = useState(false);
    const [exportLoader, setExportLoader] = useState(false);

    const {
        data: getCompanyList,
        refetch: refetchCompanyList,
        isLoading: isLoadingCompany,
        isError: isPropertiesError
    } = useGetNewCompanyListQuery({
        page: currentPage,
        per_page: exportPageSize ? exportPageSize : pageSize,
        ...(debouncedSearch && { search: debouncedSearch }),
        ...(tableSortBy && columnName && { sort_direction: tableSortBy, sort_by: columnName }),
        ...(startDate && { start_date: startDate }),
        ...(endDate && { end_date: endDate })
    });

    useEffect(() => {
        if (getCompanyList) {
            setIsLoadingTable(false);
            setLoading(false);
            setCompanyListing(getCompanyList?.data);
            setTotalCompany(getCompanyList?.meta?.total);
        }
    }, [getCompanyList]);

    const pageSizeOptions = PageSizeOptionsHelper(totalCompany);

    const fetchData = async () => {
        setLocalLoading(true);
        await refetchCompanyList();
        setLocalLoading(false);
    };

    useEffect(() => {
        fetchData()
    }, [currentPage, debouncedSearch, refetchCompanyList, columnName, tableSortBy, startDate, endDate]);

    const handleOk = async () => {
        try {
            await deleteCompany(selectedRecord?.id);
            toast.success("Company deleted successfully!");
            refetchCompanyList(`?page=${currentPage}&per_page=${pageSize}${debouncedSearch ? `&search=${debouncedSearch}` : ''}`);
            setIsModalOpen(false);
        } catch (error) {
            console.error("Error deleting company:", error);
            toast.error("Failed to delete company!");
        }
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setSelectedRecord('');
    };

    const handleChangePage = (page, size) => {
        setIsLoadingTable(true);
        setPageSize(size)
        setCurrentPage(page);
    };

    const Columns = [
        {
            title: "S.No.",
            dataIndex: "serial",
            render: (_, record, index) => (
                <span style={{ display: "flex", alignItems: "center", gap: "5px", justifyContent: 'space-evenly' }}>
                    {(currentPage - 1) * pageSize + index + 1} {" "}
                    <span className="plaidLogo">
                        {record?.from_plaid && 'Plaid'}
                    </span>
                </span>
            )
        },
        {
            title: "Name",
            dataIndex: "name",
            render: (_, record) => (
                <>
                    <div onClick={() => navigate(`/edit_company/${record.id}`, { state: record })} style={{ cursor: 'pointer' }}>
                        {record?.name}
                    </div>
                </>
            ),
            sorter: true,
        },
        {
            title: "Category",
            dataIndex: "category",
            render: (_, record) => (
                <>
                    {record?.category ? (
                        record?.category?.parent_category + ' - ' + record?.category?.name
                    ) : (
                        'NA'
                    )}
                </>
            ),
            sorter: true,
        },
        {
            title: "Created On",
            dataIndex: "created_at",
            render: (created_at) => dayjs(created_at).format("MM-DD-YYYY"),
            sorter: true,
        },
        {
            title: "Action",
            dataIndex: "id",
            render: (_, record) => (
                <div className="action-container">
                    <Tooltip title="Edit">
                        <button className="btn editButton me-2" onClick={() => navigate(`/edit_company/${record.id}`, { state: record })}>
                            <FaEdit className="iconStroke" />
                        </button>
                    </Tooltip>
                    <Tooltip title="Delete">
                        <button className="btn deleteButton me-2" onClick={() => {
                            setSelectedRecord(record);
                            setIsModalOpen(true);
                        }}>
                            <HiOutlineTrash className="iconStroke" />
                        </button>
                    </Tooltip>
                </div>
            ),
        },
    ];

    const handleSearchChange = (e) => {
        setCurrentPage(1)
        setSearchText(e.target.value || null);
    };

    useEffect(() => {
        if (searchText !== "" && searchText !== undefined && searchText !== null) {
            const handler = setTimeout(() => {
                setDebouncedSearch(searchText);
            }, 1500);

            return () => clearTimeout(handler);
        } else {
            setDebouncedSearch(searchText);
        }
    }, [searchText]);

    useEffect(() => {
        if (exportPageSize) {
            handleCompanyDataExport()
        }
    }, [exportPageSize])

    const handleCompanyDataExport = async () => {
        setExportLoader(true);
        try {
            const response = await refetchCompanyList();
            const companies = response?.data?.data || [];

            const csvData = companies.map((company, index) => {
                const categoryName = `${company?.category?.parent_category} - ${company?.category?.name || ''}`;
                return {
                    "S.NO": index + 1,
                    "Company Name": company?.name,
                    "Category": categoryName,
                    "Created On": dayjs(company.created_at).format("MM-DD-YYYY"),
                };
            });

            const csvRows = [
                ['S.NO', 'Company Name', 'Category', 'Created On'],
                ...csvData.map(item => [
                    item['S.NO'],
                    item["Company Name"],
                    item["Category"],
                    item["Created On"],
                ])
            ];

            const csvString = csvRows.map(row => row.join(',')).join('\n');
            const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
            saveAs(blob, 'comapnies.csv');
        } catch (error) {
            console.error("Error exporting data: ", error);
        }
        setExportLoader(false);
        setExportPageSize(null)
    };


    return (
        <>
            {loading && (
                <div className="commanLoaderWrapper">
                    <Spin size="large" />
                    <h4>Setting things up..</h4>
                </div>
            )}
            <TopHeader
                title={<Title title="Companies" id='companies' />}
                search={
                    <Input.Search
                        placeholder="Search..."
                        allowClear
                        value={searchText}
                        onChange={handleSearchChange}
                        className="tableSearchInput"
                    />
                }
                button={
                    <Button className="ms-3 customHeaderBtn step-one" type="primary" style={{ backgroundColor: colors?.primary || "#F47A3A" }}>
                        <Link to={"/add_company"} className="link">
                            {"Add new"}{" "}
                            <IoIosAddCircleOutline />
                        </Link>
                    </Button>
                }
            />
            <div className="customPadding">
                <div className="breadcrumbWrapper">
                    <Breadcrumb
                        items={[
                            {
                                title: (
                                    <span onClick={() => navigate('/dashboard')} style={{ cursor: 'pointer' }}>
                                        <HomeOutlined />
                                    </span>
                                ),
                            },
                            {
                                title: (
                                    <>
                                        <span>Companies</span>
                                    </>
                                ),
                            },
                        ]}
                    />
                </div>
                <div className="mainWrapper">
                    <div className="card titleCard mb-4">
                        <div className="card-body-wrapper">
                            {isPropertiesError || totalCompany < 0 ? (
                                <h4>No data found</h4>
                            ) : (
                                <div className="tableResponsives">
                                    <>
                                        {totalCompany > 0 ? (<><div className="export-data-wrapper d-flex justify-content-end align-items-center"><Button icon={<GrDocumentCsv />} loading={exportLoader} onClick={() => setExportPageSize(totalCompany)} className="export-data-button viewButton mb-3">Export</Button></div></>) : (<></>)}
                                        <Table
                                            className={`tableNew table-bordered step-two ${isLoadingTable ? "table-loading" : ""}`}
                                            rowKey={(record) => record.id}
                                            bordered={false}
                                            pagination={false}
                                            dataSource={companyListing}
                                            columns={Columns}
                                            loading={isLoadingTable || localLoading}
                                            scroll={{ x: "100%" }}
                                            onChange={(pagination, filters, sorter) => {
                                                if (sorter.order) {
                                                    const columnTitle = Columns.find(col => col.dataIndex === sorter.field)?.dataIndex;
                                                    setColumnName(columnTitle)

                                                    if (sorter.order === "ascend") {
                                                        setTableSortBy("asc")
                                                    } else {
                                                        setTableSortBy("desc")
                                                    }
                                                }
                                            }}
                                        />
                                        {totalCompany > 20 ? (
                                            <Pagination
                                                className="mt-4"
                                                current={currentPage}
                                                pageSize={pageSize}
                                                total={totalCompany}
                                                onChange={handleChangePage}
                                                pageSizeOptions={pageSizeOptions}
                                                showSizeChanger
                                                locale={{
                                                    items_per_page: `/${totalCompany || 0} Records`,
                                                }}
                                            />
                                        ) : (<></>)}
                                    </>
                                </div>
                            )}
                        </div>
                    </div>

                    <Modal
                        className="deleteModal"
                        title="Delete Company"
                        open={isModalOpen}
                        onCancel={handleCancel}
                        footer={false}
                    >
                        <>
                            <div className="iconRow">
                                <RxCrossCircled />
                            </div>
                            <div className="textRow">
                                <h3>Are you sure?</h3>
                                <p>If you delete this company, all associated expenses will be permanently removed. Are you sure you want to proceed?</p>
                            </div>
                            <div className="buttonRow companyBtnRow" style={{ marginTop: '1.5rem' }}>
                                <Button className="btnFilled text-white" style={{ color: 'white', marginRight: '1rem' }} onClick={handleOk} loading={isLoadingDeleteCompany}>Delete</Button>
                                <Button className="btnOutlined me-2" onClick={handleCancel}>Cancel</Button>
                            </div>
                        </>
                    </Modal>

                </div>
            </div>
        </>
    );
};

export default AllCompanies;
