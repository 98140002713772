import { Button, Form, Input, Modal, Spin } from "antd";
import Title from "../../component/PageTitle";
import { PiHouseLine } from "react-icons/pi";
import IncomeForm from "../../component/Form/IncomeForm";
import {
    useAddIncomeTypeMutation,
    useAddIncomeMutation,
    useEditIncomeMutation,
    useAddPaymentMethodMutation
} from "../../slices/apiSlice";
import { toast } from "react-toastify";
import {
    useGetAllPaymentDataQuery,
    useGetPropertiesQuery,
    useGetLabelsQuery,
    useGetPaymentMethodsQuery,
    useGetIncomeNameQuery
} from "../../slices/getSlice";
import { useEffect, useState } from "react";
import { SlCalender } from "react-icons/sl";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import TopHeader from "../../component/Header";
import { HomeOutlined } from '@ant-design/icons';
import { Breadcrumb } from 'antd';
import {
    RiStickyNoteLine,
} from "react-icons/ri";
import { MdOutlinePayment } from "react-icons/md";
import { FaHandHoldingDollar, FaRegCalendarCheck } from "react-icons/fa6";
import { GiMoneyStack } from "react-icons/gi";

const AddIncome = () => {
    const [form] = Form.useForm();
    const [labelForm] = Form.useForm();
    const { loading, setLoading } = useOutletContext();
    const navigate = useNavigate();
    const location = useLocation();
    const [property, setProperty] = useState();
    const [category, setCategory] = useState();
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [newDropdownOpen, setNewDropdownOpen] = useState(false);
    const [incomeDropdownOpen, setIncomeNameDropdown] = useState(false);
    const [newlyAddedIncome, setNewlyAddedIncome] = useState(null);
    const [IncomeNameDataList, setIncomeNameDataList] = useState([]);
    const [newlyAddedPaymentMethod, setNewlyAddedPaymentMethod] = useState(null);
    let FormInitialValues = location?.state;

    const [isSaving, setIsSaving] = useState(false);

    const {
        data: getProperties,
        refetch: refetchProperties,
    } = useGetPropertiesQuery();

    useEffect(() => {
        refetchProperties();
    }, [getProperties]);

    const { data: labelData, refetch: refetchLabels } = useGetLabelsQuery('income_form');
    const { data: paymentMethodData, refetch: refetchPaymentMethods } = useGetPaymentMethodsQuery();
    const { data: incomeNameData, refetch: refetchIncomeName } = useGetIncomeNameQuery();
    const { data: getCategory, refetch: refetchCategory } = useGetAllPaymentDataQuery('income');
    const [addIncome, { isLoading: isLoadingAddincome }] = useAddIncomeMutation();
    const [editIncome, { isLoading: isLoadingEditincome }] = useEditIncomeMutation();
    const [addPaymentMethod, { isLoading: paymentTypeLoading }] = useAddPaymentMethodMutation();
    const [addIncomeType, { isLoading: incomeTypeLoading }] = useAddIncomeTypeMutation();

    const capitalizeFirstLetter = (string) => {
        return string?.charAt(0)?.toUpperCase() + string?.slice(1);
    };

    useEffect(() => {
        labelForm.setFieldsValue(labelData)
    }, [labelData]);

    useEffect(() => {
        if (paymentMethodData) {
            const transformedOptions = paymentMethodData?.data.map((item) => ({
                label: item.payment_mode,
                value: item.id,
            }));
            setPaymentMethods(transformedOptions);
        }

    }, [paymentMethodData]);

    const FrequencyOptions = [
        { label: "Daily", value: "daily" },
        { label: "Weekly", value: "weekly" },
        { label: "Bi-Weekly", value: "biweekly" },
        { label: "Monthly", value: "monthly" },
        { label: "Yearly", value: "yearly" },
    ];

    const field = [
        {
            component: "select",
            label: capitalizeFirstLetter(labelData?.Property) || "Property",
            name: "property_id",
            placeholder: "Please select property",
            suffixIcon: (
                <PiHouseLine style={{ color: "#F47939", fontSize: "25px" }} />
            ),
            rules: [
                {
                    required: true,
                    message: "Please select property ",
                },
            ],
            options: property,
            colSpan: 12,
        },
        {
            component: "select",
            label: capitalizeFirstLetter(labelData?.type_of_income) || "Type of income",
            name: "category_id",
            placeholder: "Select Income or start typing to create new",
            suffixIcon: (
                <FaHandHoldingDollar style={{ color: "#F47939", fontSize: "25px" }} />
            ),
            rules: [
                {
                    required: true,
                    message: "Please select type of income",
                },
            ],
            options: category,
            colSpan: 12,
        },
        {
            component: "select",
            label: capitalizeFirstLetter(labelData?.payment_method) || "Income Type",
            name: "payment_mode_id",
            placeholder: "Select Income Type or start typing to create new",
            suffixIcon: (
                <MdOutlinePayment style={{ color: "#F47939", fontSize: "25px" }} />
            ),
            rules: [
                {
                    required: true,
                    message: "Please select income mode.",
                },
            ],
            options: paymentMethods,
            colSpan: 12,
        },
        {
            component: "input",
            type: "number",
            label: capitalizeFirstLetter(labelData?.amount) || "Amount",
            name: "amount",
            placeholder: "Enter Amount",
            prefix: (
                <GiMoneyStack
                    style={{ color: "#F47939", fontSize: "25px" }}
                />
            ),
            onKeyPress: (e) => {
                if (!/[0-9.]/.test(e.key) || (e.key === '.' && e.target.value.includes('.'))) {
                    e.preventDefault();
                }
            },
            onPaste: (e) => {
                const paste = (e.clipboardData || window.clipboardData).getData("text");
                if (!/^\d*\.?\d*$/.test(paste)) {
                    e.preventDefault();
                }
            },
            rules: [
                {
                    required: true,
                    message: "Please enter amount.",
                },
                {
                    pattern: /^(0|[1-9]\d*)(\.\d+)?$/,
                    message: 'Amount must be a positive number',
                },
            ],
            colSpan: 12,
        },
        {
            component: "input",
            type: "date",
            label: "Income Date",
            name: "start_date",
            placeholder: "Select income date",
            prefix: (
                <SlCalender
                    style={{ color: "#F47939", fontSize: "25px", height: "20px" }}
                />
            ),
            rules: [
                {
                    required: true,
                    message: "Please input your income date",
                },
            ],
            colSpan: 12,
        },
        // {
        //     component: "input",
        //     type: "date",
        //     label: capitalizeFirstLetter(labelData?.end_date) || "Next Payment Date",
        //     name: "end_date",
        //     placeholder: "Select next payment date ",
        //     prefix: (
        //         <SlCalender
        //             style={{ color: "#F47939", fontSize: "25px", height: "20px" }}
        //         />
        //     ),
        //     rules: [
        //         {
        //             required: true,
        //             message: "Please input your end date",
        //         },
        //     ],
        //     colSpan: 12,
        // },
        {
            component: "select",
            label: capitalizeFirstLetter(labelData?.frequency) || "Frequency",
            name: "frequency",
            placeholder: "Select Frequency",
            suffixIcon: (
                <FaRegCalendarCheck style={{ color: "#F47939", fontSize: "25px" }} />
            ),
            rules: [
                {
                    required: true,
                    message: "Please select frequency of income",
                },
            ],
            options: FrequencyOptions,
            colSpan: 12,
        },
        {
            component: "input",
            type: "text",
            label: capitalizeFirstLetter(labelData?.notes) || "Income Name",
            name: "notes",
            placeholder: "Enter Income Name",
            prefix: (
                <RiStickyNoteLine
                    style={{ color: "#F47939", fontSize: "25px", height: "20px" }}
                />
            ),
            rules: [
                {
                    required: true,
                    message: "Please enter income name.",
                },
            ],
            colSpan: 12,
        },
    ];

    useEffect(() => {
        if (getProperties && getProperties.data) {
            const transformedOptions = getProperties.data.map((item) => ({
                label: item.name_of_property,
                value: item.id,
            }));
            setLoading(false)
            setProperty(transformedOptions);
        }
        if (getCategory && getCategory.data) {
            const transformedOptions = getCategory.data.map((item) => ({
                label: item.name,
                value: item.id,
            }));
            setCategory(transformedOptions);
        }

    }, [getProperties, getCategory]);

    useEffect(() => {
        refetchIncomeName();
    }, []); 
    
    useEffect(() => {
        if (incomeNameData) {
            setIncomeNameDataList(incomeNameData?.data);
        }
    }, [incomeNameData]);

    const onFinish = async (values) => {
        setIsSaving(true)
        try {
            let id = location?.state?.id;
            const value = {
                property_id: values?.property_id
                    ? values?.property_id
                    : location?.state?.propertyId,

                category_id: values?.category_id
                    ? values?.category_id
                    : location?.state?.categoryId,
                amount: values?.amount
                    ? values?.amount
                    : location?.state?.amount,
                start_date: values?.start_date
                    ? values?.start_date
                    : location?.state?.start_date,
                // end_date: values?.end_date ? values?.end_date : location?.state?.end_date,
                frequency: values?.frequency ? values?.frequency : location?.state?.frequency ? location?.state?.frequency : null,
                notes: values?.notes ? values?.notes : location?.state?.notes ? location?.state?.notes : null,
                payment_mode_id: values?.payment_mode_id ? values?.payment_mode_id : location?.state?.payment_mode_id ? location?.state?.payment_mode_id : null
            };

            if (value.end_date !== null) {
                if (value.start_date > value.end_date) {
                    toast.error("Start date should be less than end date");
                    return false;
                }
            }

            if (value?.category_id !== 2) {
                value.end_date = null;
                value.frequency = null;
            }

            if (id) {
                if (typeof value.category_id == "string") {
                    let categoryData = category.find(
                        (cat) => cat.label == value.category_id
                    );
                    value.category_id = categoryData.value;
                }
                if (typeof value.property_id == "string") {
                    let proData = property.find((pro) => pro.label == value.property_id);
                    value.property_id = proData.value;
                }

                const response = await editIncome({ data: value, id });

                if (response.data) {
                    toast.success("Income updated!");
                    form.resetFields();
                    navigate("/income");
                } else {
                    toast.error(`${response?.error?.data?.message}`);
                }
            } else {
                const response = await addIncome(values);
                if (response.data) {
                    toast.success("Income added!");
                    form.resetFields();
                    navigate("/income");
                } else {
                    toast.error(`${response?.data?.message}`);
                }
            }
        } catch {
        } finally {
            setIsSaving(false)
        }
    };

    const addNewIncome = async (value) => {
        setIsSaving(true)
        const formData = {
            category_of: 'income',
            name: value
        }
        try {
            const response = await addIncomeType(formData);
            if (response.data) {
                toast.success("Income Type has been created successfully");
                let newList = await refetchCategory();
                const transformedOptions = newList.data.data.map((item) => ({
                    label: item.name,
                    value: item.id,
                }));
                setCategory(transformedOptions);
                let newIncome = transformedOptions?.find((item) => item?.label === value);
                setNewlyAddedIncome(newIncome?.value);
                setDropdownOpen(false);
            } else {
                toast.error(`${response?.error?.data?.message}`);
            }
        } catch (error) {

        } finally {
            setIsSaving(false)
        }
    }

    const handleAddPaymentType = async (value) => {
        setIsSaving(true);
        const formData = {
            payment_mode: value,
        }
        try {
            const response = await addPaymentMethod(formData);
            if (response.data) {
                toast.success("New payment method added!");
                let newList = await refetchPaymentMethods();
                const transformedOptions = newList.data.data.map((item) => ({
                    label: item.payment_mode,
                    value: item.id,
                }));
                setPaymentMethods(transformedOptions);
                let newPayment = transformedOptions?.find((item) => item?.label === value);
                setNewlyAddedPaymentMethod(newPayment?.value);
                setNewDropdownOpen(false);
            } else {
                toast.error(`${response?.error?.data?.message}`);
            }
        } catch (error) {

        } finally {
            setIsSaving(false)
        }
    }

    return (
        <>
            {loading && (
                <div className="commanLoaderWrapper">
                    <Spin size="large" />
                    <h4>Setting things up..</h4>
                </div>
            )}
            <TopHeader
                title={
                    location?.state?.id ? (
                        <Title title="Edit Income" />
                    ) : (
                        <Title title="Add Income" />
                    )
                }
            />
            <div className="customPadding">
                <div className="breadcrumbWrapper">
                    <Breadcrumb
                        items={[
                            {
                                title: (
                                    <span onClick={() => navigate('/dashboard')} style={{ cursor: 'pointer' }}>
                                        <HomeOutlined />
                                    </span>
                                ),
                            },
                            {
                                title: (
                                    <>
                                        <span>Income</span>
                                    </>
                                ),
                            },
                            {
                                title: (
                                    <>
                                        <span>{location?.state?.id ? 'Edit' : 'Add'} Income</span>
                                    </>
                                ),
                            },
                        ]}
                    />
                </div>
                <div className="mainWrapper">
                    <div className="card tableCard">
                        <div className="card-body">
                            <IncomeForm
                                form={form}
                                fields={field}
                                onFinish={onFinish}
                                buttonName={location?.state?.id ? "Save" : "Add"}
                                FormInitialValues={FormInitialValues}
                                ButtonDisable={isSaving}
                                loading={location?.state?.id ? isLoadingEditincome : isLoadingAddincome}
                                addNewIncome={addNewIncome}
                                dropdownOpen={dropdownOpen}
                                setDropdownOpen={setDropdownOpen}
                                newlyAddedIncome={newlyAddedIncome}
                                incomeTypeLoading={incomeTypeLoading}
                                handleAddPaymentType={handleAddPaymentType}
                                paymentTypeLoading={paymentTypeLoading}
                                newlyAddedPaymentMethod={newlyAddedPaymentMethod}
                                newDropdownOpen={newDropdownOpen}
                                setNewDropdownOpen={setNewDropdownOpen}
                                IncomeNameDataList={IncomeNameDataList}
                                incomeDropdownOpen={incomeDropdownOpen}
                                setIncomeNameDropdown={setIncomeNameDropdown}
                            />
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
};

export default AddIncome;
