import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import exporting from 'highcharts/modules/exporting';
import exportData from 'highcharts/modules/export-data';
import offlineExporting from 'highcharts/modules/offline-exporting';
import { Spin, Alert } from 'antd';
import { useSelector } from 'react-redux';
import { useLazyGetRevenueGrowthComparisonQuery } from '../slices/getSlice';
import dayjs from 'dayjs';

exporting(Highcharts);
exportData(Highcharts);
offlineExporting(Highcharts);

const ChartRevenue = ({ data, label, currency }) => {
    const start_Date = useSelector((state) => state.date.startDate);
    const end_Date = useSelector((state) => state.date.endDate);
    const [revenueGrowthData, setRevenueGrowthData] = useState([]);
    const [fetchRevenueGrowthComparisonData, { data: revenueGrowthComparisonData, isLoading, isError }] = useLazyGetRevenueGrowthComparisonQuery();
    const colors = useSelector((state) => state?.persistedReducer?.colors);

    const formatDate = (date) => date ? dayjs(date, "MM/DD/YYYY").format("YYYY-MM-DD") : null;

    const currencyCodeMap = {
        "Indian Rupee": "INR",
        "US Dollar": "USD",
        "Euro": "EUR",
        "British Pound": "GBP",
        "Japanese Yen": "JPY",
    };
    const currencyCode = currencyCodeMap[currency?.currency_name] || "USD";
    const currencySymbol = currency?.currency_symbol || '$';

    useEffect(() => {
        fetchRevenueGrowthComparisonData({
            filter_type: data.filter_type,
            filter_value: data.filter_value,
            property_ids: data.property_ids?.trim() ? data.property_ids : null,
            start_Date: start_Date ? formatDate(start_Date) : null,
            end_Date: end_Date ? formatDate(end_Date) : null
        });
    }, [data, start_Date, end_Date]);

    useEffect(() => {
        if (revenueGrowthComparisonData) setRevenueGrowthData(revenueGrowthComparisonData);
    }, [revenueGrowthComparisonData]);

    if (isLoading) {
        return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <Spin />
        </div>;
    }

    if (isError) {
        return <Alert message="Error loading data" type="error" />;
    }

    const formatAmount = (amount) => {
        if (amount >= 1_000_000_000) return `${currencySymbol}${(amount / 1_000_000_000).toFixed(1)}B`;
        if (amount >= 1_000_000) return `${currencySymbol}${(amount / 1_000_000).toFixed(1)}M`;
        if (amount >= 1_000) return `${currencySymbol}${(amount / 1_000).toFixed(1)}K`;
        return `${currencySymbol}${amount}`;
    };

    const RevenueChart = (data, title) => {
        const propertyNames = [...new Set(data?.filter(item => item.revenue > 0).map(item => item.property_name))];
        const months = [...new Set(data?.map(item => item.month))];

        const series = months.map(month => ({
            name: month,
            data: propertyNames.map(property => {
                const propertyData = data.find(item => item.property_name === property && item.month === month);
                return propertyData ? propertyData.revenue : 0;
            })
        }));

        return {
            chart: { type: 'bar' },
            title: { text: title, style: { color: colors?.secondary || '#000', fontSize: '16px' } },
            xAxis: { categories: propertyNames },
            yAxis: {
                min: 0,
                title: { text: `Total Revenue (${currencySymbol})` },
                labels: { formatter: function () { return formatAmount(this.value); } }
            },
            credits: { enabled: false },
            tooltip: {
                shared: true,
                formatter: function () {
                    let tooltip = `<b>${this.x}</b><br/>`;
                    this.points.forEach(point => {
                        tooltip += `<span style="color:${point.color}">\u25CF</span> ${point.series.name}: 
                                    <b>${formatAmount(point.y)}</b><br/>`;
                    });
                    return tooltip;
                }
            },
            plotOptions: {
                series: {
                    stacking: 'normal',
                    dataLabels: {
                        enabled: true,
                        align: 'right',
                        formatter: function () { return formatAmount(this.y); }
                    }
                },
                bar: { dataLabels: { enabled: false } }
            },
            exporting: {
                enabled: true,
                buttons: {
                    contextButton: {
                        menuItems: [
                            'viewFullscreen',
                            'printChart',
                            'separator',
                            'downloadPNG',
                            'downloadJPEG',
                            'downloadPDF',
                            'downloadSVG',
                            'separator',
                            'downloadCSV',
                            'downloadXLS'
                        ]
                    }
                }
            },
            series: series
        };
    };

    const options = RevenueChart(revenueGrowthData?.data, label);

    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
        />
    );
};

export default ChartRevenue;
