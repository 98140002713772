import React, { useEffect, useState } from "react";
import { Button, Modal, Table, Tooltip, Pagination, Spin, Input, Alert, Empty, Typography } from "antd";
import { IoIosAddCircleOutline } from "react-icons/io";
import Title from "../../component/PageTitle";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import { LuEye } from "react-icons/lu";
import { FaEdit } from "react-icons/fa";
import { HiOutlineTrash } from "react-icons/hi2";
import { useGetPropertiesQuery } from "../../slices/getSlice";
import { useDeletePropertyMutation } from "../../slices/apiSlice";
import { RxCrossCircled } from "react-icons/rx";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import TopHeader from "../../component/Header";
import { HomeOutlined } from '@ant-design/icons';
import { Breadcrumb } from 'antd';
import PageSizeOptionsHelper from "../../Helper/PageSizeOptionsHelper";
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import { GrDocumentCsv } from "react-icons/gr";
import { HiOutlineDocumentText } from "react-icons/hi";
import {
    useActivityLogsMutation,
} from "../../slices/apiSlice";

const AllProperties = () => {
    const [pageSize, setPageSize] = useState(20)
    const userData = useSelector((state) => state.persistedReducer);
    const parent_id = userData?.user?.user?.parent_id;
    const [deleteproperty, { }] = useDeletePropertyMutation()
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedRecord, setSelectedRecord] = useState('');
    const { loading, setLoading } = useOutletContext();
    const colors = useSelector((state) => state?.persistedReducer?.colors);
    const navigate = useNavigate();
    const [searchText, setSearchText] = useState(null);
    const [tableSortBy, setTableSortBy] = useState(null);
    const [columnName, setColumnName] = useState(null);
    const [totalProperties, setTotalProperties] = useState(0);
    const startDate = useSelector((state) => state.date.startDate);
    const endDate = useSelector((state) => state.date.endDate);
    const selectedProperties = useSelector((state) => state.Property.selectedProperties);
    const [isFetchingData, setIsFetchingData] = useState(false);
    const [localLoading, setLocalLoading] = useState(false);
    const [exportLoader, setExportLoader] = useState(false);
    const [exportPageSize, setExportPageSize] = useState(null);
    const [debouncedSearch, setDebouncedSearch] = useState("");
    const [getActivityLogs, { isLoading: loadingActivity }] = useActivityLogsMutation();
    const [ActivityModel, setActivityModel] = useState(false);
    const [ActivityModelData, setActivityModelData] = useState([]);

    const currencyGlobal = useSelector(
        (state) => state?.persistedReducer?.currency
    );
    let UserID = userData?.currency?.userID;

    const queryParams = [
        `page=${debouncedSearch || (selectedProperties?.selectedProperty?.length > 0) ? 1 : currentPage}`,
        `per_page=${exportPageSize ? exportPageSize : pageSize}`,
        debouncedSearch ? `search=${debouncedSearch}` : "",
        tableSortBy && columnName ? `sort_direction=${tableSortBy}&sort_by=${columnName}` : "",
        startDate ? `start_date=${startDate}` : "",
        endDate ? `end_date=${endDate}` : "",
        selectedProperties?.selectedProperty?.length > 0 ? `property_ids=${selectedProperties.selectedProperty.join(",")}` : ""
    ]
        .filter(Boolean)
        .join("&");

    const {
        data: getProperties,
        isLoading: isPropertiesLoading,
        isError: isPropertiesError,
        error: propertyError,
        refetch
    } = useGetPropertiesQuery(`?${queryParams}`);

    const pageSizeOptions = PageSizeOptionsHelper(getProperties?.meta?.total);

    useEffect(() => {
        if (getProperties) {
            setTotalProperties(getProperties?.meta?.total)
            setLoading(false)
        }
        refetch();
    }, [getProperties, selectedProperties]);

    const fetchData = async () => {
        setLocalLoading(true);
        await refetch(`?page=${currentPage}&per_page=${pageSize}${debouncedSearch ? `&search=${debouncedSearch}` : ''}`)
            .finally(() => setIsFetchingData(false));
        setLocalLoading(false);
    };

    useEffect(() => {
        setIsFetchingData(true);
        fetchData()
    }, [currentPage, debouncedSearch, selectedProperties, startDate, endDate]);


    const handleClick = (record) => {
        navigate("/view_property", {
            state: { propertyData: record },
        });
    };

    const handleOk = async () => {
        const res = await deleteproperty(selectedRecord);
        if (res?.data?.message) {
            refetch(`?page=${currentPage}&per_page=${pageSize}${debouncedSearch ? `&search=${debouncedSearch}` : ''}`);
            setIsModalOpen(false);
            toast.success(res?.data?.message)
        }
        else {
            toast.error("Not able to delete")
        }
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setSelectedRecord('');
    };

    const handleChangePage = async (page, size) => {
        setCurrentPage(page);
        setPageSize(size)
        setIsFetchingData(true);
        await refetch(`?page=${page}&per_page=${pageSize}${debouncedSearch ? `&search=${debouncedSearch}` : ''}`);
        setIsFetchingData(false);
    };

    const handleLogClick = async (ID) => {
        if (!ID) {
            console.error("Invalid ID");
            return;
        }

        setActivityModel(true);

        try {
            let activityData = await getActivityLogs(ID);

            if (activityData && activityData.data && activityData.data.data) {
                setActivityModelData(activityData.data.data);
            } else {
                console.error("Invalid response data format:", activityData);
            }
        } catch (error) {
            console.error("Error fetching activity logs:", error);
        }
    };

    const Columns = [
        {
            title: "S.No.",
            dataIndex: "serial",
            render: (_, __, index) => (currentPage - 1) * pageSize + index + 1,
            sorter: false,
        },
        {
            title: "Property Name",
            dataIndex: "name_of_property",
            render: (_, record) => (
                <div onClick={() => handleClick(record)} style={{ cursor: 'pointer' }}>
                    <h6 className="mb-1">{record?.name_of_property}</h6>
                    <small className="textSecondary">{record?.property_type_name?.provider_name}</small>
                </div>
            ),
            sorter: true,
        },
        {
            title: "Property Managers",
            dataIndex: "managers",
            render: (_, record) => (
                <>
                    {record?.manager_properties_users?.map((user, index) => (
                        <div key={index}>
                            <h6 className="mb-1">
                                {user.user_name} - <small className="textSecondary">{user.ownership_type_name}</small>
                            </h6>
                        </div>
                    ))}
                </>
            ),
            sorter: false,
        },
        {
            title: "Total Income",
            dataIndex: "total_income",
            render: (value) =>
                new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: currencyGlobal?.currency_name === "Indian Rupee" ? 'INR' : 'USD',
                }).format(value),
            sorter: true,
        },
        {
            title: "Total Expense",
            dataIndex: "total_expense",
            render: (value) =>
                new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: currencyGlobal?.currency_name === "Indian Rupee" ? 'INR' : 'USD',
                }).format(value),
            sorter: true,
        },
        {
            title: "Total Profit/Loss",
            dataIndex: "profit",
            render: (value) =>
                new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: currencyGlobal?.currency_name === "Indian Rupee" ? 'INR' : 'USD',
                }).format(value),
            sorter: true,
        },
        {
            title: "Created On",
            dataIndex: "created_at",
            render: (created_at) => dayjs(created_at).format("MM-DD-YYYY"),
            sorter: true,
        },
        {
            title: "Action",
            dataIndex: "id",
            render: (_, record) => {
                const isEditable = parent_id === null || parseInt(record?.user_id) === parseInt(UserID);

                return (
                    <div className="action-container">
                        <Tooltip title="View and Manage">
                            <button className="btn viewButton me-2" onClick={() => handleClick(record)}>
                                <LuEye className="iconStroke" />
                            </button>
                        </Tooltip>
                        <Tooltip title="View Activity Logs">
                            <button
                                className="btn logButton me-2"
                                onClick={() => {
                                    handleLogClick(record?.id);
                                }}
                            >
                                <HiOutlineDocumentText />
                            </button>
                        </Tooltip>
                        {isEditable && (
                            <>
                                <Tooltip title="Edit">
                                    <button
                                        className="btn editButton me-2"
                                        onClick={() => navigate("/add_property", {
                                            state: { data: record, type: 'edit' }
                                        })}
                                    >
                                        <FaEdit className="iconStroke" />
                                    </button>
                                </Tooltip>
                                <Tooltip title="Delete">
                                    <button
                                        className="btn deleteButton me-2"
                                        onClick={() => {
                                            setSelectedRecord(record.id);
                                            setIsModalOpen(true);
                                        }}
                                    >
                                        <HiOutlineTrash className="iconStroke" />
                                    </button>
                                </Tooltip>
                            </>
                        )}
                    </div>
                );
            },
            sorter: false,
        },
    ];

    const filterColumns = Columns?.filter((item) => !item?.parent_id);

    const handleSearchChange = (e) => {
        setCurrentPage(1)
        setSearchText(e?.target?.value || null);
    };

    useEffect(() => {
        if (searchText !== "" && searchText !== undefined && searchText !== null) {
            const handler = setTimeout(() => {
                setDebouncedSearch(searchText);
            }, 1500);

            return () => clearTimeout(handler);
        } else {
            setDebouncedSearch(searchText);
        }
    }, [searchText]);

    useEffect(() => {
        if (exportPageSize) {
            handlePropertyDataExport()
        }
    }, [exportPageSize])

    const handlePropertyDataExport = async () => {
        setExportLoader(true);
        try {
            const response = await refetch();
            const properties = response?.data?.data || [];

            const csvData = properties.map(property => {
                const propertyName = `${property.name_of_property} - ${property.property_type_name?.provider_name || ''}`;

                const managers = property.manager_properties_users?.map(user =>
                    `${user.user_name} - ${user.ownership_type_name}`
                ).join('; ') || "";

                return {
                    "ID": property.id,
                    "Property Name": propertyName,
                    "Total Income": property.total_income,
                    "Total Expense": property.total_expense,
                    "Total Profit/Loss": property.profit,
                    "Created On": dayjs(property.created_at).format("MM-DD-YYYY"),
                    "Property Managers": managers,
                };
            });

            const csvRows = [
                ['ID', 'Property Name', 'Property Managers', 'Total Income', 'Total Expense', 'Total Profit/Loss', 'Created On'],
                ...csvData.map(item => [
                    item.ID,
                    item["Property Name"],
                    item["Property Managers"],
                    item["Total Income"],
                    item["Total Expense"],
                    item["Total Profit/Loss"],
                    item["Created On"],
                ])
            ];

            const csvString = csvRows.map(row => row.join(',')).join('\n');
            const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
            saveAs(blob, 'properties.csv');
        } catch (error) {
            console.error("Error exporting data: ", error);
        }
        setExportLoader(false);
        setExportPageSize(null)
    };

    const logsColumns = [
        {
            title: "Action",
            dataIndex: "description",
            key: "description",
            render: (description) => {
                if (description.includes("Property Updated")) return "Updated";
                if (description.includes("Property Created")) return "Created";
                if (description.includes("Property Deleted")) return "Deleted";
                return "Unknown";
            },
        },
        {
            title: "Details",
            dataIndex: "properties",
            key: "properties",
            render: (_, record) => {
                const { attributes, old } = record.properties || {};
                if (!attributes || !old) return "No details available";

                try {
                    return Object.keys(attributes)
                        .filter((key) => key !== "updated_at")
                        .map((key, i) => {
                            const oldValue = old[key];
                            const newValue = attributes[key];

                            if (oldValue === undefined || oldValue === newValue) return null;

                            return (
                                <div key={i}>
                                    <strong>{key.replace(/_/g, " ")}:</strong> Changed from{" "}
                                    <Typography.Text delete>{oldValue}</Typography.Text> →{" "}
                                    <strong>{newValue}</strong>
                                </div>
                            );
                        })
                        .filter(Boolean);
                } catch (error) {
                    console.error("Error parsing properties:", error);
                    return "Invalid data";
                }
            },
        },
        {
            title: "Modified By",
            dataIndex: "modifiedBy",
            key: "modifiedBy",
        },
        {
            title: "Updated On",
            dataIndex: "formattedDate",
            key: "formattedDate",
        },
    ];

    const dataLogSource =
        ActivityModelData?.length > 0 &&
        [...ActivityModelData].reverse().map((log, index) => {
            let parsedProperties = {};
            try {
                parsedProperties = JSON.parse(log.properties);
            } catch (error) {
                console.error("Error parsing log properties:", error);
            }

            const causer = log.causer || {};
            const fullName = `${causer.first_name || ""} ${causer.last_name || ""}`.trim();
            const modifiedBy = fullName ? fullName : `ID: ${causer.id || "Unknown"}`;

            return {
                key: index,
                description: log.description,
                modifiedBy: modifiedBy,
                formattedDate: log.updated_at ? dayjs(log.updated_at).format("MM-DD-YYYY hh:mm A") : "N/A",
                properties: parsedProperties,
            };
        });


    const handleCancelLogModel = () => {
        setActivityModel(false);
        setActivityModelData([]);
    }

    return (
        <>
            {loading && (
                <div className="commanLoaderWrapper">
                    <Spin size="large" />
                    <h4>Setting things up..</h4>
                </div>
            )}
            <>

                <TopHeader
                    title={<Title title="All Properties" id='properties' />}
                    search={
                        <Input.Search
                            placeholder="Search..."
                            allowClear
                            value={searchText}
                            onChange={handleSearchChange}
                            className="tableSearchInput"
                        />
                    }
                    button={
                        <Button
                            className="customHeaderBtn step-1"
                            type="primary"
                            style={{
                                backgroundColor: colors?.primary || "#F47A3A",
                            }}
                        >
                            <Link to={"/add_property"} className="link">
                                {"Add new"}{" "}
                                <IoIosAddCircleOutline />
                            </Link>
                        </Button>
                    }
                />
                <div className="customPadding">
                    <div className="breadcrumbWrapper">
                        <Breadcrumb
                            items={[
                                {
                                    title: (
                                        <span onClick={() => navigate('/dashboard')} style={{ cursor: 'pointer' }}>
                                            <HomeOutlined />
                                        </span>
                                    ),
                                },
                                {
                                    title: (
                                        <>
                                            <span>Properties</span>
                                        </>
                                    ),
                                },
                            ]}
                        />
                    </div>
                    <div className="mainWrapper">
                        <div className="card titleCard mb-4">
                            <div className="card-body-wrapper">
                                {isPropertiesLoading || localLoading ? (
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                        <Spin />
                                    </div>
                                ) : isPropertiesError || totalProperties < 0 ? (
                                    <Empty description="No data found" />
                                ) : (
                                    <>
                                        {getProperties?.total_properties === 0 ?
                                            <Alert
                                                message="You don't have any property listed. Let's start adding."
                                                type="warning"
                                                showIcon
                                                closable
                                                className="propertyAlert"
                                                action={
                                                    <Button size="small" type="text" variant="filled" onClick={() => navigate("/add_property")}>
                                                        Add Property
                                                    </Button>
                                                }
                                            />
                                            : (
                                                <></>
                                            )
                                        }
                                        <div className="tableResponsives" style={{ paddingBottom: '3.5rem' }}>
                                            <>
                                                {totalProperties > 0 ? (<><div className="export-data-wrapper d-flex justify-content-end align-items-center"><Button icon={<GrDocumentCsv />} loading={exportLoader} onClick={() => setExportPageSize(totalProperties)} className="export-data-button viewButton mb-3">Export</Button></div></>) : (<></>)}
                                                <Table
                                                    className={`tableNew table-bordered step-2 ${isFetchingData ? "table-loading" : ""}`}
                                                    rowKey={(record) => record.id}
                                                    rowClassName={() => "editable-row"}
                                                    bordered
                                                    pagination={false}
                                                    dataSource={getProperties?.data}
                                                    columns={filterColumns}
                                                    scroll={{ x: "100%" }}
                                                    loading={isFetchingData}
                                                    onChange={(pagination, filters, sorter) => {
                                                        if (sorter.order) {
                                                            const columnTitle = Columns.find(col => col.dataIndex === sorter.field)?.dataIndex;
                                                            setColumnName(columnTitle);
                                                            setTableSortBy(sorter.order === "ascend" ? "asc" : "desc");
                                                        }
                                                    }}
                                                />
                                                {totalProperties > 20 ? (
                                                    <Pagination
                                                        className="mt-4"
                                                        current={currentPage}
                                                        pageSize={pageSize}
                                                        total={totalProperties}
                                                        onChange={handleChangePage}
                                                        pageSizeOptions={pageSizeOptions}
                                                        showSizeChanger
                                                        locale={{
                                                            items_per_page: `/${totalProperties || 0} Records`,
                                                        }}
                                                    />
                                                ) : (<></>)}

                                            </>

                                        </div>
                                    </>
                                )
                                }
                            </div>

                        </div>

                        <Modal
                            className="deleteModal"
                            title="Delete Property"
                            open={isModalOpen}
                            onCancel={handleCancel}
                            footer={false}
                        >
                            <div className="iconRow">
                                <RxCrossCircled />
                            </div>
                            <div className="textRow">
                                <h3>Are you sure?</h3>
                                <p>Do you really want to delete this property?</p>
                            </div>
                            <div className="buttonRow">
                                <Button className="btnOutlined me-2" onClick={handleCancel}>Cancel</Button>
                                <Button className="btnFilled text-white" onClick={() => handleOk()}>Delete</Button>
                            </div>
                        </Modal>

                        <Modal className="deleteModal customLogsModel" title="Activity Logs" open={ActivityModel} onCancel={handleCancelLogModel} footer={false}>
                            <Table columns={logsColumns} dataSource={dataLogSource} pagination={false} loading={loadingActivity} />
                        </Modal>

                    </div>
                </div>
            </>
        </>
    );
};
export default AllProperties;