import React, { useEffect, useState } from "react";
import Title from "../../component/PageTitle";
import {
    Button,
    Form,
    Collapse,
    Pagination,
    Tooltip,
    Modal,
    Spin,
    Table,
    Empty
} from "antd";
import { IoIosAddCircleOutline } from "react-icons/io";
import GroupEditModal from "../../component/CommonModal/GroupModal";
import CommanModal from "../../component/CommonModal/index";
import { toast } from "react-toastify";
import {
    useAddGroupMutation,
    useDeleteGroupMutation,
    useEditGroupMutation,
} from "../../slices/apiSlice";
import {
    useGetPropertiesQuery,
    useGetGroupQuery,
    useGetUtilityCategoryQuery,
    useGetLabelsQuery
} from "../../slices/getSlice";
import { useNavigate, useOutletContext } from "react-router-dom";
import { HiOutlineTrash } from "react-icons/hi2";
import { RxCrossCircled } from "react-icons/rx";
import { useSelector } from "react-redux";
import TopHeader from "../../component/Header";
import { HomeOutlined } from '@ant-design/icons';
import { Breadcrumb } from 'antd';
import { PiUsersFour } from "react-icons/pi";
import { BsBuildingCheck } from "react-icons/bs";
import { FaEdit } from "react-icons/fa";

const ManageProperty = () => {
    const [form] = Form.useForm();
    const [editForm] = Form.useForm();
    const { loading, setLoading } = useOutletContext();
    const [properties, setProperties] = useState([]);
    const [propertyid, setPropertyId] = useState();
    const [modelOpen, setModelOpen] = useState(false);
    const [editModel, setEditModel] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const colors = useSelector((state) => state?.persistedReducer?.colors);
    const [
        addGroup,
        { isLoading: isLoadingAddGroup }
    ] = useAddGroupMutation();

    const {
        data: getProperties,
        refetch: refetchProperties,
    } = useGetPropertiesQuery();

    const {
        data: getGroup,
        isLoading: isGroupLoading,
        isError: isGroupError,
        refetch: refetchGroup,
        error
    } = useGetGroupQuery();

    const {
        data: labelData,
        error: labelsError,
    } = useGetLabelsQuery('group_form');

    const {
        data: getUtilityCategory,
    } = useGetUtilityCategoryQuery();

    const [currentPage, setCurrentPage] = useState(1);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedValues, setSelectedValues] = useState([])
    const [selectedRecord, setSelectedRecord] = useState("");
    const [FormInitialValues, setFormInitialValues] = useState({
        id: "",
        name: "",
        properties: "",
    });
    const [propertyName, setPropertyName] = useState();
    const [propertyData, setPropertyData] = useState([]);
    const navigate = useNavigate();

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const ITEMS_PER_PAGE = 5;
    const startIdx = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIdx = startIdx + ITEMS_PER_PAGE;
    const [deletegroup,] = useDeleteGroupMutation();
    const [editgroup, { isLoading: isLoadingEditGroup }] = useEditGroupMutation();

    const redirectToProperty = (data) => {
        navigate("/view_property", {
            state: { propertyData: data },
        });
    }

    const columns = [
        {
            title: "Group Name",
            dataIndex: "group_name",
            key: "group_name",
            render: (text, record) => ({
                children: <strong>{text}</strong>,
                props: { rowSpan: record.rowSpan },
            }),
        },
        {
            title: "Property Name",
            dataIndex: "name_of_property",
            key: "name_of_property",
            render: (text, record) => (
                <div className="actionWrapper" style={{ cursor: 'pointer' }}>
                    <span onClick={() => redirectToProperty(record)}>{text}</span>
                </div>
            ),
        },
        {
            title: "Address",
            dataIndex: "address",
            key: "address",
        },
        {
            title: "Property Type",
            dataIndex: "property_type_name",
            key: "property_type_name",
        },
        {
            title: "Group Actions",
            key: "group_action",
            render: (_, record, data) => ({
                children:
                    record.rowSpan > 0 ? (
                        <div className="action-container customActionContainer" style={{ justifyContent: 'center' }}>
                            <Tooltip title="Edit">
                                <button
                                    className="btn viewButton me-2 customViewButton"
                                    onClick={() => {
                                        setEditModel(true);
                                        let findProperties = flattenedData?.filter((item) => item?.group_name === record?.group_name);
                                        setFormInitialValues({
                                            id: record.group_id,
                                            name: record.group_name,
                                            properties: findProperties,
                                        });
                                    }}
                                >
                                    <FaEdit className="iconStroke" />
                                </button>
                            </Tooltip>
                            <Tooltip title="Delete">
                                <button
                                    className="btn btnDelete me-2 customViewButton"
                                    onClick={() => {
                                        setSelectedRecord(record.group_id);
                                        setIsModalOpen(true);
                                    }}
                                >
                                    <HiOutlineTrash />
                                </button>
                            </Tooltip>
                        </div>
                    ) : null,
                props: { rowSpan: record.rowSpan },
            }),
        },
    ];

    useEffect(() => {
        if (getProperties && getProperties?.data) {
            const transformedOptions = getProperties?.data?.map((item) => ({
                label: item.name_of_property,
                value: item.id,
            }));
            setLoading(false)
            setProperties(transformedOptions);
        }
    }, [getProperties]);

    const handleAdd = async () => {
        form.resetFields();
        setFormInitialValues({
            name: "",
            properties: []
        });
        setModelOpen(true);
    };

    const handleCancel = () => {
        setIsSaving(false)
        setModelOpen(false);
        setIsModalOpen(false);
    };

    const handleCancelEdit = () => {
        setIsSaving(false)
        setModelOpen(false);
        setIsModalOpen(false);
        setEditModel(false);
    }

    const handleOk = async () => {
        const res = await deletegroup(selectedRecord);
        if (res?.data?.message) {
            refetchGroup();
            setIsModalOpen(false);
            toast.success(res?.data?.message)
        }
    };

    const capitalizeFirstLetter = (string) => {
        return string?.charAt(0)?.toUpperCase() + string?.slice(1);
    };

    const fields = [
        {
            component: "input",
            type: "text",
            label: capitalizeFirstLetter(labelData?.enter_group_name) || "Enter Group Name",
            name: "name",
            placeholder: "Enter Group Name",

            prefix: (
                <PiUsersFour style={{ color: "#F47939", fontSize: "24px" }} />
            ),
            rules: [
                {
                    required: true,
                    message: "Group name is required",
                },
                {
                    pattern: /^(?!\s)[A-Za-z\s]+(?<!\s)$/,
                    message: 'Group name must only contain letters',
                },
            ],
        },
        {
            component: "select",
            type: "text",
            mode: "multiple",
            label: capitalizeFirstLetter(labelData?.select_property) || "Select Property",
            name: "properties",
            onChange: (value) => {
                setSelectedValues(value);
            },
            placeholder: "Please select property",
            suffixIcon: (
                <BsBuildingCheck
                    style={{
                        color: "#F47939",
                        fontSize: "24px",
                    }}
                />
            ),
            rules: [
                {
                    required: true,
                    message: "Please select property",
                },
            ],
            options: properties,
        },
    ];

    const onFinish = async (data, formType) => {
        const id = FormInitialValues.id;
        if (formType === "edit") {
            let filterdProperties = [];
            if (data?.properties?.length) {
                filterdProperties = data.properties.map((item) => {
                    if (typeof item === 'number') {
                        return item;
                    }
                    const findProperty = properties?.find((record) => record.value === item?.value);
                    return findProperty ? findProperty.value : null;
                }).filter(Boolean);
            }
            data.properties = filterdProperties;
        }

        try {
            setIsSaving(true);
            let response;

            if (id) {
                response = await editgroup({ data, id });
                if (response.data) {
                    toast.success("Group has been updated successfully");
                    setEditModel(false);
                    editForm.resetFields();
                    setFormInitialValues({
                        name: "",
                        properties: []
                    });
                    setIsSaving(false);
                }
            } else {
                response = await addGroup(data);
                if (response.data) {
                    toast.success("Group has been created successfully");
                    setModelOpen(false);
                    form.resetFields();
                    setFormInitialValues({
                        name: "",
                        properties: []
                    });
                    setIsSaving(false);
                }
            }

            if (response?.data) {
                refetchGroup();
                form.resetFields();
                editForm.resetFields();
                setModelOpen(false);
            } else {
                toast.error(`${response?.error?.data?.message}`);
            }
        } catch (error) {
            console.error("Error:", error);
        } finally {
            setIsSaving(false);
        }
    };


    const handleClick = (detail) => {
        navigate("/property_details", {
            state: {
                data: detail,
                propertyid: propertyid,
                propertyName: propertyName,
                propertyData: propertyData
            },
        });
    };

    const items = getGroup?.data?.map((item) => {
        const propertyItems = item.properties.map((property) => ({
            key: property.id,
            onClick: () => {
                setPropertyId(property.id);
                setPropertyName(property.name_of_property);
                setPropertyData(property)
            },
            label: (
                <>
                    {isGroupLoading ? (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            <Spin />
                        </div>
                    ) : isGroupError ? (
                        <h4>An error occurred</h4>
                    ) : (
                        <div>
                            {property.name_of_property} - {property.zip_code}
                        </div>
                    )}
                </>
            ),
            children: (
                <>
                    {getUtilityCategory?.data?.map((utility) => (
                        <div className="provider-content" key={utility.id}>
                            <div>{utility.name}</div>
                            <Button className="add-new" onClick={() => handleClick(utility)}>
                                {" "}
                                {"Add New"}
                            </Button>
                        </div>
                    ))}
                </>
            ),
        }));

        return {
            key: item.group_id,
            label: (
                <div className="d-flex justify-content-between align-items-center">
                    {item.group_name}
                    <div className="action-container">
                        <Tooltip title="Edit">
                            <button
                                className="btn btnEdit me-2 border-0"
                                onClick={() => {
                                    setModelOpen(true);
                                    setFormInitialValues({
                                        id: item.group_id,
                                        name: item.group_name,
                                        properties: item.properties.map((data) => {
                                            return data?.id;
                                        }),
                                    });
                                }}
                            >
                                <FaEdit />
                            </button>
                        </Tooltip>
                        <Tooltip title="Delete">
                            <button
                                className="btn deleteButton me-2"
                                onClick={() => {
                                    setSelectedRecord(item.group_id);
                                    setIsModalOpen(true);
                                }}
                            >
                                <HiOutlineTrash className="iconStroke" />
                            </button>
                        </Tooltip>
                    </div>
                </div>
            ),
            children: (
                <div>
                    <Collapse accordion items={propertyItems} />
                </div>
            ),
        };
    });

    const currentItems = items ? items.slice(startIdx, endIdx) : [];

    const flattenedData = getGroup?.data.flatMap((group) =>
        group.properties.map((property, index) => ({
            key: `${group.group_id}-${property.id}`,
            group_name: group.group_name,
            group_id: group.group_id,
            rowSpan: index === 0 ? group.properties.length : 0,
            ...property,
        }))
    );

    return (
        <>
            {loading && (
                <div className="commanLoaderWrapper">
                    <Spin size="large" />
                    <h4>Setting things up..</h4>
                </div>
            )}
            <TopHeader
                title={<Title title="Manage Groups" id="groups" />}
                button={
                    <Button
                        onClick={handleAdd}
                        type="primary"
                        style={{
                            backgroundColor: colors?.primary || "#F47A3A",
                        }}
                        className="customHeaderBtn step-one"
                    >
                        Add Group <IoIosAddCircleOutline />
                    </Button>
                }
                search={""}
            />
            <div className="customPadding">
                <div className="breadcrumbWrapper">
                    <Breadcrumb
                        items={[
                            {
                                title: (
                                    <span onClick={() => navigate('/dashboard')} style={{ cursor: 'pointer' }}>
                                        <HomeOutlined />
                                    </span>
                                ),
                            },
                            {
                                title: (
                                    <>
                                        <span>Groups</span>
                                    </>
                                ),
                            },
                        ]}
                    />
                </div>
                <div className="mainWrapper">
                    <div className="card titleCard mb-4">
                        <div className="card-body-wrapper p-4">
                            {isGroupLoading ? (
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                    <Spin />
                                </div>
                            ) : isGroupError && currentItems.length < 0 ? (
                                <h4>An error occurred</h4>
                            ) : (
                                <>
                                    {currentItems.length > 0 ? (
                                        <div className="main-collapse">
                                            <div className="collapse-box">
                                                {/* <Collapse
                                                    className="groupCollapse"
                                                    items={
                                                        currentItems.length > 0 ? (
                                                            currentItems
                                                        ) : (
                                                            <p>No data found</p>
                                                        )
                                                    }
                                                /> */}
                                                <Table columns={columns} dataSource={flattenedData} rowKey="key" bordered pagination={false} />
                                            </div>
                                        </div>
                                    ) : (
                                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No data found" />
                                    )}
                                    {items && items.length > ITEMS_PER_PAGE && (
                                        <Pagination
                                            className="mt-4"
                                            current={currentPage}
                                            pageSize={ITEMS_PER_PAGE}
                                            total={items.length}
                                            onChange={handlePageChange}
                                        />
                                    )}
                                </>
                            )}
                        </div>
                    </div>

                    <Modal
                        className="deleteModal"
                        title="Delete Group"
                        open={isModalOpen}
                        onCancel={handleCancel}
                        footer={false}
                    >
                        <div className="iconRow">
                            <RxCrossCircled />
                        </div>
                        <div className="textRow">
                            <h3>Are you sure?</h3>
                            <p>Do you really want to delete this group?</p>
                        </div>
                        <div className="buttonRow">
                            <Button className="btnOutlined me-2" onClick={handleCancel}>
                                Cancel
                            </Button>
                            <Button className="btnFilled text-white" onClick={() => handleOk()}>
                                Delete
                            </Button>
                        </div>
                    </Modal>

                    <GroupEditModal
                        form={editForm}
                        open={editModel}
                        close={handleCancelEdit}
                        onFinish={onFinish}
                        fields={fields}
                        selectedValues={selectedValues}
                        title={FormInitialValues.id ? "Edit Group" : "Add Group"}
                        FormInitialValues={FormInitialValues}
                        formType={FormInitialValues.id ? "edit" : 'add'}
                        ButtonDisable={isSaving}
                        loading={FormInitialValues.id ? isLoadingEditGroup : isLoadingAddGroup}
                    />

                    <CommanModal
                        form={form}
                        open={modelOpen}
                        close={handleCancelEdit}
                        onFinish={onFinish}
                        fields={fields}
                        selectedValues={selectedValues}
                        title={FormInitialValues.id ? "Edit Group" : "Add Group"}
                        FormInitialValues={FormInitialValues}
                        formType={FormInitialValues.id ? "edit" : 'add'}
                        ButtonDisable={isSaving}
                        loading={FormInitialValues.id ? isLoadingEditGroup : isLoadingAddGroup}
                    />

                </div>
            </div>
        </>
    );
};
export default ManageProperty;
