import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import exporting from 'highcharts/modules/exporting';
import exportData from 'highcharts/modules/export-data';
import { useSelector } from 'react-redux';

exporting(Highcharts);
exportData(Highcharts);

function ProfitChart(data, label, color, currency) {
    if (!data) {
        return {};
    }

    const currencyCodeMap = {
        "Indian Rupee": "INR",
        "US Dollar": "USD",
        "Euro": "EUR",
        "British Pound": "GBP",
        "Japanese Yen": "JPY",
    };
    const currencyCode = currencyCodeMap[currency?.currency_name] || "USD";

    const categories = data.map(property => property.name_of_property);

    const seriesData = data.map(property => property?.total_profit);

    const options = {
        chart: {
            type: 'column'
        },
        legend: {
            enabled: false,
        },
        title: {
            text: label,
            style: {
                color: color?.secondary ?? '#000',
                fontSize: '16px',
            }
        },
        xAxis: {
            categories: categories
        },
        yAxis: {
            min: 0,
            title: {
                text: `Total Profit (${currency?.currency_symbol || '$'})`
            },
            stackLabels: {
                enabled: true,
                formatter: function () {
                    return this.total != null && this.total !== undefined
                        ? new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: currencyCode
                        }).format(Number(this.total))
                        : '';
                },
                style: {
                    fontWeight: 'bold',
                    color: 'black'
                }
            }
        },
        credits: {
            enabled: false
        },
        tooltip: {
            headerFormat: '<b>{point.x}</b><br/>',
            pointFormatter: function () {
                return `Total Profit: <b>${new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: currencyCode
                }).format(this.y)}</b>`;
            }
        },
        plotOptions: {
            column: {
                stacking: 'normal',
                dataLabels: {
                    enabled: false,
                }
            }
        },
        series: [{
            name: 'Total Profit',
            data: seriesData
        }],
        exporting: {
            enabled: true
        },
        lang: {
            downloadPNG: 'Download PNG image',
            downloadJPEG: 'Download JPEG image',
            downloadPDF: 'Download PDF document',
            downloadSVG: 'Download SVG vector image',
            downloadXLS: 'Download XLS data'
        }
    };

    return options;
}


const ChartProfit = ({ data, label, profitableProperty, currency }) => {
    let profitProperty = profitableProperty ? profitableProperty : [];

    const colors = useSelector((state) => state?.persistedReducer?.colors);

    const options = ProfitChart(profitProperty?.topProperties, label, colors, currency);

    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
        />
    );
};

export default ChartProfit;
