import React, { useEffect, useState } from "react";
import Title from "../../component/PageTitle";
import { Button, Form, message, Spin } from "antd";
import { RiUser2Line } from "react-icons/ri";
import { FaBuildingUser, FaRegAddressCard } from "react-icons/fa6";
import { SlCalender } from "react-icons/sl";
import PropertyDetailsForm from "../../component/Form/PropertyDetailsForm";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import {
    useGetCompanyQuery,
    useGetUtilityQuery,
    useGetFieldsQuery,
    useGetPropertyProvidersQuery,
    useGetAllPaymentDataQuery,
    useGetLabelsQuery,
    useGetNewCompanyListQuery,
    useGetExpenseDataByIdQuery
} from "../../slices/getSlice";
import {
    useAddCompanyMutation,
    useEditPropertyProviderMutation,
    useDeleteFieldMutation,
    useGetPropertyProviderByIdMutation,
    useAddNewExpenseMutation,
    useCreateNewCompanyMutation
} from "../../slices/apiSlice";
import { toast } from "react-toastify";
import { PiHouseLine } from "react-icons/pi";
import { UserOutlined } from "@ant-design/icons";
import TopHeader from "../../component/Header";
import { HomeOutlined } from '@ant-design/icons';
import { Breadcrumb } from 'antd';
import { GiMoneyStack } from "react-icons/gi";
import { TbCategoryPlus } from "react-icons/tb";

const PropertyDetails = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate()
    const [company, setCompany] = useState();
    const location = useLocation();
    const { loading, setLoading } = useOutletContext();
    const [selectedUtilityType, setSelectedUtilityType] = useState(null);
    const [category, setCategory] = useState();
    const [editPropertyProvider, { isLoading: isLoadingEdit }] = useEditPropertyProviderMutation();
    const [addNewExpense, { isLoading: isLoadingAddExpense }] = useAddNewExpenseMutation();
    const [addCompany] = useAddCompanyMutation();
    const [deleteField] = useDeleteFieldMutation()
    const [getPropertyProviderById] = useGetPropertyProviderByIdMutation()
    const [formKey, setFormKey] = useState(0);
    const [isSaving, setIsSaving] = useState(false)
    const [isDeleteSave, setIsDeleteSave] = useState(false)
    const [expenseType, setExpenseType] = useState('')
    const [propertyProvider, setPropertyProvider] = useState();
    const [companyList, setCompanyList] = useState([]);
    const [addNew, setAddNew] = useState(false);
    const [remind, setRemind] = useState(false);
    const [remindDays, setRemindDays] = useState(null);
    const [addNewCompany, { isLoading: companyAddLoading }] = useCreateNewCompanyMutation();

    let propertyDetailState = location?.state;
    let ProviderTypeID = Array.isArray(propertyDetailState?.providerType)
        ? propertyDetailState.providerType[0]
        : propertyDetailState?.providerType ?? propertyDetailState?.data?.id;
    let FormInitialValues = location?.state?.data;
    let PropertyCompanyId = propertyDetailState?.activePropertyID;
    const isEdit = location?.state?.isEdit;

    let detail = {
        propertyId: location?.state?.data?.property_id,
        providerTypesId: location?.state?.providerType,
        skip: location?.state?.data?.property_id && location?.state?.providerType
    };

    const fetchPropertyProvider = () => {
        if (location?.state?.data?.id !== undefined) {
            getPropertyProviderById(location?.state?.data?.id).then((response) => {
                setPropertyProvider(response?.data)
            }).catch((error) => {
                console.error({ error })
            })
        }
    }

    const {
        data: getFields = [],
        isSuccess: isSuccessFields,
        refetch: refetchFields,
    } = useGetFieldsQuery(selectedUtilityType ? selectedUtilityType : propertyProvider?.provider?.id ? propertyProvider?.provider?.id : location?.state?.data?.providerId ? location?.state?.data?.providerId : location?.state?.data?.id);

    useEffect(() => {
        if (location?.state?.data?.id && location?.state?.isEdit === true && isSuccessFields) {
            fetchPropertyProvider();
        }
    }, [isSuccessFields]);

    const {
        data: getPropertyProviders,
        refetch: refetchPropertyProvider,
    } = useGetPropertyProvidersQuery(detail ? detail : "");

    const {
        data: getUtility,
    } = useGetUtilityQuery(propertyProvider?.provider ? propertyProvider?.provider?.provider_type_id : propertyProvider?.provider_type_id ? propertyProvider?.provider_type_id : location?.state?.data.id ? location?.state?.data.id : location?.state?.providerType[0]);

    const {
        data: getCompany,
        refetch: refetchCompany,
    } = useGetCompanyQuery(selectedUtilityType ? selectedUtilityType : propertyProvider?.provider?.id ? propertyProvider?.provider?.id : location?.state?.data?.providerId ? location?.state?.data?.providerId : location?.state?.data?.id);

    const {
        data: getExpenseData,
        refetch: refetchExpenseData,
        isLoading: isDataLoading,
    } = useGetExpenseDataByIdQuery(propertyDetailState?.activePropertyID || '');

    const {
        data: getCategory,
    } = useGetAllPaymentDataQuery('expense');

    const {
        data: getCompanyList,
        isLoading: isLoadingCompany,
        refetch: refetchCompanyList,
    } = useGetNewCompanyListQuery();

    const { data: labelData } = useGetLabelsQuery('expense_form');
    const [disable, setDisable] = useState(location?.state?.data?.property_id ? false : true);
    const [isChecked, setIsChecked] = useState(false);
    const [compnayID, setCompanyID] = useState(null);
    const [initialData, setInitialData] = useState([]);
    const [filteredOptions, setFilteredOptions] = useState('Old data');
    const [newSelectedCompnay, setNewSelectedCompany] = useState();

    const handleCheckboxChange = (e) => {
        setIsChecked(e.target.checked);
    };

    useEffect(() => {
        refetchCompanyList();
    }, []);

    useEffect(() => {
        if (!isLoadingCompany && getCompanyList) {
            setCompanyList(getCompanyList?.data?.companies);
        }
    }, [getCompanyList, isLoadingCompany]);

    const formatUtilityandCategory = () => {
        if (getCategory && getCategory?.data) {
            const transformedOptions = getCategory.data.map((item) => ({
                label: item.name,
                value: item.id,
            }));

            if (transformedOptions?.length > 0) {
                setCategory((prevCategory) =>
                    JSON.stringify(prevCategory) !== JSON.stringify(transformedOptions)
                        ? transformedOptions
                        : prevCategory
                );
            }
        }
    };

    useEffect(() => {
        formatUtilityandCategory();
        setLoading(false);
    }, [getUtility, getCategory]);

    useEffect(() => {
        refetchPropertyProvider();
    }, [getPropertyProviders]);

    useEffect(() => {
        if (getCompany && getCompany?.data) {
            const companies = getCompany?.data?.map((item) => ({
                label: item.company_name,
                value: item.company_id,
            }));
            const sortedOptions = companies?.sort((a, b) =>
                a?.label.toLowerCase().localeCompare(b?.label.toLowerCase())
            );
            setCompany(sortedOptions);
        }
    }, [getCompany]);

    useEffect(() => {
        refetchExpenseData();
    }, []);

    useEffect(() => {
        if (propertyDetailState?.activePropertyID) {
            refetchExpenseData();
        }
    }, [propertyDetailState?.activePropertyID]);

    useEffect(() => {
        refetchExpenseData();
    }, [location.pathname]);

    useEffect(() => {
        if (getExpenseData) {
            setInitialData(getExpenseData[0]);
        }
    }, [getExpenseData]);

    useEffect(() => {
        if (expenseType) {
            const filtered = category.filter((option) => option.value === expenseType);
            setFilteredOptions(filtered[0]?.label);
        } else {
            setFilteredOptions('')
        }
    }, [expenseType]);

    const dynamicValue = FormInitialValues?.form_fields ?? location?.state?.data?.form_fields

    const renderFormFields = () => {
        if (!dynamicValue) {
            return [];
        } else {
            let parsedDynamicValue = dynamicValue;

            const isEmpty = (parsedDynamicValue) => {
                return Object.keys(parsedDynamicValue).length === 0;
            };

            if (!isEmpty(parsedDynamicValue)) {

                return Object.keys(parsedDynamicValue).map((key) => {

                    const fieldValue = parsedDynamicValue[key];
                    return {
                        component: "input",
                        name: key,
                        type: 'text',
                        label: key,
                        defaultValue: fieldValue,
                        colSpan: 8,
                        static: false,
                    };
                });
            } else {
                return [];
            }
        }
    };

    const dynamicfields = getFields?.data?.length && (isEdit || addNew === true || addNew === 'true')
        ? getFields.data.map((item) => {
            const rules = item.field_type === 'phone' ? [
                {
                    pattern: /^\+?[1-9]\d{0,2}\s?\d{10}$/,
                    message: `${item.name} must be a valid phone number with an optional country code`,
                },
            ] : item.field_type === 'text' ? [
                {
                    pattern: /^[A-Za-z\s]+$/,
                    message: 'It can only contain letters and spaces',
                },
                {
                    max: 25,
                    message: 'It cannot exceed 25 characters',
                },
            ] : [];

            return {
                component: "input",
                type: (item.field_type === 'url') ? "text" : item.field_type === 'phone' ? "phone" : item.field_type,
                label: item.name,
                name: item.name,
                placeholder: `Enter ${item.name}`,
                colSpan: 8,
                static: false,
                id: item?.id,
                rules,
            };
        })
        : null;

    const capitalizeFirstLetter = (string) => {
        return string?.charAt(0)?.toUpperCase() + string?.slice(1);
    };;

    const fields = [
        {
            component: "select",
            label: capitalizeFirstLetter(labelData?.company_id) || "Company",
            name: "company_id",
            placeholder: "Select Company or start typing to add new",
            hidden: false,
            suffixIcon: (
                <FaBuildingUser style={{ color: "#F47939", fontSize: "25px" }} />
            ),
            options: companyList,
            colSpan: 12,
            static: true,
            rules: [
                {
                    required: true,
                    message: "Please select a company!",
                },
            ],
        },
        {
            component: "select",
            label: capitalizeFirstLetter(labelData?.provider_id) || "Category",
            name: "provider_id",
            disabled: location?.state?.data?.property_id && location?.state?.data?.provider_id ? true : false,
            placeholder: "Start Typing to Search Category",
            suffixIcon: (
                <TbCategoryPlus style={{ color: "#F47939", fontSize: "25px" }} />
            ),
            onChange: (value) => {
                setDisable(false);
                setSelectedUtilityType(value);
            },
            rules: [
                {
                    required: true,
                    message: "Please select a category!",
                },
            ],
            options: '',
            colSpan: 12,
            static: true,
        },
        {
            component: "select",
            label: capitalizeFirstLetter(labelData?.category_id) || "Expense Type",
            name: "category_id",
            placeholder: "Please select type",
            rules: [
                {
                    required: true,
                    message: "Please select type",
                },
            ],
            disabled: location?.state?.data?.property_id ? true : false,
            onChange: (value) => {
                setExpenseType(value)
            },
            suffixIcon: (
                <PiHouseLine style={{ color: "#F47939", fontSize: "25px" }} />
            ),
            options: category,
            hidden: disable,
            colSpan: 8,
            static: true,
        },
        {
            component: "input",
            type: "number",
            label: capitalizeFirstLetter(labelData?.amount) || "Amount",
            name: "amount",
            hidden: disable,
            placeholder: "Enter Amount",
            prefix: (
                <GiMoneyStack
                    style={{ color: "#F47939", fontSize: "25px" }}
                />
            ),
            rules: [
                {
                    pattern: /^(0|[1-9]\d*)(\.\d+)?$/,
                    message: 'Amount must be positive number',
                },
            ],
            colSpan: 8,
            static: true,
        },
        {
            component: "input",
            type: "date",
            label: labelData?.start_date ? capitalizeFirstLetter(labelData?.start_date) : filteredOptions === 'Recurring' ? 'Start Date' : 'Payment Date',
            name: "start_date",
            hidden: disable,
            disabled: location?.state?.data?.property_id ? true : false,
            placeholder: "Enter start date ",
            prefix: (
                <SlCalender
                    style={{ color: "#F47939", fontSize: "25px", height: "20px" }}
                />
            ),
            colSpan: 8,
            static: true,
        },
        // {
        //     component: "input",
        //     type: "date",
        //     label: capitalizeFirstLetter(labelData?.next_payment_date) || "Next Payment Date",
        //     name: "next_payment_date",
        //     hidden: location?.state?.data?.category_id === 'Recurring' || filteredOptions === 'Recurring' ? false : true,
        //     disabled: location?.state?.data?.property_id ? true : false,
        //     placeholder: "Enter Next Payment Date ",
        //     prefix: (
        //         <SlCalender
        //             style={{ color: "#F47939", fontSize: "25px", height: "20px" }}
        //         />
        //     ),
        //     colSpan: 12,
        //     static: true,
        // },
        {
            component: "select",
            label: capitalizeFirstLetter(labelData?.frequency) || "Frequency",
            name: "frequency",
            hidden: filteredOptions === 'Recurring' || location?.state?.data?.category_id === 'Recurring' ? false : true,
            disabled: location?.state?.data?.property_id ? true : false,
            placeholder: "Select the Frequency",
            suffixIcon: (
                <RiUser2Line style={{ color: "#F47939", fontSize: "25px" }} />
            ),
            options: [
                { value: "7", label: "Weekly" },
                { value: "14", label: "Fortnight" },
                { value: "30", label: "Monthly" },
                { value: "91", label: "Quarterly" },
                { value: "181", label: "Half Yearly" },
                { value: "365", label: "Yearly" },
            ],
            colSpan: 12,
            static: true,
        },
        {
            component: 'checkbox',
            type: 'checkbox',
            hidden: disable,
            name: 'reminder',
            label: 'Remind',
            static: true,
            onChange: handleCheckboxChange
        },
        {
            component: "input",
            type: "number",
            hidden: isChecked || FormInitialValues?.reminder ? false : true,
            label: capitalizeFirstLetter(labelData?.remainder) || "Reminder (Please specify the day the reminder is needed)",
            name: "reminder_duration",
            placeholder: "Enter Reminder",
            prefix: <UserOutlined style={{ color: "#F47939", fontSize: "25px" }} />,
            colSpan: 12,
            static: true,
        },
        {
            component: "input",
            type: "text",
            label: capitalizeFirstLetter("Account / ID Number"),
            name: "account_number",
            placeholder: "Enter Account Number",
            prefix: <FaRegAddressCard style={{ color: "#F47939", fontSize: "25px" }} />,
            colSpan: 12,
            static: true,
            rules: [
                {
                    validator: (_, value) => {
                        if (!value) return Promise.resolve();

                        const stringValue = value.toString();
                        if (stringValue.length > 12) {
                            return Promise.reject(new Error("Account number cannot exceed 12 digits"));
                        }

                        // if (!/^\d+$/.test(stringValue)) {
                        //     return Promise.reject(new Error("Only numeric values are allowed"));
                        // }

                        return Promise.resolve();
                    },
                },
            ],
        },

    ];

    const sortedField = fields;

    const addExpense = async (staticValue, dynamicValue, formType, formValues) => {
        setIsSaving(true);

        try {
            const stateData = location?.state;
            let values = {};
            let company_id = null

            if (formType === "add") {
                setSelectedUtilityType(null)
                setDisable(true)

                values = {
                    provider_type_id: stateData.data.id,
                    property_id: location?.state?.propertyid,
                    form_fields: Object.keys(dynamicValue).length !== 0 ? dynamicValue : null,
                    ...staticValue,
                }
            } else {
                if (typeof staticValue.company_id === "string") {
                    let company_data = company.find((companyData) => companyData.label === staticValue.company_id);
                    company_id = company_data.value;
                } else {
                    company_id = staticValue.company_id;
                }

                values = {
                    provider_type_id: stateData?.data?.provider_type_id
                        ? stateData.data.provider_type_id
                        : Array.isArray(stateData?.providerType)
                            ? stateData.providerType[0]
                            : stateData?.providerType,
                    property_id: location?.state?.propertyid ? location?.state?.propertyid : stateData?.propertyid ? stateData?.propertyid : stateData?.propertyData?.id,
                    company_id: company_id ? company_id : stateData?.data?.companyId,
                    form_fields: Object.keys(dynamicValue).length !== 0 ? dynamicValue : stateData?.data?.form_fields ? Object.keys(stateData?.data?.form_fields).length > 0 ? stateData?.data?.form_fields : null : '',
                    frequency: staticValue?.frequency,
                    due_date: staticValue?.due_date,
                    amount: staticValue?.amount,
                    expenses: staticValue?.expenses,
                    provider_id: stateData?.data?.providerId ?? staticValue?.provider_id,
                    category_id: staticValue?.category_id,
                    start_date: staticValue?.start_date,
                    reminder_duration: staticValue?.reminder_duration,
                    reminder: staticValue?.reminder,
                    is_remind: remind === true ? 1 : 0,
                    remind_days: formValues?.reminder_days ? formValues?.reminder_days : remindDays
                };
            }

            let id = location?.state?.data?.id

            if (formType === "edit") {
                const response = await editPropertyProvider({ data: values, id });
                if (response.data) {
                    refetchPropertyProvider()
                    setFormKey((prevKey) => prevKey + 1);
                    fetchPropertyProvider();
                    navigate('/expense')
                    toast.success("Property provider has been updated successfully");
                    form.resetFields();
                } else {
                    toast.error(`${response?.error?.data?.message}`);
                }
            } else {
                let accountNumber = values?.account_number !== undefined ? values?.account_number : values?.form_fields?.account_number === "" ? null : values?.form_fields?.account_number ? values?.form_fields?.account_number : propertyDetailState?.data?.account_number;

                let formData = {
                    "category_id":
                        (typeof formValues?.provider_id === "number" ? formValues?.provider_id : undefined)
                        ?? (values?.provider_id !== undefined ? values?.provider_id : undefined)
                        ?? (initialData?.category_id ? initialData?.category_id : undefined)
                        ?? staticValue?.provider_id,

                    "company_id": values?.company_id,
                    "account_number": accountNumber,
                    "expenses": staticValue?.expenses,
                    "property_id": values?.property_id,
                    "provider_type_id": parseInt(ProviderTypeID),
                    "is_remind": remind === true ? 1 : 0,
                    "remind_days": formValues?.reminder_days ? formValues?.reminder_days : remindDays
                };

                if (PropertyCompanyId) {
                    formData.property_company_id = PropertyCompanyId;
                }

                if (initialData?.account_id) {
                    formData.account_id = initialData.account_id;
                }

                const response = await addNewExpense(formData);
                if (response.data !== undefined) {
                    toast.success(response?.data?.message);
                    form.resetFields();
                    setFilteredOptions("Old Data");
                    setIsChecked(false);
                    setSelectedUtilityType(null);
                    setAddNew(false);
                    refetchPropertyProvider();
                    setFormKey((prevKey) => prevKey + 1);
                    fetchPropertyProvider();
                    navigate("/expense");
                } else {
                    toast.error(`${response?.error?.data?.message}`);
                }
            }

        } catch (error) {
            console.error(error)
        } finally {
            setIsSaving(false);
        }
    };

    const companyAdd = async (values) => {
        let data = {
            provider_type_id: values.provider_type_id ? values.provider_type_id : location?.state?.data.id ? location?.state?.data.id : location?.state?.providerType[0],
            provider_id: values?.providerID ? values?.providerID : selectedUtilityType ? selectedUtilityType : location?.state?.data?.providerId ? location?.state?.data?.providerId : location?.state?.data?.id,
            name: values?.company_name
        };

        const response = await addCompany(data);
        if (response.data) {
            refetchCompany();
            setCompanyID(response?.data?.data?.company_id);
            toast.success("Company has been added successfully");
        } else {
            toast.error(`${response?.error?.data?.message}`);
        }
    };

    const saveAndExit = (
        location?.pathname === '/property_details' ? <Button
            type="primary"
            htmlType={isEdit ? "" : "submit"}
            className="btnFilled ms-3 w-150"
            onClick={() => {
                if (isEdit) {
                    navigate('/view_property', {
                        state: {
                            propertyData: propertyDetailState.propertyData
                        }
                    });
                }
            }}
        >
            {isEdit ? "Cancel" : "Save & Exit"}
        </Button> : ''
    );

    useEffect(() => {

        if (getExpenseData?.length > 0) {
            setRemind(getExpenseData[0]?.is_remind)
            setRemindDays(getExpenseData[0]?.remind_days)
        }

    }, [getExpenseData]);
    
    const handleDelete = async (id) => {
        setIsDeleteSave(true);
        try {
            const res = await deleteField(id);
            if (res?.data) {
                message.success('Fields deleted successfully!');
                refetchFields();
            } else {
                message.error(`${res?.error?.data?.error}`);
            }
        }
        catch {
            message.error('An error occured');
        } finally {
            setIsDeleteSave(false);
        }
    }

    const handleAddNewCompany = async (value) => {
        let formData = {
            name: value
        }
        try {
            const response = await addNewCompany(formData);
            if (response.data) {
                form.resetFields();
                let newCompanyData = await refetchCompanyList();
                setCompanyList(newCompanyData?.data);
                setNewSelectedCompany(value);
                setFormKey((prevKey) => prevKey + 1);
                toast.success("Company Added");
            } else {
                toast.error(`${response?.error?.data?.message}` || "Failed to add new company!");
            }
        } catch (error) {
            toast.error("Failed to add new company!");
        }
    }

    const fetchLastParent = () => {
        let getParent = JSON.parse(localStorage?.getItem('lastOpenMenu'));
        let child = getParent?.child;

        if (child === "manage_property") {
            return "Properties";
        }

        return child ? child.charAt(0).toUpperCase() + child.slice(1) : "";
    };

    return (
        <>
            {loading && (
                <div className="commanLoaderWrapper">
                    <Spin size="large" />
                    <h4>Setting things up..</h4>
                </div>
            )}
            <TopHeader title={<Title title="Property Details" className='custom' />} />
            <div className="customPadding">
                <div className="breadcrumbWrapper">
                    <Breadcrumb
                        items={[
                            {
                                title: (
                                    <span onClick={() => navigate('/dashboard')} style={{ cursor: 'pointer' }}>
                                        <HomeOutlined />
                                    </span>
                                ),
                            },
                            {
                                title: (
                                    <>
                                        <span>{fetchLastParent()}</span>
                                    </>
                                ),
                            },
                            {
                                title: (
                                    <>
                                        <span>{isEdit ? 'Edit' : 'Add'} Expense</span>
                                    </>
                                ),
                            },
                        ]}
                    />
                </div>
                <div className="mainWrapper">
                    <div className="fixedWrapper">
                        <div className="card titleCard mb-4 propertyTitleCard">
                            <div className="card-body d-flex justify-content-start align-items-start flex-column">
                                <p className="property-name"><span className="property-name-key">Property Name</span> :- {location?.state?.propertyData?.name_of_property}</p>
                            </div>
                        </div>
                    </div>

                    <div className="scrollingWrapper">
                        <div className="card tableCard customTableCard">
                            <div className="card-body pt-0">
                                <div className="topRow">
                                    <div className="propertyName">
                                        <h5> Property: {propertyDetailState?.data?.property ? propertyDetailState?.data?.property : propertyDetailState?.propertyName ? propertyDetailState?.propertyName : propertyDetailState?.propertyData?.name_of_property}</h5>
                                    </div>
                                    <div className="topTile">
                                        <h5>
                                            {`${location?.state?.data?.name
                                                ? location?.state?.data?.name
                                                : location.state?.providerTypeName
                                                } Details`}
                                        </h5>
                                    </div>
                                </div>
                                <div className="contetnWrapper mt-4">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <PropertyDetailsForm
                                                key={formKey}
                                                form={form}
                                                fields={sortedField?.length === 9 ? sortedField : fields}
                                                onFinish={addExpense}
                                                buttonName={isEdit ? "Update" : "Save & Continue"}
                                                dynamicfields={
                                                    isEdit && FormInitialValues?.form_fields !== null && FormInitialValues?.form_fields !== undefined
                                                        ? renderFormFields()
                                                        : (selectedUtilityType || propertyProvider || initialData) ? dynamicfields : ''
                                                }
                                                propertyProvider={propertyProvider}
                                                onUtilityTypeChange={setSelectedUtilityType}
                                                selectedValue={selectedUtilityType}
                                                FormInitialValues={FormInitialValues}
                                                formType={isEdit ? "Save" : "add"}
                                                ButtonDisable={isSaving}
                                                isDeleteSave={isDeleteSave}
                                                handleDelete={handleDelete}
                                                saveAndExit={saveAndExit}
                                                loading={isEdit ? isLoadingEdit : isLoadingAddExpense}
                                                compnayID={compnayID}
                                                companyAdd={companyAdd}
                                                initialData={initialData}
                                                isDataLoading={isDataLoading}
                                                buttonLabel={isEdit ? "Update" : "Add"}
                                                handleAddNewCompany={handleAddNewCompany}
                                                newSelectedCompnay={newSelectedCompnay}
                                                setRemind={setRemind}
                                                remind={remind}
                                                remindDays={remindDays}
                                                companyAddLoading={companyAddLoading}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PropertyDetails;
