import React, { useEffect, useState, useCallback } from "react";
import { Button, Checkbox, Form, Modal, Select, Table, Tooltip, Input, Row, Col, Spin, Space, Pagination, Alert } from "antd";
import Title from "../../component/PageTitle";
import {
	useCategorizePlaidDataQuery,
	useGetPropertiesQuery,
	useGetUtilityCategoryQuery,
	useGetNewCompanyListQuery,
	useGetAllPaymentDataQuery,
	useGenrateLinkTokenQuery
} from "../../slices/getSlice";
import { useAddRecurringTransactionMutation, useAddNewExpenseMutation, useGetCategoryListingMutation, useCreateNewCompanyMutation, useRemoveTransactionMutation, useAddAccessTokenMutation } from "../../slices/apiSlice";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import swal from "sweetalert";
import { useOutletContext, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import TopHeader from "../../component/Header";
import { HomeOutlined } from '@ant-design/icons';
import { Breadcrumb } from 'antd';
import PageSizeOptionsHelper from "../../Helper/PageSizeOptionsHelper";
import { FaMinus, FaPlus } from "react-icons/fa";
import { usePlaidLink } from 'react-plaid-link';
import { saveAs } from 'file-saver';
import { GrDocumentCsv } from "react-icons/gr";

function RecurringTransaction() {
	const navigate = useNavigate();
	const currencyGlobal = useSelector((state) => state?.persistedReducer?.currency)
	const [form] = Form.useForm();
	const [searchText, setSearchText] = useState(null);
	const [filter, setFilter] = useState(null);
	const [tableSortBy, setTableSortBy] = useState(null);
	const [columnName, setColumnName] = useState(null);
	const { loading, setLoading } = useOutletContext();
	const { data: getProperties } = useGetPropertiesQuery();
	const { data: getUtilityCategory } = useGetUtilityCategoryQuery();
	const [addRecurringTransaction] = useAddRecurringTransactionMutation();
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [selectedRowsData, setSelectedRowsData] = useState([])
	const [properties, setProperties] = useState([]);
	const [selectedProviderType, setSelectedProviderType] = useState(null);
	const [linkData, setLinkData] = useState();
	const [utilityCategory, setUtilityCategory] = useState();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const colors = useSelector((state) => state?.persistedReducer?.colors);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(20)
	const [exportPageSize, setExportPageSize] = useState(null)
	const [isSplitPayment, setIsSplitPayment] = useState(false);
	const [propertyAmounts, setPropertyAmounts] = useState({});
	const [isSaving, setIsSaving] = useState(false)
	const [companyList, setCompanyList] = useState([]);
	const [categoryData, setCategoryData] = useState([]);
	const [dataSource, setDataSource] = useState([]);
	const [totalData, setTotalData] = useState(0)
	const [localLoading, setLocalLoading] = useState(false);
	const [ExpenseOptions, setExpenseOptions] = useState([]);
	const [getCategoryListing, { data }] = useGetCategoryListingMutation();
	const [addNewExpense, { isLoading: isLoadingAddExpense }] = useAddNewExpenseMutation();
	const [addNewCompany, { isLoading: isLoadingAddCompany }] = useCreateNewCompanyMutation();
	const [inputValue, setInputValue] = useState("");
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const [loader, setLoader] = useState(false);
	const startDate = useSelector((state) => state.date.startDate);
	const endDate = useSelector((state) => state.date.endDate);
	const [exportLoader, setExportLoader] = useState(false);
	const isRecurring = true;
	const [removeTransaction, { isLoading: loadingRecurring }] = useRemoveTransactionMutation();
	const [transaction_id, setTransactionId] = useState(null);
	const [splitTransactionId, setSplitTransactionId] = useState(null);
	const [isLoadingForm, setIsLoadingForm] = useState(false);
	const userData = useSelector((state) => state.persistedReducer);
	const parent_id = userData?.user?.user?.parent_id;
	const [debouncedSearch, setDebouncedSearch] = useState("");

	const { data: generateToken, refetch: transactionRefetch, isLoading, error } = useGenrateLinkTokenQuery(
		`?page=${currentPage}&per_page=${pageSize}${debouncedSearch ? `&search=${debouncedSearch}` : ""}`
	);

	const linkToken = generateToken?.token?.link_token;

	const {
		data: categorizePlaidData,
		refetch,
		isLoading: isLoadingPlaid
	} = useCategorizePlaidDataQuery({
		is_recurring: isRecurring,
		start_date: startDate || null,
		filter: filter?.length > 0 ? filter : null,
		searchText: (debouncedSearch ? `search=${debouncedSearch}` : null),
		end_date: endDate || null,
		query: (tableSortBy && columnName) ? `&sort_direction=${tableSortBy}&sort_by=${columnName}` : null,
		pageOptions: `page=${currentPage}&per_page=${exportPageSize ? exportPageSize : pageSize}`,
	});

	const {
		data: getExpenseList,
	} = useGetAllPaymentDataQuery('expense');
	const [setAccessToken] = useAddAccessTokenMutation();

	const {
		data: getCompanyList,
		isLoading: isLoadingCompany,
		refetch: refetchCompanyList,
	} = useGetNewCompanyListQuery(transaction_id ? { transaction_id } : undefined);

	useEffect(() => {
		if (getExpenseList && getExpenseList?.data) {
			const transformedOptions = getExpenseList.data.map((item) => ({
				label: item.name,
				value: item.id,
			}));
			const filterOptions = transformedOptions?.filter((item) => item?.label === "Recurring");
			setExpenseOptions(filterOptions);
		}
	}, [getExpenseList]);

	const pageSizeOptions = PageSizeOptionsHelper(getProperties?.meta?.total);

	const fetchData = async () => {
		setLocalLoading(true);
		await refetch();
		setLocalLoading(false);
	};

	useEffect(() => {
		fetchData()
	}, [startDate, endDate])


	useEffect(() => {
		if (categorizePlaidData?.data?.length > 0) {
			const filteredData = categorizePlaidData.data
				?.map((transaction) => {
					const parsedData = JSON.parse(transaction.json_data);
					const filteredItems = [parsedData].filter((item) => {
						const itemDate = new Date(item.last_date);
						return itemDate >= startDate && itemDate <= endDate;
					});

					if (filteredItems.length > 0) {
						return {
							key: filteredItems[0]?.transaction_id,
							amount: filteredItems[0]?.last_amount?.amount,
							date: filteredItems[0]?.last_date,
							name: filteredItems[0]?.description,
							transaction_id: transaction?.id,
							stream_id: filteredItems[0]?.stream_id,
							payment_date: filteredItems[0]?.last_date,
							next_payment_date: filteredItems[0]?.predicted_next_date,
							start_date: filteredItems[0]?.first_date,
							frequency: filteredItems[0]?.frequency,
							property_id: null,
							provider_type_id: null,
							category_id: null,
							category_name: parsedData?.category[0],
							status: transaction?.status,
							mask: transaction?.mask,
							billing_descriptor: transaction?.billing_descriptor
						};
					} else if (filteredItems.length === 0) {
						return {
							key: parsedData?.transaction_id,
							amount: parsedData?.last_amount?.amount,
							date: parsedData?.last_date,
							name: parsedData?.description,
							transaction_id: transaction?.id,
							stream_id: parsedData?.stream_id,
							payment_date: parsedData?.last_date,
							next_payment_date: parsedData?.predicted_next_date,
							start_date: parsedData?.first_date,
							frequency: parsedData?.frequency,
							property_id: null,
							provider_type_id: null,
							category_id: null,
							category_name: parsedData?.category[0],
							status: transaction?.status,
							mask: transaction?.mask,
							billing_descriptor: transaction?.billing_descriptor
						};
					} else {
						return null;
					}
				})
				.filter((item) => item !== null);

			setTotalData(filteredData?.length)

			setDataSource(filteredData);
		}
		else {
			setDataSource([])
		}
	}, [categorizePlaidData, startDate, endDate]);

	const DateFormatter = (date) => {
		const dateObj = new Date(date);
		const monthNames = [
			"January", "February", "March", "April", "May", "June",
			"July", "August", "September", "October", "November", "December"
		];
		const day = dateObj.getDate();
		const month = monthNames[dateObj.getMonth()];
		const year = dateObj.getFullYear();
		return `${month} ${day} ${year}`;
	};

	const formatDate = (dateString) => {
		const date = new Date(dateString);
		const formattedDate = `${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}-${date.getFullYear()}`;
		return formattedDate;
	};

	const onChangeProvider = (data) => {
		setSelectedProviderType(data.value);
	};

	useEffect(() => {
		if (categorizePlaidData) {
			form.setFieldsValue(linkData)
		}
	}, [linkData])

	const handleTransactionRemove = (record) => {

		swal({
			title: "Are you sure?",
			text: `Remove transaction.`,
			// icon: "warning",
			// buttons: ["Cancel", "Yes, confirm!"],
			dangerMode: true,
			buttons: {
				confirm: {
					text: "Remove",
					value: true,
					visible: true,
					className: "custom-confirm-btn",
					closeModal: true,
				},
				cancel: {
					text: "Cancel",
					value: null,
					visible: true,
					className: "custom-cancel-btn",
					closeModal: true,
				},
			},
		}).then(async (willConfirm) => {
			if (willConfirm) {

				let formData = {
					"transaction_id": record?.transaction_id
				}

				try {
					let changeStatus = await removeTransaction(formData);

					if (changeStatus?.error) {
						toast.error(changeStatus?.error?.message || "Failed to update status.");
					} else {
						toast.success(changeStatus?.data?.message || "Status updated successfully.");
						refetch();
					}
				} catch (error) {
					toast.error("Something went wrong! Please try again.");
					console.error("API Error:", error);
				}
			}
		});

	}

	const capitalizeFirstLetter = (string) => {
		return string?.charAt(0)?.toUpperCase() + string?.slice(1);
	};

	const handleAddTransactionCLick = async (record) => {
		setIsModalOpen(true);
		setLinkData(record);

		const id = record?.transaction_id;
		setIsLoadingForm(true);
		if (id) {
			setTransactionId(id);
			setSplitTransactionId(id)

			setTimeout(async () => {
				let fetchedCompaniesData = await refetchCompanyList({ transaction_id: id });

				let companyListing = fetchedCompaniesData?.data?.data?.companies;
				setCompanyList(companyListing);

				let selectedCompany = fetchedCompaniesData?.data?.data?.selected_company;
				if (selectedCompany !== undefined) {
					form.setFieldsValue({
						company_id: selectedCompany?.id
					});

					let getCategory = await getCategoryListing({ search_term: "", transaction_id: id });
					setCategoryData(getCategory?.data?.categories);

					let selectedCategory = getCategory?.data?.selected_category;
					form.setFieldsValue({
						category_id: selectedCategory?.id,
						mask: record?.mask,
						billing_descriptor: record?.billing_descriptor
					});

					setTransactionId(null);
					setIsLoadingForm(false);
				} else {
					setIsLoadingForm(false);
				}
			}, 0);
		}
	};

	const columns = [
		{
			title: "Transaction Name",
			dataIndex: "transactionName",
			render: (text, record) => (
				<div className="category-data-container">
					<p className="category-data-text">{record.name}</p>
				</div>
			),
			sorter: true,
		},
		{
			title: "Amount",
			dataIndex: "amount",
			render: (text, record) =>
				record.amount ? (
					<div className="amount-data-container">
						<p className="amount-data-text">{new Intl.NumberFormat('en-US', { style: 'currency', currency: currencyGlobal?.currency_name === "Indian Rupee" ? 'INR' : 'USD' }).format(record.amount?.toFixed(2))}</p>
					</div>
				) : (
					""
				),
			sorter: true,
		},
		{
			title: "Category",
			dataIndex: "category_name",
			render: (text, record) => record?.category_name,
			sorter: true,
		},
		{
			title: "Transaction Date",
			dataIndex: "transactionDate",
			render: (text, record) =>
				record.date ? (
					<div className="date-data-container">
						<p className="date-data-text">{formatDate(record.date)}</p>
					</div>
				) : (
					""
				),
			sorter: true,
		},
		{
			title: "Card/Account Number",
			dataIndex: "mask",
			render: (text, record) => (
				<>
					{record?.mask}
				</>
			),
			sorter: true,
		},
		{
			title: "Billing Descriptor",
			dataIndex: "billing_descriptor",
			render: (text, record) => (
				<>
					{record?.billing_descriptor}
				</>
			),
		},
		{
			title: "Status",
			dataIndex: "status",
			render: (text, record) =>
				record.date ? (
					<div className="date-data-container">
						<p className="date-data-text">{capitalizeFirstLetter(record?.status)}</p>
					</div>
				) : (
					""
				),
			filters: [
				{
					text: 'Removed',
					value: 'removed',
				},
				{
					text: 'Uncategorized',
					value: 'uncategorized',
				},
				{
					text: 'Imported',
					value: 'imported',
				},
			],
		},
		{
			title: "Action",
			dataIndex: "action",
			render: (_, record) => (
				<div className="action-container">
					{record?.status === "removed" || record?.status === "imported" ? (<></>) : (
						<>
							<Tooltip>
								<button
									className="btn btn-add me-2"
									onClick={() => handleAddTransactionCLick(record)}
								>
									<FaPlus />
								</button>
							</Tooltip>

							<Tooltip title="Remove Transaction">
								<button onClick={() => { handleTransactionRemove(record); }} className="btn btn-remove me-2">
									<FaMinus />
								</button>
							</Tooltip>
						</>
					)}
				</div>
			),
		},
	];

	useEffect(() => {
		if (data) {
			setCategoryData(data?.categories);
		}
	}, [data]);

	useEffect(() => {
		if (!isLoadingCompany && getCompanyList) {
			setCompanyList(getCompanyList?.data?.companies);
		}
	}, [getCompanyList, isLoadingCompany]);

	useEffect(() => {
		if (getProperties && getProperties?.data) {
			const transformedOptions = getProperties?.data?.map((item) => ({
				label: item.name_of_property,
				value: item.id,
			}));
			setLoading(false)
			setProperties(transformedOptions);
		}
		if (getUtilityCategory && getUtilityCategory?.data) {
			const transformedOptions = getUtilityCategory?.data?.map((item) => ({
				label: item.name,
				value: item.id,
			}));
			setUtilityCategory(transformedOptions);
		}

	}, [getProperties, getUtilityCategory]);

	const handleFinish = async (value) => {
		setIsSaving(true);
		try {
			if (isSplitPayment) {
				const propertyAmount = Object.entries(propertyAmounts).map(([key, amount]) => ({
					property_id: parseInt(key, 10),
					amount: parseFloat(amount) || 0,
					payment_type_id: value.expense_type?.value
				}));

				const finalValues = {
					category_id: value?.category_id?.value !== undefined ? value.category_id.value : value.category_id,
					company_id: value.company_id?.value,
					is_split: 1,
					start_date: dayjs(linkData?.date).format("DD-MM-YY"),
					propertyAmount,
					provider_type_id: value.utilityCategory?.value,
					amount: linkData?.amount,
					transaction_id: linkData?.transaction_id,
					stream_id: linkData?.stream_id,
					payment_date: linkData?.payment_date,
					// next_payment_date: linkData?.next_payment_date,
					frequency: linkData?.frequency,
					transaction_id: splitTransactionId
				};

				delete finalValues.propertyAmounts;

				const response = await addNewExpense(finalValues);
				if (response.data) {
					toast.success(response.data.message);
					fetchData();
					form.resetFields();
					setPropertyAmounts({});
					setIsModalOpen(false);
					setIsSplitPayment(false);
				} else {
					setIsSplitPayment(false);
					toast.error(`${response?.error?.data?.message}`);
				}
			} else {
				let rowData = Array.isArray(selectedRowsData) && selectedRowsData.length > 0 ? selectedRowsData : [linkData];

				let expense = rowData?.map((item) => ({
					amount: item.amount,
					start_date: item.start_date
						? dayjs(item.start_date).format("MM-DD-YY")
						: dayjs(item.date).format("MM-DD-YY"),
					payment_type_id: value.expense_type?.value,
					payment_type: value.expense_type?.value,
					transaction_id: item?.transaction_id
				}));

				let formData = {
					provider_type_id: value.utilityCategory.value,
					property_id: value.properties.value,
					company_id: value.company_id?.value,
					expenses: expense,
					category_id: value?.category_id?.key ? value?.category_id?.key : value?.category_id,
					amount: linkData?.amount,
					start_date: dayjs(linkData?.date).format("DD-MM-YY"),
					transaction_id: linkData?.transaction_id,
					stream_id: linkData?.stream_id,
					payment_date: linkData?.payment_date,
					// next_payment_date: linkData?.next_payment_date,
					frequency: linkData?.frequency
				};

				const response = await addNewExpense(formData);
				if (response.data) {
					toast.success(response?.data?.message);
					fetchData();
					form.resetFields();
					setIsModalOpen(false);
					setIsSplitPayment(false);
				} else {
					toast.error(`${response?.error?.data?.message}`);
				}
			}

		} catch (error) {
			console.error(error);
			toast.error("An error occurred. Please try again.");

		} finally {
			setLinkData()
			setIsSaving(false)
		}
	};

	const getTransactions = async () => {
		try {
			const response = await addRecurringTransaction();
			if (response?.data?.data?.length === 0) {
				swal({
					title: "Alert",
					text: `${response.data.message}`,
					// icon: "warning",
					dangerMode: true,
					buttons: {
						confirm: {
							text: "Ok",
							value: true,
							visible: true,
							className: "custom-confirm-btn",
							closeModal: true,
						},
					},
				})
			}
			fetchData()
		} catch (error) {
			console.log(error, 'error')
		}
	};

	const handleSplitPaymentChange = (e) => {
		setIsSplitPayment(e.target.checked);
		if (!e.target.checked) {
			setPropertyAmounts({});
		}
	};

	const closeAndReset = () => {
		form.resetFields();
		setSelectedProviderType(null);
		setIsModalOpen(false);
		setIsSplitPayment(false);
	}

	const handleDropdownVisibleChange = (value) => {
		if (value) {
			getCategoryListing({ search_term: value });
		}
	};

	const handleCompanyChange = async (data) => {
		let findSelectedCategory = companyList?.find((item) => data.value === item.id);
		if (findSelectedCategory && findSelectedCategory.category) {
			setLoader(true);
			let categoryData = await getCategoryListing({ search_term: findSelectedCategory.category.name });
			if (categoryData?.data?.categories?.length > 0) {
				let findRealCateogory = categoryData?.data?.categories.find((item) => item?.id === findSelectedCategory?.category.id);
				if (findRealCateogory) {
					form.setFieldsValue({
						'category_id': findRealCateogory.id,
					});
					setLoader(false);
				} else {
					form.setFieldsValue({
						'category_id': null,
					});
					setLoader(false);
				}
			} else {
				setLoader(false);
			}
		} else {
			form.setFieldsValue({
				'category_id': null,
			});
			setLoader(false);
		}
	}

	const renderAmount = () => {
		let amount = 0;
		if (selectedRowsData?.length > 0) {
			amount = selectedRowsData.reduce((sum, row) => sum + (row.amount || 0), 0).toFixed(2);
		} else {
			amount = linkData?.amount?.toFixed(2);
		}

		return amount;
	};

	const onSuccess = useCallback(
		async (public_token) => {
			try {
				const response = await setAccessToken({ public_token });

				if (response.error) {
					toast.error(response.error.data.message);
				} else {
					fetchData()
					toast.success('Account Connected');
				}
			} catch (error) {
				toast.error('Error connecting account');
			}
		},
		[setAccessToken, refetch, navigate]
	);

	const config = {
		token: linkToken,
		onSuccess,
	};

	const { open, ready } = usePlaidLink(config);

	const handleAddCompany = async () => {
		let formData = {
			name: inputValue
		}
		try {
			const response = await addNewCompany(formData);
			if (response.data) {
				let newCompanyData = await refetchCompanyList();
				setCompanyList(newCompanyData?.data?.data?.companies);
				let findNewCompany = newCompanyData?.data?.data?.companies?.find((item) => item?.name === inputValue);
				form.setFieldsValue({
					"company_id": findNewCompany?.id,
					"category_id": null
				});
				setDropdownOpen(false);
				toast.success("Company Added");
			} else {
				toast.error(`${response?.error?.data?.message}` || "Failed to add new company!");
			}
		} catch (error) {
			console.error(error);
			toast.error(error);
		}
	}

	const handleCheckboxChange = (record) => {
		const newSelectedRowKeys = selectedRowKeys.includes(record.transaction_id)
			? selectedRowKeys.filter(key => key !== record.transaction_id)
			: [...selectedRowKeys, record.transaction_id];

		const newSelectedRowsData = selectedRowKeys.includes(record.transaction_id)
			? selectedRowsData.filter(row => row.transaction_id !== record.transaction_id)
			: [...selectedRowsData, record];

		setSelectedRowKeys(newSelectedRowKeys);
		setSelectedRowsData(newSelectedRowsData);
	};

	const handleSearchChange = (e) => {
		setCurrentPage(1)
		setSearchText(e?.target?.value || null);
	};

	useEffect(() => {
		if (searchText !== "" && searchText !== undefined && searchText !== null) {
			const handler = setTimeout(() => {
				setDebouncedSearch(searchText);
			}, 1500);

			return () => clearTimeout(handler);
		} else {
			setDebouncedSearch(searchText);
		}
	}, [searchText]);

	const handleChangePage = (page, size) => {
		setCurrentPage(page);
		setPageSize(size)
	}

	useEffect(() => {

		if (exportPageSize) {
			handleTransactionsDataExport()
		}

	}, [exportPageSize])


	const handleTransactionsDataExport = async () => {
		setExportLoader(true);
		try {
			const response = await refetch();
			const transactions = response?.data?.data || [];

			const csvData = transactions.map((transaction, index) => {
				return {
					"S.NO": index + 1,
					"Transaction Name": transaction?.name,
					"Amount": transaction?.amount,
					"Category": transaction?.category_name,
					"Transaction Date": dayjs(transaction?.date).format("MM-DD-YYYY"),
					"Status": capitalizeFirstLetter(transaction?.status),
					"Card/Account No.": transaction?.mask
				};
			});

			const csvRows = [
				['S.NO', 'Transaction Name', "Amount", 'Category', 'Transaction Date', 'Status'],
				...csvData.map(item => [
					item['S.NO'],
					item["Transaction Name"],
					item["Amount"],
					item["Category"],
					item["Transaction Date"],
					item["Status"],
				])
			];

			const csvString = csvRows.map(row => row.join(',')).join('\n');
			const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
			saveAs(blob, 'recurring_transactions.csv');
		} catch (error) {
			console.error("Error exporting data: ", error);
		}
		setExportLoader(false);
		setExportPageSize(null)
	};

	return (
		<>
			{loading && (
				<div className="commanLoaderWrapper">
					<Spin size="large" />
					<h4>Setting things up..</h4>
				</div>
			)}
			<TopHeader
				title={<Title title="Recurring Transactions" id="recurring_transaction" />}
				search={
					<Input.Search
						placeholder="Search..."
						allowClear
						value={searchText}
						onChange={handleSearchChange}
						className="me-3 tableSearchInput"
					/>
				}
				button={
					<>
						{dataSource?.length ? (
							selectedRowKeys?.length > 1 ||
								selectedRowKeys?.length === dataSource?.length ? (
								<Button
									type="primary"
									style={{
										backgroundColor: colors?.primary || "#F47A3A", marginRight: '1rem'
									}}
									onClick={() => setIsModalOpen(true)}
									className="step-one"
								>
									{"Add your transaction"}
								</Button>
							) : (
								""
							)
						) : (
							""
						)}
						<Button
							type="primary"
							style={{
								backgroundColor: colors?.primary || "#F47A3A",
							}}
							onClick={() => getTransactions()}
							className="customHeaderBtn step-two"
						>
							{"Refresh transaction"}
						</Button>
					</>
				}
			/>
			<div className="customPadding">
				<div className="breadcrumbWrapper">
					<Breadcrumb
						items={[
							{
								title: (
									<span onClick={() => navigate('/dashboard')} style={{ cursor: 'pointer' }}>
										<HomeOutlined />
									</span>
								),
							},
							{
								title: (
									<>
										<span>Recurring Transaction</span>
									</>
								),
							},
						]}
					/>
				</div>
				<div className="mainWrapper">
					<div className="card titleCard mb-4">
						<div className="card-body-wrapper">
							<div className="table-responsives step-three" style={{ paddingBottom: '3.5rem' }}>
								{categorizePlaidData?.total_transactions === 0 && parent_id === null ? (
									<Alert
										message="Transactions are associated with your bank accounts, since you have not connect your bank account. Let's connect accounts now."
										type="warning"
										showIcon
										closable
										className="propertyAlert"
										action={
											<Button size="small" type="text" variant="filled" onClick={() => open()} className="step-two">
												Link Account
											</Button>
										}
									/>
								) : (<></>)}
								{isLoadingPlaid || localLoading || loadingRecurring ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
									<Spin />
								</div> :
									<>
										{totalData > 0 ? (<><div className="export-data-wrapper d-flex justify-content-end align-items-center"><Button icon={<GrDocumentCsv />} loading={exportLoader} onClick={() => setExportPageSize(totalData)} className="export-data-button viewButton mb-3">Export</Button></div></>) : (<></>)}
										<Table
											className="table-bordered"
											rowKey={(record) => record.key}
											columns={columns}
											dataSource={dataSource || []}
											scroll={{ x: '100%' }}
											pagination={false}
											loading={isLoading || isLoadingPlaid}
											onChange={(pagination, filters, sorter) => {
												if (sorter.order) {
													if (filters?.status) {
														setFilter(filters?.status)
													} else {
														setFilter(null)
													}
													const columnTitle = columns.find(col => col.dataIndex === sorter.field)?.dataIndex;
													setColumnName(columnTitle);

													if (sorter.order === "ascend") {
														setTableSortBy("asc");
													} else {
														setTableSortBy("desc");
													}
												}
											}}
										/>
										{totalData > 20 ? (
											<Pagination
												className="mt-4"
												total={totalData}
												pageSize={pageSize}
												current={currentPage}
												onChange={handleChangePage}
												pageSizeOptions={pageSizeOptions}
												showSizeChanger
												locale={{
													items_per_page: `/${totalData || 0} Records`,
												}}
											/>
										) : (<></>)}
									</>
								}
							</div>
						</div>

					</div>
				</div>
			</div>

			<Modal
				className="deleteModal addTransCustomModal"
				open={isModalOpen}
				onCancel={() => closeAndReset()}
				footer={false}
				title={
					<>
						Add your transaction

						<div style={{ marginTop: '8px', fontWeight: 700, width: 'fit-content', fontSize: '1rem' }}>
							${renderAmount()}
						</div>
					</>
				}>
				<p className="transaction-name">Transaction Name - {linkData?.name}</p>
				{isLoadingForm ? (
					<Spin style={{ display: "flex", justifyContent: "center", margin: "20px 0" }} />
				) : (
					<Form
						form={form}
						layout="vertical"
						onFinish={(values) => {
							const totalSplitAmount = Object.values(propertyAmounts)
								.reduce((sum, amount) => sum + (parseFloat(amount) || 0), 0)
								.toFixed(2);

							const actualAmount = linkData?.amount?.toFixed(2);

							if (isSplitPayment && totalSplitAmount !== actualAmount) {
								toast.error(`The total split payment ($${totalSplitAmount}) must match the actual amount ($${actualAmount}).`);
								return;
							}

							const finalValues = {
								...values,
								propertyAmounts,
							};
							handleFinish(finalValues);
							setIsSplitPayment(false);
						}}
						initialValues={{
							properties: [],
							utilityCategory: [],
							splitPayment: false,
						}}
						className="transactionForm"
					>
						<div className="accountNumberWrapper">
							<Form.Item
								label="Card / Account No."
								name="mask"
							>
								<Input disabled className="form-control" />
							</Form.Item>
						</div>

						{/* <div className="accountNumberWrapper">
							<Form.Item
								label="Billing Descriptor"
								name="billing_descriptor"
							>
								<Input disabled className="form-control" />
							</Form.Item>
						</div> */}

						<div className="textRow me-2">
							{!isSplitPayment ? (
								<Form.Item
									label="Property Name"
									className="firstFormItem"
									name="properties"
									rules={[{ required: true, message: "Please select at least one property." }]}
								>
									<Select
										className="formControl select-custom"
										style={{ width: "100%" }}
										placeholder="Select Property"
										labelInValue
										options={properties}
										showSearch
										filterOption={(input, option) =>
											(option?.label ?? "").toLowerCase().includes(input.toLowerCase())
										}
									/>
								</Form.Item>
							) : (
								<Row className="splitRow">
									{properties.map((property) => (
										<Col md={11} xs={24} className="splitCol" key={property.value}>
											<Form.Item label={
												<span style={{
													display: "inline-block",
													maxWidth: "200px",
													whiteSpace: "nowrap",
													overflow: "hidden",
													textOverflow: "ellipsis"
												}}>
													{property.label}
												</span>
											} style={{ marginBottom: '16px' }}>
												<Input
													type="number"
													value={propertyAmounts[property.value] || ''}
													onChange={(e) => {
														setPropertyAmounts({
															...propertyAmounts,
															[property.value]: e.target.value,
														})
													}}
													onKeyPress={(e) => {
														if (!/[0-9.]/.test(e.key) || (e.key === '.' && e.target.value.includes('.'))) {
															e.preventDefault();
														}
													}}
													onPaste={(e) => {
														const paste = (e.clipboardData || window.clipboardData).getData("text");
														if (!/^\d*\.?\d*$/.test(paste)) {
															e.preventDefault();
														}
													}}
													placeholder={`Amount for ${property.label}`}
												/>

											</Form.Item>
										</Col>
									))}
								</Row>
							)}

							<div className="splitPaymentButton mt-2">
								<Checkbox onChange={handleSplitPaymentChange} checked={isSplitPayment ? true : false} disabled={selectedRowsData?.length > 0 ? true : false}>Split Payment</Checkbox>
							</div>

							<Form.Item
								label="Provider Type"
								name="utilityCategory"
								rules={[{ required: true, message: "Please select at least one Provider." }]}
							>
								<Select
									className="formControl select-custom"
									style={{ width: "100%" }}
									placeholder="Provider Type"
									labelInValue
									options={utilityCategory}
									onChange={onChangeProvider}
									showSearch
									filterOption={(input, option) =>
										(option?.label ?? "").toLowerCase().includes(input.toLowerCase())
									}
								/>
							</Form.Item>

							<div className="companyWrapper">
								<Form.Item
									label="Company"
									name="company_id"
									rules={[{ required: true, message: "Please select a company." }]}
								>
									<Select
										className="formControl select-custom"
										style={{ width: "100%" }}
										showSearch
										filterOption={(input, option) =>
											(option?.label ?? "").toLowerCase().includes(input.toLowerCase())
										}
										open={dropdownOpen}
										onSearch={(value) => setInputValue(value)}
										placeholder="Select a company"
										labelInValue
										onChange={(value) => {
											handleCompanyChange(value);
											setDropdownOpen(false);
										}}
										onDropdownVisibleChange={(visible) => setDropdownOpen(visible)}
										options={companyList?.map((item) => ({
											label: item.name,
											value: item.id,
										}))}
										optionRender={(option) => (
											<Space>
												<span role="img" aria-label={option.label}>
													{option.label}
												</span>
											</Space>
										)}
										dropdownRender={(menu) => (
											<>
												{menu}
												{inputValue && !companyList.some((opt) => opt.name.toLowerCase() === inputValue.toLowerCase()) && (
													<div style={{ display: "flex", justifyContent: "space-between", padding: 8 }}>
														<span>Add "{inputValue}"</span>
														<Button type="link" onClick={handleAddCompany} disabled={isLoadingAddCompany}>
															{isLoadingAddCompany ? <Spin size="small" /> : "Add"}
														</Button>
													</div>
												)}
											</>
										)}
									/>
								</Form.Item>
							</div>

							<div className="serviceWrapper" style={{ position: "relative" }}>
								{loader && (
									<div className="select-loader">
										<Spin />
									</div>
								)}
								<Form.Item
									label="Category Name"
									name="category_id"
									rules={[{ required: true, message: "Please select a category." }]}
								>
									<Select
										className={`formControl select-custom ${loader ? "loading" : ""}`}
										style={{ width: "100%" }}
										placeholder="Start typing to search"
										labelInValue
										showSearch
										disabled={loader}
										onSearch={handleDropdownVisibleChange}
										filterOption={(input, option) =>
											(option?.label ?? "").toLowerCase().includes(input.toLowerCase())
										}
										notFoundContent={categoryData?.length === 0 ? null : undefined}
										options={categoryData?.map((item) => ({
											label: item.name,
											value: item.id,
										}))}
									/>
								</Form.Item>
							</div>

							<Form.Item
								label="Amount"
								name="amount"

							>
								<Input
									className="form-control"
									style={{ width: "100%" }}
									placeholder="Amount"
									disabled
								/>
							</Form.Item>

							<Form.Item
								label="Start Date"
								name="start_date"
							>
								<Input
									className="form-control"
									style={{ width: "100%" }}
									placeholder="Start Date"
									type="date"
									disabled
								/>
							</Form.Item>

							<Form.Item
								label="Frequency"
								name="frequency"
							>
								<Select
									className="formControl select-custom"
									style={{ width: "100%" }}
									placeholder="Frequency"
									disabled
								/>
							</Form.Item>

							<Form.Item
								label="Payment Date"
								name="payment_date"
							>
								<Input
									className="form-control"
									style={{ width: "100%" }}
									placeholder="Payment Date"
									type="date"
									disabled
								/>
							</Form.Item>

							<Form.Item
								label="Next Payment Date"
								name="next_payment_date"
							>
								<Input
									className="form-control"
									style={{ width: "100%" }}
									placeholder="Next Payment Date"
									type="date"
									disabled
								/>
							</Form.Item>

						</div>

						<Form.Item>
							<Button type="primary" htmlType="submit" className="authBtn" disabled={isSaving} loading={isLoadingAddExpense || isLoadingAddCompany}>
								Submit
							</Button>
						</Form.Item>

					</Form>
				)}
			</Modal>

		</>
	);
}

export default RecurringTransaction;