import React, { useEffect, useState } from "react";
import {
    Button,
    Form,
    Input,
    Select,
    Row,
    Col,
    DatePicker,
    Space,
    Spin,
    InputNumber
} from "antd";
import { RiContactsLine } from "react-icons/ri";
import { useSelector } from "react-redux";
import dayjs from 'dayjs';
import { GiMoneyStack } from "react-icons/gi";
const { TextArea } = Input;

const DynamicForm = ({
    form,
    fields,
    onFinish,
    buttonName,
    loading,
    ButtonDisable,
    FormInitialValues = {},
    addNewIncome,
    dropdownOpen,
    setDropdownOpen,
    newlyAddedIncome,
    incomeTypeLoading,
    handleAddPaymentType,
    paymentTypeLoading,
    newlyAddedPaymentMethod,
    newDropdownOpen,
    setNewDropdownOpen,
    IncomeNameDataList,
    incomeDropdownOpen,
    setIncomeNameDropdown
}) => {
    const [formValues, setFormValues] = useState({});
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [dropdownPropertyVisible, setDropdownPropertyVisible] = useState(false);
    const colors = useSelector((state) => state?.persistedReducer?.colors);
    const [selectedDate, setSelectedDate] = useState();
    const [inputValue, setInputValue] = useState();
    const [inputPaymentValue, setInputPaymentValue] = useState();
    const [IncomeNameValue, setIncomeNameValue] = useState(null);
    const [amount, setAmount] = useState("");
    const [selectedExpenseType, setSelectedExpenseType] = useState(null);
    const [loaderValue, setLoaderValue] = useState(false);

    let options = Object.values(IncomeNameDataList).map((item) => ({
        value: item.notes,
        label: item.notes,
    }));

    const [dropdownOptions, setDropdownOptions] = useState(options || []);

    const preprocessFormInitialValues = (values) => {
        return {
            ...values,
            start_date: values.start_date ? dayjs(values.start_date, 'YYYY-MM-DD') : null,
            end_date: values.end_date ? dayjs(values.end_date, 'YYYY-MM-DD') : null,
        };
    };

    useEffect(() => {
        if (dropdownOptions?.length === 0) {
            setDropdownOptions(options);
        }
    }, [options]);

    useEffect(() => {
        if (newlyAddedIncome !== null) {
            form.setFieldsValue({
                "category_id": newlyAddedIncome
            })
        }
    }, [newlyAddedIncome]);

    const handleDropdownVisibleChange = (value) => {
        setInputValue(value);
        if (value) {
            setDropdownVisible(true);
        } else {
            setDropdownVisible(false);
        }
    };

    const handlePaymentDropdownChange = (value) => {
        if (value !== true && value !== undefined) {
            setInputPaymentValue(value);
        }
        if (value) {
            setNewDropdownOpen(true);
        } else {
            setNewDropdownOpen(false);
        }
    }

    const handleDropdownVisibleChangeProperty = (open) => {
        setDropdownPropertyVisible(open);
    };

    const handleValuesChange = (allValues) => {
        if (allValues) {
            setFormValues(allValues);
        }
    };

    const handleFinish = (allValues) => {
        const formattedValues = {
            ...allValues,
            start_date: allValues.start_date ? dayjs(allValues.start_date).format("MM-DD-YYYY") : null,
            end_date: allValues.end_date ? dayjs(allValues.end_date).format("MM-DD-YYYY") : null,
        };

        onFinish(formattedValues);
    };

    const handleFieldChange = (field, value, setFormValues) => {
        const updatedFormValues = {
            ...formValues,
            [field?.name]: value?.format ? value.format("YYYY-MM-DD") : value,
        };

        setFormValues(updatedFormValues);

        if (field?.name === "category_id") {
            setSelectedExpenseType(value);
        }
        if (field?.name === 'notes') {
            form.setFieldsValue({
                notes: value
            })
        }
        if (field?.onChange) {
            field.onChange(value);
        }
        setDropdownPropertyVisible(false);
    };

    const disabledDate = (current) => {
        return current && current.year() > dayjs().year();
    };

    // const handleAddIncomeType = () => {
    //     addNewIncome(inputValue);
    // }

    const handleAddPaymentMethod = () => {
        handleAddPaymentType(inputPaymentValue);
        setLoaderValue(true);
        showLoader();
    }

    const formatter = (value) => {
        if (!value || value === '') return '';
        value = value?.toString().replace(/[^\d.]/g, '');
        const parts = value.split('.');
        const integer = parts[0] ? parseInt(parts[0], 10).toLocaleString() : '0';
        const decimal = parts[1] ? `.${parts[1].slice(0, 2)}` : '';
        return `$${integer}${decimal}`;
    };

    const parser = (value) => {
        if (!value) return '';
        return value?.replace(/[$,]/g, '');
    };

    // const formatAmount = (amount) => {
    //     return new Intl.NumberFormat('en-US', {
    //         style: 'currency',
    //         currency: 'USD',
    //     }).format(amount);
    // };

    const handleInputChange = (value) => {
        setAmount(value);
    }

    const handleBlur = () => {
        if (!amount || isNaN(amount)) {
            setAmount('');
            return;
        }
        setAmount(parseFloat(amount).toFixed(2));
    };

    useEffect(() => {
        if (FormInitialValues) {
            form.setFieldsValue({
                'category_id': FormInitialValues?.payment_type_id,
            });
            setSelectedExpenseType(FormInitialValues?.payment_type_id);
        }
    }, [FormInitialValues]);

    useEffect(() => {
        if (newlyAddedPaymentMethod) {
            form.setFieldsValue({
                'payment_mode_id': newlyAddedPaymentMethod
            });
        }
    }, [newlyAddedPaymentMethod]);

    const handleEnterPress = (e, field) => {
        if (e.key === "Enter") {
            e.preventDefault();
            addNewOption(field, IncomeNameValue);
        }
    };

    const addNewOption = (field, value) => {
        if (!value) return;

        const matchedOption = dropdownOptions.find(
            (opt) => opt.label.toLowerCase() === value.toLowerCase()
        );

        if (!matchedOption) {
            const newOption = { value, label: value };
            setDropdownOptions([...dropdownOptions, newOption]);
        }

        handleFieldChange(field, value, setFormValues);
        setIncomeNameDropdown(false);
    };

    const showLoader = () => {
        setTimeout(() => {
            setLoaderValue(false);
        }, 2000);
    }

    return (
        <>

            <Form
                form={form}
                layout="vertical"
                onFinish={handleFinish}
                onValuesChange={handleValuesChange}
                initialValues={FormInitialValues ? preprocessFormInitialValues(FormInitialValues) : formValues}
            >
                <Row gutter={16}>
                    {fields.map((field, index) => {
                        return (
                            <>
                                <Col
                                    key={field?.name}
                                    md={field?.name === "frequency" && selectedExpenseType !== 2 ? 0 : field?.name === "end_date" && selectedExpenseType !== 2 ? 0 : field?.colSpan || 24}
                                    offset={field?.offset || 0}
                                >
                                    {((field?.component === "input" || !field?.component) &&
                                        field?.type !== "date" && field?.name !== 'amount') && field?.type !== "textarea" && (
                                            <>

                                                <Form.Item
                                                    label={field?.label}
                                                    name={field?.name}
                                                    rules={field?.rules}
                                                    dependencies={field?.dependencies ? [field?.dependencies] : undefined}
                                                >
                                                    <Select
                                                        loading={ButtonDisable}
                                                        className="formControl select-custom"
                                                        disabled={field?.disabled || ""}
                                                        showSearch
                                                        open={incomeDropdownOpen}
                                                        onDropdownVisibleChange={setIncomeNameDropdown}
                                                        placeholder={field?.placeholder}
                                                        options={dropdownOptions}
                                                        onSearch={(value) => setIncomeNameValue(value)}
                                                        onChange={(value) => handleFieldChange(field, value, setFormValues)}
                                                        onKeyDown={(e) => handleEnterPress(e, field)}
                                                        filterOption={(input, option) =>
                                                            option?.label.toLowerCase().includes(input.toLowerCase())
                                                        }
                                                        prefix={
                                                            field?.prefix ? (
                                                                field?.prefix
                                                            ) : (
                                                                <RiContactsLine
                                                                    style={{
                                                                        color: colors?.primary || "#F47A3A",
                                                                        fontSize: "25px",
                                                                    }}
                                                                />
                                                            )
                                                        }
                                                        dropdownRender={(menu) => (
                                                            <>
                                                                {menu}
                                                                {IncomeNameValue &&
                                                                    !dropdownOptions.some(
                                                                        (opt) => opt.label.toLowerCase() === IncomeNameValue.toLowerCase()
                                                                    ) && (
                                                                        <div style={{ display: "flex", justifyContent: "space-between", padding: 8 }}>
                                                                            <span>Add "{IncomeNameValue}"</span>
                                                                            <Button type="link" onClick={() => addNewOption(field, IncomeNameValue)}>
                                                                                Add
                                                                            </Button>
                                                                        </div>
                                                                    )}
                                                            </>
                                                        )}
                                                    />
                                                </Form.Item>
                                                {/* <Form.Item
                                                    label={field?.label}
                                                    name={field?.name}
                                                    rules={field?.rules}
                                                    dependencies={
                                                        field?.dependencies
                                                            ? [field?.dependencies]
                                                            : undefined
                                                    }
                                                >
                                                    <Input
                                                        placeholder={field?.placeholder}
                                                        className="form-control"
                                                        defaultValue={field?.defaultValue}
                                                        disabled={field?.disabled || ""}
                                                        prefix={
                                                            field?.prefix ? (
                                                                field?.prefix
                                                            ) : (
                                                                <RiContactsLine
                                                                    style={{
                                                                        color: colors?.primary || "#F47A3A",
                                                                        fontSize: "25px",
                                                                    }}
                                                                />
                                                            )
                                                        }
                                                        type={field?.type}
                                                        onKeyPress={field?.onKeyPress}
                                                        onPaste={field?.onPaste}
                                                    />
                                                </Form.Item> */}
                                            </>
                                        )}

                                    {field?.name === 'amount' && (
                                        <Form.Item
                                            label={field?.label}
                                            name={field?.name}
                                            rules={[
                                                ...(field?.rules || []),
                                                { pattern: /^\d+(\.\d{1,2})?$/, message: 'Enter a valid amount (max 2 decimal places)' },
                                            ]}
                                            dependencies={field?.dependencies ? [field?.dependencies] : undefined}
                                        >
                                            <InputNumber
                                                onChange={(value) => handleInputChange(value)}
                                                value={amount || ''}
                                                onBlur={handleBlur}
                                                placeholder="Enter Amount"
                                                style={{ width: '100%', padding: '0.51rem' }}
                                                step={0.01}
                                                min={0}
                                                formatter={formatter}
                                                parser={parser}
                                                precision={2}
                                                className="form-control"
                                                prefix={<GiMoneyStack style={{ color: "#F47939", fontSize: "25px" }} />}
                                            />
                                        </Form.Item>
                                    )}

                                    {field?.type === "textarea" && (
                                        <Form.Item
                                            label={field?.label}
                                            name={field?.name}
                                            rules={field?.rules}
                                        >
                                            <TextArea
                                                placeholder={field?.placeholder}
                                                className="form-control"
                                                defaultValue={field?.defaultValue}
                                                disabled={field?.disabled || false}
                                                prefix={field?.prefix}
                                                onKeyPress={field?.onKeyPress}
                                                onPaste={field?.onPaste}
                                                rows={1}
                                            />
                                        </Form.Item>
                                    )}

                                    {field?.type === "date" && (
                                        <>
                                            {field?.name === "start_date" ? (
                                                <Form.Item
                                                    label={field?.label}
                                                    name={field?.name}
                                                    rules={field?.rules}
                                                >
                                                    <DatePicker
                                                        key={index}
                                                        format="MM/DD/YYYY"
                                                        className="form-control customDatepicker"
                                                        placeholder={field?.placeholder}
                                                        onChange={(date) =>
                                                            handleFieldChange(field, date, setFormValues)
                                                        }
                                                        prefix={field?.prefix}
                                                        value={selectedDate}
                                                        disabled={field?.disabled || ""}
                                                        disabledDate={disabledDate}
                                                    />
                                                </Form.Item>
                                            ) : (
                                                <>
                                                    {selectedExpenseType === 2 ? (
                                                        <Form.Item
                                                            label={field?.label}
                                                            name={field?.name}
                                                            rules={field?.rules}
                                                        >
                                                            <DatePicker
                                                                key={index}
                                                                format="MM/DD/YYYY"
                                                                className="form-control customDatepicker"
                                                                placeholder={field?.placeholder}
                                                                prefix={field?.prefix}
                                                                onChange={(date) =>
                                                                    handleFieldChange(field, date, setFormValues)
                                                                }
                                                                value={selectedDate}
                                                                disabled={field?.disabled || ""}
                                                                disabledDate={disabledDate}
                                                            />
                                                        </Form.Item>
                                                    ) : (
                                                        ''
                                                    )}
                                                </>
                                            )}

                                        </>
                                    )}

                                    {field?.component === "select" && field?.name !== "frequency" && field?.name !== "payment_mode_id" && (
                                        <>
                                            {field?.name !== "category_id" ? (
                                                <Form.Item
                                                    label={field?.label}
                                                    name={field?.name}
                                                    rules={field?.rules}
                                                >
                                                    <Select
                                                        id={index}
                                                        dropdownRender={(originNode) => <div>{originNode}</div>}
                                                        open={dropdownPropertyVisible}
                                                        className="formControl select-custom"
                                                        disabled={field.disabled || ""}
                                                        showSearch
                                                        onDropdownVisibleChange={handleDropdownVisibleChangeProperty}
                                                        onSelect={() => setDropdownPropertyVisible(false)}
                                                        onSearch={() => setDropdownPropertyVisible(true)}
                                                        filterOption={(input, option) =>
                                                            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                                                        }
                                                        mode={field.mode || ""}
                                                        optionLabelProp="label"
                                                        placeholder={field?.placeholder}
                                                        options={field?.options}
                                                        suffixIcon={field?.suffixIcon}
                                                        onChange={(value) => handleFieldChange(field, value, setFormValues)}
                                                        optionRender={(option) => (
                                                            <Space>
                                                                <span role="img" aria-label={option.data.value ? option.data.value : option.value}>
                                                                    {option.data.label ? option.data.label : option.label}
                                                                </span>
                                                            </Space>
                                                        )}
                                                    />
                                                </Form.Item>
                                            ) : (
                                                <Form.Item
                                                    label={field?.label}
                                                    name={field?.name}
                                                    rules={field?.rules}
                                                >
                                                    <Select
                                                        id={index}
                                                        open={dropdownOpen}
                                                        onDropdownVisibleChange={(visible) => setDropdownOpen(visible)}
                                                        className="formControl select-custom "
                                                        disabled={field.disabled || ""}
                                                        showSearch
                                                        onSelect={() => setDropdownVisible(false)}
                                                        onSearch={handleDropdownVisibleChange}
                                                        filterOption={(input, option) =>
                                                            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                                                        }

                                                        mode={field.mode || ""}
                                                        optionLabelProp="label"
                                                        placeholder={field?.placeholder}
                                                        options={field?.options}
                                                        suffixIcon={field?.suffixIcon}
                                                        notFoundContent={
                                                            field.options?.length === 0 || !inputValue
                                                                ? null
                                                                : "No matching records found!"
                                                        }
                                                        onChange={(value) => handleFieldChange(field, value, setFormValues)}
                                                        optionRender={(option) => (
                                                            <Space>
                                                                <span role="img" aria-label={option.data.value ? option.data.value : option.value}>
                                                                    {option.data.label ? option.data.label : option.label}
                                                                </span>
                                                            </Space>
                                                        )}
                                                    // dropdownRender={(menu) => (
                                                    //     <>
                                                    //         {menu}
                                                    //         {inputValue &&
                                                    //             field.options?.some((opt) =>
                                                    //                 opt.label.toLowerCase().includes(inputValue.toLowerCase())
                                                    //             ) === false && parent_id === null && (
                                                    //                 <div style={{ display: "flex", justifyContent: "space-between", padding: 8 }}>
                                                    //                     <span>Add "{inputValue}"</span>
                                                    //                     <Button type="link" onClick={handleAddIncomeType} disabled={incomeTypeLoading}>
                                                    //                         {incomeTypeLoading ? <Spin size="small" /> : "Add"}
                                                    //                     </Button>
                                                    //                 </div>
                                                    //             )}
                                                    //     </>
                                                    // )}
                                                    />
                                                </Form.Item>
                                            )}
                                        </>
                                    )}

                                    {field?.name === "payment_mode_id" && (
                                        <>
                                            {loaderValue ? (
                                                <div className="ownerLoaderWrapper">
                                                    <Spin />
                                                </div>
                                            ) : ('')}
                                            <Form.Item
                                                label={field?.label}
                                                name={field?.name}
                                                rules={field?.rules}
                                            >
                                                <Select
                                                    loading={ButtonDisable}
                                                    id={index}
                                                    open={newDropdownOpen}
                                                    onDropdownVisibleChange={handlePaymentDropdownChange}
                                                    className="formControl select-custom"
                                                    disabled={field.disabled || ""}
                                                    showSearch
                                                    onSelect={() => setNewDropdownOpen(false)}
                                                    onSearch={handlePaymentDropdownChange}
                                                    filterOption={(input, option) =>
                                                        (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                                                    }
                                                    mode={field.mode || ""}
                                                    optionLabelProp="label"
                                                    placeholder={field?.placeholder}
                                                    options={field?.options}
                                                    suffixIcon={field?.suffixIcon}
                                                    notFoundContent={
                                                        ButtonDisable ? (
                                                            <div style={{ textAlign: "center", padding: "10px" }}>
                                                                <Spin size="small" />
                                                                <div>Loading...</div>
                                                            </div>
                                                        ) : field.options?.length === 0 && !inputPaymentValue ? null : "No matching records found!"
                                                    }
                                                    onChange={(value) => handleFieldChange(field, value, setFormValues)}
                                                    onKeyDown={(e) => {
                                                        if (e.key === "Enter") {
                                                            e.preventDefault();
                                                            let matchedOption;
                                                            if (inputPaymentValue !== false && inputPaymentValue !== "") {
                                                                matchedOption = field.options?.find(
                                                                    (opt) => opt.label.toLowerCase() === inputPaymentValue.toLowerCase()
                                                                );
                                                            }
                                                            if (matchedOption === undefined && inputPaymentValue !== false && inputPaymentValue !== "") {
                                                                handleAddPaymentMethod();
                                                            }
                                                        }
                                                    }}
                                                    optionRender={(option) => (
                                                        <Space>
                                                            <span role="img" aria-label={option.data.value ? option.data.value : option.value}>
                                                                {option.data.label ? option.data.label : option.label}
                                                            </span>
                                                        </Space>
                                                    )}
                                                    dropdownRender={(menu) => (
                                                        <>
                                                            {menu}
                                                            {inputPaymentValue &&
                                                                field.options?.some((opt) =>
                                                                    opt.label.toLowerCase().includes(inputPaymentValue.toLowerCase())
                                                                ) === false && (
                                                                    <div style={{ display: "flex", justifyContent: "space-between", padding: 8 }}>
                                                                        <span>Add "{inputPaymentValue}"</span>
                                                                        <Button type="link" onClick={handleAddPaymentMethod} disabled={paymentTypeLoading}>
                                                                            {paymentTypeLoading ? <Spin size="small" /> : "Add"}
                                                                        </Button>
                                                                    </div>
                                                                )}
                                                        </>
                                                    )}
                                                />
                                            </Form.Item>
                                        </>
                                    )}

                                    {field?.name === "frequency" && (
                                        <>
                                            {selectedExpenseType === 2 ? (
                                                <Form.Item
                                                    label={field?.label}
                                                    name={field?.name}
                                                    rules={field?.rules}
                                                >
                                                    <Select
                                                        id={index}
                                                        dropdownRender={(originNode) => <div>{originNode}</div>}
                                                        className="formControl select-custom"
                                                        disabled={field.disabled || ""}
                                                        filterOption={(input, option) =>
                                                            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                                                        }
                                                        mode={field.mode || ""}
                                                        optionLabelProp="label"
                                                        placeholder={field?.placeholder}
                                                        options={field?.options}
                                                        suffixIcon={field?.suffixIcon}
                                                        onChange={(value) => handleFieldChange(field, value, setFormValues)}
                                                        optionRender={(option) => (
                                                            <Space>
                                                                <span role="img" aria-label={option.data.value ? option.data.value : option.value}>
                                                                    {option.data.label ? option.data.label : option.label}
                                                                </span>
                                                            </Space>
                                                        )}
                                                    />
                                                </Form.Item>
                                            ) : ("")}
                                        </>
                                    )}
                                </Col>
                            </>
                        );
                    })}
                </Row>

                <Row className="formButtons">
                    <Col md={24} className="text-center mt-3">
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="authBtn"
                            disabled={loading ? loading : ButtonDisable}
                        >
                            {loading ? "Loading..." : "Save"}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </>
    );
};

export default DynamicForm;
