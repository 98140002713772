import React, { useEffect, useState, useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {
    useGetCategoryQuery,
    useGetProfitLossQuery,
    useGetTotalExpenseQuery,
    useGetTotalIncomeQuery,
    useGetUtilityCategoryQuery,
    useMonthlyExpenseQuery,
} from "../../slices/getSlice";
import { fetchDetail_Url } from "../../Helper";
import { useSelector } from "react-redux";
import { Select, Spin, Empty } from "antd";
import Title from "../../component/PageTitle";
import axios from "axios";
import { useOutletContext } from "react-router-dom";
import TopHeader from "../../component/Header";
import { HomeOutlined } from '@ant-design/icons';
import { Breadcrumb } from 'antd';
import { useNavigate } from "react-router-dom";

const Report = () => {
    const startDate = useSelector((state) => state.date?.startDate);
    const endDate = useSelector((state) => state.date?.endDate);
    const selectedProperties = useSelector((state) => state.Property?.selectedProperties);
    const [providerType, setProviderType] = useState();
    const [filteredData, setFilteredData] = useState();
    const [incomeType, setIncomeType] = useState();
    const [selectedProperty, setSelectedProperty] = useState([]);
    const { loading, setLoading } = useOutletContext();
    const [selectedGraphType, setSelectedGraphType] = useState();
    const { data: getCategory } = useGetCategoryQuery("income");
    const propertyQuery = selectedProperty?.length > 0 ? `&property_ids=${selectedProperty}` : "";
    const [triggerApi, setTriggerApi] = useState(false);
    const userData = useSelector((state) => state.persistedReducer?.walkthroughData);

    const buildQueryParams = () => {
        let params = [];

        if (startDate) params.push(`start_date=${startDate}`);
        if (endDate) params.push(`end_date=${endDate}`);
        if (propertyQuery) params.push(propertyQuery.replace("&", ""));

        return params.length ? `?${params.join("&")}` : "";
    };

    const queryParams = buildQueryParams();

    const { data: getTotalIncome, isLoading: IncomeLoading, refetch: refetchIncome } =
        useGetTotalIncomeQuery(triggerApi ? queryParams : "", { skip: !triggerApi });

    const { data: getTotalExpense, isLoading: ExpenseLoading, refetch: refetchExpense } =
        useGetTotalExpenseQuery(triggerApi ? queryParams : "", { skip: !triggerApi });

    const { data: getProfitLoss, isLoading: ProfitLossLoading, refetch: refetchProfitLoss } =
        useGetProfitLossQuery(triggerApi ? queryParams : "", { skip: !triggerApi });


    const { data: getUtilityCategory = [] } = useGetUtilityCategoryQuery();
    const { data: getGraphData = [] } = useMonthlyExpenseQuery('both');

    const navigate = useNavigate();

    const [loader, setLoader] = useState(true);
    const [chartLoader, setChartLoader] = useState(true);

    const chartRef = useRef(null);
    const currencyName = useSelector((state) => state?.persistedReducer?.currency);

    useEffect(() => {
        if (selectedProperties?.selectedProperty) {
            setSelectedProperty(selectedProperties?.selectedProperty)
        }
    }, [selectedProperties])

    useEffect(() => {
        if (triggerApi) {
            refetchIncome();
            refetchExpense();
            refetchProfitLoss();
        }
    }, [refetchIncome, refetchExpense, refetchProfitLoss, selectedProperty, triggerApi]);

    useEffect(() => {
        if (getUtilityCategory && getUtilityCategory.data) {
            const transformedOptions = getUtilityCategory.data.map((item) => ({
                label: item.name,
                value: item.id,
            }));
            setLoading(false)
            setProviderType(transformedOptions);
        }

        if (getCategory && getCategory.data) {
            const transformedOptions = getCategory.data.map((item) => ({
                label: item.name,
                value: item.id,
            }));

            setIncomeType(transformedOptions);
        }
    }, [getUtilityCategory, getCategory]);

    const handleSelectIncome = (value) => {
        axios.get(fetchDetail_Url + `monthly-expense?type=${selectedGraphType}&category_id=${value}`)
            .then((res) => {
                const newData = res?.data;
                setFilteredData(newData);

                if (chartRef.current) {
                    chartRef.current.chart.series[0].setData(newData.map(item => item.income_amount));
                    chartRef.current.chart.series[1].setData(newData.map(item => item.expense_amount));
                }
            })
            .catch((err) => {
                console.error(err);
            });
    };

    const handleSelectExpense = (value) => {
        axios.get(fetchDetail_Url + `monthly-expense?type=${selectedGraphType}&provider_type_id=${value}`)
            .then((res) => {
                const newData = res?.data;
                setFilteredData(newData);

                if (chartRef.current) {
                    chartRef.current.chart.series[0].setData(newData.map(item => item.income_amount));
                    chartRef.current.chart.series[1].setData(newData.map(item => item.expense_amount));
                }
            })
            .catch((err) => {
                console.error(err);
            });

    };

    useEffect(() => {
        if (!ProfitLossLoading && !ExpenseLoading && !IncomeLoading) {
            setLoader(false);
        }
    }, [ProfitLossLoading, ExpenseLoading, IncomeLoading]);

    const renderIncomeExpenseData = (key) => {
        if (filteredData && filteredData.length > 0) {
            return filteredData.map(item => item[key]);
        }
        if (getGraphData?.length > 0) {
            return getGraphData.map(item => item[key]);
        }
        return [];
    };

    const nonZeroIncomeValues = getTotalIncome?.filter(property => parseFloat(property.total_income) > 0) || [];
    const numNonZeroIncomeBars = nonZeroIncomeValues.length;
    const dynamicIncomePointWidth = Math.max(10, Math.min(50, 300 / numNonZeroIncomeBars));

    const currencyData = {
        currency: currencyName && currencyName.currency_name,
        symbol: currencyName && currencyName.currency_symbol,
        code: currencyName && currencyName.currency_id === 1 ? "USD" : "INR",
    };

    const locale = currencyData.code === "USD" ? "en-US" : "en-IN";

    const incomeChart = {
        chart: {
            type: "column",
        },
        credits: {
            enabled: false,
        },
        title: {
            text: "Total Income by Property",
        },
        legend: {
            enabled: false,
        },
        plotOptions: {
            series: {
                pointWidth: dynamicIncomePointWidth,
                dataLabels: {
                    enabled: true,
                    formatter: function () {
                        return this.y && this.y > 0 ? `${currencyData.symbol}${this.y.toFixed(2)}` : null;
                    },
                    style: {
                        fontSize: "10px",
                        color: "#000",
                    },
                },
            },
            column: {
                colorByPoint: true,
                groupPadding: 0.1,
                pointPadding: 0.1,
            },
        },
        tooltip: {
            formatter: function () {
                const propertyName = this.x;
                const formattedValue = new Intl.NumberFormat(locale, {
                    style: "currency",
                    currency: currencyData.code,
                }).format(this.y);
                return `<b>${propertyName}</b><br/>Total Income: ${formattedValue}`;
            },
        },
        colors: ["#3A54A5"],
        xAxis: {
            categories: getTotalIncome?.map(property => property.property_name) || [],
            title: {
                text: null,
            },
            labels: {
                style: {
                    fontSize: "12px",
                },
                formatter: function () {
                    const maxLength = 10;
                    const text = this.value;
                    return text.length > maxLength ? text.substring(0, maxLength) + "..." : text;
                },
                useHTML: true,
            },
        },
        yAxis: {
            min: 0,
            title: {
                text: null,
            },
            labels: {
                formatter: function () {
                    return this.value && this.value > 0 ? `${currencyData.symbol}${parseFloat(this.value).toFixed(2)}` : "";
                },
                style: {
                    fontSize: "10px",
                },
            },
        },
        series: [
            {
                name: "Total Income",
                data: getTotalIncome
                    ?.map(property =>
                        property.total_income && property.total_income > 0 ? parseFloat(property.total_income) : null
                    )
                    .filter(val => val !== null) || [],
                color: "#0095FF",
            },
        ],
    };

    useEffect(() => {
        const delayTimer = setTimeout(() => {
            setChartLoader(false);
            setTriggerApi(true);
        }, 1600);

        return () => clearTimeout(delayTimer);
    }, [startDate, endDate]);


    const nonZeroValues = getTotalExpense?.filter(property => parseFloat(property.total_expense) > 0) || [];
    const numNonZeroBars = nonZeroValues.length;

    const dynamicPointWidth = Math.max(10, Math.min(50, 300 / numNonZeroBars));

    const expenseChart = {
        chart: {
            type: "column",
        },
        credits: {
            enabled: false,
        },
        title: {
            text: "Total Expense by Property",
        },
        legend: {
            enabled: false,
        },
        plotOptions: {
            series: {
                pointWidth: dynamicPointWidth,
                dataLabels: {
                    enabled: true,
                    formatter: function () {
                        return this.y > 0 ? `${currencyData.symbol}${this.y.toFixed(2)}` : "";
                    },
                    style: {
                        fontSize: "10px",
                        color: "#000",
                    },
                },
            },
            column: {
                colorByPoint: true,
                groupPadding: 0.1,
                pointPadding: 0.1,
            },
        },
        colors: ["#F47939"],
        xAxis: {
            categories: getTotalExpense?.map(property => property.name_of_property) || [],
            title: {
                text: null,
            },
            labels: {
                style: {
                    fontSize: "12px",
                },
                formatter: function () {
                    const maxLength = 10;
                    const text = this.value;
                    return text.length > maxLength ? text.substring(0, maxLength) + "..." : text;
                },
                useHTML: true,
            },
        },
        tooltip: {
            formatter: function () {
                const propertyName = this.x;
                const formattedValue = new Intl.NumberFormat(locale, {
                    style: "currency",
                    currency: currencyData.code,
                }).format(this.y);
                return `<b>${propertyName}</b><br/>Total Expense: ${formattedValue}`;
            },
        },
        yAxis: {
            min: 1,
            type: "logarithmic",
            title: {
                text: "Total Expense",
            },
            labels: {
                formatter: function () {
                    return `${currencyData.symbol}${parseFloat(this.value).toFixed(2)}`;
                },
                style: {
                    fontSize: "10px",
                },
            },
        },
        series: [
            {
                name: "Total Expense",
                data: getTotalExpense?.map(property =>
                    property.total_expense && property.total_expense > 0 ? parseFloat(property.total_expense) : null
                ).filter(val => val !== null) || [],
                color: "#FF5733",
            },
        ],
    };

    const profitLossChart = {
        chart: {
            type: "column",
        },
        title: {
            text: "Profit and Loss by Property",
        },
        legend: {
            enabled: false,
        },
        xAxis: {
            categories: getProfitLoss?.map((property) => property.property_name) || [],
            labels: {
                style: {
                    fontSize: "12px",
                },
                formatter: function () {
                    const maxLength = 10;
                    const text = this.value;
                    return text.length > maxLength ? text.substring(0, maxLength) + "..." : text;
                },
                useHTML: true,
            },
        },
        yAxis: {
            title: {
                text: "Amount (Profit/Loss)",
            },
            plotLines: [
                {
                    value: 0,
                    color: "black",
                    width: 2,
                    dashStyle: "solid",
                    zIndex: 5,
                },
            ],
            labels: {
                formatter: function () {
                    return `${currencyData.symbol}${this.value}`;
                },
            },
        },
        credits: {
            enabled: false,
        },
        tooltip: {
            formatter: function () {
                const propertyName = this.x;
                const formattedValue = new Intl.NumberFormat(locale, {
                    style: "currency",
                    currency: currencyData.code,
                }).format(this.y);
                return `<b>${propertyName}</b><br/>Amount: ${formattedValue}`;
            },
        },
        series: [
            {
                name: "Profit/Loss",
                data: getProfitLoss?.map((property) => {
                    const profit = parseFloat(property.profit);
                    return {
                        y: profit,
                        color: profit >= 0 ? "green" : "red",
                    };
                }) || [],
                dataLabels: {
                    enabled: true,
                    formatter: function () {
                        return new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                        }).format(this.y);
                    },
                    style: {
                        fontSize: "12px",
                        fontWeight: "bold",
                    },
                },
            },
        ],
    };

    const combinedIncomeExpenseChart = {
        chart: {
            type: "spline",
        },
        title: {
            text: "Monthly Income vs Expense",
        },
        credits: {
            enabled: false,
        },
        xAxis: {
            categories: renderIncomeExpenseData("month"),
            title: {
                text: null,
            },
            labels: {
                style: {
                    fontSize: "12px",
                },
                formatter: function () {
                    const maxLength = 10;
                    const text = this.value;
                    return text.length > maxLength ? text.substring(0, maxLength) + "..." : text;
                },
                useHTML: true,
            },
        },
        tooltip: {
            useHTML: true,
            formatter: function () {
                const seriesName = this.series.name;
                const formattedValue = new Intl.NumberFormat(locale, {
                    style: "currency",
                    currency: currencyData.code,
                }).format(this.y);

                return `<b>${seriesName}</b> for <b>${this.x}</b>: <strong>${formattedValue}</strong>`;
            },
        },
        yAxis: {
            title: {
                text: "Total Amount",
            },
            labels: {
                formatter: function () {
                    return `${currencyData.symbol}${parseFloat(this.value).toFixed(2)}`;
                },
            },
        },
        series: [
            {
                name: "Total Income",
                data: renderIncomeExpenseData("income_total"),
                color: "green",
            },
            {
                name: "Total Expense",
                data: renderIncomeExpenseData("expense_total"),
                color: "red",
            },
        ],
    };


    const graphType = [
        { value: 'income', label: 'Income' },
        { value: 'expense', label: 'Expense' },
    ];

    const showType = async (value) => {
        setSelectedGraphType(null);
        setSelectedGraphType(value);
        axios.get(fetchDetail_Url + `monthly-expense?type=${value}`)
            .then((res) => {
                const newData = res?.data;
                setFilteredData(newData);

                if (chartRef.current) {
                    chartRef.current.chart.series[0].setData(newData.map(item => item.income_amount));
                    chartRef.current.chart.series[1].setData(newData.map(item => item.expense_amount));
                }
            })
            .catch((err) => {
                console.error(err);
            });
    };

    return (
        <>
            {loading && (
                <div className="commanLoaderWrapper">
                    <Spin size="large" />
                    <h4>Setting things up..</h4>
                </div>
            )}

            {loader || chartLoader ? (
                <div className="loaderWrapper">
                    <Spin />
                </div>
            ) : (
                <>
                    <TopHeader title={<Title title="Overall Performance" />} />
                    <div className="customPadding">
                        <div className="breadcrumbWrapper">
                            <Breadcrumb
                                items={[
                                    {
                                        title: (
                                            <span onClick={() => navigate('/dashboard')} style={{ cursor: 'pointer' }}>
                                                <HomeOutlined />
                                            </span>
                                        ),
                                    },
                                    {
                                        title: (
                                            <>
                                                <span>Overall Performance</span>
                                            </>
                                        ),
                                    },
                                ]}
                            />
                        </div>
                        <div className="mainWrapper">
                            <div className="row mt-1">
                                <div className="col-md-6">
                                    <div className="card chartCard mb-4">
                                        <div className="card-body">
                                            {getTotalIncome?.length > 0 ? (
                                                <HighchartsReact highcharts={Highcharts} options={incomeChart} />
                                            ) : (
                                                <Empty description="No data found" />
                                            )}

                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="card chartCard mb-4">
                                        <div className="card-body">
                                            {getTotalExpense?.length > 0 ? (
                                                <HighchartsReact highcharts={Highcharts} options={expenseChart} />
                                            ) : (
                                                <Empty description="No data found" />
                                            )}

                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 pb-4 pt-4">
                                    <div className="card chartCard">
                                        <div className="card-body">
                                            {getProfitLoss?.length > 0 ? (
                                                <HighchartsReact highcharts={Highcharts} options={profitLossChart} />
                                            ) : (
                                                <Empty description="No data found" />
                                            )}

                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="card chartCard mb-4">
                                        <div className="card-body">
                                            <div className="highchart-select d-flex justify-content-end" style={{ marginBottom: '15px' }}>
                                                <Select
                                                    defaultValue={"Select Filter Type"}
                                                    style={{ width: 150 }}
                                                    options={graphType}
                                                    onChange={showType}
                                                />
                                                {selectedGraphType === 'income' && (
                                                    <Select
                                                        defaultValue={"Select Income Type"}
                                                        style={{ width: 150 }}
                                                        options={incomeType}
                                                        onChange={handleSelectIncome}
                                                    />
                                                )}
                                                {selectedGraphType === 'expense' && (
                                                    <Select
                                                        defaultValue={"Select Provider Type"}
                                                        style={{ width: 150 }}
                                                        options={providerType}
                                                        onChange={handleSelectExpense}
                                                    />
                                                )}
                                            </div>
                                            <HighchartsReact highcharts={Highcharts} options={combinedIncomeExpenseChart} ref={chartRef} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default Report;
