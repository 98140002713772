import React, { useState } from "react";
import { Button, Form, Input, Image } from "antd";
import { CiLock } from "react-icons/ci";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import images from "../../constant/images";
import { useUserRegisterMutation } from "../../slices/loginRegisterSlice";
import { MailOutlined } from "@ant-design/icons";
import NewLogo from "../../assets/logo/newLogo.png";

const SignUp = () => {
  const [successMessage, setSuccessMessage] = useState("");
  const [signup] = Form.useForm();
  const { SignUpImage } = images;
  const [userRegister, { isLoading }] = useUserRegisterMutation();

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const onFinish = async (values) => {
    const trimmedValues = {
      email: values.email.trim(),
      password: values.password.trim(),
      password_confirmation: values.password_confirmation.trim(),
    };

    try {
      const response = await userRegister(trimmedValues);
      handleResponse(response);
    } catch (error) {
      handleError();
    }
  };

  const handleResponse = (response) => {
    signup.resetFields();

    if (response.error) {
      toast.error(response.error.data.message);
    } else {
      toast.success("Registration Successful");
      setSuccessMessage(response.data.message);
      resetMessage(setSuccessMessage);
    }
  };

  const handleError = () => {
    toast.error("Incorrect password and email");
  };

  const resetMessage = (setMessage) => {
    setTimeout(() => {
      setMessage("");
    }, 5000);
  };

  return (
    <div className="authWrapper">
      <div className="authInner">
        <div className="container-fluid h-100">
          <div className="row h-100">
            <div className="col-md-6 whiteBg">
              <div className="formWrapper">
                <div className="logoWrap">
                  <Image
                    src={NewLogo}
                    alt="Logo"
                    className="img-fluid"
                    preview={false}
                  />
                </div>
                <div className="titleWrap">
                  <h2 className="authTitle">Create your Account</h2>
                  <p className="authSubTitle">Unlock all features</p>
                </div>
                <div className="formWrap">
                  {successMessage && (
                    <div style={{ color: "#F47A3A", paddingBottom: "1rem" }}>
                      {successMessage}
                    </div>
                  )}
                  <Form
                    form={signup}
                    name="signup"
                    onFinish={onFinish}
                    initialValues={{ rememberMe: false }}
                    layout="vertical"
                  >
                    {/* Email Input */}
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "Please input your email",
                        },
                        {
                          pattern: emailRegex,
                          message: "Please enter a valid email address",
                        },
                      ]}
                      normalize={(value) => value.trim()}
                    >
                      <Input
                        placeholder="Enter Email"
                        className="form-control"
                        prefix={
                          <MailOutlined
                            style={{ color: "#F47939", fontSize: "18px" }}
                          />
                        }
                      />
                    </Form.Item>

                    <Form.Item
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: "Please input your password",
                        },
                        {
                          pattern:
                            /^(?!.*\s)(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                          message:
                            "Password must be at least 8 characters, include one uppercase letter, one lowercase letter, one number, and one special character.",
                        },
                      ]}
                    // normalize={(value) => value.trim()}
                    >
                      <Input.Password
                        placeholder="Enter Password"
                        className="form-control"
                        prefix={
                          <CiLock
                            style={{ color: "rgb(244, 121, 57)", fontSize: "22px" }}
                          />
                        }
                      />
                    </Form.Item>

                    <Form.Item
                      name="password_confirmation"
                      dependencies={["password"]}
                      rules={[
                        {
                          required: true,
                          message: "Please input your confirm password",
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue("password") === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error("The confirm passwords do not match!")
                            );
                          },
                        }),
                      ]}
                    // normalize={(value) => value.trim()}
                    >
                      <Input.Password
                        placeholder="Enter Confirm Password"
                        className="form-control"
                        prefix={
                          <CiLock
                            style={{ color: "#F47939", fontSize: "22px" }}
                          />
                        }
                      />
                    </Form.Item>

                    {/* Submit Button */}
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="authBtn"
                        loading={isLoading}
                      >
                        Sign Up
                      </Button>
                    </Form.Item>
                  </Form>
                </div>

                {/* Bottom Link */}
                <div className="bottomTextRow">
                  <span>Already have an account?</span>
                  <span className="text-wrapper-10">&nbsp;</span>
                  <Link to="/login" className="signUpLink">
                    Sign In
                  </Link>
                </div>
              </div>
            </div>

            {/* Right-Side Image */}
            <div className="col-md-6 mobHide">
              <div className="imgWrapper">
                <SignUpImage />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
