import React, { useState } from "react";
import { Form, Input, Button, Image } from "antd";
import { MailOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { useForgotPasswordMutation } from "../../slices/loginRegisterSlice";
import { saveEmail } from "../../slices/authSlice";
import images from "../../constant/images";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import NewLogo from "../../assets/logo/newLogo.png";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [forgotPassword, { isLoading }] = useForgotPasswordMutation();
  const { Logo, SignInImage } = images;

  const onFinish = async (values) => {
    try {
      const response = await forgotPassword(values);
      if (response.data) {
        setSuccessMessage(response.data.message);
        dispatch(saveEmail({ email: values }));
        setTimeout(() => {
          setSuccessMessage("");
        }, 5000);
      } else {
        setErrorMessage(response.error.data.message);
      }
    } catch (error) {
      setErrorMessage("User not found");
    }
    setTimeout(() => {
      setErrorMessage("");
    }, 5000);
  };

  return (
    <div className="authWrapper">
      <div className="authInner">
        <div className="container-fluid h-100">
          <div className="row h-100">
            <div className="col-md-6 whiteBg">
              {/* <button className="mt-3 authBtn " style={{width:"20%",border:"none", backgroundColor:"rgb(244, 121, 57)", borderRadius:"8px"}}onClick={()=> navigate(-1)}><span><IoMdArrowBack/></span>Back to login</button> */}
              <div className="formWrapper">
                <div className="logoWrap">
                <Image src={NewLogo} alt="Logo" className="img-fluid" preview={false} />
                </div>
                <div className="titleWrap">
                  <h2 className="authTitle">Forgot Password</h2>
                </div>
                <div className="formWrap">
                  {errorMessage && (
                    <div style={{ color: "#F47A3A", paddingBottom: '1rem' }}>{errorMessage}</div>
                  )}
                  {successMessage && (
                    <div style={{ color: "#F47A3A", paddingBottom: '1rem' }}>{successMessage}</div>
                  )}




                  <Form
                    form={form}
                    name="forgot_password"
                    onFinish={onFinish}
                    layout="vertical"
                    autoComplete="off"
                  >
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "Please input your email",
                        },
                        {
                          type: "email",
                          message: "Please enter a valid email"
                        }
                      ]}
                    >
                      <Input
                        prefix={<MailOutlined style={{ color: "#F47939", fontSize: "20px" }} />}
                        placeholder="Enter Email"
                        size="large"
                      />
                    </Form.Item>

                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        loading={isLoading}
                        block
                        size="large"
                        style={{
                          backgroundColor: "#F47939"
                        }}
                      >
                        Send Link
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
                <div className="bottomTextRow">
                  <span>Already have an account?</span>
                  <span className="text-wrapper-10">&nbsp;</span>
                  <Link to='/login' className="signUpLink">Sign In</Link>
                </div>
              </div>
            </div>
            <div className="col-md-6 mobHide">
              <div className="imgWrapper">
                <SignInImage />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;